<template>
  <div
    class="server_time_wrapper"
    :style="{
      left: _left,
      top: _top,
      right: _right,
      bottom: _bottom,
    }"
  >
    {{ store.server_time_data }}
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { computed, onBeforeMount, onBeforeUnmount } from "vue";
import { sendInstructions } from "@/api/sendData";
import { propValueUnificationString } from "@/utils/utils";
const store = useStore();

const props = defineProps({
  left: {
    type: [String, Number],
    default: "auto",
  },
  top: {
    type: [String, Number],
    default: "auto",
  },
  right: {
    type: [String, Number],
    default: "auto",
  },
  bottom: {
    type: [String, Number],
    default: "auto",
  },
});

const _left = computed(() => propValueUnificationString(props.left));
const _top = computed(() => propValueUnificationString(props.top));
const _right = computed(() => propValueUnificationString(props.right));
const _bottom = computed(() => propValueUnificationString(props.bottom));

let getServerTimer = null;
onBeforeMount(() => {
  getServerTimer = setInterval(() => {
    const getTimer = {
      msg_type: "get_server_time",
    };
    sendInstructions(getTimer);
  }, 900);
});

onBeforeUnmount(() => {
  getServerTimer != null && clearInterval(getServerTimer);
});
</script>

<style lang="scss" scoped>
.server_time_wrapper {
  position: absolute;
  font-weight: bold;
  font-size: 12px;
  z-index: 4;
  color: #fff;
  // background: rgba(0, 0, 0, 0.4);
  // transform: translateX();
  transform-origin: center center;
  padding: 4px 16px;
  border-bottom-left-radius: 10px;
}
</style>
