<template>
  <div class="game_loding_wrapper">
    <van-image width="100%" height="100%" :src="lodingImgUrl" />
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { useRouter } from "vue-router";
import { computed } from "vue";

import findGameInfoData from "@/utils/gameInfoMap";

const store = useStore();

const lodingImgUrl = computed(() => {
  return findGameInfoData(
    store.current_machine.child_name || store.current_machine.product_name
  ).load;
});
</script>

<style lang="scss" scoped>
.game_loding_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
</style>
