<template>
  <div
    class="active_rank_wrapper"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <NavBar />

    <div class="rank_wrapper">
      <div class="top_rank_wrapper">
        <div class="top_two">
          <div class="top_container">
            <div class="top_icon">
              <div class="top_head_frame"></div>
              <div class="top_head" v-if="initRankList.topRank[1]">
                <HeadPortrait
                  width="100%"
                  height="100%"
                  :src="initRankList.topRank[1].head"
                />
              </div>
            </div>

            <template v-if="initRankList.topRank[1]">
              <div class="top_user_name">
                {{ decodeBase64String(initRankList.topRank[1].player_name) }}
              </div>
              <div class="top_user_level">
                <Level
                  :border="false"
                  level_type="recharge_level"
                  :level_value="initRankList.topRank[1].recharge_level"
                />
                <Level
                  :border="false"
                  :level_value="initRankList.topRank[1].tianti_level"
                />
              </div>
            </template>

            <template v-else>
              <div class="_text_">无人登榜</div>
            </template>

            <div class="top_rewrad">
              <div class="rank_num" v-if="initRankList.topRank[1]">
                比赛积分： {{ initRankList.topRank[1].num }}
              </div>

              <div
                class="rewrad_type_gold"
                v-if="
                  store.activeData.reward_data_list[1] &&
                  store.activeData.reward_data_list[1].reward_type == 'gold'
                "
              >
                <span style="margin-right: 2px">奖励</span>
                <span>{{ store.activeData.reward_data_list[1].reward }}</span>
                <span class="gold_icon"></span>
              </div>
              <div
                class="rewrad_type_lottery"
                v-else-if="
                  store.activeData.reward_data_list[1] &&
                  store.activeData.reward_data_list[1].reward_type == 'lottery'
                "
              >
                <span style="margin-right: 2px">奖励</span>
                <span>{{ store.activeData.reward_data_list[1].reward }}</span>
                <span class="lottery_icon"></span>
              </div>
              <div
                class="rewrad_type_gift"
                v-else-if="store.activeData.reward_data_list[1]"
              >
                奖励:
                {{ store.activeData.reward_data_list[1].reward.name }}
              </div>
            </div>
            <div
              class="kill_numer"
              v-if="initRankList.topRank[1]"
              @click="lookKillNum(initRankList.topRank[1].record_class)"
            >
              魂卡收集
            </div>
          </div>
        </div>
        <div class="top_one">
          <div class="top_container">
            <div class="top_icon">
              <div class="top_head_frame"></div>
              <div class="top_head" v-if="initRankList.topRank[0]">
                <HeadPortrait
                  width="100%"
                  height="100%"
                  :src="initRankList.topRank[0].head"
                />
              </div>
            </div>

            <template v-if="initRankList.topRank[0]">
              <div class="top_user_name">
                {{ decodeBase64String(initRankList.topRank[0].player_name) }}
              </div>
              <div class="top_user_level">
                <Level
                  :border="false"
                  level_type="recharge_level"
                  :level_value="initRankList.topRank[0].recharge_level"
                />
                <Level
                  :border="false"
                  :level_value="initRankList.topRank[0].tianti_level"
                />
              </div>
            </template>

            <template v-else>
              <div class="_text_">无人登榜</div>
            </template>

            <div class="top_rewrad">
              <div class="rank_num" v-if="initRankList.topRank[0]">
                比赛积分： {{ initRankList.topRank[0].num }}
              </div>

              <div
                class="rewrad_type_gold"
                v-if="
                  store.activeData.reward_data_list[0] &&
                  store.activeData.reward_data_list[0].reward_type == 'gold'
                "
              >
                <span style="margin-right: 2px">奖励</span>
                <span>{{ store.activeData.reward_data_list[0].reward }}</span>
                <span class="gold_icon"></span>
              </div>
              <div
                class="rewrad_type_lottery"
                v-else-if="
                  store.activeData.reward_data_list[0] &&
                  store.activeData.reward_data_list[0].reward_type == 'lottery'
                "
              >
                <span style="margin-right: 2px">奖励</span>
                <span>{{ store.activeData.reward_data_list[0].reward }}</span>
                <span class="lottery_icon"></span>
              </div>
              <div
                class="rewrad_type_gift"
                v-else-if="store.activeData.reward_data_list[0]"
              >
                奖励:
                {{ store.activeData.reward_data_list[0].reward.name }}
              </div>
            </div>
            <div
              class="kill_numer"
              v-if="initRankList.topRank[0]"
              @click="lookKillNum(initRankList.topRank[0].record_class)"
            >
              魂卡收集
            </div>
          </div>
        </div>

        <div class="top_three">
          <div class="top_container">
            <div class="top_icon">
              <div class="top_head_frame"></div>
              <div class="top_head" v-if="initRankList.topRank[2]">
                <HeadPortrait
                  width="100%"
                  height="100%"
                  :src="initRankList.topRank[2].head"
                />
              </div>
            </div>

            <template v-if="initRankList.topRank[2]">
              <div class="top_user_name">
                {{ decodeBase64String(initRankList.topRank[2].player_name) }}
              </div>
              <div class="top_user_level">
                <Level
                  :border="false"
                  level_type="recharge_level"
                  :level_value="initRankList.topRank[2].recharge_level"
                />
                <Level
                  :border="false"
                  :level_value="initRankList.topRank[2].tianti_level"
                />
              </div>
            </template>

            <template v-else>
              <div class="_text_">无人登榜</div>
            </template>

            <div class="top_rewrad">
              <div class="rank_num" v-if="initRankList.topRank[2]">
                比赛积分：{{ initRankList.topRank[2].num }}
              </div>
              <div
                class="rewrad_type_gold"
                v-if="
                  store.activeData.reward_data_list[2] &&
                  store.activeData.reward_data_list[2].reward_type == 'gold'
                "
              >
                <span style="margin-right: 2px">奖励</span>
                <span>{{ store.activeData.reward_data_list[2].reward }}</span>
                <span class="gold_icon"></span>
              </div>
              <div
                class="rewrad_type_lottery"
                v-else-if="
                  store.activeData.reward_data_list[2] &&
                  store.activeData.reward_data_list[2].reward_type == 'lottery'
                "
              >
                <span style="margin-right: 2px">奖励</span>
                <span>{{ store.activeData.reward_data_list[2].reward }}</span>
                <span class="lottery_icon"></span>
              </div>
              <div
                class="rewrad_type_gift"
                v-else-if="store.activeData.reward_data_list[2]"
              >
                奖励：
                {{ store.activeData.reward_data_list[2].reward.name }}
              </div>
            </div>
            <div
              class="kill_numer"
              v-if="initRankList.topRank[2]"
              @click="lookKillNum(initRankList.topRank[2].record_class)"
            >
              魂卡收集
            </div>
          </div>
        </div>
      </div>

      <p style="color: #fbe6a3; text-align: center; padding: 10px 0">
        · 榜单实时更新 最终排名以比赛终结时间为准 ·
      </p>

      <div class="rank_list">
        <div
          class="rank_item"
          v-for="(rankItem, rankIndex) in initRankList.otherRankList"
        >
          <div class="ranking">
            <div class="ranking_num">{{ rankIndex + 4 }}</div>
          </div>
          <template v-if="rankItem">
            <div class="rank_content">
              <div class="rank_user_head">
                <HeadPortrait
                  width="100%"
                  height="100%"
                  :level="rankItem.recharge_level"
                  :src="rankItem.head"
                  ae
                  fe
                  :ae_id="rankItem.select_title"
                />
              </div>

              <div class="rank_user_info">
                <div class="rank_user_name">
                  {{ decodeBase64String(rankItem.player_name) }}
                </div>
                <div class="rank_user_level">
                  <Level
                    :border="false"
                    level_type="recharge_level"
                    :level_value="rankItem.recharge_level"
                  />
                  <Level :border="false" :level_value="rankItem.tianti_level" />
                </div>
              </div>
            </div>
            <div class="rank_user_kill_num">
              <span
                class="rank_user_look_kill"
                @click="lookKillNum(rankItem.record_class)"
                >魂卡收集</span
              >
            </div>
          </template>
          <div class="empty_wrapper" v-else>暂无人登榜 ,迅速抢占！</div>

          <div class="rank_reward">
            <div class="rank_num" v-if="initRankList.otherRankList[rankIndex]">
              比赛积分：{{ rankItem.num }}
            </div>
            <template v-if="store.activeData.reward_data_list[rankIndex + 3]">
              <div
                class="rank_reward_type_gold"
                v-if="
                  store.activeData.reward_data_list[rankIndex + 3]
                    .reward_type == 'gold'
                "
              >
                <span
                  >奖励：{{
                    store.activeData.reward_data_list[rankIndex + 3].reward
                  }}</span
                >
                <div class="gold_icon"></div>
              </div>
              <div
                class="rank_reward_type_lottery"
                v-else-if="
                  store.activeData.reward_data_list[rankIndex + 3]
                    .reward_type == 'lottery'
                "
              >
                <span
                  >奖励：{{
                    store.activeData.reward_data_list[rankIndex + 3].reward
                  }}</span
                >
                <div class="lottery_icon"></div>
              </div>
              <div class="rank_reward_type_gift" v-else>
                奖励：{{
                  store.activeData.reward_data_list[rankIndex + 3].reward.name
                }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model:show="ruleShow" style="background: none">
      <div class="rule_wrapper">
        <div class="close_icon" @click="lookRuleShowToggle(false)"></div>
        <div class="rule_title">击杀数量</div>
        <div class="rule_content">
          <div class="kill_card_list">
            <div class="kill_card_item" v-for="cardItem in lookKillList">
              <div class="kill_card_img">
                <van-image
                  width="100%"
                  height="100%"
                  block
                  :show-loading="false"
                  :src="cardItem.url"
                />
              </div>
              <div class="kill_card_name">
                <p>{{ cardItem.cardName }}</p>
                <p class="kill_card_num">x{{ cardItem.num }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import collectClassIDs from "@/utils/collectClassIDs";
import { useStore } from "@/store/store";
import HeadPortrait from "@/components/HeadPortrait.vue";
import NavBar from "@/components/NavBar.vue";
import Level from "@/components/Level.vue";
import { decodeBase64String } from "@/utils/utils.js";
import { useRoute } from "vue-router";
import {
  onBeforeMount,
  ref,
  onBeforeUnmount,
  reactive,
  watch,
  onMounted,
} from "vue";
import { asyncSendInstructions } from "@/api/sendData";
import { showToast, closeToast } from "vant";
const store = useStore();
const route = useRoute();
const initRankList = reactive({
  topRank: [null, null, null],
  otherRankList: [null, null, null, null, null, null, null],
});

const initRankListData = (res) => {
  if (!Array.isArray(res) || res.length == 0) return;

  const _num = route.query.rank_num ?? 10;

  const list = [null, null, null];
  const topList = [null, null, null, null, null, null, null];
  res.length > _num && (res.length = _num);

  for (let i = 0; i < res.length; i++) {
    const item = res[i];
    if (i <= 2) topList[i] = item;
    if (i > 2 && i < 10) list[i - 3] = item;
    if (i >= 10) list.push(item);
  }

  initRankList.topRank = topList;
  initRankList.otherRankList = list;
};

const ruleShow = ref(false);
const lookRuleShowToggle = (type) => {
  ruleShow.value = type;
};

const getRankListData = async () => {
  showToast({
    type: "loading",
    duration: 0,
    forbidClick: true,
  });
  try {
    const res = await asyncSendInstructions({
      msg_type: "get_shipin_active_rank",
      game_name: store.activeData.game_name,
      active_type: store.activeData.active_type,
      rank_type: "ranking",
      active_index: store.activeData.active_index,
    });

    initRankListData(res);
    closeToast();
  } catch (error) {
    closeToast();
  }
};

const lookKillList = ref([]);
const lookKillNum = (list) => {
  const _list = [];
  for (const key in list) {
    _list.push({
      ...collectClassIDs[store.activeData.game_name][key],
      num: list[key],
    });
  }

  lookKillList.value = _list;
  lookRuleShowToggle(true);
};

onBeforeMount(() => {});

onMounted(() => {
  getRankListData();
});

onBeforeUnmount(() => {});
</script>

<style lang="scss" scoped>
.active_rank_wrapper {
  width: 100vw;
  min-height: 100vh;
  background-image: url("@/assets/images/active_rank_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #2f155a;
}

.rule_wrapper {
  width: 306px;
  height: 447px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/tutor_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 60px 26px 30px 26px;

  .rule_title {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0%);
    height: 52px;
    line-height: 56px;
    width: 188px;
    font-size: 16px;
    font-weight: 1000;
    text-align: center;

    background-image: url("@/assets/images/challenge/guize_title.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .close_icon {
    position: absolute;
    right: 12px;
    top: 12px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
  }

  .rule_content {
    height: 100%;
    overflow: auto;
    .kill_card_list {
      display: flex;
      flex-wrap: wrap;
      background: linear-gradient(
          270deg,
          #9510ce 0%,
          rgba(149, 16, 206, 0.37) 100%
        ),
        linear-gradient(90deg, #9211cc 0%, #7d17b2 32%, #223087 100%);
      border-radius: 10px;
      .kill_card_item {
        position: relative;
        width: 50%;
        height: 56px;
        display: flex;
        padding: 12px 0 0 20px;

        border-bottom: 1px solid #2f155a;

        &:nth-child(odd) {
          &::after {
            content: "";
            position: absolute;
            right: -1px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 32px;
            background-color: #2f155a;
          }
        }

        .kill_card_img {
          width: 32px;
          height: 32px;
          border: 1px solid #f1ae42;
          border-radius: 4px;
        }

        .kill_card_name {
          flex: 1;
          padding-left: 8px;
          // text-align: center;
          font-size: 12px;
          .kill_card_num {
            color: #fee22a;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.rank_wrapper {
  padding: 0 15px;
  .top_rank_wrapper {
    display: flex;
    min-height: 200px;
    justify-content: space-around;

    .top_one,
    .top_two,
    .top_three {
      position: relative;
      width: 100px;
      padding-top: 80px;

      .top_container {
        position: relative;
        width: 100%;
        min-height: 100px;
        padding-top: 46px;
        border-top: 1px solid;
        border-left: 1px solid;
        border-right: 1px solid;
        border-bottom: none;
        background: linear-gradient(180deg, #4e1c77 0%, #2f155a 100%);
        border-image: linear-gradient(
            180deg,
            rgba(251, 230, 163, 1),
            rgba(47, 21, 90, 1)
          )
          1 1;

        .top_icon {
          position: absolute;
          width: 100%;
          height: 124px;
          top: -80px;
          .top_head_frame {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url("@/assets/images/rank_top_1.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            z-index: 2;
          }
          .top_head {
            position: absolute;
            left: 14px;
            top: 26px;
            // transform: translate(-50%, 0);
            width: 72px;
            height: 72px;
            z-index: 1;
          }
        }

        .top_user_name {
          font-size: 10px;
          text-align: center;
          white-space: nowrap;
          text-wrap: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .top_user_level {
          margin-top: 4px;
          display: flex;
          justify-content: center;
          align-content: center;
        }

        ._text_ {
          margin-top: 4px;
          font-size: 10px;
          text-align: center;
        }

        .top_rewrad {
          font-size: 10px;
          color: #fee22a;
          .rewrad_type_gold {
            margin-top: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            .gold_icon {
              width: 12px;
              height: 12px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
          }
          .rewrad_type_lottery {
            margin-top: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            .lottery_icon {
              width: 12px;
              height: 12px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/ticket.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
          }
          .rewrad_type_gift {
            margin-top: 4px;
            text-align: center;
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .rank_num {
            padding-top: 4px;
            color: #fff;
            text-align: center;
            font-size: 10px;
            white-space: nowrap;
          }
        }

        .kill_numer {
          margin-top: 4px;
          width: 44px;
          height: 16px;
          line-height: 16px;
          margin: 0 auto;
          background: linear-gradient(180deg, #a88bff 11%, #7e5df1 100%);
          text-align: center;
          border-radius: 8px;
          font-size: 8px;
        }
      }
    }

    .top_one {
      .top_container {
        background: linear-gradient(
          180deg,
          #c822ca 0%,
          rgba(87, 36, 112, 0) 100%
        );
        border-image: linear-gradient(
            180deg,
            rgba(251, 230, 163, 1),
            rgba(86, 35, 112, 0)
          )
          1 1;
      }
    }
    .top_two {
      margin-top: 14px;
      .top_container {
        background: linear-gradient(
          180deg,
          #9510ce 0%,
          rgba(59, 21, 101, 0) 100%
        );
        border-image: linear-gradient(
            180deg,
            rgba(251, 230, 163, 1),
            rgba(74, 26, 108, 0)
          )
          1 1;

        .top_icon {
          .top_head_frame {
            background-image: url("@/assets/images/rank_top_2.png");
          }
        }
      }
    }
    .top_three {
      margin-top: 22px;
      .top_container {
        background: linear-gradient(
          180deg,
          #223087 0%,
          rgba(45, 17, 94, 0) 100%
        );
        border-image: linear-gradient(
            180deg,
            rgba(251, 230, 163, 1),
            rgba(43, 17, 92, 0)
          )
          1 1;

        .top_icon {
          .top_head_frame {
            background-image: url("@/assets/images/rank_top_3.png");
          }
        }
      }
    }
  }

  .rank_list {
    .rank_item {
      display: flex;
      padding: 8px 10px;
      .ranking {
        color: #fff;
        font-size: 14px;
        width: 22px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ranking_num {
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          border: 1px solid #fff;
          border-radius: 10px;
        }
      }

      .empty_wrapper {
        flex: 1;
        line-height: 38px;
        text-align: center;
        padding-left: 10px;
      }

      .rank_content {
        width: 138px;
        display: flex;
        .rank_user_head {
          width: 38px;
          height: 38px;
          margin-right: 4px;
        }
        .rank_user_info {
          // flex: 1;
          .rank_user_name {
            max-width: 100px;
            overflow: hidden;
            text-wrap: nowrap;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .rank_user_kill_num {
        // margin-left: 20px;
        margin: 0 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50px;
        font-size: 10px;

        .rank_user_look_kill {
          width: 44px;
          height: 16px;
          line-height: 16px;
          background: linear-gradient(180deg, #a88bff 11%, #7e5df1 100%);
          border-radius: 10px;
          text-align: center;
          font-size: 8px;
        }
      }

      .rank_reward {
        min-width: 70px;
        max-width: 92px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .rank_num {
          white-space: nowrap;
        }

        .rank_reward_type_gold {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fee22a;
          .gold_icon {
            margin-left: 2px;
            width: 16px;
            height: 16px;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
        .rank_reward_type_lottery {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fee22a;
          .lottery_icon {
            margin-left: 2px;
            width: 16px;
            height: 16px;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/ticket.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
        .rank_reward_type_gift {
          color: #fee22a;
        }
      }

      &:nth-child(1) {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      &:nth-child(odd) {
        background: linear-gradient(
          90deg,
          #272778 0%,
          rgba(39, 39, 120, 0.2) 100%
        );
      }
      &:nth-child(even) {
        background: linear-gradient(
          90deg,
          #5d138e 0%,
          rgba(93, 19, 142, 0.2) 100%
        );
      }
    }
  }
}
</style>
