<template>
  <div id="player_wrapper" ref="_Player_wrapper">
    <video
      id="jswebrtc"
      ref="jswebrtc"
      playsinline
      webkit-playsinline
      style="width: 100%; height: 100%; object-fit: fill"
    ></video>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { onMounted, onBeforeUnmount, watch, ref } from "vue";
import { showToast } from "vant";
import { useCountDown } from "@vant/use";
import { useRouter } from "vue-router";
const router = useRouter();
const store = useStore();
const props = defineProps({
  landscape: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits(["readyChange", "errorCallBack"]);

const player = ref(null);
const _Player_wrapper = ref();
const isReady = ref(false);

const initVideoCountDown = useCountDown({
  // 倒计时
  time: 1e4,
  onFinish() {
    if (!isReady.value) {
      showToast({
        type: "fail",
        forbidClick: true,
        duration: 3000,
        message: "画面加载超时\n请稍后重试",
        teleport: store.isLandscape ? "#rotate_box" : "body",
        onClose() {
          router.replace("/home");
        },
      });
    }
  },
  onChange(current) {
    // if (current.seconds == 8) {
    //   handlePlayUpdata();
    // }
  },
});

// watch(
//   () => props.soundValue,
//   (newV) => {
//     if (player.value != null) {
//       player.value.options.video.muted = !player.value.options.video.muted;
//     }
//   }
// );

const videoMuted = (statu = false) => {
  // if (player.value.options.video.muted == false) return;
  setTimeout(() => {
    player.value.options.video.volume = 1;
    player.value.options.video.muted = statu;
  }, 50);
};

const calculateVideoStyle = (videoWidth = 0, videoHeight = 0) => {
  const viewWidth = window.innerWidth;
  const viewHeight = window.innerHeight;

  let scaleX = 1;
  let scaleY = 1;
  let rotate = 0;
  let gameViewWidth = 0;
  let gameViewHeight = 0;

  let translateY = 0;
  const scaleNumber = videoWidth / videoHeight;

  if (props.landscape) {
    rotate = 90;
    translateY = 100;
    gameViewHeight = parseInt(viewWidth);
    gameViewWidth = parseInt(gameViewHeight * scaleNumber);
    scaleX = Number((viewHeight / gameViewWidth).toFixed(3));
  }

  if (!props.landscape) {
    gameViewWidth = viewWidth;
    gameViewHeight = parseInt(viewWidth / scaleNumber);
    scaleY = Number((viewHeight / gameViewHeight).toFixed(3));
    translateY = 0;
  }

  const transformStyle = `rotate(${rotate}deg) scaleX(${scaleX}) scaleY(${scaleY}) translateY(-${translateY}%)`;
  return {
    width: gameViewWidth,
    height: gameViewHeight,
    transformStyle,
  };
};

// let init_count_num = 0;
const initPlayerVideo = (url) => {
  let urlStr = url;
  // console.log("urlStr--------->", urlStr);
  urlStr = urlStr.replace(/&amp;/g, "&");

  if (player.value) {
    player.value.destroy();
    player.value = null;
  }

  let videoDom = document.getElementById("jswebrtc");
  // 初始化播放器
  player.value = new JSWebrtc.Player(urlStr, {
    video: videoDom,
    autoplay: true,
    onPlay: (obj) => {
      videoDom.play();
    },
  });

  player.value.on("canplay", () => {
    if (player.value) {
      const wrapperStyle = calculateVideoStyle(
        player.value.videoWidth(),
        player.value.videoHeight()
      );

      if (_Player_wrapper.value) {
        _Player_wrapper.value.style.width = wrapperStyle.width + "px";
        _Player_wrapper.value.style.height = wrapperStyle.height + "px";
        _Player_wrapper.value.style.transform = wrapperStyle.transformStyle;
        _Player_wrapper.value.style.transformOrigin = "0 0";
      }
    }

    // const wrapper = document.getElementById("player_wrapper");

    // initVideoCountDown.reset();
    // initVideoCountDown.pause();
    // isReady.value && (isReady.value = true);
    // emits("readyChange", true);
  });

  player.value.on("play", () => {
    initVideoCountDown.reset();
    initVideoCountDown.pause();
    isReady.value && (isReady.value = true);
    emits("readyChange", true);
  });
};

const handlePlayUpdata = () => {
  // player.value.load();
  // player.value.play();
  player.value.options.video.load();
  player.value.options.video.play();
  // console.log("刷新视频", );
};

onMounted(() => {
  initVideoCountDown.start();
  // 初始化播放器开始播放
  // setTimeout(() => {
  //   initPlayerVideo();
  // }, 0);
});

onBeforeUnmount(() => {
  if (player.value) {
    player.value.destroy();
    player.value = null;
  }
});

defineExpose({ initPlayerVideo, videoMuted, handlePlayUpdata });
</script>

<style lang="scss" scoped>
#player_wrapper {
  overflow: hidden;
  // width: 100vh;
  // height: 100vw;
  // transform: ;
  // transform-origin: 0 0;
  // transform-origin: ;
}
</style>
