<template>
  <div class="tabbar-wrapper">
    <van-config-provider>
      <van-tabbar
        fixed
        v-model="active"
        active-color="#3de1c3"
        inactive-color="#fff"
        route
        placeholder
        :safe-area-inset-bottom="false"
        z-index="999"
      >
        <van-tabbar-item
          v-for="item in props.tabbarList"
          :key="item.id"
          :to="item.path"
          replace
        >
          <span>{{ item.text }}</span>
          <template #icon="props">
            <van-image
              v-if="props.active"
              width="100%"
              height="auto"
              fit="contain"
              position="center"
              :show-loading="false"
              block
              :src="item.activeIcon"
            />
            <van-image
              v-else
              width="100%"
              height="auto"
              fit="contain"
              position="center"
              :show-loading="false"
              block
              :src="item.inactiveIcon"
            />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </van-config-provider>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";

const active = ref(0);

//#region  苹果 tablist

const props = defineProps({
  tabbarList: {
    type: Array,
    default: [],
  },
});

//#endregion

onBeforeMount(() => {});
</script>

<style lang="scss">
// .test{
//     background: ;
// }
:root:root {
  --van-tabbar-height: 72px;
  --van-tabbar-background: #221c2b;
  --van-tabbar-item-active-background: none;
  --van-tabbar-item-icon-margin-bottom: 10px;
}
</style>
