<template>
  <div
    class="create_forum"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <div class="my_nav_bar_wrapper">
      <div class="nav_bar">
        <div class="nav_left_wrapper" @click="router.back()">
          <van-icon name="arrow-left" />
        </div>
        <div class="nav_text_wrapper"><span>新发表</span></div>
        <div class="nav_right_wrapper">
          <div class="nav_right_icon" @click="submit">
            <van-image
              :show-loading="false"
              width="100%"
              height="100%"
              :src="submit_btn"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="forum_form_wrapper">
        <div class="textarea_wrapper">
          <van-config-provider
            :theme-vars="{
              fieldInputTextColor: '#fff',
              cellVerticalPadding: '0',
              cellHorizontalPadding: '0',
            }"
          >
            <van-field
              v-model="mytext"
              rows="3"
              autosize
              type="textarea"
              maxlength="300"
              show-word-limit
              placeholder="秀一下我在极客的高光时刻.."
            />
          </van-config-provider>
        </div>

        <div class="images_list_wrapper">
          <div class="images_list">
            <div
              class="image_item"
              v-for="(imgItem, imgIndex) in uploadImgUrls"
            >
              <van-image
                width="100%"
                height="100%"
                :show-loading="false"
                :src="'data:image/png;base64,' + imgItem"
                @click="handleImagePreview(imgIndex)"
              />
              <van-icon
                class="delect_icon_wrapper"
                size="20"
                color="#fff"
                name="clear"
                @click="delImagUrl(imgIndex)"
              />
            </div>
            <div
              class="image_item"
              v-if="uploadImgUrls.length < 3"
              @click="addImageUrl"
            >
              <van-image
                width="100%"
                height="100%"
                :show-loading="false"
                :src="uploadImg_bg"
              />
            </div>
          </div>
        </div>

        <div class="tisp">
          <van-icon name="comment" />
          将在审核通过后别人即可见，帖子将会保留30天。
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref, reactive, watch, onBeforeUnmount } from "vue";
import { closeToast, showToast } from "vant";
import { useStore } from "@/store/store";
import { showImagePreview } from "vant";
import { forumPost, imageUpload } from "@/api";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
import submit_btn from "@/assets/images/submit_btn.png";
import uploadImg_bg from "@/assets/images/uploadImg_bg.png";

const store = useStore();
const router = useRouter();
const mytext = ref("");

const uploadImgUrls = reactive([]);
// 删除图片
const delImagUrl = (index) => {
  uploadImgUrls.splice(index, 1);
};
// 增加图片
const addImageUrl = async () => {
  // 调起设备相册选择

  const res = await asyncSendInstructions({
    msg_type: "get_fankui_pic",
  });

  uploadImgUrls.push(res);
};

// 预览图片
const handleImagePreview = (index) => {
  const newUploadImgUrls = uploadImgUrls.map((url) => {
    return "data:image/png;base64," + url;
  });

  showImagePreview({
    images: newUploadImgUrls,
    startPosition: index,
  });
};

// 上传图片
const uploadImgPromes = () => {
  const allTask = [];
  uploadImgUrls.forEach((url) => {
    allTask.push(
      imageUpload({
        img: url,
      })
    );
  });

  return Promise.all(allTask);
};

// 提交
const submit = async () => {
  const content = mytext.value.trim();
  if (content.length < 5) {
    showToast({
      type: "text",
      message: "请至少输入5个字符",
    });
    return;
  }
  if (uploadImgUrls.length < 1) {
    showToast({
      type: "text",
      message: "请至少选择一张图片",
    });
    return;
  }

  const params = {
    uid: parseInt(store.userInfo.uid),
    content,
    images: "",
  };

  const imageRes = await uploadImgPromes();
  const imageUrls = imageRes.map((url) => {
    if ("headimgurl" in url && url.msg == "ok") return url.headimgurl;
  });

  params.images = imageUrls.join(";");
  const res = await forumPost(params);

  if (res.code == 200) {
    router.replace("/myForums");
    showToast({
      type: "success",
      message: "发布成功",
      forbidClick: true,
      onClose() {},
    });
  } else {
    showToast({
      type: "fail",
      message: res.msg,
    });
  }
};
</script>

<style lang="scss" scoped>
.my_nav_bar_wrapper {
  padding: 0 15px;

  .nav_bar {
    display: flex;
    height: 34px;
    line-height: 34px;
    .nav_left_wrapper {
      width: 28px;
      font-size: 20px;
    }
    .nav_text_wrapper {
      position: relative;
      flex: 1;
      font-size: 20px;
      span {
        position: relative;
        z-index: 2;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 66px;
        height: 12px;
        border-radius: 6px;
        z-index: 1;
        background: linear-gradient(270deg, #391284 0%, #871fba 100%);
      }
      &::before {
        content: "";
        position: absolute;
        top: 4px;
        left: 74px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        z-index: 1;
        background: linear-gradient(270deg, #391284 0%, #871fba 100%);
      }
    }
    .nav_right_wrapper {
      width: 72px;
      display: flex;
      justify-content: space-around;
      align-content: center;
      .nav_right_icon {
        width: 56px;
        height: 26px;
      }
    }
  }
}

.create_forum {
  padding-bottom: 20px;
  background-color: #181627;
  min-height: 100vh;

  .forum_form_wrapper {
    margin-top: 25px;
    background-color: #212040;
    border-radius: 16px;
    padding: 20px 15px;

    .textarea_wrapper {
      background-color: transparent;
      border-radius: 8px;
      .van-cell {
        background: none;
      }
    }

    .images_list_wrapper {
      margin-top: 20px;
      .images_list {
        display: flex;
        .image_item {
          height: 100px;
          width: 100px;
          margin-right: 8px;
          position: relative;
          .delect_icon_wrapper {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
          }
        }
      }
    }

    .tisp {
      margin-top: 20px;
      color: #ffffff80;
      font-size: 12px;
    }
  }
}
</style>
