<template>
  <div class="upcoin-wrapper" v-if="props.forbid" key="challengeTimerShow">
    <div class="upcoin-btn-active"></div>
    <div class="upcoin-btn-timer">
      {{ activeTime }}
    </div>
  </div>
  <div class="upcoin-wrapper" v-else key="upCoinShow">
    <div
      class="upcoin-btn"
      @touchstart="handleUpCoinBtn($event)"
      @touchend="handleUpCoinBtn($event)"
    >
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        v-show="btnStatu"
        :src="btnUi[store.current_machine.product_name][1]"
        :show-loading="false"
      />
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        :show-loading="false"
        v-show="!btnStatu"
        :src="btnUi[store.current_machine.product_name][0]"
      />
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { sendInstructions } from "@/api/sendData";
import { computed, ref, onBeforeUnmount, onMounted } from "vue";
import { showToast } from "vant";
import { useCountDown } from "@vant/use";
const store = useStore();

const mgc_coin_btn = store.imgBaseUrl + "/control/coin_btn.png";
const mgc_coin_btn_active = store.imgBaseUrl + "/control/coin_btn_active.png";

const bbl_coin_btn = store.imgBaseUrl + "/control/bbl_coin_btn.png";
const bbl_coin_btn_active =
  store.imgBaseUrl + "/control/bbl_coin_btn_active.png";

const emits = defineEmits(["btnsound", "onfinish", "upcoin_num"]);
const props = defineProps({
  forbid: {
    type: Boolean,
    default: false,
  },
});

const btnUi = {
  mgc: [mgc_coin_btn, mgc_coin_btn_active],
  hw3: [mgc_coin_btn, mgc_coin_btn_active],
  other: [mgc_coin_btn, mgc_coin_btn_active],
  bird: [mgc_coin_btn, mgc_coin_btn_active],
  ttq: [mgc_coin_btn, mgc_coin_btn_active],
  bbl: [bbl_coin_btn, bbl_coin_btn_active],
  zww: [bbl_coin_btn, bbl_coin_btn_active],
};

const btnStatu = ref(false);

// 投币/上分
const handleUpCoinBtn = (evt, keyStatu) => {
  const evtType = evt.type;
  evt.stopPropagation();
  evt.preventDefault();

  if (evtType === "touchstart") {
    btnStatu.value = true;
  }
  if (evtType === "touchend") {
    emits("upcoin_num");
    emits("btnsound");
    btnStatu.value = false;
  }

  return;

  const params = {
    msg_type: "up_coin",
    machine_index: store.current_machine.machine_index,
    game_name: store.current_machine.product_name,
    device_name: store.current_device.device_name,
    up_num: 1,
  };

  if (keyStatu === "down") {
    btnStatu.value = true;
    return;
  }

  btnStatu.value = false;
  if (Number(store.userInfo.gold) >= Number(store.current_machine.up_coin)) {
    // 上分
    sendInstructions(params);
  } else {
    showToast({
      type: "fail",
      message: "币量不足,请充值",
      teleport: "#rotate_box",
      onClose() {
        store.payPupToggle(true);
      },
    });
  }
};

const activeTime = computed(() => {
  if (store.sync_player_free_match_time > 0) {
    const time = Number(store.sync_player_free_match_time);
    if (time == 1 || time == undefined) emits("onfinish");

    let minute = parseInt(time / 60);
    minute = minute < 10 ? "0" + minute : minute;
    let second = time % 60;
    second = second < 10 ? "0" + second : second;

    return `${minute}:${second}`;
  } else return "00:00";
});

onMounted(() => {});

onBeforeUnmount(() => {});
</script>

<style lang="scss">
.upcoin-wrapper {
  position: absolute;
  right: 34px;
  top: 170px;
  width: 48px;
  height: 48px;
  z-index: 4;
  opacity: 0.8;
  overflow: hidden;
  .upcoin-btn {
    width: 100%;
    height: 100%;
  }

  .upcoin-btn-active {
    width: 100%;
    height: 100%;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/control/coin_btn_active.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .upcoin-btn-timer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 4;
    top: 0;
    left: 0;
    line-height: 1.28rem;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
