<template>
  <div class="_Landscape">
    <slot></slot>
  </div>
</template>

<script setup></script>

<style scoped>
._Landscape {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vh;
  height: 100vw;
  transform: rotate(90deg) translateY(-100%);
  transform-origin: left top;
}
</style>
