import axios from "axios";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000, // 请求超时时间毫秒
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // config.headers["Content-Type"] = "multipart/form-data";
    // config.headers["Content-Type"] = "application/json";

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    // console.log(error);
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data;
    // 这个状态码是和后端约定的
    // const code = dataAxios.reset;
    // console.log("添加响应拦截器-------", response);

    return dataAxios;
  },
  function (error) {
    // 对响应错误做点什么
    // console.log(error);
    return Promise.reject(error);
  }
);
export default service;
