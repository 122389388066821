<template>
  <div class="watch_wrapper">
    围观倒计时
    <span style="font-weight: 1000">{{
      parseInt(watchTimeCountDown.current.value.total / 1000)
    }}</span
    >s
  </div>
</template>
<script setup>
import { useCountDown } from "@vant/use";
import { useStore } from "@/store/store";
const store = useStore();
import { onMounted, onBeforeUnmount, reactive } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const emits = defineEmits(["onFinish"]);

const watchTimeCountDown = useCountDown({
  // 倒计时
  time: Number(store.current_machine.visit_time) * 1000,
  onFinish() {
    emits("onFinish");
    router.replace("/home");
  },
});

onMounted(() => {
  watchTimeCountDown.start();
});

onBeforeUnmount(() => {
  watchTimeCountDown.pause();
});
</script>

<style lang="scss" scoped>
.watch_wrapper {
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 14px;
}
</style>
