<template>
  <div class="fire-wrapper">
    <div
      class="fire-btn"
      @touchstart="handleFireBtnStart"
      @touchend="handleFireBtnEnd"
      @click="handleFireBtnClick"
    >
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        v-show="fireBtnData.statu"
        :show-loading="false"
        :src="btnUiUrl[1]"
      />
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        v-show="!fireBtnData.statu"
        :show-loading="false"
        :src="btnUiUrl[0]"
      />
    </div>

    <div class="fire-auto" v-show="fireBtnData.fireDomShow">
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        :show-loading="false"
        :src="btnFireUi[store.current_machine.product_name]"
      />
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { reactive, ref, computed } from "vue";

import { controlRequest } from "@/api";

import { Howl, Howler } from "howler";

import fireAutoOpen from "@/assets/audios/auto_fire_open.mp3";
import fireAutoClose from "@/assets/audios/auto_fire_end.mp3";

const fireAutoOpenSound = new Howl({
  src: [fireAutoOpen],
  autoplay: false,
  loop: false,
});
const fireAutoCloseSound = new Howl({
  src: [fireAutoClose],
  autoplay: false,
  loop: false,
});

const store = useStore();

const mgc_fire_btn = store.imgBaseUrl + "/control/fire_btn.png";
const mgc_fire_btn_active = store.imgBaseUrl + "/control/fire_btn_active.png";

const bbl_fire_btn = store.imgBaseUrl + "/control/bbl_fire_btn.png";
const bbl_fire_btn_active =
  store.imgBaseUrl + "/control/bbl_fire_btn_active.png";

const ttq_fire_btn = store.imgBaseUrl + "/control/ttq_fire_btn.png";
const ttq_fire_btn_active =
  store.imgBaseUrl + "/control/ttq_fire_btn_active.png";

const bbl_fire_auto = store.imgBaseUrl + "/control/fire_auto_open_2.png";
const mgc_fire_auto = store.imgBaseUrl + "/control/fire_auto_open.png";

const btnUi = {
  mgc: [mgc_fire_btn, mgc_fire_btn_active],
  hw3: [mgc_fire_btn, mgc_fire_btn_active],
  ttq: [ttq_fire_btn, ttq_fire_btn_active],
  other: [mgc_fire_btn, mgc_fire_btn_active],
  bbl: [bbl_fire_btn, bbl_fire_btn_active],
};

const btnUiUrl = computed(() => {
  if (
    store.current_machine.product_name == "other" &&
    store.current_machine.child_name == "ttq"
  ) {
    return btnUi.ttq;
  }

  return btnUi[store.current_machine.product_name];
});

const emits = defineEmits(["btnsound"]);

const props = defineProps({
  auto: {
    type: Boolean,
    default: true,
  },
});

const btnFireUi = {
  mgc: mgc_fire_auto,
  hw3: mgc_fire_auto,
  ttq: mgc_fire_auto,
  bbl: bbl_fire_auto,
  other: mgc_fire_auto,
};

const fireBtnData = reactive({
  btn_type: "fire",
  statu: false, // 开火的状态
  fireDomShow: false, // 自动开火特效
});

let fireBtnDownTime = 0;
let fireBtnUpTime = 0;
let fireTimer = null;
const controlTime = 1000;

let CLICK_Throttle = false;

const handleFireBtnStart = () => {
  fireBtnData.statu = true;
  fireBtnDownTime = new Date().getTime();
  // 按下一秒自动
  if (props.auto) {
    const params = {
      device: store.current_device.device_name,
      product_id: store.current_machine.product_id,
      channel: 5,
      type: 2,
      times: 0,
      width: 80,
      interval: 80,
      uid: parseInt(store.userInfo.uid),
    };
    fireTimer != null && clearTimeout(fireTimer);
    fireTimer = setTimeout(() => {
      fireAutoOpenSound.play();
      fireBtnData.fireDomShow = true;
      controlRequest(params);
      fireTimer = null;
    }, controlTime);
  }
};

const handleFireBtnEnd = () => {
  fireBtnUpTime = new Date().getTime();
  fireBtnUpTime - fireBtnDownTime < controlTime && (fireBtnData.statu = false);
};

const handleFireBtnClick = () => {
  // 清除自动定时器
  if (fireBtnUpTime - fireBtnDownTime < controlTime && fireTimer != null) {
    clearTimeout(fireTimer);
    fireTimer = null;
  }

  // 自动状态下 切换成正常的点击模式
  if (
    fireBtnData.fireDomShow &&
    fireBtnUpTime - fireBtnDownTime < controlTime
  ) {
    fireBtnData.fireDomShow = false;
    fireAutoCloseSound.play();
    fireBtnData.statu = false;
  }

  // 第一次开启自动
  if (
    fireBtnData.fireDomShow &&
    fireBtnUpTime - fireBtnDownTime > controlTime
  ) {
    // 不再发点击
    return;
  }

  // 防止多次点击 单击
  if (CLICK_Throttle) return;
  CLICK_Throttle = true;
  const params = {
    device: store.current_device.device_name,
    product_id: store.current_machine.product_id,
    channel: 5,
    type: 2,
    times: 1,
    width: 80,
    interval: 80,
    uid: parseInt(store.userInfo.uid),
  };

  controlRequest(params);

  emits("btnsound");

  !fireBtnData.statu && (fireBtnData.statu = false);
  setTimeout(() => {
    CLICK_Throttle = false;
  }, 200);
};

const handleFireBtn = (evt, press = 80) => {
  evt.stopPropagation && evt.stopPropagation();
  evt.preventDefault && evt.preventDefault();

  const evtType = evt.type;
  const params = {
    device: store.current_device.device_name,
    product_id: store.current_machine.product_id,
    channel: 5,
    type: 2,
    times: 1,
    width: press,
    interval: 80,
    uid: parseInt(store.userInfo.uid),
  };

  // 其他类型的开火
  if (evtType === "touchstart") {
    // 按下
    fireBtnDownTime = new Date().getTime();
    fireBtnData.statu = true;
    // 单次发送
    params.type = 2;

    controlRequest(params);
    // 长按自动
    if (props.auto) {
      fireTimer != null && clearTimeout(fireTimer);

      fireTimer = setTimeout(() => {
        fireBtnData.fireDomShow = true;
        // 自动开火音效
        if (vuesoundFier.value) {
          vuesoundFier.value.src = fireAutoOpen;
          vuesoundFier.value.play();
        }
        params.times = 0;
        controlRequest(params);
        fireTimer = null;
      }, controlTime);
    }
  }

  if (evtType === "touchend") {
    params.type = 0;
    fireBtnUpTime = new Date().getTime();

    if (fireBtnData.fireDomShow) {
      if (fireBtnUpTime - fireBtnDownTime < controlTime) {
        // 开火特效关掉
        fireBtnData.fireDomShow = false;
        fireBtnData.statu = false;
        if (vuesoundFier.value) {
          vuesoundFier.value.pause();
          vuesoundFier.value.src = fireAutoClose;
          vuesoundFier.value.play();
        }

        if (fireTimer != null) {
          clearTimeout(fireTimer);
          fireTimer = null;
        }
        params.type = 0;
        controlRequest(params);
      }
    } else {
      // 单击状态恢复
      fireBtnData.statu = false;
      emits("btnsound");
      if (fireTimer != null) {
        clearTimeout(fireTimer);
        fireTimer = null;
      }
    }
  }
};

defineExpose({ fireBtnData });
</script>

<style lang="scss">
.fire-wrapper {
  position: absolute;
  right: 45px;
  bottom: 50px;
  width: 72px;
  height: 72px;
  z-index: 4;

  .fire-btn {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 5;
    opacity: 0.8;
  }

  .fire-auto {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 104px;
    height: 104px;
    z-index: 4;
    animation: firerAnimt 1s infinite linear;
    transform-origin: left top;
  }
}

@keyframes firerAnimt {
  from {
    transform: rotate(0deg) translate(-50%, -50%);
  }

  to {
    transform: rotate(360deg) translate(-50%, -50%);
  }
}

@-webkit-keyframes firerAnimt

/* Safari 与 Chrome */ {
  from {
    transform: rotate(0deg) translate(-50%, -50%);
  }

  to {
    transform: rotate(360deg) translate(-50%, -50%);
  }
}
</style>
