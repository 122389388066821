<template>
  <template v-if="isReady">
    <router-view />
  </template>

  <!-- 游戏断线 -->
  <van-popup
    v-model:show="store.serverStatus.net_close_statu"
    round
    :close-on-click-overlay="false"
    z-index="2000"
  >
    <div class="net_close_pup">
      <p class="close_title">温馨提示</p>
      <p class="close_content">当前设备已离线，请重新登录</p>
      <div class="close_btn" @touchend="handleNetClose">确定</div>
    </div>
  </van-popup>

  <!-- 青少年模式 -->
  <ChildModel />

  <Turntable />

  <!-- 退分结果 -->

  <RotateWrapper v-show="store.settlementResult.result_show">
    <GameResult @exitBtnClick="confirmResilt" />
  </RotateWrapper>

  <!-- 充值 -->
  <RechargeView />
  <!-- 实名认证 -->
  <AuthUserIdCard @close="store.authUserShowToggle()" />

  <!-- 卡券消息通知 -->
</template>

<script setup>
import { useStore } from "@/store/store";
import { onMounted, onUpdated, onBeforeMount, ref, watch } from "vue";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
import safeAreaInsets from "safe-area-insets";
import { getCurrentDevice } from "@/utils/utils";
import { useRouter } from "vue-router";

import { showToast, closeToast } from "vant";

import { getTianti, playerRechargeStatus } from "@/api";
import ChildModel from "@/components/ChildModel.vue";

import Turntable from "@/components/Turntable.vue";

import GameResult from "@/components/GameResult.vue";
import RotateWrapper from "@/components/RotateWrapper.vue";

import RechargeView from "@/components/RechargeView.vue";
import AuthUserIdCard from "@/components/AuthUserIdCard.vue";

let noInitializeSucceedCallBackTimer = setTimeout(() => {
  if (!isReady.value) {
    sendInstructions(
      {
        msg_type: "get_player_common",
      },
      100
    );

    sendInstructions(
      {
        msg_type: "get_player_common",
      },
      500
    );
  }
}, 6e3);

const store = useStore();
const router = useRouter();
// 项目初始化
const isReady = ref(false);
const curDevType = getCurrentDevice();

const _initialize = async () => {
  isReady.value = true;
  clearTimeout(noInitializeSucceedCallBackTimer);
  const params = {
    uid: parseInt(store.userInfo.uid),
  };

  const res = await getTianti(params);
  const res2 = await playerRechargeStatus(params);
  store.$patch((state) => {
    state.userInfo.dashi_card = !!res.data.dashi_card;
    state.userInfo.zongshi_card = !!res.data.zongshi_card;
    state.userInfo.level = res.data.level;
    state.userInfo.recharge_level = res2.data.level;
  });
};

watch(
  () => store.userInfo.share_code,
  (newV) => {
    if (newV != "cctv101") {
      _initialize();
    }
  }
);

store.$patch((state) => {
  state.channelType = curDevType == "IOS" ? "ios" : "zyw";
});

sendInstructions(
  {
    msg_type: "get_player_child_info",
  },
  100
);
// 转盘
sendInstructions(
  {
    msg_type: "get_player_turntable",
  },
  500
);

function styleCallback(style) {
  if (style.top > 0) {
    store.$patch((state) => {
      state.areaInsetTopValue = safeAreaInsets.top;
    });
  }
}

safeAreaInsets.onChange(styleCallback);

const handleNetClose = () => {
  sendInstructions(
    {
      msg_type: "restart_game",
    },
    0
  );
};

const confirmResilt = () => {
  const isMachine =
    store.settlementResult.machine_index == store.current_machine.machine_index;
  const isDevice =
    store.settlementResult.device_name == store.current_device.device_name;

  if (isMachine && isDevice) {
    store.initStatu();
    router.replace({
      name: "home",
    });

    return;
  }

  store.settlementResult.result_show = false;
};

onBeforeMount(() => {
  sendInstructions({
    msg_type: "get_login_reward",
  });
});

onMounted(() => {
  sendInstructions({
    msg_type: "get_player_common",
  });

  sendInstructions(
    {
      msg_type: "get_login_reward",
    },
    100
  );

  sendInstructions(
    {
      msg_type: "get_player_child_info",
    },
    500
  );
});
</script>

<style lang="scss">
@import "./assets/css/main.scss";

#app {
  // width: 100%;
  // overflow-x: hidden;
  // overflow-y: auto;

  background-color: #3a1b67;
  color: $white;
  font-size: 12px;
  font-family: SOURCEHANSANSCN, Source Han Sans CN-Bold, Source Han Sans CN;
}

#outer_wrapper {
  position: absolute;
}

.hh_dialog {
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    #bbd8f9 0%,
    #ffffff 58%,
    #eef6f9 100%
  ) !important;

  .van-dialog__header {
    font-size: 24px;
    background: linear-gradient(90deg, #3967ff 0%, #7091ff 44%, #8c85ff 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 1000;
  }

  .van-dialog__message {
    margin-top: 20px;
    color: #3e4056;
    font-size: 16px;
    font-weight: bold;
  }

  .van-dialog__cancel {
    background: linear-gradient(90deg, #81c3ff 0%, #5034ff 100%);
    // opacity: 0.7;
    border-radius: var(--van-radius-max);
    margin-left: 4px;
    margin-right: 4px;
  }

  .van-dialog__confirm {
    background: linear-gradient(90deg, #ff819b 0%, #ff3450 100%);
    // opacity: 0.7;
    border-radius: var(--van-radius-max);
    margin-left: 4px;
    margin-right: 4px;
  }
}

:root:root {
  --van-nav-bar-background: none;
  --van-nav-bar-icon-color: #fff;
  --van-nav-bar-text-color: #fff;
  --van-nav-bar-title-text-color: #fff;
  // --van-cell-background: rgb(75, 35, 128) !important;
  // --van-cell-text-color: #fff;
  // --van-cell-right-icon-color: #fff;
}

.return-lottery-pup-wapper {
  width: 300px;
  height: 120px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_bg.png");
  background-size: 100% 100%;
  position: relative;
  padding: 20px 10px;
  text-align: center;
  font-size: 16px;

  .pup-close-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 26px;
    height: 26px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close.png");
    background-size: 100% 100%;
  }
}

.net_close_pup {
  width: 90vw;
  padding-top: 30px;
  padding-bottom: 20px;
  min-height: 200px;
  background: linear-gradient(180deg, #bbd8f9 0%, #ffffff 58%, #eef6f9 100%);
  text-align: center;

  .close_title {
    font-size: 24px;
    background: linear-gradient(90deg, #3967ff 0%, #7091ff 44%, #8c85ff 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 1000;
  }

  .close_content {
    margin-top: 20px;
    color: #3e4056;
    font-size: 16px;
    margin-bottom: 34px;
  }

  .close_btn {
    width: 164px;
    height: 42px;
    font-size: 16px;
    border-radius: 22px;
    line-height: 42px;
    color: #fff;
    margin: 0 auto;
    background: linear-gradient(180deg, #81c3ff 0%, #5034ff 100%);
  }
}
</style>
