<template>
  <div
    class="direction-wrapper"
    :style="{
      left: store.areaInsetTopValue + 4 + 'px',
    }"
  >
    <!-- 上 -->
    <div
      class="direction-keys"
      @touchstart="handleDirectionBtnsTouchStart($event, key.channel)"
      @touchend="handleDirectionBtnsTouchEnd($event, key.channel)"
      v-for="key in directionKeys"
      :style="key.style"
    >
      <van-image
        v-show="key.statu"
        width="100%"
        height="auto"
        fit="fill"
        position="center"
        :src="d_key_active"
        :show-loading="false"
      />
      <van-image
        v-show="!key.statu"
        width="100%"
        height="auto"
        fit="fill"
        position="center"
        :show-loading="false"
        :src="d_key"
      />
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { reactive, onBeforeUnmount, onMounted } from "vue";
import { controlRequest } from "@/api";
// import { sendInstructions } from "@/api/sendData";
const store = useStore();
const emits = defineEmits(["btnsound"]);

const d_key =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/control/top_btn.png";
const d_key_active =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/control/top_btn_active.png";

// 显示用
const direction_btns = reactive({
  1: 0,
  2: 0,
  3: 0,
  4: 0,
});
const directionKeys = reactive([
  {
    channel: 1,
    statu: false,
    style: {
      top: "50%",
      left: "0",
      transform: "translateY(-50%) rotate(-90deg)",
    },
  },
  {
    channel: 2,
    statu: false,
    style: {
      top: "50%",
      right: "0",
      transform: "translateY(-50%) rotate(90deg)",
    },
  },
  {
    channel: 3,
    statu: false,
    style: {
      top: "0",
      left: "50%",
      transform: "translateX(-50%)",
    },
  },
  {
    channel: 4,
    statu: false,
    style: {
      bottom: "0",
      left: "50%",
      transform: "translateX(-50%) rotate(180deg)",
    },
  },
]);
const KEY_INTERVAL_TIME = 300;
let KEY_DOWN_TIMER = null;
let TOUCHSTART_TIME = 0;
let TOUCHEND_TIME = 0;
const handleDirectionBtnsTouchStart = async (evt, channel) => {
  evt && evt.preventDefault();
  evt && evt.stopPropagation();

  const params = {
    product_id: store.current_machine.product_id,
    device: store.current_device.device_name,
    channel,
    type: 1,
    times: 1,
    width: 100,
    interval: 30,
    uid: parseInt(store.userInfo.uid),
  };

  TOUCHSTART_TIME = Date.now();
  emits("btnsound");
  KEY_DOWN_TIMER = setTimeout(() => {
    controlRequest(params);
  }, KEY_INTERVAL_TIME);

  directionKeys[channel - 1].statu = true;
};

const handleDirectionBtnsTouchEnd = async (evt, channel) => {
  evt && evt.preventDefault();
  evt && evt.stopPropagation();
  TOUCHEND_TIME = Date.now();
  const params = {
    product_id: store.current_machine.product_id,
    device: store.current_device.device_name,
    channel,
    type: 2,
    times: 1,
    width: 100,
    interval: 30,
    uid: parseInt(store.userInfo.uid),
  };

  directionKeys[channel - 1].statu = false;

  if (TOUCHEND_TIME - TOUCHSTART_TIME < KEY_INTERVAL_TIME) {
    clearTimeout(KEY_DOWN_TIMER);
    controlRequest(params);
    return;
  }

  params.type = 0;
  if (
    TOUCHEND_TIME - TOUCHSTART_TIME > KEY_INTERVAL_TIME &&
    TOUCHEND_TIME - TOUCHSTART_TIME - KEY_INTERVAL_TIME < 300
  ) {
    controlRequest(params);
    setTimeout(() => {
      controlRequest(params);
    }, 100);
  }

  controlRequest(params);
};

onMounted(() => {});

onBeforeUnmount(() => {});
</script>

<style lang="scss">
.direction-wrapper {
  position: absolute;

  bottom: 60px;
  width: 150px;
  height: 150px;

  z-index: 4;

  .direction-keys {
    position: absolute;
    width: 48px;
    height: 48px;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.75;
  }
}
</style>
