<template>
  <div class="game_view_wrapper" @click="handleWrapperClick">
    <!-- 播放器 -->
    <Player ref="_Player" @ready-change="readyChange" />

    <div id="rotate_box">
      <GameLoding v-if="!isReady" />

      <WheelEntrance :right="80" :top="20" v-show="ui_control_show" />
      <!-- 占位信息 -->
      <div class="playing_location" v-show="ui_control_show">
        <div
          class="location_item"
          v-for="playingItem in store.current_machine.device_info"
        >
          <div class="head_cover">
            <HeadPortrait
              v-if="
                playingItem.occupy &&
                playingItem.occupy.player_id &&
                playingItem.occupy.player_id != ''
              "
              :level="playingItem.occupy.recharge_level"
              :ae_id="playingItem.occupy.select_title"
              ae
              fe
              width="100%"
              height="100%"
              :src="
                playingItem.occupy.head != ''
                  ? playingItem.occupy.head
                  : playingItem.occupy.head_frame
              "
            />

            <van-image
              v-else
              width="100%"
              height="100%"
              block
              :src="head_phone"
            />
          </div>

          <div class="location_p">{{ playingItem.device_index }}P</div>
        </div>
      </div>

      <!-- 围观者 -->
      <div
        class="visits_list_wrapper"
        v-if="store.current_machine.visits.length > 0 && ui_control_show"
      >
        <div class="visits_text_wrapper">围观：</div>

        <div
          class="visit_item_user_header"
          v-for="(visitsItem, vIndex) in store.current_machine.visits"
          :key="'visits_' + visitsItem.uid"
          :style="{
            transform: `translateX(-${vIndex * 25}%)`,
          }"
        >
          <HeadPortrait
            width="100%"
            height="100%"
            :src="visitsItem.head ? visitsItem.head : visitsItem.head_frame"
          />
        </div>
      </div>

      <!-- 观战计时 -->
      <WatchTime
        :watch_time="Number(store.current_machine.visit_time)"
        @onFinish="exitGameRequest"
        v-if="store.gameIsWatch && isReady && !store.userInfo.is_gm"
      />

      <!-- 菜单 -->
      <Menu
        ref="menud"
        @menuSoundClick="menuSoundClick"
        @handleExitEvent="handleExitEvent"
        @handle_update="handle_update_player"
        @handle_display="ui_control_show_toggle"
      />

      <!-- 时间 -->
      <ServerTime :right="40" :top="80" />

      <!-- 坐下-- -->
      <Seats v-if="store.gameIsWatch && isReady" />

      <!-- 坐下后的元素 -->
      <template v-if="!store.gameIsWatch && isReady">
        <!-- 顶部玩家信息 -->
        <TopInfo v-if="!store.gameIsWatch && isReady && ui_control_show" />
        <!-- 方向键 -->
        <!-- <Direction @btnsound="btnsoundPlay" /> -->
        <!-- 投币键 -->
        <!-- <UpcoinBtn @btnsound="btnsoundPlay" @upcoin_num="upcoinClick" /> -->
        <!-- 加强键 -->
        <!-- <PlusBtn @btnsound="btnsoundPlay" /> -->
        <Control @count-down-end="handleExitEvent" />

        <!-- 开火键 -->
        <!-- <FireBtn ref="childRef" @btnsound="btnsoundPlay" /> -->

        <!-- 房号和金币混合 -->
        <MinxGoldRoom v-show="ui_control_show" />
      </template>
      <!-- 座位提示 -->
      <SitDownHint v-if="sit_down_hint_show" />

      <!-- 收集提示 -->
      <CollectHint />

      <!-- 投币选择 -->
      <van-popup v-model:show="upcoinNumShow" style="background: none">
        <div class="upcoin_num_wrapper">
          <div class="upcoin_num_title">
            <p>选择投币次数</p>
          </div>
          <div class="upcoin_num_list">
            <div
              class="upcoin_num_item"
              v-for="upcoinItem in upcoinNumList"
              @click="upcoinRequsult(upcoinItem.num)"
            >
              <div class="upcoin_info">
                <span class="gold_icon"></span>
                <span class="gold_num">{{ upcoinItem.gold_num }}</span>
              </div>
              <div class="upcoin_num">
                投币&nbsp;{{ upcoinItem.num }}&nbsp;次
              </div>
            </div>
          </div>
          <div
            class="upcoin_num_tips"
            @click="
              () => {
                upcoinNumShow = false;
              }
            "
          >
            取消投币
          </div>
        </div>
      </van-popup>

      <AwaitLoding
        v-if="await_tiaozhan_result_show && !store.settlementResult.result_show"
      />

      <!-- GM 特权 -->
      <div class="test_box_wrapper" v-if="store.userInfo.is_gm && isReady">
        <div
          class="test_item"
          v-for="test_item in store.current_machine.device_info"
        >
          <p>
            {{
              test_item.occupy.player_id
                ? "UID:" + test_item.occupy.player_id
                : "UID: 0"
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import GameLoding from "@/components/GameLoding.vue";
import AwaitLoding from "@/components/AwaitLoding.vue";

import Player from "@/components/Player.vue";
import Menu from "@/components/Menu.vue";
import HeadPortrait from "@/components/HeadPortrait.vue";
import Seats from "@/components/Seats.vue";
import WheelEntrance from "@/components/WheelEntrance.vue";
// import Direction from "@/components/Direction.vue";
// import UpcoinBtn from "@/components/UpcoinBtn.vue";
// import PlusBtn from "@/components/PlusBtn.vue";
// import FireBtn from "@/components/FireBtn.vue";
import SitDownHint from "@/components/SitDownHint.vue";
import WatchTime from "@/components/WatchTime.vue";
import TopInfo from "@/components/TopInfo.vue";
import ServerTime from "@/components/ServerTime.vue";
import MinxGoldRoom from "@/components/MinxGoldRoom.vue";

import btnClick from "@/assets/audios/btn_click.mp3";
import {
  asyncSendInstructions,
  sendInstructions,
  pollingGameinfo,
} from "@/api/sendData";
import CollectHint from "@/components/CollectHint.vue";
import { useCountDown } from "@vant/use";
import {
  onMounted,
  onBeforeUnmount,
  ref,
  watch,
  reactive,
  onBeforeMount,
} from "vue";
import { useStore } from "@/store/store";
import { useRouter } from "vue-router";
import { controlRequest } from "@/api";

import { Howl, Howler } from "howler";

import { showToast } from "vant";
import Control from "@/components/Control.vue";
const store = useStore();
const router = useRouter();

const head_phone = store.imgBaseUrl + "/head_phone.png";

// 坐下提示
const sit_down_hint_show = ref(false);

watch(
  () => store.gameIsWatch,
  (newV) => {
    if (!newV) {
      // 坐下提示
      sit_down_hint_show.value = true;
      setTimeout(() => {
        sit_down_hint_show.value = false;
      }, 5000);
    }
  }
);

//#region 播放器
const _Player = ref(null);
const isReady = ref(false);
let Onlookers = false;
const readyChange = (statu) => {
  isReady.value = statu;
  if (statu && !Onlookers && store.settlementResult.type == "normal") {
    Onlookers = true;
    sendInstructions(
      {
        msg_type: "enter_machine",
        game_name: store.current_machine.product_name,
        machine_index: store.current_machine.machine_index,
      },
      100
    );
  }
  store.channelType == "ios" && _Player.value && _Player.value.videoMuted();
};
const handle_update_player = () => {
  _Player.value?.handlePlayUpdata();
};
onMounted(() => {
  if (!store.get_player_tiaozhan_info_show) {
    _Player.value &&
      _Player.value.initPlayerVideo(store.current_machine.sp_url);
  }
});

//#endregion

//#region 菜单
const menud = ref();
// 声音
const menuSoundClick = (statu) => {
  _Player.value && _Player.value.videoMuted(!statu);
};

//#endregion

//#region 游戏返回 退出 结束

const AwaitResultBackCountDownTime = 10000;
const AwaitResultBackCountDown = useCountDown({
  // 倒计时
  time: AwaitResultBackCountDownTime,
  onFinish() {
    exitRoom();
  },
  onChange(current) {},
});

// 等待挑战结果loding
const await_tiaozhan_result_show = ref(false);

watch(
  () => store.notify_mqtt_counting_result_key,
  (newV) => {
    if (await_tiaozhan_result_show.value && newV != 0) {
      AwaitResultBackCountDown.reset();
      AwaitResultBackCountDown.start();
    }
  }
);

// 退分/退出观战 协议
const exitGameRequest = (type = "visit") => {
  const backType = type;
  // 退出观战
  if (backType == "visit") {
    const leave_params = {
      msg_type: "leave_machine",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
    };
    sendInstructions(leave_params, 0);
    if (store.channelType == "ios") {
      sendInstructions(leave_params, 50);
      sendInstructions(leave_params, 100);
    }
  }
  // 退分
  if (backType == "exit") {
    // 退分 params
    const params = {
      msg_type: "return_lottery",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
      device_name: store.current_device.device_name,
    };

    // 第一次
    sendInstructions(params, 0);
    // 第二次
    sendInstructions(params, 1000);
    // 第三次
    sendInstructions(params, 1800);
    // 虫虫乐园特殊退分
    if (store.current_machine.child_name == "ccly") {
      // 操控
      const params = {
        product_id: store.current_machine.product_id,
        device: store.current_device.device_name,
        channel: 1,
        type: 2,
        times: 1,
        width: 200,
        interval: 30,
        uid: parseInt(store.userInfo.uid),
      };

      setTimeout(() => {
        controlRequest(params).then(() => {
          setTimeout(() => {
            params.channel = 5;
            controlRequest(params);
          }, 1000);
        });
      }, 3000);

      return;
    }
  }
};

// 得到最新设备信息
const getNewDeviceInfo = () => {
  const new_current_device = store.current_machine.device_info.find(
    (dvItem) => dvItem.device_name == store.current_device.device_name
  );
  return new_current_device;
};

const handleExitEvent = () => {
  // 观战直接退
  if (store.gameIsWatch) {
    isReady.value && exitGameRequest();
    router.replace("/home");

    return;
  }
  // 结算退出
  if (await_tiaozhan_result_show.value) return;
  await_tiaozhan_result_show.value = true;
  AwaitResultBackCountDown.start();
  exitGameRequest("exit");
};

// 退出房间！！！
const exitRoom = () => {
  store.initStatu();
  router.replace({
    name: "home",
  });
};

//#endregion

//#region 投币
const upcoinNumList = [
  {
    gold_num: Number(store.current_machine.up_coin) * 1,
    num: 1,
  },
  {
    gold_num: Number(store.current_machine.up_coin) * 10,
    num: 10,
  },
  {
    gold_num: Number(store.current_machine.up_coin) * 30,
    num: 30,
  },
];
const upcoinNumShow = ref(false);
const upcoinRequsult = (num) => {
  upcoinNumShow.value = false;
  if (
    Number(store.userInfo.gold) >=
    Number(store.current_machine.up_coin) * num
  ) {
    //  投币
    const params = {
      msg_type: "up_coin",
      machine_index: store.current_machine.machine_index,
      game_name: store.current_machine.product_name,
      device_name: store.current_device.device_name,
      up_num: num,
    };

    sendInstructions(params);
  } else {
    showToast({
      type: "fail",
      message: "币量不足,请充值。",
      teleport: "#rotate_box",
      onClose() {
        store.payPupToggle(true);
      },
    });
  }
};

//#endregion

// 全局点击
const handleWrapperClick = (evt) => {
  evt.stopPropagation();
  evt.preventDefault();
  menud.value && menud.value.menuOpenStatu && menud.value.menuStatuToggle(evt);
};

//#region 数据同步
onMounted(() => {
  setTimeout(() => {
    pollingGameinfo({
      game_name: "other",
      statu: "start",
    });
  }, 1500);
});

onBeforeUnmount(() => {
  pollingGameinfo({
    game_name: "other",
    statu: "close",
  });
});
//#endregion

//#region 部分ui控制

const ui_control_show = ref(true);
let ui_flag = false;
const ui_control_show_toggle = () => {
  if (ui_flag) return;
  ui_flag = true;

  ui_control_show.value = !ui_control_show.value;
  setTimeout(() => {
    ui_flag = false;
  }, 5e2);
};

//#endregion
</script>

<style scoped lang="scss">
.game_view_wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
}

#rotate_box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vh;
  height: 100vw;
  transform: rotate(90deg) translateY(-100%);
  transform-origin: left top;
}

// 爆机记录
.baoji_log_wrapper {
  width: 375px;
  height: 260px;
  background-color: #6e23a2;
  border-radius: 16px;

  .title {
    position: relative;
    height: 32px;

    .close_icon {
      position: absolute;
      left: 0;
      width: 68px;
      height: 100%;
      background-image: url("@/assets/images/game/close_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .baoji_title_img {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      background-image: url("@/assets/images/game/baoji_tltle.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 188px;
      height: 52px;
    }
  }

  .log_list {
    height: 228px;
    // background-color: pink;
    overflow: auto;
    padding: 0 15px 10px 15px;
    .log_item {
      position: relative;
      margin-top: 10px;
      height: 60px;
      background: linear-gradient(
        270deg,
        rgba(149, 69, 226, 0.2) 0%,
        rgba(149, 69, 226, 0.5) 100%
      );
      border-radius: 6px;
      padding: 10px 12px;

      .log_text {
        font-size: 16px;
      }
      .log_time {
        font-size: 12px;
        color: rgba($color: #fff, $alpha: 0.5);
      }

      .get_btn {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 56px;
        height: 22px;
        border-radius: 12px;
        background: linear-gradient(90deg, #ffd884 0%, #ffc018 100%);
        line-height: 22px;
        color: #280923;
        font-size: 12px;
        text-align: center;
      }

      .done_reward {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 110px;
        height: 22px;
        line-height: 22px;
        display: flex;
        .reward {
          color: #fee22a;
          margin: 0 6px 0 0;
        }
        .lottery_icon {
          width: 22px;
          height: 22px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/ticket.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.playing_location {
  position: absolute;
  top: 110px;
  left: 34px;
  display: flex;
  .location_item {
    margin-right: 4px;
    position: relative;
    width: 38px;

    .head_cover {
      position: relative;
      width: 30px;
      height: 30px;
      margin: 0 auto;
      z-index: 1;
    }

    .location_p {
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(50%);
      width: 100%;
      z-index: 2;
      background-color: rgba($color: #000000, $alpha: 0.6);
      font-size: 10px;
      font-weight: bold;
      color: #fff;
      border-radius: 8px;
      text-align: center;
    }
  }
}

.upcoin_num_wrapper {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // transform: rotate(90deg);

  .upcoin_num_title {
    font-size: 20px;
    font-weight: bold;
  }

  .upcoin_num_list {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .upcoin_num_item {
      width: 80px;
      height: 80px;
      background: linear-gradient(180deg, #d7bdfa 0%, #9553ff 100%);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .upcoin_info {
        display: flex;
        justify-content: center;
        align-items: center;
        .gold_icon {
          width: 20px;
          height: 20px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
          background-size: 100% 100%;
          margin-right: 4px;
        }
        .gold_num {
          font-size: 14px;
          height: 20px;
        }
      }

      .upcoin_num {
        text-align: center;
        width: 100%;
        height: 20px;
      }
    }
  }

  .upcoin_num_tips {
    margin-top: 40px;
    font-size: 16px;
    color: #fff;
  }
}

// 围观者
.visits_list_wrapper {
  // display: flex;
  z-index: 4;
  position: absolute;
  right: 30%;
  top: 20px;
  width: 110px;
  height: 32px;

  .visits_text {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
    font-size: 12px;
  }

  .visit_item_user_header {
    position: absolute;
    top: 0;
    width: 32px;
    height: 32px;
    flex: 1;
    // position: relative;
    // left: -50%;
  }
}

// GM 特权
.test_box_wrapper {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  z-index: 2;
  display: flex;
  .test_item {
    flex: 1;
    font-size: 28px;
    font-weight: 1000;
    text-align: center;
    color: #ef5b91;
    background-color: rgba($color: #000000, $alpha: 0.3);
  }
}
</style>
