<template>
  <div class="_rotate_wrapper" :style="_rotate_style">
    <slot></slot>
  </div>
</template>

<script setup>
import { reactive, computed } from "vue";

import { useStore } from "@/store/store";

const store = useStore();
const _rotate_style = computed(() => {
  return store.isLandscape
    ? {
        width: "100vh",
        height: "100vw",
        transform: "rotate(90deg) translateY(-100%)",
        transformOrigin: "left top",
      }
    : {
        width: "100vw",
        height: "100vh",
      };
});
</script>

<style lang="scss" scoped>
._rotate_wrapper {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  inset: 0;
  z-index: 9999;
}
</style>
