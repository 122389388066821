<template>
  <!-- 集魂殿 -->
  <van-popup
    v-model:show="props.pupshow"
    @open="pupOpenCallBack"
    position="center"
    :close-on-click-overlay="false"
    :style="pupStyle"
  >
    <div class="collect_pup_wrapper">
      <div class="collect_pup_title">
        <div class="collect_icon_wrapper">
          <div class="collect_icon">
            <van-image
              width="100%"
              height="100%"
              fit="cover"
              position="center"
              :show-loading="false"
              :src="collect_icon"
            />
          </div>
        </div>
        <div class="collect_pup_title_text">
          <div class="collect_pup_title_text_icon">
            <van-image
              width="auto"
              height="100%"
              fit="cover"
              position="center"
              :show-loading="false"
              :src="collect_title_text_icon"
            />
          </div>
        </div>

        <div class="collect_tips">每月1日零点清零</div>

        <div class="collect_pup_close_wrapper">
          <div
            class="collect_pup_close_icon"
            @click="collectPupIconClose"
          ></div>
        </div>
      </div>

      <!-- // 竖屏 -->
      <template v-if="!props.islandscape">
        <div class="collect_pup_content">
          <div
            :class="[
              'collect_pup_tab',
              curCollectType == 'collect_my_card'
                ? 'collect_pup_tab_left'
                : 'collect_pup_tab_right',
            ]"
          >
            <div
              v-for="tabItem in collectTabs"
              :key="tabItem.key"
              class="collect_pup_tab_item"
              @click="collectTypeChange(tabItem.key)"
            >
              <div class="collect_pup_tab_item_content">
                <div class="collect_pup_tab_item_cover">
                  <van-image
                    class="absolute-center"
                    :width="tabItem.key == 'collect_my_card' ? '16px' : '22px'"
                    height="auto"
                    fit="cover"
                    position="center"
                    :show-loading="false"
                    :src="tabItem.icon"
                  />
                </div>
                <div class="collect_pup_tab_item_text">
                  {{ tabItem.text }}
                </div>
              </div>
            </div>
          </div>

          <div class="collect_pup_bottom">
            <div class="collect_wrapper">
              <div
                class="my_collect_card"
                v-if="curCollectType == 'collect_my_card'"
              >
                <div
                  class="my_collect_card_item"
                  v-for="(cardItem, cardIndex) in store.initCollectPageData[
                    store.current_machine.product_name
                  ].myCollectCards"
                >
                  <div class="collect_card_item_left">
                    <div class="collect_card_item_left_cover">
                      <van-image
                        width="100%"
                        height="100%"
                        fit="fill"
                        position="center"
                        :show-loading="false"
                        :src="cardItem.url"
                      />
                    </div>
                  </div>
                  <div class="collect_card_item_right">
                    <p class="collect_card_name">{{ cardItem.cardName }}</p>
                    <p class="collect_card_number_text">所获数量</p>
                    <div class="collect_card_number">
                      <div
                        class="collect_card_number_icon"
                        v-if="
                          store.current_machine.product_name == 'bbl' &&
                          cardItem.classID != 4
                        "
                      >
                        <van-image
                          width="100%"
                          height="100%"
                          fit="fill"
                          position="center"
                          :show-loading="false"
                          :src="bblDifCover[cardItem.classID].icon"
                        />
                      </div>
                      <div>X{{ cardItem.num }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="collect_card_task"
                v-if="curCollectType == 'collect_card_task'"
              >
                <div
                  class="collect_card_task_item"
                  v-for="(task, taskIndex) in store.initCollectPageData[
                    store.current_machine.product_name
                  ].collectCardTaskList"
                >
                  <div class="collect_card_task_item_top">
                    <div class="collect_card_task_name">
                      {{ task.taskName }}
                    </div>
                    <div class="collect_card_task_exchange">
                      <span class="text">任务奖励</span>
                      <span class="award">{{ task.reward }}</span>
                      <span
                        :class="[
                          'exchange_btn',
                          task.isExchange ? 'exchange_btn_active' : '',
                        ]"
                        @click="
                          onHandleTaskExchange(task.isExchange, taskIndex)
                        "
                        >兑换</span
                      >
                    </div>
                  </div>
                  <div class="my_collect_task_item_content">
                    <div
                      class="task_content_item"
                      v-for="taskcard in task.taskCardList"
                      :key="'task_' + taskcard.classID"
                    >
                      <div class="card_cover">
                        <van-image
                          width="100%"
                          height="100%"
                          fit="fill"
                          position="center"
                          :show-loading="false"
                          v-if="store.current_machine.product_name == 'bbl'"
                          :src="bblDifCover[taskcard.classID].icon"
                        />
                        <van-image
                          v-else
                          width="100%"
                          height="100%"
                          fit="fill"
                          position="center"
                          :show-loading="false"
                          :src="taskcard.url"
                        />
                      </div>
                      <div class="task_right">
                        <p class="card_name">{{ taskcard.cardName }}</p>
                        <p class="card_number">
                          <span class="aim">{{ taskcard.num }}</span>
                          <span>/{{ taskcard.condition }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div></template
      >
      <!--  横屏 -->
      <template v-if="props.islandscape">
        <div class="landscape_collect_pup_content">
          <div class="collect_pup_left_tab">
            <div
              v-for="tabItem in collectTabs"
              :key="tabItem.key"
              :class="[
                'collect_pup_left_tab_item',
                curCollectType == tabItem.key
                  ? 'collect_pup_left_tab_item_active'
                  : '',
              ]"
              @click="collectTypeChange(tabItem.key)"
            >
              <div
                class="tab_item_icon"
                :style="{
                  width: tabItem.iconWidth,
                }"
              >
                <van-image
                  :width="tabItem.iconWidth"
                  height="auto"
                  fit="cover"
                  position="center"
                  :src="tabItem.icon"
                  :show-loading="false"
                />
              </div>

              <div class="tab_item_text">
                {{ tabItem.text }}
              </div>
            </div>
          </div>

          <div class="collect_pup_right_content">
            <!-- 魔鬼城 -->
            <template v-if="store.current_machine.product_name == 'mgc'">
              <div class="mgc_collect_wrapper">
                <div
                  class="mgc_my_collect_card"
                  v-if="curCollectType == 'collect_my_card'"
                >
                  <div
                    class="mgc_my_collect_card_item"
                    v-for="(cardItem, cardIndex) in store.initCollectPageData[
                      store.current_machine.product_name
                    ].myCollectCards"
                  >
                    <div class="collect_card_cover">
                      <van-image
                        width="100%"
                        height="100%"
                        fit="fill"
                        position="center"
                        :show-loading="false"
                        :src="cardItem.url"
                      />

                      <div class="collect_card_number">X{{ cardItem.num }}</div>
                    </div>

                    <div class="collect_card_name">{{ cardItem.cardName }}</div>
                  </div>
                </div>
                <div
                  class="mgc_collect_card_task"
                  v-if="curCollectType == 'collect_card_task'"
                >
                  <div
                    class="mgc_collect_card_task_item"
                    v-for="(task, taskIndex) in store.initCollectPageData[
                      store.current_machine.product_name
                    ].collectCardTaskList"
                  >
                    <div class="mgc_collect_card_task_item_top">
                      <div class="mgc_collect_card_task_name">
                        {{ task.taskName }}
                      </div>
                      <div class="mgc_collect_card_task_exchange">
                        <span class="text">任务奖励</span>
                        <span class="award">{{ task.reward }}</span>
                        <span
                          :class="[
                            'exchange_btn',
                            task.isExchange ? 'exchange_btn_active' : '',
                          ]"
                          @click="
                            onHandleTaskExchange(task.isExchange, taskIndex)
                          "
                          >兑换</span
                        >
                      </div>
                    </div>
                    <div class="my_collect_task_item_content">
                      <div
                        class="task_content_item"
                        v-for="taskcard in task.taskCardList"
                        :key="'task_' + taskcard.classID"
                      >
                        <div class="card_cover">
                          <van-image
                            width="100%"
                            height="100%"
                            fit="fill"
                            position="center"
                            :show-loading="false"
                            :src="taskcard.url"
                          />
                        </div>
                        <div class="task_right">
                          <p class="card_name">{{ taskcard.cardName }}</p>
                          <p class="card_number">
                            <span class="aim">{{ taskcard.num }}</span>
                            <span>/{{ taskcard.condition }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!-- 爆爆乐 -->
            <template v-if="store.current_machine.product_name == 'bbl'">
              <div class="bbl_collect_wrapper">
                <div
                  class="bbl_my_collect_card"
                  v-if="curCollectType == 'collect_my_card'"
                >
                  <div
                    class="bbl_my_collect_card_item"
                    v-for="(cardItem, cardIndex) in store.initCollectPageData[
                      store.current_machine.product_name
                    ].myCollectCards"
                  >
                    <div class="collect_card_cover">
                      <van-image
                        width="100%"
                        height="100%"
                        fit="fill"
                        position="center"
                        :show-loading="false"
                        :src="cardItem.url"
                      />

                      <div class="collect_card_number">
                        {{ cardItem.cardName }}
                      </div>
                    </div>

                    <div class="collect_card_name" v-if="cardItem.classID != 4">
                      <div class="h-18" style="line-height: 18px">
                        {{ bblDifCover[cardItem.classID].name }}
                      </div>
                      <div class="w-18 h-18 mr-4 ml-4">
                        <van-image
                          width="100%"
                          height="100%"
                          fit="fill"
                          position="center"
                          :show-loading="false"
                          :src="bblDifCover[cardItem.classID].icon"
                        />
                      </div>
                      <div class="h-18" style="line-height: 18px">
                        X{{ cardItem.num }}
                      </div>
                    </div>
                    <div class="collect_card_name" v-if="cardItem.classID == 4">
                      <div class="h-18" style="line-height: 18px">
                        {{ bblDifCover[cardItem.classID].name }}
                      </div>
                      <div class="h-18" style="line-height: 18px">
                        &nbsp;&nbsp;X{{ cardItem.num }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="bbl_collect_card_task"
                  v-if="curCollectType == 'collect_card_task'"
                >
                  <div
                    class="bbl_collect_card_task_item"
                    v-for="(task, taskIndex) in store.initCollectPageData[
                      store.current_machine.product_name
                    ].collectCardTaskList"
                  >
                    <div class="bbl_collect_card_task_item_top">
                      <div class="bbl_collect_card_task_name">
                        {{ task.taskName }}
                      </div>
                      <div class="bbl_collect_card_task_exchange">
                        <span class="text">任务奖励</span>
                        <span class="award">{{ task.reward }}</span>
                        <span
                          :class="[
                            'exchange_btn',
                            task.isExchange ? 'exchange_btn_active' : '',
                          ]"
                          @click="
                            onHandleTaskExchange(task.isExchange, taskIndex)
                          "
                          >兑换</span
                        >
                      </div>
                    </div>
                    <div class="my_collect_task_item_content">
                      <div
                        class="task_content_item"
                        v-for="taskcard in task.taskCardList"
                        :key="'task_' + taskcard.classID"
                      >
                        <div class="card_cover">
                          <van-image
                            width="100%"
                            height="100%"
                            fit="fill"
                            position="center"
                            :show-loading="false"
                            :src="bblDifCover[taskcard.classID].icon"
                          />
                        </div>
                        <div class="task_right">
                          <p class="card_name">{{ taskcard.cardName }}</p>
                          <p class="card_number">
                            <span class="aim">{{ taskcard.num }}</span>
                            <span>/{{ taskcard.condition }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!-- 海王 -->
            <template v-if="store.current_machine.product_name == 'hw3'">
              <div class="mgc_collect_wrapper">
                <div
                  class="mgc_my_collect_card"
                  v-if="curCollectType == 'collect_my_card'"
                >
                  <div
                    class="mgc_my_collect_card_item"
                    v-for="(cardItem, cardIndex) in store.initCollectPageData[
                      store.current_machine.product_name
                    ].myCollectCards"
                  >
                    <div class="collect_card_cover">
                      <van-image
                        width="100%"
                        height="100%"
                        fit="fill"
                        position="center"
                        :show-loading="false"
                        :src="cardItem.url"
                      />

                      <div class="collect_card_number">X{{ cardItem.num }}</div>
                    </div>

                    <div class="collect_card_name">{{ cardItem.cardName }}</div>
                  </div>
                </div>
                <div
                  class="mgc_collect_card_task"
                  v-if="curCollectType == 'collect_card_task'"
                >
                  <div
                    class="mgc_collect_card_task_item"
                    v-for="(task, taskIndex) in store.initCollectPageData[
                      store.current_machine.product_name
                    ].collectCardTaskList"
                  >
                    <div class="mgc_collect_card_task_item_top">
                      <div class="mgc_collect_card_task_name">
                        {{ task.taskName }}
                      </div>
                      <div class="mgc_collect_card_task_exchange">
                        <span class="text">任务奖励</span>
                        <span class="award">{{ task.reward }}</span>
                        <span
                          :class="[
                            'exchange_btn',
                            task.isExchange ? 'exchange_btn_active' : '',
                          ]"
                          @click="
                            onHandleTaskExchange(task.isExchange, taskIndex)
                          "
                          >兑换</span
                        >
                      </div>
                    </div>
                    <div class="my_collect_task_item_content">
                      <div
                        class="task_content_item"
                        v-for="taskcard in task.taskCardList"
                        :key="'task_' + taskcard.classID"
                      >
                        <div class="card_cover">
                          <van-image
                            width="100%"
                            height="100%"
                            fit="fill"
                            position="center"
                            :show-loading="false"
                            :src="taskcard.url"
                          />
                        </div>
                        <div class="task_right">
                          <p class="card_name">{{ taskcard.cardName }}</p>
                          <p class="card_number">
                            <span class="aim">{{ taskcard.num }}</span>
                            <span>/{{ taskcard.condition }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </van-popup>
</template>

<script setup>
import { reactive, ref, onBeforeMount, computed } from "vue";

import { useStore } from "@/store/store";
import { showToast, closeToast } from "vant";
import { sendInstructions } from "@/api/sendData";
const store = useStore();

const mgc_my_card = store.imgBaseUrl + "/collect/mgc_my_card.png";
const mgc_card_task = store.imgBaseUrl + "/collect/mgc_card_task.png";

const bbl_dif_03 = store.imgBaseUrl + "/bbl/gem_green@2x.png";
const bbl_dif_04 = store.imgBaseUrl + "/bbl/gem_yellow@2x.png";
const bbl_dif_01 = store.imgBaseUrl + "/bbl/gem_red@2x.png";
const bbl_dif_02 = store.imgBaseUrl + "/bbl/gem_diamond@2x.png";
const bbl_dif_05 = store.imgBaseUrl + "/bbl/BianBao@2x.png";

const emit = defineEmits(["pupCloseIconClick"]);

const props = defineProps({
  pupshow: {
    type: Boolean,
    default: () => false,
  },
  game_name: {
    type: String,
    default: () => "mgc",
  },
  islandscape: {
    type: Boolean,
    default: () => false,
  },
});

const curCollectType = ref("collect_my_card");

const collectTabs = reactive([
  {
    text: "我的魂卡",
    icon: mgc_my_card,
    key: "collect_my_card",
    iconWidth: "25px",
  },
  {
    text: "我的魂卡",
    icon: mgc_card_task,
    iconWidth: "32px",
    key: "collect_card_task",
  },
]);

const pupOpenCallBack = () => {
  if (
    store.current_machine.product_name == "mxt" ||
    store.current_machine.product_name == "msqy"
  ) {
    collectTabs[0].text = "我的小丑卡";
    collectTabs[1].text = "欢乐园任务";
  }

  if (store.current_machine.product_name == "mgc") {
    collectTabs[0].text = "我的魂卡";
    collectTabs[1].text = "魂卡任务";
  }
  if (store.current_machine.product_name == "bbl") {
    collectTabs[0].text = "我的爆爆卡";
    collectTabs[1].text = "爆爆堂任务";
  }
  if (store.current_machine.product_name == "hw3") {
    collectTabs[0].text = "我的鱼卡";
    collectTabs[1].text = "捕鱼任务";
  }
  if (store.current_machine.product_name == "other") {
    collectTabs[0].text = "我的鱼卡";
    collectTabs[1].text = "捕鱼任务";
  }
  sendInstructions({ msg_type: "get_player_collect" });
};

const bblDifCover = [
  {
    name: "绯红水晶",
    icon: bbl_dif_01,
  },
  {
    name: "传奇宝石",
    icon: bbl_dif_02,
  },
  {
    name: "绿松石",
    icon: bbl_dif_03,
  },
  {
    name: "幸运宝珠",
    icon: bbl_dif_04,
  },
  {
    name: "连爆大挑战",
    icon: bbl_dif_05,
  },
];

const collect_icon_data = {
  hw3: store.imgBaseUrl + "/hw/collect_icon.png",
  mgc: store.imgBaseUrl + "/game/mgc_collect_icon.png",
  bbl: store.imgBaseUrl + "/bbl/bbl_collect_icon.png",
  mxt: store.imgBaseUrl + "/mxt/collect_icon.png",
  msqy: store.imgBaseUrl + "/mxt/collect_icon.png",
};
const collect_title_text_icon_data = {
  hw3: store.imgBaseUrl + "/hw/collect_title.png",
  mgc: store.imgBaseUrl + "/game/mgc_collect_title.png",
  bbl: store.imgBaseUrl + "/collect/bbl_title.png",
  mxt: store.imgBaseUrl + "/collect/mxt_title.png",
  msqy: store.imgBaseUrl + "/collect/mxt_title.png",
};

const collect_icon = computed(() => {
  return collect_icon_data[store.current_machine.product_name];
});

const collect_title_text_icon = computed(() => {
  return collect_title_text_icon_data[store.current_machine.product_name];
});

const collectTypeChange = (key) => {
  curCollectType.value = key;
};

const collectPupIconClose = () => {
  emit("pupCloseIconClick");
};

const pupStyle = props.islandscape
  ? {
      height: "80vw",
      width: "98vh",
      maxWidth: "98vh",
      background: "none",
      padding: "0 20px",
      transform: "translateX(-25%) translateY(-50%) rotate(90deg)",
      transformOrigin: "center center",
    }
  : {
      height: "80%",
      width: "100vw",
      maxWidth: "100vw",
      background: "none",
      padding: "0 20px",
    };

const onHandleTaskExchange = (isExchange, index) => {
  if (!isExchange) return;
  const params = {
    msg_type: "exchange_collect",
    index: index + 1,
    type: "collect",
    game_name: store.current_machine.product_name,
  };
  sendInstructions(params);
};
</script>

<style scoped lang="scss">
.collect_pup_wrapper {
  width: 100%;
  height: 100%;
  border-radius: 15px 15px 15px 15px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/mgc_collect_pup_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  // transform: translate();
  .collect_pup_title {
    width: 100%;
    height: 50px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    background: linear-gradient(
      180deg,
      #172f4e 0%,
      rgba(48, 132, 171, 0.26) 100%
    );
    display: flex;
    .collect_icon_wrapper {
      position: relative;
      width: 50px;
      height: 100%;

      .collect_icon {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 34px;
        height: 34px;
        transform: translate(-50%, -50%);
      }
    }

    .collect_pup_title_text {
      position: relative;
      width: 74px;
      height: 100%;
      .collect_pup_title_text_icon {
        min-width: 70px;
        height: 23px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .collect_tips {
      font-size: 12px;
      line-height: 60px;
      padding: 0 10px;
      color: #ffffff60;
    }
    .collect_pup_close_wrapper {
      position: relative;
      flex: 1;

      .collect_pup_close_icon {
        width: 40px;
        height: 40px;
        position: absolute;
        right: -12px;
        top: 0;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close2.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }

  // 竖屏的
  .collect_pup_content {
    width: 100%;
    flex: 1;
    overflow: hidden;

    .collect_pup_tab {
      width: 100%;
      height: 40px;

      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      .collect_pup_tab_item {
        flex: 1;
        position: relative;
        display: flex;
        justify-content: center;

        .collect_pup_tab_item_content {
          display: flex;
          .collect_pup_tab_item_cover {
            height: 40px;
            position: relative;
            width: 26px;
          }
          .collect_pup_tab_item_text {
            height: 40px;
            font-weight: bold;
            text-shadow: 0px 2px 1px #162e4d;
            text-wrap: nowrap;
            display: inline-block;
            line-height: 40px;
          }
        }
      }
    }
    .collect_pup_tab_left {
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/mxt_collect_left_active.png");
    }
    .collect_pup_tab_right {
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/mxt_collect_right_active.png");
    }

    .collect_pup_bottom {
      height: calc(100% - 40px);
      overflow: auto;
      .collect_wrapper {
        padding: 10px 20px;

        .my_collect_card {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .my_collect_card_item {
            width: 50%;
            height: 80px;
            display: flex;
            margin-top: 10px;
            .collect_card_item_left {
              flex: 1;
              padding: 8px;
              position: relative;
              width: 40%;
              .collect_card_item_left_cover {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background-color: #294c73;
                box-shadow: 0px 0px 1px 1px rgba(19, 45, 72, 0.6),
                  inset 0px 0px 3px 1px rgba(255, 255, 255, 0.25);
                padding: 8px;
                width: 62px;
                height: 62px;
                border-radius: 10px;
                overflow: hidden;
              }
            }

            .collect_card_item_right {
              flex: 1;
              padding: 12px 0 8px 4px;
              text-shadow: 0px 2px 1px #162e4d;

              .collect_card_name {
                font-size: 12px;
                font-weight: bold;
                text-overflow: ellipsis;
                text-wrap: nowrap;
                overflow: hidden;
              }
              .collect_card_number_text {
                margin-top: 4px;
                font-size: 10px;
                color: rgba(255, 255, 255, 0.6);
              }
              .collect_card_number {
                display: flex;
                font-size: 12px;
                color: #ffcb72;
                font-weight: bold;
                .collect_card_number_icon {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }
        .collect_card_task {
          .collect_card_task_item {
            background: #294c73;
            box-shadow: 0px 0px 1px 1px rgba(19, 45, 72, 0.6),
              inset 0px 0px 3px 1px rgba(255, 255, 255, 0.25);
            border-radius: 15px 15px 15px 15px;
            opacity: 1;
            border: 1px solid #15375c;
            margin-top: 10px;

            .collect_card_task_item_top {
              display: flex;
              justify-content: space-between;
              padding-right: 10px;
              .collect_card_task_name {
                padding: 4px 24px;
                background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/task_name_bg.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                color: #865534;
                font-weight: 800;
              }
              .collect_card_task_exchange {
                position: relative;
                padding: 4px 0;
                .text {
                  font-weight: bold;
                  color: #ffffff;
                }
                .award {
                  display: inline-block;
                  font-size: 12px;
                  color: #fee22a;
                  padding: 0 0 0 22px;
                  margin: 0 4px;
                  position: relative;
                  &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 20px;

                    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                  }
                }

                .exchange_btn {
                  width: 40px;
                  height: 18px;
                  background: linear-gradient(180deg, #edc484 0%, #f7ffe0 100%);
                  border-radius: 100px 100px 100px 100px;
                  display: inline-block;
                  color: #865534;
                  line-height: 18px;
                  text-align: center;
                  opacity: 0.5;
                }
                .exchange_btn_active {
                  opacity: 1 !important;
                }

                &::after {
                  content: "";
                  position: absolute;
                  left: 0;
                  bottom: -1px;
                  width: 100%;
                  height: 2px;
                  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/bottom_line.png");
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                }
              }
            }

            .my_collect_task_item_content {
              display: flex;
              padding: 15px 10px;

              flex-wrap: wrap;
              // justify-content: space-around;

              .task_content_item {
                // flex: 1;
                // width: 32%;
                display: flex;
                margin-top: 10px;
                width: 50%;
                // margin-right: 1%;
                // margin-right: 10px;
                .card_cover {
                  width: 40px;
                  height: 40px;
                  min-width: 40px !important;
                }
                .task_right {
                  font-size: 10px;
                  font-weight: bold;
                  color: #ffffff;
                  line-height: 12px;
                  text-shadow: 0px 2px 1px #162e4d;
                  padding-top: 6px;
                  padding-left: 4px;
                  overflow: hidden;
                  .card_name {
                    text-wrap: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                  .card_number {
                    margin-top: 4px;
                    .aim {
                      color: #ffcb72;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // 横屏的
  .landscape_collect_pup_content {
    flex: 1;
    overflow: hidden;
    display: flex;
    .collect_pup_left_tab {
      width: 65px;

      .collect_pup_left_tab_item {
        height: 80px;
        padding-top: 10px;
        .tab_item_icon {
          margin: 0 auto;
        }
        .tab_item_text {
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          margin-top: 6px;
          text-shadow: 0px 2px 2px rgba($color: #162e4d, $alpha: 0.4);
          background: linear-gradient(
            180deg,
            #83b1dc 0%,
            #91c0ef 52%,
            #83b1dc 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .collect_pup_left_tab_item_active {
        background: linear-gradient(90deg, #38a6d0 0%, #205a84 100%);
        .tab_item_text {
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          margin-top: 6px;
          text-shadow: 0px 2px 2px rgba($color: #162e4d, $alpha: 0.4);
          background: linear-gradient(
            180deg,
            #a1d3ff 0%,
            #ccecfb 38%,
            #6bade8 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .collect_pup_right_content {
      flex: 1;
      background: linear-gradient(
        90deg,
        #1d496c 0%,
        rgba(41, 70, 110, 0.4) 100%
      );
      height: 100%;
      overflow: auto;
      .mgc_collect_wrapper {
        padding: 10px 20px;

        .mgc_my_collect_card {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .mgc_my_collect_card_item {
            width: 15%;
            height: 90px;
            margin-top: 20px;
            background: #294c73;
            box-shadow: 0px 0px 1px 1px rgba(19, 45, 72, 0.6),
              inset 0px 0px 3px 1px rgba(255, 255, 255, 0.25);
            border-radius: 15px 15px 15px 15px;
            opacity: 1;
            border: 1px solid #15375c;
            padding: 10px 0;
            .collect_card_cover {
              width: 50px;
              height: 50px;
              position: relative;
              // border-radius: 10px;
              margin: 0 auto;
              .collect_card_number {
                position: absolute;
                bottom: 0;
                right: -4px;
                font-weight: bold;
                font-size: 12px;
                color: #fff;
                // width: 100%;
                // text-align: center;
                padding: 0 4px;
                border-radius: 2px;
                background-color: rgba(19, 45, 72, 0.6);
              }
            }

            .collect_card_name {
              color: #ffffff;
              font-weight: bold;
              margin-top: 4px;
              text-wrap: nowrap;
              text-align: center;
            }
          }
        }
        .mgc_collect_card_task {
          .mgc_collect_card_task_item {
            background: #294c73;
            box-shadow: 0px 0px 1px 1px rgba(19, 45, 72, 0.6),
              inset 0px 0px 3px 1px rgba(255, 255, 255, 0.25);
            border-radius: 15px 15px 15px 15px;
            opacity: 1;
            border: 1px solid #15375c;
            margin-top: 10px;
            .mgc_collect_card_task_item_top {
              display: flex;
              justify-content: space-between;
              padding-right: 10px;
              .mgc_collect_card_task_name {
                padding: 4px 24px;
                background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/task_name_bg.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                color: #865534;
                font-weight: 800;
              }
              .mgc_collect_card_task_exchange {
                position: relative;
                padding: 4px 0;
                .text {
                  font-weight: bold;
                  color: #ffffff;
                }
                .award {
                  display: inline-block;
                  font-size: 12px;
                  color: #fee22a;
                  padding: 0 0 0 22px;
                  margin: 0 4px;
                  position: relative;
                  &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 20px;

                    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                  }
                }

                .exchange_btn {
                  width: 40px;
                  height: 18px;
                  background: linear-gradient(180deg, #edc484 0%, #f7ffe0 100%);
                  border-radius: 100px 100px 100px 100px;
                  display: inline-block;
                  color: #865534;
                  line-height: 18px;
                  text-align: center;
                  opacity: 0.5;
                }
                .exchange_btn_active {
                  opacity: 1 !important;
                }

                &::after {
                  content: "";
                  position: absolute;
                  left: 0;
                  bottom: -1px;
                  width: 100%;
                  height: 2px;
                  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/bottom_line.png");
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                }
              }
            }

            .my_collect_task_item_content {
              display: flex;
              padding: 15px 10px;
              //   flex-wrap: wrap;
              justify-content: space-between;

              .task_content_item {
                // width: 18%;
                display: flex;
                margin-top: 10px;
                // margin-right: 10px;
                .card_cover {
                  width: 40px;
                  height: 40px;
                }
                .task_right {
                  font-size: 10px;
                  font-weight: bold;
                  color: #ffffff;
                  line-height: 12px;
                  text-shadow: 0px 2px 1px #162e4d;
                  padding-top: 6px;
                  padding-left: 4px;
                  .card_name {
                    text-wrap: nowrap;
                  }
                  .card_number {
                    margin-top: 4px;
                    .aim {
                      color: #ffcb72;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .bbl_collect_wrapper {
        padding: 0 10px 20px;

        .bbl_my_collect_card {
          display: flex;
          flex-wrap: wrap;
          //   justify-content: space-around;
          .bbl_my_collect_card_item {
            width: 28%;
            height: 100px;
            margin-right: 20px;
            margin-top: 20px;
            background: #294c73;
            box-shadow: 0px 0px 1px 1px rgba(19, 45, 72, 0.6),
              inset 0px 0px 3px 1px rgba(255, 255, 255, 0.25);
            border-radius: 15px 15px 15px 15px;
            opacity: 1;
            border: 1px solid #15375c;
            padding: 10px 0;
            .collect_card_cover {
              width: 100px;
              height: 60px;
              border-radius: 10px;
              overflow: hidden;
              position: relative;
              margin: 0 auto;
              .collect_card_number {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                text-align: center;
                font-weight: bold;
                font-size: 12px;
                color: #ffffff;
                text-shadow: 0px 2px 1px #162e4d;
              }
            }

            .collect_card_name {
              color: #ffffff;
              font-weight: bold;
              margin-top: 4px;
              text-wrap: nowrap;
              text-align: center;
              display: flex;
              justify-content: center;
            }
          }
        }
        .bbl_collect_card_task {
          .bbl_collect_card_task_item {
            background: #294c73;
            box-shadow: 0px 0px 1px 1px rgba(19, 45, 72, 0.6),
              inset 0px 0px 3px 1px rgba(255, 255, 255, 0.25);
            border-radius: 15px 15px 15px 15px;
            opacity: 1;
            border: 1px solid #15375c;
            margin-top: 10px;
            .bbl_collect_card_task_item_top {
              display: flex;
              justify-content: space-between;
              padding-right: 10px;
              .bbl_collect_card_task_name {
                padding: 4px 24px;
                background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/task_name_bg.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                color: #865534;
                font-weight: 800;
              }
              .bbl_collect_card_task_exchange {
                position: relative;
                padding: 4px 0;
                .text {
                  font-weight: bold;
                  color: #ffffff;
                }
                .award {
                  display: inline-block;
                  font-size: 12px;
                  color: #fee22a;
                  padding: 0 0 0 22px;
                  margin: 0 4px;
                  position: relative;
                  &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 20px;

                    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                  }
                }

                .exchange_btn {
                  width: 40px;
                  height: 18px;
                  background: linear-gradient(180deg, #edc484 0%, #f7ffe0 100%);
                  border-radius: 100px 100px 100px 100px;
                  display: inline-block;
                  color: #865534;
                  line-height: 18px;
                  text-align: center;
                  opacity: 0.5;
                }
                .exchange_btn_active {
                  opacity: 1 !important;
                }

                &::after {
                  content: "";
                  position: absolute;
                  left: 0;
                  bottom: -1px;
                  width: 100%;
                  height: 2px;
                  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/bottom_line.png");
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                }
              }
            }

            .my_collect_task_item_content {
              display: flex;
              padding: 15px 10px;
              //   flex-wrap: wrap;
              justify-content: space-around;

              .task_content_item {
                // width: 18%;
                display: flex;
                margin-top: 10px;
                // margin-right: 10px;
                .card_cover {
                  width: 40px;
                  height: 40px;
                }
                .task_right {
                  font-size: 10px;
                  font-weight: bold;
                  color: #ffffff;
                  line-height: 12px;
                  text-shadow: 0px 2px 1px #162e4d;
                  padding-top: 6px;
                  padding-left: 4px;
                  .card_name {
                    text-wrap: nowrap;
                  }
                  .card_number {
                    margin-top: 4px;
                    .aim {
                      color: #ffcb72;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
