<template>
  <div class="game_view_wrapper" @click="handleWrapperClick">
    <!-- 播放器 -->
    <Player ref="_Player" @ready-change="readyChange" />
    <!-- 消息通知 -->
    <MyNoticeBar :top="20" left="auto" right="auto" />
    <div id="rotate_box">
      <GameLoding v-if="!isReady" />

      <!-- 占位者 -->
      <div
        class="occupy_info_wrapper"
        v-if="
          store.gameIsWatch &&
          store.current_machine.occupy.length > 0 &&
          ui_control_show
        "
      >
        <div class="user_portrait">
          <HeadPortrait
            width="100%"
            height="100%"
            :src="
              store.current_machine.occupy[0].head ||
              String(store.current_machine.occupy[0].head_frame)
            "
            :level="Number(store.userInfo.recharge_level)"
          />
        </div>
        <div class="user_name">
          {{ decodeBase64String(store.current_machine.occupy[0].player_name) }}
          &nbsp;&nbsp;
          <span
            style="color: red; font-weight: bold"
            v-if="store.userInfo.is_gm"
            >UID: {{ store.current_machine.occupy[0].player_id }}</span
          >
        </div>
      </div>
      <WheelEntrance :right="80" :top="20" v-show="ui_control_show" />
      <Sprites
        :left="34"
        :top="112"
        :bg_x="-260"
        @_click="collectCardShowToggle(true)"
        v-show="ui_control_show"
      />
      <Sprites
        :left="86"
        :top="112"
        :bg_x="-312"
        @_click="rankPupShowToggle(true)"
        v-show="ui_control_show"
      />

      <!-- 围观者 -->
      <div
        class="visits_list_wrapper"
        v-if="store.current_machine.visits.length > 0 && ui_control_show"
      >
        <div class="visits_text_wrapper">围观：</div>

        <div
          class="visit_item_user_header"
          v-for="(visitsItem, vIndex) in store.current_machine.visits"
          :key="'visits_' + visitsItem.uid"
          :style="{
            transform: `translateX(-${vIndex * 25}%)`,
          }"
        >
          <HeadPortrait
            width="100%"
            height="100%"
            :src="visitsItem.head ? visitsItem.head : visitsItem.head_frame"
          />
        </div>
      </div>

      <!-- 观战计时 -->
      <WatchTime
        :watch_time="Number(store.current_machine.visit_time)"
        @onFinish="exitGameRequest"
        v-if="store.gameIsWatch && isReady && !store.userInfo.is_gm"
      />

      <!-- 菜单 -->
      <Menu
        ref="menud"
        @menuSoundClick="menuSoundClick"
        @handleExitEvent="handleExitEvent"
        @handle_update="handle_update_player"
        @handle_display="ui_control_show_toggle"
      />

      <!-- 时间 -->
      <ServerTime :right="40" :top="80" />

      <!-- 坐下-- -->
      <Seats v-if="store.gameIsWatch && isReady" />

      <!-- 坐下后的元素 -->
      <template v-if="!store.gameIsWatch && isReady">
        <!-- 顶部玩家信息 -->
        <TopInfo v-if="!store.gameIsWatch && isReady && ui_control_show" />

        <Control
          ref="childRef"
          @count-down-end="handleExitEvent"
          @fire-click="collectMonitoringClose"
          :level="4"
        />

        <!-- 房号和金币混合 -->
        <MinxGoldRoom v-show="ui_control_show" />
      </template>

      <!-- 收集提示 -->
      <CollectHint @callback="collectMonitoringStart" />

      <!-- 投币选择 -->
      <van-popup v-model:show="upcoinNumShow" style="background: none">
        <div class="upcoin_num_wrapper">
          <div class="upcoin_num_title">
            <p>选择投币次数</p>
          </div>
          <div class="upcoin_num_list">
            <div
              class="upcoin_num_item"
              v-for="upcoinItem in upcoinNumList"
              @click="upcoinRequsult(upcoinItem.num)"
            >
              <div class="upcoin_info">
                <span class="gold_icon"></span>
                <span class="gold_num">{{ upcoinItem.gold_num }}</span>
              </div>
              <div class="upcoin_num">
                投币&nbsp;{{ upcoinItem.num }}&nbsp;次
              </div>
            </div>
          </div>
          <div
            class="upcoin_num_tips"
            @click="
              () => {
                upcoinNumShow = false;
              }
            "
          >
            取消投币
          </div>
        </div>
      </van-popup>

      <!-- 等待游戏结果动画 -->
      <div class="await_tiaozhan_result" v-if="await_tiaozhan_result_show">
        <div class="result_content">
          <div class="w-141 h-141 m-auto relative">
            <div class="w-79 h-79 absolute-center">
              <van-image
                width="100%"
                height="auto"
                fit="fill"
                position="center"
                :show-loading="false"
                :src="await_tiaozhan_text"
              />
            </div>
            <div class="await_tiaozhan_loding width height">
              <van-image
                width="100%"
                :show-loading="false"
                height="auto"
                fit="fill"
                position="center"
                :src="await_tiaozhan_loding"
              />
            </div>
          </div>
          <div
            class="mt-12 h-20 f-13 t-center width"
            style="color: #fffab9; text-shadow: 1px 1px #945f00"
          >
            {{
              store.settlementResult.type == "normal"
                ? "游戏数据正在结算中，请稍后..."
                : "比赛数据正在结算中，请稍等..."
            }}
          </div>
        </div>
      </div>

      <!-- 继续挑战 -->
      <van-popup
        v-model:show="store.get_player_tiaozhan_info_show"
        round
        :close-on-click-overlay="false"
      >
        <div class="net_close_pup">
          <p class="close_title">温馨提示</p>
          <p class="close_content">请点击继续比赛...</p>
          <div class="close_btn" @click="continueChallenge">继续比赛</div>
        </div>
      </van-popup>
    </div>

    <!-- 收集 待优化的组件 -->
    <CollectCardPup
      :pupshow="collectCardShow"
      game_name="bbl"
      :islandscape="true"
      @pup-close-icon-click="collectCardShowToggle(false)"
    />
    <!-- 排行 待优化的组件 -->
    <PumpkinPup
      :pupshow="rankPupShow"
      :islandscape="true"
      @close-icon-click="rankPupShowToggle(false)"
    />
  </div>
</template>

<script setup>
import GameLoding from "@/components/GameLoding.vue";
import Player from "@/components/Player.vue";
import Menu from "@/components/Menu.vue";
import HeadPortrait from "@/components/HeadPortrait.vue";
import Seats from "@/components/Seats.vue";
import Control from "@/components/Control.vue";
import MyNoticeBar from "@/components/MyNoticeBar.vue";
// import UpcoinBtn from "@/components/UpcoinBtn.vue";
// import PlusBtn from "@/components/PlusBtn.vue";
// import FireBtn from "@/components/FireBtn.vue";
import WheelEntrance from "@/components/WheelEntrance.vue";
import WatchTime from "@/components/WatchTime.vue";

import TopInfo from "@/components/TopInfo.vue";
import ServerTime from "@/components/ServerTime.vue";
import MinxGoldRoom from "@/components/MinxGoldRoom.vue";
import CollectCardPup from "@/components/CollectCardPup.vue";
import PumpkinPup from "@/components/PumpkinPup.vue";
import Sprites from "@/components/Sprites.vue";
import btnClick from "@/assets/audios/btn_click.mp3";

import {
  asyncSendInstructions,
  sendInstructions,
  pollingGameinfo,
} from "@/api/sendData";
import CollectHint from "@/components/CollectHint.vue";
import { useCountDown } from "@vant/use";
import { onMounted, onBeforeUnmount, ref, watch, reactive } from "vue";
import { useStore } from "@/store/store";
import { useRouter } from "vue-router";
import { decodeBase64String } from "@/utils/utils";
import { Howl, Howler } from "howler";

import { showToast } from "vant";
import { controlRequest } from "@/api";
const store = useStore();
const router = useRouter();

const await_tiaozhan_text = store.imgBaseUrl + "/game/await_tiaozhan_text.png";
const await_tiaozhan_loding =
  store.imgBaseUrl + "/game/await_tiaozhan_loding.png";

//#region 播放器
const _Player = ref(null);
const isReady = ref(false);
let Onlookers = false;
const readyChange = (statu) => {
  isReady.value = statu;
  if (statu && !Onlookers && store.settlementResult.type == "normal") {
    Onlookers = true;
    sendInstructions(
      {
        msg_type: "enter_machine",
        game_name: store.current_machine.product_name,
        machine_index: store.current_machine.machine_index,
      },
      100
    );
  }
  store.channelType == "ios" && _Player.value && _Player.value.videoMuted();
};

const handle_update_player = () => {
  _Player.value?.handlePlayUpdata();
};
onMounted(() => {
  if (!store.get_player_tiaozhan_info_show) {
    _Player.value &&
      _Player.value.initPlayerVideo(store.current_machine.sp_url);
  }
});

//#endregion

//#region 收集识别
const collectCardShow = ref(false);
const collectCardShowToggle = (statu) => {
  collectCardShow.value = statu;
};

const rankPupShow = ref(false);
const rankPupShowToggle = (statu) => {
  rankPupShow.value = statu;
};

//#endregion

//#region 防重复收集监测
const monitoring_point = ref(false);
const getBblRightWinNumber = () => {
  const params = {
    device: store.current_device.device_name,
    product_id: store.current_machine.product_id,
    channel: 5,
    type: 2,
    times: 1,
    width: 500,
    interval: 80,
    uid: parseInt(store.userInfo.uid),
  };
  controlRequest(params);
};

const collectMonitoringCountDown = useCountDown({
  // 倒计时
  time: 30000,
  onFinish() {
    // 收分
    if (!childRef.value?.autoStatu && !await_tiaozhan_result_show.value)
      getBblRightWinNumber();
    monitoring_point.value && (monitoring_point.value = false);
  },
});

const collectMonitoringStart = () => {
  if (monitoring_point.value || await_tiaozhan_result_show.value) return;

  if (!childRef.value?.autoStatu) {
    monitoring_point.value = true;
    collectMonitoringCountDown.reset();
    collectMonitoringCountDown.start();
  }
};
const collectMonitoringClose = () => {
  if (!monitoring_point.value) return;

  monitoring_point.value = false;
  collectMonitoringCountDown.reset();
  collectMonitoringCountDown.pause();
};

//#endregion

//#region 菜单
const childRef = ref(null);
const menud = ref();
// 声音
const menuSoundClick = (statu) => {
  _Player.value && _Player.value.videoMuted(!statu);
};

//#endregion

//#region 游戏返回 退出 结束
let counting_key = store.notify_mqtt_counting_result_key;
const AwaitResultBackCountDownTime = 1e4;
const AwaitResultBackCountDown = useCountDown({
  // 倒计时
  time: AwaitResultBackCountDownTime,
  onFinish() {
    // 有结果直接退

    if (
      store.settlementResult.result_show ||
      store.settlementResult.type == "normal"
    ) {
      exitRoom();
    } else if (store.settlementResult.type != "normal") {
      // 没有结果
      window.set_data({
        msg_type: "return_lottery_result",
        info: {
          lottery: 0,
          game_name: store.current_machine.product_name,
          machine_index: store.current_machine.machine_index,
          device_name: store.current_device.device_name,
        },
      });
    }
  },
  onChange(current) {
    if (
      current.seconds == 7 &&
      counting_key == store.notify_mqtt_counting_result_key
    ) {
      counting_key = Math.round();
      getBblRightWinNumber();
      setTimeout(() => {
        exitGameRequest("exit");
        AwaitResultBackCountDown.reset();
        AwaitResultBackCountDown.start();
      }, 2e3);
    }
  },
});

// 等待挑战结果loding
const await_tiaozhan_result_show = ref(false);

// 退分/退出观战 协议
const exitGameRequest = (type = "visit") => {
  const backType = type;
  // 退出观战
  if (backType == "visit") {
    const leave_params = {
      msg_type: "leave_machine",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
    };
    sendInstructions(leave_params, 0);
    if (store.channelType == "ios") {
      sendInstructions(leave_params, 50);
      sendInstructions(leave_params, 100);
    }
  }
  // 退分
  if (backType == "exit") {
    // 退分 params
    const params = {
      msg_type: "return_lottery",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
      device_name: store.current_device.device_name,
    };

    // 关闭刷分监测
    monitoring_point.value && collectMonitoringClose();
    // 第一次
    sendInstructions(params, 0);
    // 第二次
    sendInstructions(params, 1000);
    // 第三次
    sendInstructions(params, 1800);
  }
};

watch(
  () => store.notify_mqtt_counting_result_key,
  (newV) => {
    if (await_tiaozhan_result_show.value && newV != 0) {
      AwaitResultBackCountDown.reset();
      AwaitResultBackCountDown.start();
    }
  }
);

const handleExitEvent = () => {
  // 观战直接退
  if (store.gameIsWatch) {
    isReady.value && exitGameRequest();
    router.replace("/home");
    return;
  }
  // 结算退出
  if (await_tiaozhan_result_show.value) return;
  await_tiaozhan_result_show.value = true;
  AwaitResultBackCountDown.start();
  exitGameRequest("exit");
};

// 退出房间！！！
const exitRoom = () => {
  store.initStatu();
  // console.log("333333333333333333333333333333333333333");
  router.replace({
    name: "home",
  });
};

//#endregion

//#region 投币
const upcoinNumList = [
  {
    gold_num: Number(store.current_machine.up_coin) * 1,
    num: 1,
  },
  {
    gold_num: Number(store.current_machine.up_coin) * 5,
    num: 5,
  },
  {
    gold_num: Number(store.current_machine.up_coin) * 10,
    num: 10,
  },
];
const upcoinNumShow = ref(false);
const upcoinRequsult = (num) => {
  upcoinNumShow.value = false;
  if (
    Number(store.userInfo.gold) >=
    Number(store.current_machine.up_coin) * num
  ) {
    //  投币
    const params = {
      msg_type: "up_coin",
      machine_index: store.current_machine.machine_index,
      game_name: store.current_machine.product_name,
      device_name: store.current_device.device_name,
      up_num: num,
    };

    sendInstructions(params);
  } else {
    showToast({
      type: "fail",
      message: "币量不足,请充值。",
      teleport: "#rotate_box",
      onClose() {
        store.payPupToggle(true);
      },
    });
  }
};

const upcoinClick = () => {
  upcoinNumShow.value = true;
};

//#endregion

// 继续挑战
const continueChallenge = () => {
  store.get_player_tiaozhan_info_show = false;
  _Player.value && _Player.value.initPlayerVideo(store.current_machine.sp_url);
};

// 全局点击
const handleWrapperClick = (evt) => {
  evt.stopPropagation();
  evt.preventDefault();
  menud.value && menud.value.menuOpenStatu && menud.value.menuStatuToggle(evt);
};

onMounted(() => {
  setTimeout(() => {
    pollingGameinfo({
      game_name: "bbl",
      statu: "start",
    });
  }, 1500);
});

onBeforeUnmount(() => {
  pollingGameinfo({
    game_name: "bbl",
    statu: "close",
  });
});

//#region 部分ui控制
const ui_control_show = ref(true);
let ui_flag = false;
const ui_control_show_toggle = () => {
  if (ui_flag) return;
  ui_flag = true;

  ui_control_show.value = !ui_control_show.value;
  setTimeout(() => {
    ui_flag = false;
  }, 5e2);
};

//#endregion
</script>

<style scoped lang="scss">
.game_view_wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
}

#rotate_box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vh;
  height: 100vw;
  transform: rotate(90deg) translateY(-100%);
  transform-origin: left top;
}

.upcoin_num_wrapper {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // transform: rotate(90deg);

  .upcoin_num_title {
    font-size: 20px;
    font-weight: bold;
  }

  .upcoin_num_list {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .upcoin_num_item {
      width: 80px;
      height: 80px;
      background: linear-gradient(180deg, #d7bdfa 0%, #9553ff 100%);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .upcoin_info {
        display: flex;
        justify-content: center;
        align-items: center;
        .gold_icon {
          width: 20px;
          height: 20px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
          background-size: 100% 100%;
          margin-right: 4px;
        }
        .gold_num {
          font-size: 14px;
          height: 20px;
        }
      }

      .upcoin_num {
        text-align: center;
        width: 100%;
        height: 20px;
      }
    }
  }

  .upcoin_num_tips {
    margin-top: 40px;
    font-size: 16px;
    color: #fff;
  }
}

// 结算结果
.await_tiaozhan_result {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  z-index: 99;

  .result_content {
    // w-185 absolute-center
    width: 185px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .await_tiaozhan_loding {
      width: 100%;
      height: 100%;

      animation: tiaozhan_result_loding 2s;
      animation-timing-function: linear;
      // animation-iteration-count: 1;
      animation-iteration-count: infinite;
    }
  }
}

// 围观者
.visits_list_wrapper {
  // display: flex;
  z-index: 4;
  position: absolute;
  right: 30%;
  top: 20px;
  width: 110px;
  height: 32px;

  .visits_text {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
    font-size: 12px;
  }

  .visit_item_user_header {
    position: absolute;
    top: 0;
    width: 32px;
    height: 32px;
    flex: 1;
    // position: relative;
    // left: -50%;
  }
}

@keyframes tiaozhan_result_loding {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes tiaozhan_result_loding

/* Safari 与 Chrome */ {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// 挂机提醒
.hang_hint_wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  width: 224px;
  // height: 108px;
  padding: 18px 15px 10px 15px;
  background-color: rgba($color: #000000, $alpha: 0.7);
  border-radius: 10px;
  z-index: 8;

  .timer_wrapper {
    font-size: 20px;
    text-align: center;
    color: #e69e22;
    font-weight: 1000;
  }
}

.occupy_info_wrapper {
  position: absolute;
  top: 50px;
  left: 15px;
  background-color: rgba($color: #000, $alpha: 0.4);
  border-radius: 40px;
  display: flex;
  .user_portrait {
    width: 50px;
    height: 50px;
  }
  .user_name {
    //   width: ;
    line-height: 50px;
    padding: 0 10px;
  }
}
</style>
