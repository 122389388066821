<template>
  <div
    class="forum_wrapper"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <div class="my_nav_bar_wrapper">
      <div class="nav_bar">
        <div class="nav_left_wrapper" @click="router.replace('/home')">
          <van-icon name="arrow-left" />
        </div>
        <div class="nav_text_wrapper"><span>极客秀</span></div>
        <div class="nav_right_wrapper">
          <div class="nav_right_icon" @click="goToUrl('create')">
            <van-image
              width="100%"
              height="100%"
              :show-loading="false"
              :src="create_forum_icon"
            />
          </div>
          <div class="nav_right_icon" @click="goToUrl('my')">
            <van-image
              width="100%"
              height="100%"
              :show-loading="false"
              :src="my_forums_icon"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="forum_list_wrapper">
      <van-notice-bar
        color="#fff"
        class="width f-12 mt-12"
        background="none"
        left-icon="volume-o"
        text="文明上网，理性发言，共建和谐文明交谈环境！"
      />
      <div class="forum_list">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="-------没有更多啦-------"
          @load="onLoadList"
        >
          <div
            class="forum_item"
            v-for="formItem in listData"
            :key="formItem.id"
          >
            <div class="author_wrapper">
              <HeadPortrait
                width="40px"
                height="40px"
                :level="Number(formItem.recharge_level)"
                :src="formItem.head_img_url"
                :ae_id="formItem.select_title"
                ae
                fe
              />
              <div class="author_name">
                <span>{{ formItem.user_name }}</span>
              </div>
              <div class="author_level">
                <Level
                  :border="false"
                  level_type="recharge_level"
                  :level_value="Number(formItem.recharge_level)"
                />
                <Level :border="false" :level_value="Number(formItem.level)" />
              </div>
            </div>

            <div class="forum_content">
              {{ formItem.content }}
            </div>
            <div class="forum_images">
              <div
                class="forum_image_item"
                v-for="(imgItem, imgIndex) in formItem.images"
                @click="handleImagePreview(formItem.images, imgIndex)"
              >
                <van-image
                  width="100%"
                  height="100%"
                  fit="contain"
                  :show-loading="false"
                  :src="imgItem"
                />
              </div>
            </div>
            <div class="time_like_wrapper">
              <div class="time">{{ formItem.create_time }}</div>
              <div
                class="like"
                @click="whenTheUserLike(formItem.id, formItem.liked_by_me)"
              >
                <div class="like_icon_wrapper">
                  <van-image
                    class="like_icon"
                    width="20px"
                    height="20px"
                    :show-loading="false"
                    :src="formItem.liked_by_me ? like_icon_active : like_icon"
                  />
                </div>

                <div class="count" style="margin-left: 4px">
                  {{ formItem.likes_count }}
                </div>
              </div>
            </div>

            <div class="liker_names" v-if="formItem.likers != ''">
              {{ formItem.likers }}
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { useRouter } from "vue-router";
import { onBeforeMount, ref } from "vue";
import { showImagePreview } from "vant";
import HeadPortrait from "@/components/HeadPortrait.vue";
import Level from "@/components/Level.vue";
import { gradLevelIcon } from "@/utils/levelData.js";

import { getForumList, likePost } from "@/api/index";
import like_icon from "@/assets/images/like_icon.png";
import like_icon_active from "@/assets/images/like_icon_active.png";

import create_forum_icon from "@/assets/images/create_forum_icon.png";
import my_forums_icon from "@/assets/images/my_forums_icon.png";

const store = useStore();
const router = useRouter();

const listData = ref([]);

const initListData = (dataArr) => {
  const dataClone = JSON.parse(JSON.stringify(dataArr));

  const initData = [];

  dataClone.forEach((item) => {
    const initDataObj = item;
    // initDataObj.headimgurl = `https://jikejingji.oss-cn-guangzhou.aliyuncs.com/headSculpture/${initDataObj.uid}.jpg`;

    initDataObj.images = initDataObj.images.split(";");
    initDataObj.likes_count = initDataObj.likers
      ? initDataObj.likers.length
      : 0;
    initDataObj.likers = initDataObj.likers
      ? initDataObj.likers.join("、")
      : "";

    // initData.recharge_level =

    // const _index = gradLevelIcon.findIndex((level) => {
    //   return level.total_recharge >= item.total_recharge;
    // });
    initDataObj.recharge_level = item.recharge_level;
    initData.push(initDataObj);
  });

  return initData;
};

// 预览
const handleImagePreview = (images, index) => {
  showImagePreview({
    images,
    startPosition: index,
  });
};

const page = ref(1);
const pageSize = ref(10);
const loading = ref(false);
const finished = ref(false);

const onLoadList = async () => {
  try {
    const res = await getForumList({
      uid: parseInt(store.userInfo.uid),
      form: "all",
      page: page.value,
      pageSize: pageSize.value,
    });

    loading.value = false;

    if (res.code == 200) {
      listData.value.push(...initListData(res.data));
      page.value = page.value + 1;
      res.data.length < 10 && (finished.value = true);
    }
  } catch (error) {
    loading.value = false;
    finished.value = true;
  }
};

const goToUrl = (type) => {
  if (type == "create") router.push("/createForum");
  else router.push("/myForums");
};

// 用户点赞文章
const whenTheUserLike = async (post_id, liked_by_me) => {
  if (liked_by_me) return;

  const params = {
    post_id: Number(post_id),
    uid: parseInt(store.userInfo.uid),
  };

  // 请求
  try {
    const res = await likePost(params);

    if (res.code == 200) {
      listData.value.some((item) => {
        if (item.id == post_id) {
          item.liked_by_me = true;

          let likers = item.likers;

          likers == ""
            ? (likers = store.userInfo.player_name)
            : (likers += `、${store.userInfo.player_name}`);

          item.likers = likers;
          item.likes_count += 1;
          return true;
        } else return false;
      });
    }
  } catch (error) {}
};

onBeforeMount(() => {});
</script>

<style lang="scss" scoped>
.forum_wrapper {
  background-color: #181627;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("@/assets/images/forum_top_1bg.png");

  min-height: 100vh;
}

.my_nav_bar_wrapper {
  padding: 0 30px 0 15px;

  .nav_bar {
    display: flex;
    height: 34px;
    line-height: 34px;
    .nav_left_wrapper {
      width: 28px;
      font-size: 20px;
    }
    .nav_text_wrapper {
      position: relative;
      flex: 1;
      font-size: 20px;
      span {
        position: relative;
        z-index: 2;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 66px;
        height: 12px;
        border-radius: 6px;
        z-index: 1;
        background: linear-gradient(270deg, #391284 0%, #871fba 100%);
      }
      &::before {
        content: "";
        position: absolute;
        top: 4px;
        left: 74px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        z-index: 1;
        background: linear-gradient(270deg, #391284 0%, #871fba 100%);
      }
    }
    .nav_right_wrapper {
      width: 72px;
      display: flex;
      justify-content: space-between;
      align-content: center;
      .nav_right_icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.forum_list_wrapper {
  padding: 0 15px;

  .forum_list {
    height: 80vh;
    overflow: auto;
  }

  .forum_item {
    background: linear-gradient(180deg, #20192b 0%, #212040 100%);
    border-radius: 10px;
    padding: 20px 15px;
    margin-top: 10px;
    overflow: hidden;
    .author_wrapper {
      display: flex;
      .author_name {
        margin: 0 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }

      .author_level {
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    .forum_content {
      margin-top: 15px;
      font-size: 14px;
    }

    .forum_images {
      margin-top: 15px;
      display: flex;
      justify-content: start;
      .forum_image_item {
        width: 31%;
        margin-right: 8px;
        height: 100px;
        background-color: #181627;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .time_like_wrapper {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      height: 24px;
      line-height: 24px;
      .time {
        font-size: 14px;
        color: rgba($color: #ffffff, $alpha: 0.5);
      }
      .like {
        font-size: 12px;
        display: flex;
        .like_icon_wrapper {
          width: 22px;
          position: relative;
          .like_icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        // .count {}
        // vertical-align: middle;
      }
    }

    .liker_names {
      margin-top: 15px;
      padding: 8px 15px 8px 32px;
      // display: -webkit-box; /* 必须设置display属性为-webkit-box */
      overflow: hidden; /* 超出部分隐藏 */
      text-overflow: ellipsis; /* 显示省略号 */
      // -webkit-line-clamp: 2; /* 限制显示两行 */
      // -webkit-box-orient: vertical; /* 垂直方向上的换行 */
      // word-break: keep-all;
      white-space: nowrap;
      color: rgba($color: #ffffff, $alpha: 0.7);
      background-color: #181627;
      font-size: 10px;
      font-weight: 500;
      border-radius: 6px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 10px;
        top: 50%;
        height: 14px;
        width: 14px;
        transform: translateY(-50%);
        background-size: 100% 100%;
        background-image: url("@/assets/images/like_icon_active.png");
      }
    }
  }
}
</style>
