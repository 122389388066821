<template>
  <div class="outer_wrapper">
    <NavBar fixed />

    <div class="recharge_icon" @click="goToR"></div>

    <div class="in_focus_bg_img" v-show="in_focus_bg_img_show">
      <van-image
        width="100%"
        height="auto"
        block
        :show-loading="false"
        :src="guoqing_bg_max"
        @load="bg_load_succ"
      />

      <div class="rule_msg">
        活动截止时间：10月7日23点59分;<br />
        1.月饼说明：在活动期间玩家获得所有奖项为月饼，每获得1个奖励，+1个月饼。注：中秋假期月饼X2 <br />
        2.宝箱说明：宝箱分为铁宝箱、铜宝箱、银宝箱、金宝箱、钻石宝箱，每个宝箱需要消耗对应的月饼或宝石，宝箱开启后获得随机宝物奖励 。<br />
        3.宝石说明：宝石分为蓝宝石、绿宝石、红宝石。宝石可在游戏《秘境夺宝》中通过开启宝箱获得和在限时充值活动中购买宝石礼包获得。<br />
      </div>
    </div>

    <div class="pay_list_wrappe">
      <div
        class="pay_list_item"
        v-for="(_v, _k) in paylist"
        :key="_v.product_id"
      >
        <van-image
          width="100%"
          height="auto"
          :show-loading="false"
          block
          :src="gqItemImgs[_k]"
        />

        <div class="pay_item_btn" @click="selectPayItem(_v, _k)"></div>
      </div>

      <div class="cc_icon">
        <van-image
          width="100%"
          height="auto"
          block
          :show-loading="false"
          :src="cc_icon"
        />
      </div>
    </div>

    <!-- 购买确认 -->
    <van-popup
      v-model:show="confirmPupShow"
      :close-on-click-overlay="false"
      position="bottom"
      round
      closeable
      :style="{
        backgroundColor: '#f7f8fa',
        overflow: 'hidden',
      }"
    >
      <div class="pay_confirm_wrapper">
        <div class="pay_title">充值金币</div>

        <div class="price_container">
          <div class="level_wrapper">
            <span>您是 </span>
            <span>{{
              store.gradLevels[store.userInfo.recharge_level - 1].level_text
            }}</span>
            <span> 等級用戶,充值额外获得 </span>
            <span class="bold"
              >{{ store.initPayPupData.userPayInfo.extraGoldRatio }}%</span
            >
          </div>
          <div class="pay_price">
            <span class="price">￥{{ currentSelectPayItem.price }}</span>
          </div>
          <div class="get_gold_wrapper">
            <p>
              本次充值可获得
              <span class="f-16" style="color: #e2c820">
                {{ currentSelectPayItem.gold }}
              </span>
              <span class="f-16" style="color: #e2c820">
                + {{ currentSelectPayItem.extraGold }}
              </span>

              电玩币
            </p>
          </div>
        </div>

        <div class="confirm_pay_btn" @click="confirmPay">确认充值</div>
        <p class="tips">注意：未成年人禁止在app内充值消费</p>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
//  活动充值页
import NavBar from "@/components/NavBar.vue";
const guoqing_bg_max =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/activeRecharge/guoqing_bg_max.png";

const gq_item_1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/activeRecharge/gq_item_1.png";
const gq_item_2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/activeRecharge/gq_item_2.png";
const gq_item_3 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/activeRecharge/gq_item_3.png";
const gq_item_4 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/activeRecharge/gq_item_4.png";
const gq_item_5 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/activeRecharge/gq_item_5.png";

const cc_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/activeRecharge/cc_icon.png";

// import Decimal from "decimal.js";
import { asyncSendInstructions, sendInstructions } from "@/api/sendData";
import { playerRechargeStatus } from "@/api/index";
import { useStore } from "@/store/store";
import { ref, onBeforeMount, reactive, computed } from "vue";
import { closeToast, showToast } from "vant";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

//#region 渐加载高清图
const in_focus_bg_img_show = ref(false);
const bg_load_succ = () => {
  in_focus_bg_img_show.value = true;
};

//#endregion

const goToR = () => {
  router.replace("/guoqing");
};

const paylist = ref({});

const gqItemImgs = {
  1: gq_item_1,
  2: gq_item_2,
  3: gq_item_3,
  4: gq_item_4,
  5: gq_item_5,
};

const getData = async () => {
  showToast({
    type: "loading",
    duration: 0,
    forbidClick: true,
  });

  try {
    const res = await asyncSendInstructions({
      msg_type: "get_player_recharge_status",
    });

    const dev = store.channelType == "ios" ? "ios" : "android";

    console.log("resssss", res);

    const { player_recharge_status, recharge_def } = res;

    const boughtIndexList = Object.keys(player_recharge_status.guoqing);

    const _guoqing = recharge_def[dev].guoqing;

    const new_guoqing = {};

    for (const key in _guoqing) {
      const item = _guoqing[key];

      new_guoqing[key] = {
        ...item,
        isPay: !boughtIndexList.includes(key),
      };
    }

    paylist.value = new_guoqing;
    console.log("_guoqing", new_guoqing);

    closeToast();
  } catch (error) {
    console.log("error", error);
    closeToast();
  }
};

const confirmPupShow = ref(false);
const currentSelectPayItem = reactive({
  challengeCard: 0,
  daily_gold: 0,
  days: 0,
  gold: 0,
  isPay: false,
  price: "0.00",
  product_id: "jk_LNfk0cQOrOw0AdRj",
  type: "0",
  yield: 0,
  extraGold: 0,
  payIndex: 0,
});

const selectPayItem = (data, index) => {
  // if (!data.isPay) return;
  // console.log("data", data);

  // return;

  const extraGold = parseInt(
    (data.gold * store.initPayPupData.userPayInfo.extraGoldRatio) / 100
  );
  currentSelectPayItem.payIndex = index;
  currentSelectPayItem.gold = data.gold;
  currentSelectPayItem.extraGold = extraGold;
  currentSelectPayItem.price = data.rmb;
  currentSelectPayItem.product_id = data.product_id;

  confirmPupShow.value = true;
};

// 确认支付
const confirmPay = async () => {
  // 实名认证
  if (!store.userInfo.id_verification && !store.userInfo.is_gm) {
    confirmPupShow.value = false;
    store.authUserShowToggle(true);
    return;
  }

  confirmPupShow.value = false;
  showToast({
    type: "loading",
    forbidClick: true,
    message: "正在充值,\n请稍后...",
    duration: 0,
  });

  // const { data } = await playerRechargeStatus({
  //   uid: parseInt(store.userInfo.uid),
  // });

  // if (data.guoqing[currentSelectPayItem.payIndex]) {
  //   closeToast();
  //   showToast({
  //     type: "fail",
  //     message: "购买上限！",
  //     onClose() {
  //       getData();
  //     },
  //   });

  //   return;
  // }

  // 充值~
  const params = {
    msg_type: "player_recharge",
    type: "guoqing",
    index: currentSelectPayItem.payIndex,
    product_id: currentSelectPayItem.product_id,
    item_id: "",
    area_name: "jk",
  };

  sendInstructions(params, 0);
};

onBeforeMount(() => {
  getData();
});
</script>

<style lang="scss">
.outer_wrapper {
  position: relative;
  min-height: 1566px;
  // min-height: 120vh;
  // padding-bottom: 20px;
  // background-color: #0c0733;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/activeRecharge/guoqing_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .recharge_icon {
    position: fixed;
    position: -webkit-fixed; /* Safari */
    top: 180px;
    right: 10px;
    z-index: 3;
    width: 55px;
    height: 55px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/open_box_icon.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .in_focus_bg_img {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;

    .rule_msg {
      position: absolute;
      bottom: 50px;
      left: 0;
      // font-weight: 800;
      height: 314px;
      padding: 20px 22px;
      font-size: 14px;
      color: #fdd9a6;
      line-height: 26px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }

  .pay_list_wrappe {
    position: absolute;
    left: 50%;
    top: 360px;
    transform: translateX(-50%);
    width: 342px;
    height: 700px;
    z-index: 1;
    // background-color: pink;
    // overflow: visible;

    .cc_icon {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(22px, 26px);
      width: 86px;
      // height: 126px;
      // background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/activeRecharge/cc_icon.png");
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      z-index: 3;
    }

    .pay_list_item {
      position: relative;
      .pay_item_btn {
        position: absolute;
        bottom: 10px;
        right: 46px;
        width: 120px;
        height: 48px;
        // background-color: pink;
        z-index: 3;
      }
    }
  }
}

// 充值确认
.pay_confirm_wrapper {
  transform-origin: left top;
  overflow-y: auto;
  padding: 0 28px;
  .pay_title {
    padding: 8px 0;
    font-size: 20px;
    font-weight: 800;
    color: #724026;
    text-align: center;
  }

  .price_container {
    position: relative;
    margin-top: 26px;
    background-color: #fff;
    border-radius: 10px;
    height: 104px;
    .level_wrapper {
      position: absolute;
      top: -14px;
      left: 0;
      height: 28px;
      line-height: 28px;
      padding: 0 4px;
      border-radius: 14px;
      background: linear-gradient(270deg, #f8887e 0%, #e2487c 100%);
      color: #fff;
      font-size: 12px;
    }

    .pay_price {
      font-size: 28px;
      padding-top: 18px;
      width: 100%;
      text-align: center;

      color: #f91111;
      font-weight: 800;
      .cost {
        // color: #f8f8f8;
        font-size: 20px;
        text-decoration: line-through;
      }
    }

    .get_gold_wrapper {
      font-size: 14px;
      min-height: 37px;
      // line-height: 37px;
      padding-top: 4px;
      font-weight: 500;
      text-align: center;
      background: linear-gradient(90deg, #7c69f2 0%, #b28ce7 100%);
      color: #fff;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  .use_card_wrapper {
    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;
  }

  .tips {
    text-align: center;
    color: #a0a0a0;
    font-size: 12px;
    padding: 4px;
  }

  .confirm_pay_btn {
    height: 44px;
    background: linear-gradient(270deg, #f8887e 0%, #e2487c 100%);
    line-height: 44px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    border-radius: 22px;
    margin: 20px 0;
  }

  // .pay_tips {
  //   text-align: center;
  //   color: #a0a0a0;
  //   padding-bottom: 10px;
  // }

  // .pay_price {
  //   width: 90%;
  //   margin: 0 auto;
  //   background: #ffffff;
  //   box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  //   border-radius: 10px;
  //   height: 56px;
  //   line-height: 56px;
  //   color: #ff3450;
  //   text-align: center;
  //   font-size: 24px;
  //   font-weight: 1000;
  // }

  // .get_gold_wrapper {
  //   margin-top: 16px;
  //   margin-bottom: 16px;
  //   color: #9c5317;
  //   text-align: center;
  //   font-size: 14px;
  //   font-weight: 800;
  //   white-space: nowrap;
  // }

  // .card_detail {
  //   padding: 0 60px;
  //   color: #9c5317;
  //   // color: rgb(226, 200, 32);
  //   margin-top: 10px;
  //   margin-bottom: 10px;
  //   font-weight: 800;
  //   text-align: center;
  // }
  // .confirm_pay {
  //   width: 90%;
  //   margin: 0 auto;
  //   background: linear-gradient(90deg, #e6dbff 0%, #b798fa 100%);
  //   height: 46px;
  //   border-radius: 23px;
  //   line-height: 46px;
  //   text-align: center;
  //   color: #1d0053;
  //   font-weight: 800;
  //   font-size: 18px;
  // }
}
</style>
