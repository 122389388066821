<template>
  <div
    class="container pb-20 home"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <div class="user_info_wrapper">
      <div class="user_info_left">
        <HeadPortrait
          :level="Number(store.userInfo.recharge_level)"
          :src="store.userInfo.headimgurl"
          ae
          fe
          :ae_id="store.userInfo.select_title"
          width="100%"
          height="100%"
        />
      </div>
      <div class="user_info_content">
        <div class="user_name">{{ store.userInfo.player_name }}</div>
        <div class="user_level_wrapper">
          <Level
            style="margin-right: 4px"
            level_type="recharge_level"
            :level_value="Number(store.userInfo.recharge_level)"
          />
          <Level :level_value="Number(store.userInfo.level)" />
        </div>
        <div class="wallet_lottery">
          <UserWallet type="lottery" />
        </div>
        <div class="wallet_gold">
          <UserWallet />
        </div>
      </div>
    </div>

    <!-- 安卓苹果控制的 -->
    <div class="mt-8"></div>
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item
        v-for="item in bannerData.banner"
        :key="item.key"
        @click="swipeItemClick(item.key, item.imageUrl)"
      >
        <div class="width h-84">
          <van-image
            :show-loading="false"
            class="b-rounded of-hidden"
            width="100%"
            height="100%"
            :src="item.imageUrl"
          />
        </div>
      </van-swipe-item>
    </van-swipe>

    <template v-if="store.channelType == 'ios' || store.channelType == 'zyw'">
      <van-notice-bar
        background="none"
        color="#Fff"
        v-if="calculateTimeMantissa()"
      >
        <div class="flex">
          <div class="flex-1 flex" v-for="noticeItem in noticeList">
            <span class="mr-8">恭喜</span>

            <HeadPortrait
              :frameshow="false"
              :src="noticeItem.head"
              :width="'22px'"
              :height="'22px'"
              :ae_id="noticeItem.select_title"
              ae
              fe
            />
            <span class="ml-8 mr-4" style="color: #fae523">{{
              noticeItem.userName
            }}</span>
            <span class="mr-4">在{{ noticeItem.gameName }}</span>
            <span class="mr-4" style="color: #fae523"
              >获得了{{ noticeItem.content }}</span
            >
            <span>&nbsp;&nbsp;</span>
          </div>
        </div>
      </van-notice-bar>
      <van-notice-bar
        v-else
        color="#fff"
        class="width f-12"
        background="none"
        scrollable
        :text="bannerData.notice"
      />

      <MenuList />

      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="3000"
        :touchable="false"
        :show-indicators="false"
      >
        <van-swipe-item v-for="barItem in lotteryList">
          <div class="notice-swipe-item">
            <div class="notice_head">
              <HeadPortrait :src="barItem.head" width="100%" height="100%" />
            </div>
            <div class="notice_text">
              <span class="mr-2" style="color: #ffdf7c"
                >{{ barItem.userName }}:</span
              >
              <span>
                {{ `在${barItem.gameName}${barItem.machine_index}号房` }}</span
              >
              <span>获得了</span>

              <span class="lottery_style">{{ barItem.lottery }}</span>

              <div class="lottery_icon"></div>
            </div>
          </div>

          <!-- <p>
              <span>{{ barItem.userName }}</span>
              <span>
                {{ `在${barItem.gameName}${barItem.machine_index}号房` }}</span
              >
              <span v-if="barItem.device_index != ''"
                >{{ barItem.device_index }}号位</span
              >
              <span>获得了{{ barItem.lottery }}彩劵</span>
            </p> -->
        </van-swipe-item>
      </van-swipe>

      <!-- 热门活动 -->
      <div class="active_list_wrapper">
        <!-- 轮播 -->
        <swiper
          :slides-per-view="2"
          :space-between="10"
          :modules="activeModules"
          autoplay
          loop
          navigation
          :pagination="{ clickable: false, type: 'bullets' }"
        >
          <swiper-slide v-for="activeItem in activeListBanner">
            <div
              class="swiper_item_cotent"
              @click="activeTypeClick(activeItem)"
            >
              <van-image
                width="100%"
                height="auto"
                :src="activeItem.banner"
                block
                :show-loading="false"
              />
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="user_online_list_wrapper">
        <div class="online_list">
          <div
            class="online_item"
            v-for="onlineItem in userOnlineData"
            :key="onlineItem.uid"
          >
            <div class="online_head_wrapper">
              <div class="online_head">
                <HeadPortrait
                  :src="
                    onlineItem.headimgurl == ''
                      ? onlineItem.headFrame
                      : onlineItem.headimgurl
                  "
                  width="100%"
                  height="100%"
                />
              </div>

              <div class="online_icon">
                <span class="mr-2">热玩</span>
                <i class="hot_icon"></i>
                <i class="hot_icon"></i>
                <i class="hot_icon"></i>
              </div>
            </div>
            <div class="online_name">
              {{ onlineItem.player_name }}
            </div>
          </div>

          <div class="online_item">
            <div class="online_head_wrapper" style="opacity: 0">
              <div class="online_head"></div>

              <div class="online_icon">
                <span class="mr-2">热玩</span>
              </div>
            </div>
            <div class="online_name" style="opacity: 0">1233</div>

            <i class="more_icon_one"></i>
            <i class="more_icon_to"></i>
            <i class="more_icon_cc"></i>
          </div>
        </div>
      </div>
    </template>

    <GameList />

    <van-popup
      v-model:show="store.get_player_tiaozhan_info_show"
      round
      :close-on-click-overlay="false"
      z-index="2000"
    >
      <div class="net_close_pup">
        <p class="close_title">温馨提示</p>
        <p class="close_content">您正在比赛中,请返回</p>
        <div class="close_btn" @click="continueChallenge">返回比赛</div>
      </div>
    </van-popup>

    <!-- 在线客服图片 -->
    <ServicePup
      :show="serverPupShow"
      @close_icon_click="serverPupShowChange(false)"
      :landscape="false"
    />

    <van-popup
      v-model:show="new_version_show"
      style="background: none; max-width: 100vw"
    >
      <div class="new_version_pup">
        <div class="c_btn_1" @click="new_version_toggle(false)"></div>
        <div class="c_btn_2" @click="goToUpdata"></div>

        <div class="no_show_wrap">
          <van-checkbox v-model="checked" checked-color="#D977FF"
            >我已更新不再提醒</van-checkbox
          >
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model:show="store.cardNotifyData.show"
      style="
        background: none;
        width: 100%;
        max-width: 100vw;
        padding: 0;
        margin: 0;
        height: 460px;
      "
      @closed="card_closed"
    >
      <div class="card_notify">
        <van-image
          :show-loading="false"
          width="100%"
          height="100%"
          src="https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/card_notify.png"
          @click="card_closed"
        />
        <!-- <div class="card_notify_list">
          <div
            class="card_notify_item"
            v-for="notifyItem in store.cardNotifyData.list"
          >
            <van-image
              width="100%"
              height="auto"
              :src="notify_card_jiacheng"
              v-if="notifyItem.type == 'jiacheng_card'"
            />
            <van-image
              width="100%"
              height="auto"
              :src="notify_card_jifen"
              v-if="notifyItem.type == 'jifen_card'"
            />
            <van-image
              width="100%"
              height="auto"
              :src="notify_card_zhekou"
              v-if="notifyItem.type == 'zhekou_card'"
            />

            <p class="mt-4 t-center bold">
              {{ notifyItem.card_use_name }}
            </p>
          </div>
        </div> -->
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import HeadPortrait from "@/components/HeadPortrait.vue";
import ServicePup from "@/components/ServicePup.vue";
import Level from "@/components/Level.vue";
import MenuList from "@/components/MenuList.vue";
import GameList from "@/components/GameList.vue";
import UserWallet from "@/components/UserWallet.vue";

import findGameInfoData from "@/utils/gameInfoMap";
import { useStore } from "@/store/store";
import { findCollectCardName } from "@/utils/utils";
import { sendInstructions } from "@/api/sendData";
import { onBeforeUnmount, onMounted, onBeforeMount, ref, reactive } from "vue";
import { closeToast, showToast, showConfirmDialog } from "vant";
import { decode } from "js-base64";
import { useRouter } from "vue-router";
import { homeBanner, userOnline, mailStatus, getSys } from "@/api";
const defaultHead =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/user_info.png";
const store = useStore();
const router = useRouter();

const new_version_show = ref(false);
const checked = ref(false);
const new_version_toggle = (statu) => {
  new_version_show.value = statu;
};

onMounted(() => {
  if (store.channelType == "ios") {
    const nv = localStorage.getItem("noV");
    new_version_show.value = !nv;
  }
});

const goToUpdata = () => {
  if (checked.value) {
    localStorage.setItem("noV", true);
    new_version_show.value = false;
    return;
  }

  //   if (!statu && checked.value) {
  //   localStorage.setItem("noV", true);
  // }

  sendInstructions({
    msg_type: "open_ios_store",
  });
};

const card_closed = () => {
  store.$patch((state) => {
    state.cardNotifyData.show = false;
    state.cardNotifyData.listdata = [];
  });

  router.push("/cardbag");
};

const serverPupShow = ref(false);

// sendInstructions(
//   {
//     msg_type: "get_player_tiaozhan_info",
//   },
//   0
// );

const continueChallenge = () => {
  const game_name = store.settlementResult.game_name;
  if (!game_name) {
    store.get_player_tiaozhan_info_show = false;

    return;
  }

  sendInstructions(
    {
      msg_type: "get_machine_info",
      game_name: game_name,
    },
    50
  );

  showToast({
    type: "loading",
    message: "请稍后...",
    duration: 15000,
    forbidClick: true,
  });

  let isTrue = false;

  setTimeout(() => {
    isTrue = store.gameDataList[game_name].some((item) => {
      return item.device_info.some((dev) => {
        if (dev.occupy.player_id == parseInt(store.userInfo.uid)) {
          closeToast();
          store.$patch((state) => {
            state.gameIsWatch = false;
            state.current_machine = item;
            state.current_device = dev;
            state.settlementResult.game_name = item.product_name;
            state.settlementResult.device_name = dev.device_name;
          });
          return true;
        } else return false;
      });
    });

    isTrue &&
      router.push({
        name: game_name,
      });

    if (!isTrue) {
      showToast({
        type: "fail",
        message: "比赛已结束",
      });
      store.get_player_tiaozhan_info_show = false;
    }
  }, 2000);
};

const serverPupShowChange = (type) => {
  serverPupShow.value = type;
};

function getImageFileNameWithoutExtensionFromUrl(url) {
  // 使用正则表达式匹配URL中的文件名（不包括扩展名）
  // 这个正则表达式首先匹配文件名（包括扩展名），然后捕获扩展名之前的部分
  const regex = /([^/?#]+)\.[^/?#]+$/;
  const matches = url.match(regex);

  // 如果找到匹配项，返回文件名（不包括扩展名）；否则返回null或空字符串
  return matches && matches[1] ? matches[1] : "";
}

const swipeItemClick = (key, imageUrl) => {
  const urlName = getImageFileNameWithoutExtensionFromUrl(imageUrl);

  switch (urlName) {
    case "swipe_4":
      router.push("/welfareLobby");
      break;
    case "swipe_11":
      serverPupShowChange(true);
      break;
    case "swipe_10":
      router.replace("/my");
      break;
  }
};

const userOnlineData = ref([]);
const bannerData = reactive({
  banner: [
    {
      imageUrl:
        "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/other/swipe_2.png",
      key: 1,
    },
  ],
  notice:
    "本平台提供纯娱乐体验服务，禁止卖币/买币，违规账号系统将自行封控处理!请合理安排游戏时间!",
});

//#region  活动轮播
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css/pagination";

const activeBanner1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/active_banner_1.png";
const activeBanner2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/active_banner_2.png";
const activeBanner3 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/active_banner_3.png";

const activeBanner6 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/active_banner_6.png";
const activeBanner7 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/active_banner_7.png";
const activeBanner9 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/active_banner_9.png";
const activeBanner10 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/active_banner_10.png";
const activeBanner11 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/active_banner_11.png";

const activeModules = [Pagination, Navigation, Autoplay];
const activeListBanner = reactive([
  {
    type: "guoqing",
    banner: activeBanner11,
    start_time: 0,
    end_time: 0,
  },
  {
    type: "active_r",
    banner: activeBanner10,
    start_time: 0,
    end_time: 0,
  },
  {
    type: "welfare",
    banner: activeBanner1,
    start_time: 0,
    end_time: 0,
  },
  {
    type: "challenge",
    banner: activeBanner2,
    start_time: 0,
    end_time: 0,
  },
]);

// 活动页面跳转
const activeTypeClick = (item) => {
  switch (item.type) {
    case "challenge":
      router.replace("/challenge");
      break;
    case "mary":
      router.push({
        path: "/mary",
        query: {
          start_time: item.start_time,
          end_time: item.end_time,
        },
      });
      break;

    case "active_r":
      router.push("/rRecharge");
      break;
    case "guoqing":
      router.push("/guoqing");
      break;

    case "dsp":
      // router.push("/tasss");
      break;
    default:
      router.push("/welfareLobby");
      break;
  }
};

//#endregion
onBeforeMount(() => {
  getSysNoticeData();

  userOnline().then((res) => {
    if (Array.isArray(res)) {
      const arr = res || [];

      arr.sort(() => Math.random() - 0.5);
      arr.length > 10 && (arr.length = 10);
      userOnlineData.value = arr;
    }
  });

  homeBanner({
    type: store.channelType == "ios" ? "ios" : "android",
  }).then((res) => {
    if (res.banner) {
      bannerData.banner = res.banner;
      bannerData.notice = res.notice;
    }
  });

  mailStatus().then((res) => {
    if (res.data.mail) {
      activeListBanner.unshift({
        type: "mary",
        banner: activeBanner3,
        start_time: res.data.mail.start_time,
        end_time: res.data.mail.end_time,
      });
    }

    if (res.data.magpie) {
      activeListBanner.unshift({
        type: "magpie",
        banner: activeBanner6,
        start_time: res.data.magpie.start_time,
        end_time: res.data.magpie.end_time,
      });
    }

    if (res.data.guoqing) {
      store.$patch((state) => {
        state.yuebing_mul_start_time = res.data.guoqing.yuebing_mul_start_time;
        state.yuebing_mul_end_time = res.data.guoqing.yuebing_mul_end_time;
      });
    }
  });
});

// 获取公告

const noticeList = ref([]);
const lotteryList = ref([]);

const getSysNoticeData = async () => {
  const res = await getSys();

  console.log("res", res);

  const s_typeTexts = [
    {
      type: "login_reward",
      text: "登录奖励",
    },
    {
      type: "recharge",
      text: "充值",
    },
    {
      type: "up_coin",
      text: "投币",
    },
    {
      type: "base",
      text: "基础礼包",
    },
    {
      type: "day",
      text: "每日礼包",
    },
    {
      type: "first",
      text: "首冲礼包",
    },
    {
      type: "card",
      text: "周卡/月卡奖励",
    },
    {
      type: "apply_tiaozhan",
      text: "参加挑战",
    },
    {
      type: "recharge_card",
      text: "购买周卡/月卡",
    },
    {
      type: "exchange",
      text: "积分兑换",
    },
    {
      type: "gift",
      text: "特惠礼包",
    },
    {
      type: "code_reward",
      text: "兑换码奖励",
    },
    {
      type: "weal_box",
      text: "福利罐奖励",
    },
    {
      type: "task",
      text: "任务奖励",
    },
    {
      type: "tiaozhan",
      text: "挑战赛",
    },
    {
      type: "tiaozhan_success",
      text: "挑战成功",
    },
    {
      type: "nangua_rank",
      text: "南瓜榜奖励",
    },
    {
      type: "bbl_rank",
      text: "爆爆乐高分榜奖励",
    },
    {
      type: "mxt_rank",
      text: "马戏团小丑榜奖励",
    },
    {
      type: "recharge_level",
      text: "充值等级",
    },
    {
      type: "recharge_level",
      text: "充值等级",
    },
    {
      type: "invite_reward",
      text: "邀请奖励",
    },
    {
      type: "tianqi_week",
      text: "天梯周榜奖励",
    },
    {
      type: "tianqi_month",
      text: "天梯月榜奖励",
    },
    {
      type: "tianqi_jidu",
      text: "天梯季榜奖励",
    },
    {
      type: "tianzhan_rank",
      text: "挑战排行榜奖励",
    },
    {
      type: "mxttribe_rank",
      text: "马戏团排行榜奖励",
    },
    {
      type: "mgctribe_rank",
      text: "魔鬼城排行榜奖励",
    },
    {
      type: "hw3tribe_rank",
      text: "海王3排行榜奖励",
    },
    {
      type: "hw3_rank",
      text: "海王3觉醒榜奖励",
    },
    {
      type: "bbltribe_rank",
      text: "爆爆乐排行榜奖励",
    },
    {
      type: "msqy_rank",
      text: "魔术奇缘排行榜",
    },
    {
      type: "collect",
      text: "收集任务兑换奖励",
    },
    {
      type: "tz_card",
      text: "挑战卡",
    },
    {
      type: "lottery_tz_rank",
      text: "积分挑战赛",
    },
    {
      type: "mali",
      text: "幸运小玛莉",
    },
    {
      type: "baoji",
      text: "爆机奖励",
    },
    {
      type: "foot",
      text: "囤币活动",
    },
    {
      type: "foot_vs",
      text: "欧洲杯大竞猜",
    },
  ];

  if (res.data) {
    const noticeLista = [];

    for (const key in res.data.sys) {
      const item = res.data.sys[key];
      const obj = {
        userName: item.param.player_name,
        gameName: findGameInfoData(item.param.game_name).game_name,
        head: item.param.head || item.param.head_frame,
        machine_index: item.param.machine_index,
        uid: item.param.uid,
        content: "",
      };

      if (item.param.type == "collect") {
        obj.content = findCollectCardName(
          item.param.class_id,
          item.param.game_name
        );
      } else {
        if (item.param.type == "tribe_rank") {
          item.param.type = item.param.game_name + item.param.type;
        }
        let s_typeName = s_typeTexts.find(
          (s_typeItem) => s_typeItem.type == item.param.type
        );

        if (!s_typeName) {
          s_typeName = {
            text: "游戏排名",
          };
        }

        obj.content = `${s_typeName.text}第${item.param.index}名 ${item.param.reward}电玩币`;
      }

      noticeLista.push(obj);
    }

    noticeList.value = noticeLista;
    const lotteryLista = [];
    for (const k in res.data.game) {
      const gameItem = res.data.game[k];
      const dataInfo = {
        userName: gameItem.param.player_name,
        gameName: findGameInfoData(gameItem.param.game_name).game_name,
        lottery: gameItem.param.lottery || gameItem.param.reward,
        machine_index: gameItem.param.machine_index || gameItem.param.index,
        head: gameItem.param.head || String(gameItem.param.head_frame),
      };

      Number(dataInfo.lottery > 0) && lotteryLista.push(dataInfo);
    }
    lotteryList.value = lotteryLista;
  }
};

const calculateTimeMantissa = () => {
  // console.log("store.send_marquee_config.show", store.send_marquee_config.show);
  if (store.send_marquee_config.show) {
    bannerData.notice = store.send_marquee_config.content;
    return false;
  }

  const currentTime = new Date(); // 创建一个表示当前日期和时间的对象
  const minutes = currentTime.getMinutes(); // 获取当前时间的分
  const arr = String(minutes).split("");
  const Mantissa = arr[arr.length - 1];
  return Number(Mantissa) < 5;
};

// 查看是否拥有卡券通知

if (store.cardNotifyData.first) {
  store.cardNotifyData.first = false;
  sendInstructions({
    msg_type: "get_item_notify",
  });
}

onMounted(() => {});
</script>

<style lang="scss" scoped>
.home {
  min-height: 100%;
}

.new_version_pup {
  position: relative;
  width: 300px;
  height: 368px;
  background-image: url("@/assets/images/new_version_pup.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .c_btn_1,
  .c_btn_2 {
    position: absolute;
    top: 276px;
    width: 130px;
    height: 42px;
    left: 13px;
    background-image: url("@/assets/images/c_btn_2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .c_btn_2 {
    left: auto;
    right: 13px;
    background-image: url("@/assets/images/c_btn_1.png");
  }
  .no_show_wrap {
    position: absolute;
    bottom: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.card_notify {
  position: relative;
  width: 100%;
  height: 440px;

  .card_notify_list {
    position: absolute;
    top: 190px;
    left: 50%;
    width: 230px;
    height: 100px;
    transform: translateX(-48%);
    // background-color: pink;
    display: flex;
    justify-content: center;
    .card_notify_item {
      width: 80px;
    }
  }
}

.notice-swipe {
  margin-top: 10px;
  height: 30px;
  line-height: 30px;

  .notice-swipe-item {
    // width: 300px;
    height: 100%;
    border-radius: 16px;
    background: linear-gradient(89deg, #a451ff 0%, #860789 100%);
    // overflow: hidden;
    display: flex;
    .notice_head {
      width: 26px;
      height: 26px;
    }
    .notice_text {
      margin-left: 4px;
      flex: 1;
      font-size: 12px;
      display: flex;
      letter-spacing: 1px;
      .lottery_style {
        font-weight: 1000;
        font-size: 14px;
        margin-left: 2px;

        background: linear-gradient(180deg, #fff1bd 0%, #ffcf44 100%);

        // text-shadow: 0px 2px 0px #ae6209;
        background-clip: text;
        -webkit-background-clip: text;

        -webkit-text-fill-color: transparent;
      }

      .lottery_icon {
        margin-left: 2px;
        width: 26px;
        height: 26px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/ticket.png");
        background-size: 100% 100%;
      }
    }
  }
}

.user_online_list_wrapper {
  width: 100%;
  margin-top: 4px;
  .online_list {
    padding: 6px 0;
    height: 100%;
    white-space: nowrap;
    text-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    text-wrap: nowrap;

    &::-webkit-scrollbar {
      width: 0;
    }
    .online_item {
      position: relative;
      display: inline-block;
      width: 32px;
      height: 100%;
      // float: left;
      margin-right: 8px;

      .online_head_wrapper {
        position: relative;
        width: 32px;
        height: 32px;
        padding: 1px;
        border-radius: 16px;
        background-image: linear-gradient(
          180deg,
          rgba(127, 0, 255, 1),
          rgba(229, 71, 255, 1)
        );

        .online_head {
          width: 100%;
          height: 100%;
          border-radius: 16px;
          padding: 2px;
          background-color: #3a1b67;
        }

        .online_icon {
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 8px;
          width: 100%;
          height: 14px;
          line-height: 14px;
          text-align: center;

          background: linear-gradient(90deg, #952bdd 0%, #6e1fc2 100%);
          border-radius: 8px;
          z-index: 12;
          span {
            display: inline-block;

            color: #fff;
          }
          .hot_icon {
            display: inline-block;
            width: 2px;
            height: 6px;
            border-radius: 2px;
            background-color: #fff;
            margin-right: 1px;
            transform: translateY(25%);
            animation: hotAinmeis 0.8s infinite;
            animation-direction: reverse;

            &:nth-child(1) {
              // animation: delay;
              animation-delay: 0.2s;
            }
            &:nth-child(2) {
              // animation: delay;
              animation-delay: 0.4s;
            }
            &:nth-child(3) {
              // animation: delay;
              animation-delay: 0.6s;
            }
          }
        }
      }
      .online_name {
        text-align: center;
        margin-top: 2px;
        font-size: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:last-child {
        // background: red;

        .more_icon_one,
        .more_icon_to,
        .more_icon_cc {
          position: absolute;
          display: block;
          width: 8px;
          height: 8px;

          border-radius: 4px;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          z-index: 333;
        }
        .more_icon_one {
          background-color: #cc36ff;
        }
        .more_icon_to {
          left: 50% !important;
          transform: translate(-50%, -50%);
          // background-color: transparent !important;
          background-image: linear-gradient(
            93deg,
            #cc36ff 0%,
            #cc36ff 70%,
            rgba(58, 27, 103, 0) 100%
          ) !important;
        }
        .more_icon_cc {
          left: auto !important;
          right: 0 !important;
          // background-color: transparent !important;
          background-image: linear-gradient(
            90deg,
            #cc36ff 0%,
            transparent 80%,
            transparent 100%
          );
        }
      }
    }
  }
}

.active_list_wrapper {
  margin-top: 6px;
  width: 100%;
  height: 152px;
  background-image: url("@/assets/images/active_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 44px;
  padding-left: 14px;
  padding-right: 14px;
  // display: flex;
  // justify-content: space-between;
  .swiper_item_cotent {
    height: 100px;
  }

  // .active_swipe_item {
  //   width: 100%;
  //   height: 100%;
  //   display: flex;
  //   justify-content: space-between;
  //   .active_list_item {
  //     // flex: 1;
  //     width: 49%;
  //     height: 100px;

  //     .img_cover {
  //       width: 100%;
  //       height: auto;
  //       max-height: 84px;
  //     }
  //   }
  // }
}

@keyframes hotAinmeis {
  0% {
    height: 0px;
  }
  100% {
    height: 8px;
  }
}

:root:root {
  --van-notice-bar-padding: 0;
}
.user_info_wrapper {
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;
  .user_info_left {
    width: 40px;
    height: 40px;
  }

  .user_info_content {
    flex: 1;
    height: 100%;
    position: relative;
    // padding-left: 10px;
    .user_name {
      position: absolute;
      left: 10px;
      bottom: 26px;
      font-size: 12px;
      font-weight: bold;
    }

    .user_level_wrapper {
      position: absolute;
      left: 10px;
      bottom: 6px;
      height: 14px;

      display: flex;
    }

    .wallet_lottery {
      position: absolute;
      right: 0;
      top: 15px;
    }
    .wallet_gold {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
