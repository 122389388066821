<template>
  <van-overlay :show="store.child_info.child_show" z-index="2000">
    <div class="child_model">
      <div
        class="child_pup"
        :style="{
          top: showKeyboard ? '10%' : '20%',
        }"
      >
        <div class="child_container">
          <div class="chidl_close_icon" @click="child_model_pup_close"></div>
          <div class="child_img">
            <van-image
              :src="child_icon"
              :show-loading="false"
              width="100%"
              height="100%"
            />
          </div>
          <div class="child_title">
            <span class="child_title_text">青少年模式</span>
            <span class="child_open" v-if="store.child_info.is_open"
              >已开启</span
            >
            <span class="child_close" v-else>未开启</span>
          </div>
          <div class="child_v_text">
            <p>功能限制</p>
            <p>1.无法进行游戏和充值</p>
            <p>
              2.仅可在周五、周六、周日和法定节假日的20:00-21:00使用本APP部分功能
            </p>
          </div>
          <div class="child_password">
            <p class="pt-18 pb-18">
              请设置4位数字密码<span v-if="!store.child_info.is_open">开启</span
              ><span v-if="store.child_info.is_open">关闭</span>青少年模式
            </p>

            <van-password-input
              :error-info="errorInfo"
              :value="passWordKey"
              :length="4"
              :gutter="6"
              :mask="false"
              @focus="
                () => {
                  showKeyboard = true;
                }
              "
              :focused="showKeyboard"
            />
          </div>

          <div
            class="child_btn_wrapper van-haptics-feedback"
            @click="handleChildModelToggle"
          >
            <div
              class="child_btn"
              :style="{
                opacity: passWordKey.length < 4 ? '.3' : '1',
              }"
            >
              <span v-if="store.child_info.is_open">关闭青少年模式</span>
              <span v-else>开启青少年模式</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 数字键盘 -->
      <van-number-keyboard
        style="color: #3e4056"
        v-model="passWordKey"
        :show="showKeyboard"
        @blur="
          () => {
            showKeyboard = false;
          }
        "
        z-index="1002"
      />
    </div>
  </van-overlay>
</template>

<script setup>
import { useStore } from "@/store/store";
import { sendInstructions } from "@/api/sendData";
import { ref, watch } from "vue";
import { showToast } from "vant";
import { useRouter } from "vue-router";
const router = useRouter();
const store = useStore();
const passWordKey = ref("");
const errorInfo = ref("");
const showKeyboard = ref(false);

const child_icon = store.imgBaseUrl + "/myview/child_icon.png";
const child_model_pup_close = () => {
  if (store.child_info.is_open) {
    showToast({
      type: "fail",
      message: "请先解除青少年模式",
    });
    return;
  }

  store.$patch((state) => {
    state.child_info.child_show = false;
  });
};

const handleChildModelToggle = () => {
  if (passWordKey.value.length < 4) return;

  if (store.child_info.error_num >= 10) {
    showToast({
      type: "fail",
      message: "错误已达上限,请联系客服解除！",
    });
    return;
  }

  sendInstructions(
    {
      msg_type: "open_child",
      password: passWordKey.value,
      type: store.child_info.is_open ? "close" : "open",
    },
    0,
    true
  );

  passWordKey.value = "";
};
</script>

<style lang="scss" scoped>
.child_model {
  width: 100%;
  height: 100%;
  position: relative;
  .child_pup {
    position: absolute;
    left: 50%;
    padding-top: 52px;
    transform: translateX(-50%);
    transition: top 0.3s;
    min-height: 500px;
    color: #3e4056;
    .child_container {
      width: 300px;
      padding-bottom: 15px;
      position: relative;
      background: linear-gradient(
        180deg,
        #bbd8f9 0%,
        #ffffff 58%,
        #eef6f9 100%
      );
      border-radius: 30px;
      padding-top: 60px;
      padding-left: 26px;
      padding-right: 26px;
      .child_img {
        position: absolute;
        left: 50%;
        top: -60px;
        transform: translateX(-50%);
        width: 120px;
        height: 120px;
      }

      .child_title {
        display: flex;
        justify-content: center;
        height: 28px;
        align-items: center;
        .child_title_text {
          font-weight: 800;
          margin-right: 4px;
          font-size: 16px;
        }
        .child_open {
          font-size: 16px;
          background: linear-gradient(90deg, #f2cc55 0%, #db932c 100%);
          color: #fff;
          font-size: 10px;
          height: 14px;
          line-height: 14px;
          padding: 0 4px;
          border-radius: 2px;
        }
        .child_close {
          font-size: 16px;

          background: linear-gradient(90deg, #d4d4d4 0%, #9c9c9c 100%);
          color: #fff;
          font-size: 10px;
          height: 14px;
          line-height: 14px;
          padding: 0 4px;
          border-radius: 2px;
        }
      }

      .child_v_text {
        margin-top: 6px;
      }

      .child_password {
        // margin-top: 18px;

        :deep(.van-password-input__security li) {
          border: 1px solid #3e4056;
          border-radius: 4px;
        }
      }

      .child_btn_wrapper {
        margin-top: 20px;
        .child_btn {
          color: #fff;
          font-weight: bold;
          width: 200px;
          height: 46px;
          margin: 0 auto;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/myview/child_model_btn.png");
          font-size: 16px;
          line-height: 46px;
          text-align: center;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }

    .chidl_close_icon {
      position: absolute;
      top: -13px;
      right: -6px;
      height: 40px;
      width: 40px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}
</style>
