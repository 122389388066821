<template>
  <van-popup
    style="width: 100%; height: 100%; background: none"
    v-model:show="props.show"
  >
    <div class="outermost_layer">
      <div class="center_container">
        <div class="close_icon" @click="closePup"></div>
        <p class="title">极客福利官</p>
        <div class="content">
          <p class="hint_text">
            <i class="weixin_icon"></i>
            <span>添加极客福利官</span>
          </p>
          <div class="qrcode_wrapper">
            <van-image width="100%" height="100%" :src="ServiceQRCode" />
          </div>
        </div>

        <div class="footer">
          <div class="confirm_btn" @click="confirm">查看大图</div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
const emits = defineEmits(["close_icon_click"]);
import { showImagePreview } from "vant";
const qrCode =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/qrcode2.png";

const props = defineProps({
  show: {
    type: Boolean,
    default: () => false,
  },
  landscape: {
    type: Boolean,
    default: () => false,
  },
});

const ServiceQRCode = qrCode;

const confirm = async () => {
  showImagePreview({
    images: [ServiceQRCode],
    closeable: true,
    maxZoom: 1,
    minZoom: 1,
    showIndicators: false,
    closeOnPopstate: false,
    closeOnClickOverlay: false,
  });
  // 模拟从服务器获取图片数据
  // const imageUrl = qrCode;
  // const response = await fetch(imageUrl);
  // const blob = await response.blob();
  // const url = URL.createObjectURL(blob);

  // // 创建一个隐藏的a标签来下载图片
  // const a = document.createElement("a");
  // a.href = url;
  // a.download = "jike_service_qrcode.png"; // 可以自定义文件名
  // a.style.display = "none";
  // document.body.appendChild(a);
  // a.click(); // 触发点击事件以开始下载
  // document.body.removeChild(a);

  emits("close_icon_click");
};

const closePup = () => {
  emits("close_icon_click");
};
</script>

<style lang="scss" scoped>
.outermost_layer {
  width: 100%;
  height: 100%;
  position: relative;
  .center_container {
    border-radius: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: center center;
    background: linear-gradient(180deg, #bbd8f9 0%, #ffffff 58%, #eef6f9 100%);
    padding: 30px 16px 20px 16px;
    width: 90vw;
    height: auto;
    transform: translate(-50%, -50%);
    .close_icon {
      position: absolute;
      top: -10px;
      right: 0;
      width: 40px;
      height: 40px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .title {
      font-size: 24px;
      color: #3967ff;
      font-weight: 1000;
      text-align: center;
    }

    .content {
      margin-top: 20px;
      margin-bottom: 34px;
      color: #3e4056;
      font-size: 16px;
      text-align: center;

      .hint_text {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 24px;
        font-size: 14px;
        margin: 10px 0;
        .weixin_icon {
          width: 24px;
          height: 24px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/weixin_icon.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }

      .qrcode_wrapper {
        min-height: 90px;
        min-width: 90px;
        width: 143px;
        height: 143px;
        background-color: #b5b5b5;
        margin: 0 auto;

        img {
          pointer-events: auto !important;
          user-select: auto !important;
          -webkit-user-select: auto !important;
          -ms-user-select: auto !important;
          touch-callout: auto !important;
          -webkit-touch-callout: auto !important;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      .confirm_btn {
        line-height: 42px;
        color: #fff;
        width: 164px;
        height: 42px;
        background: linear-gradient(90deg, #81c3ff 0%, #5034ff 100%);
        border-radius: 22px;
        text-align: center;
        font-size: 16px;
      }
    }
  }
}
</style>
