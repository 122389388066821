<template>
  <van-popup
    v-model:show="store.auth_user_show"
    :style="{ background: 'none' }"
    :close-on-click-overlay="false"
    round
  >
    <div class="auth_user_wrapper" v-if="!store.userInfo.id_verification">
      <div class="auth_user_cotainer">
        <div class="auth_user_title">实名认证 :</div>
        <div class="auth_user_content">
          <p>亲爱的玩家：</p>
          <p style="text-indent: 2em">
            为了防止未成年人沉迷游戏、过度消费等行为，保护未成年人身心健康成长，需填写有效身份证信息来完成实名认证。
          </p>
          <p style="text-indent: 2em">
            您的信息将用与判断是否成年，我们不会将此信息用于其他场景，请务必保证您的身份证信息为真实有效的本人信息，一经实名认证默认为成年人本人游玩。
          </p>
        </div>

        <div class="auth_user_info_wrapper">
          <div class="auth_user_info_input">
            <van-config-provider :theme-vars="themeVars">
              <van-field
                v-model="auth_user_info.userName"
                placeholder="真实姓名"
              />
              <van-field
                v-model="auth_user_info.userIDcard"
                maxlength="18"
                placeholder="身份证号码"
              />
            </van-config-provider>
          </div>

          <div
            class="confirm_btn van-haptics-feedback"
            @click="autoUserConfirm"
          >
            提交认证
          </div>
          <p class="tips">如认证不通过，请联系在线客服</p>
        </div>
      </div>
      <div class="auth_close_icon" @click="closeAuthPup"></div>
    </div>
    <div class="w-325 pb-30 relative" v-else>
      <van-image
        width="100%"
        height="auto"
        fit="contain"
        position="center"
        :show-loading="false"
        :src="store.imgBaseUrl + '/myview/authuser_ok_bg.png.png'"
      />
      <div class="confirm_ok_btn">联系客服</div>
      <div class="auth_close_icon" @click="closeAuthPup"></div>
    </div>
  </van-popup>
</template>

<script setup>
import { useStore } from "@/store/store";
import { reactive } from "vue";
import { showToast } from "vant";
import { sendInstructions } from "@/api/sendData";
import { encode } from "js-base64";
const store = useStore();

const emits = defineEmits(["close"]);
const themeVars = reactive({
  fieldInputTextColor: "#3e4056",
});

//#region 实名认证

function validateIdCard(idCard) {
  // 正则表达式匹配18位数字
  const regex =
    /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2]\d|3[0-1])\d{3}(\d|X|x)$/;

  // 如果不满足正则表达式，则不是有效的身份证号码
  if (!regex.test(idCard)) {
    return false;
  }

  // 提取17位数字
  const numbers = idCard.substring(0, 17);

  // 计算校验码
  const weight = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  const sum = numbers
    .split("")
    .reduce(
      (acc, char, index) => acc + (char.charCodeAt(0) - 48) * weight[index],
      0
    );
  const checkCode = Math.ceil(sum % 11) === 10 ? "X" : Math.ceil(sum % 11);

  // 检查校验码是否匹配
  return checkCode === idCard.charAt(17);
}
const auth_user_info = reactive({
  userName: "",
  userIDcard: "",
});

const autoUserConfirm = () => {
  if (auth_user_info.userIDcard.length != 18) {
    showToast({
      type: "fail",
      message: "请检查您的身份证号",
    });
    return;
  }

  if (auth_user_info.userName == "") {
    showToast({
      type: "fail",
      message: "请输入您的姓名",
    });
    return;
  }

  sendInstructions(
    {
      msg_type: "id_verification",
      name: encode(auth_user_info.userName),
      id: auth_user_info.userIDcard,
    },
    0,
    true
  );
  emits("close");
  //
};

const closeAuthPup = () => {
  // if (!store.userInfo.id_verification) return;
  emits("close");
};

//#endregion
</script>

<style lang="scss">
.auth_user_wrapper {
  width: 326px;
  // height: 469px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/myview/authuser_bg.png");
  background-size: 100% 469px;
  background-repeat: no-repeat;
  padding: 22px 14px 80px 12px;

  .auth_user_cotainer {
    width: 100%;
    height: 100%;
    // background-color: rgba($color: #f0d24e, $alpha: 0.3);
    padding: 20px;

    .auth_user_title {
      background: linear-gradient(90deg, #3967ff 0%, #7091ff 44%, #8c85ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 800;
      font-size: 24px;
    }
    .auth_user_content {
      color: #3e4056;
      font-size: 12px;
      font-weight: bold;
      margin-top: 10px;
    }
    .auth_user_info_wrapper {
      margin-top: 10px;
      .auth_user_info_input {
        // display: flex;
        // flex-direction: column;
        // color: #3e4056;
        .van-cell {
          border: 1px solid #e1e1e1;
          border-radius: 30px;
          margin-top: 10px;
          overflow: hidden;
        }
      }
    }

    .confirm_btn {
      width: 164px;
      height: 42px;
      background: linear-gradient(90deg, #ff819b 0%, #ff3450 100%);
      box-shadow: inset 0px -5px 13px 0px #fba6b2;
      border-radius: 100px 100px 100px 100px;
      opacity: 1;
      font-size: 16px;
      text-align: center;
      line-height: 42px;
      font-weight: 800;
      margin: 0 auto;
      margin-top: 10px;
    }

    .tips {
      margin-top: 10px;
      text-align: center;
      color: #ff425c;
      font-size: 12px;
    }
  }
}

.confirm_ok_btn {
  position: absolute;
  bottom: 22%;
  left: 50%;
  transform: translateX(-50%);
  width: 164px;
  height: 42px;
  border-radius: 22px;
  background: linear-gradient(90deg, #81c3ff 0%, #5034ff 100%);
  box-shadow: inset 0px -5px 13px 0px #bed9ff;
  text-align: center;
  font-size: 16px;
  line-height: 42px;
}
.auth_close_icon {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  width: 40px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close_icon.png");
  background-size: 100% 100%;
}
</style>
