<template>
  <div class="question_view_wrapper">
    <NavBar fixed />

    <div class="_n_container">
      <div class="type_outer_wrapper">
        <div class="type_active_block" ref="_Active_block"></div>
        <div class="type_wrapper">
          <div
            class="type_itme"
            :style="{
              color: cur_type == 0 ? '#eaecfe' : '#8f8f8f',
            }"
            @click="cur_type_toggle(0)"
          >
            常见问题
          </div>
          <div
            class="type_itme"
            :style="{
              color: cur_type == 1 ? '#eaecfe' : '#8f8f8f',
            }"
            @click="cur_type_toggle(1)"
          >
            游戏反馈
          </div>
        </div>
      </div>

      <div class="message_box_wrapper" v-show="cur_type == 0">
        <div class="left_type_nav">
          <div
            class="nav_item"
            v-for="(qItem, qIndex) in cur_question_list"
            :class="{
              nav_item_active: qIndex == cur_active_question,
            }"
            @click="cur_active_question_toggle(qIndex)"
          >
            <div class="text">
              {{ qItem.text }}
            </div>
          </div>
        </div>
        <div class="right_block_content">
          <div
            class="dialogue_item"
            v-for="d_item in cur_question_list[cur_active_question].datas"
          >
            <div class="question_wrapper">
              <div class="wen_icon"></div>
              <div class="question_text">{{ d_item.question }}</div>
            </div>

            <div class="answer_wrapper">
              <div class="d_icon"></div>
              <div class="answer_text" v-html="d_item.answer"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="feedback_wrapper" v-show="cur_type == 1">
        <div class="title">意见或问题：</div>
        <div class="textarea_wrapper">
          <van-config-provider :theme-vars="themeVars">
            <van-field
              v-model="mytext"
              rows="3"
              autosize
              type="textarea"
              placeholder="请输入您要反馈的问题或建议，提供截图更有助于我们快速为您解决问题"
            />
          </van-config-provider>
        </div>

        <div class="title">上传图片：</div>

        <div class="uploader_wrapper">
          <div
            class="add_img_wrapper"
            v-if="!imageShow"
            @click="whenUserSelectImges"
          >
            <van-image
              width="100%"
              height="100%"
              fit="fill"
              :src="add_img_icon"
              :show-loading="false"
            />
          </div>
          <div class="select_img_wrapper" v-else>
            <van-image
              class="relative z-1"
              width="100%"
              height="100%"
              fit="fill"
              :src="imageUrl"
              @click="handleImagePreview"
              :show-loading="false"
            />
            <van-icon
              class="delect_icon_wrapper"
              size="20"
              color="#fff"
              name="clear"
              @click="clearImgUrl"
            />
          </div>
        </div>

        <div class="title">联系手机号：</div>

        <div class="input_wrapper">
          <van-config-provider :theme-vars="themeVars">
            <van-field
              v-model="phoneNumber"
              type="number"
              placeholder="请输入您的联系方式"
            />
          </van-config-provider>
        </div>

        <div class="confirm_btn" @click="submit">提交</div>
      </div>

      <div class="bottom_block">
        <p class="service_text" @click="handleServiceClick">
          <span>仍有其他疑惑？</span
          ><span class="service_enter">点击联系在线客服</span>
        </p>
        <p>在线客服工作时间：10:00~20:00</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import { useStore } from "@/store/store";
import { reactive, ref } from "vue";
import { asyncSendInstructions } from "@/api/sendData";
import { addFeedBack } from "@/api";
import { showToast, closeToast } from "vant";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const _Active_block = ref(null);
const cur_type = ref(0);

const cur_type_toggle = (type) => {
  cur_type.value = type;

  if (_Active_block.value) {
    _Active_block.value.style.transform = `translateX(${type ? 100 : 0}%)`;
  }
};

const cur_active_question = ref(0);

const themeVars = {
  fieldInputTextColor: "#000",
  fieldPlaceholderTextColor: "#00000060",
};

const cur_active_question_toggle = (type) => {
  cur_active_question.value = type;
};

const cur_question_list = reactive([
  {
    type: "common",
    text: "常见问题",
    datas: [
      {
        question: "1.如何获取更多游戏币和福利？",
        answer:
          "①游戏里的福利厅限时领,首页连续签到,以及抖音快手福利码均可每日领取电玩币；②应用好评、关注公众号、添加福利官企微等都会有相应电玩币赠送,每周企微会不定期赠送福利码；③用户通过完成任务,参与挑战赛,充值、福利罐等活动可获取额外的电玩币。",
      },
      {
        question: "2.福利码是什么？如何进行兑换？",
        answer:
          "福利码可以用来兑换电玩币。玩家可以通过极客竞技公众号菜单,游戏福利-兑换码处找到兑换入口进行兑换,亦可联系在线客服获取福利码兑换详情页链接。",
      },
      {
        question: "3.充值不到账怎么办？",
        answer:
          "充值不到账请第一时间请提供充值订单号、付款截图给客服,客服收到反馈会在24小时内作处理。",
      },
      {
        question: "4.充值后请求申请退款可行吗？",
        answer:
          "APP设置了青少年模式,严禁未成年游玩和充值。年满18周岁的玩家,可以进行免费的游戏体验。游戏内充值消费,需要玩家成年并进行个人实名认证,充值需要确认金额和二次确认操作,才算作充值成功。因此,已经正常充值的费用不支持退款。",
      },
      {
        question: "5.如何正确下机,中途可退出游戏吗？",
        answer:
          "正确下机操作应该是点击右上角扩展图标,点击退出下机。游戏时请勿在中奖过程里中途退出,如果中途退出造成无法计算下分,或分数丢失,一律不予处理。请勿在游戏中奖时点击结算退出,待奖励结束后再操作退出下分。",
      },
      {
        question: "6.对于中奖时离线导致被“捡分”如何处理？",
        answer:
          "在游戏过程中,用户A因个人原因切出游戏、网络信号问题导致游戏离线退出房间,用户B进入该房间游戏,属于正常游戏范畴,不予处理。如因游戏bug原因出现无法下分情况,能核实捡分用户身份的,彩票或对应金币将会追回扣除并返回给损失的用户。",
      },
      {
        question: "7.游戏过程中掉线,会进行补偿吗?",
        answer: `
        1.系统原因:游戏过程中,因系统版本更新或者系统维护,造成的下线,我们会对您进行补偿,具体请联系客服。
        2.用户原因:游戏过程中因用户网络故障、接电话或误操作等行为造成掉线,不会对您进行补偿,但是会保留当前游戏倒计时,倒计时结束后自动下机,建议您网络稳定时游戏。
        `,
      },
      {
        question: "8.如何情况下会封号？",
        answer:
          "充值成功且消费,多次充值且多次退款属于不诚信行为,恶意充值会进行封号处理。",
      },
    ],
  },
  {
    type: "tbj",
    text: "推币机",
    datas: [
      {
        question: "1.推币机怎么玩,如何结算积分？",
        answer:
          "点开房间内右上角扩展图标再点击玩法,即可查看完整的游戏说明。 推币机是实时结算的,当局游戏消耗的金币与获得的彩券会实时刷新并在左上角的显示当前累计的金币及彩券。点开右上角扩展图标点击退出即可下分结算,退出后显示的彩券为本局游戏获得的彩券。",
      },
      {
        question: "2.投币不掉币,机器无反应怎么处理？",
        answer:
          "如遇到投币不出币的情况,请录屏提供给客服,客服会在24小时内核实处理。",
      },
      {
        question: "3.中奖了,但是机器故障不出奖怎么办？",
        answer:
          "如中奖后出现机器故障,请停止游戏,点击右上角扩展图标,点击报修选项后在房间内等待工作人员的修理,修理完毕之后,游戏奖励会继续。如机修繁忙长时间未上机维修,请保持房间坐下状态并即可联系客服（录屏以及情况说明）协助您催促。",
      },
      {
        question: "4.对于中奖时离线导致被“捡分”如何处理？",
        answer:
          "在游戏过程中,用户A因个人原因切出游戏、网络信号问题导致游戏离线退出房间,用户B进入该房间游戏,属于正常游戏范畴,不予处理。如因游戏bug原因出现无法下分情况,能核实捡分用户身份的,彩票或对应金币将会追回扣除并返回给损失的用户。",
      },
    ],
  },
  {
    type: "mgc",
    text: "魔鬼城/海王/捕鸟",
    datas: [
      {
        question: "1.魔鬼城海王捕鸟游戏怎么玩？如何结算积分",
        answer: `点开房间内右上角扩展图标再点击玩法,即可查看完整的游戏说明。魔鬼城、海王Ⅲ获得的彩券是退出游戏时下分结算的,当局游戏后剩余分数会的显示在当前座位上。点开右上角扩展图标点击退出即可结算下机,提示“正在结算中请稍后”请耐心等候分数结算。
魔鬼城:⽕药数*0.1*房间倍数。如10000⽕药,10币房间结算积分是1000彩券；20币房间结算积分是2000彩券,如此类推。少于1000⽕药部分不参与结算。
海王3和捕鸟:⽕药数*0.1*房间倍数。如10000⽕药,10币房间结算积分是1000彩券；少于1000⽕药部分不参与结算。`,
      },
      {
        question: "2.哪些游戏有爆机奖励，为何投币不增加火药？",
        answer:
          "魔鬼城火药超过100w后(浴火凤凰50w)，投币只扣币不增加火药，投币数量无法找回。如爆机，可以在游戏右上角领取爆机奖励。",
      },
      {
        question: "3.中奖加分中途可以退出游戏吗？结算过程中可以退出游戏吗？",
        answer: `游戏中奖或加分时过程请勿退出下分，在中奖过程中下分都导致机器卡顿。须待奖励结束后再操作退出下分，如因此情况造成卡机、丢分无法处理。
结算过程中提示“正在结算中请稍后”请耐心等候分数结算，请勿在此提示过程中切出游戏或强行退出，会导致分数丢失无法补回。分数越多机器结算时间越久，这属于正常的结算过程。直至显示“恭喜获得XXX彩券”为结算完毕，即本局游戏获得的彩券。`,
      },
      {
        question: "4.为什么游戏突然自动结算下机?",
        answer:
          "在手动操作模式下，超过90秒无任何游戏界面操作，或者在自动模式下自动发射10分钟后，超过90秒无任何游戏界面操作，火药均会自动结算并且下机",
      },
      {
        question: "5.超出正常结算时间，仍无法下机成功怎么办?",
        answer: `在点击下机时，游戏还有未结算的火药积分，需要等分数结算完成后才能下机，正常结算时长：100W分下分结算用时80秒（1分钟20秒）左右，以此类推。
如超出正常结算时间且一直处于结算界面，请先截图该游戏界面保留证据。若倒计时结束仍未结算，请尝试第二次手动下分，仍不成功请务必截图游戏界面分数以及下分图，提供给客服进行反馈，在核实房间、时间、ID后，会在24小时内处理。
*切忌强行退出下机，若强行下机，造成非正常下机导致丢分，无法处理补偿。`,
      },
    ],
  },
  {
    type: "",
    text: "秘境夺宝",
    datas: [
      {
        question: "1.秘境夺宝怎么玩？",
        answer: `点开房间内右上角扩展图标再点击玩法，即可查看完整的游戏说明。
          游戏内赢分=押注x宝箱赔率，强攻模式赢分=押注x宝箱赔率/3。
        `,
      },
      {
        question: "2.为什么游戏突然自动结算下机?",
        answer:
          "在手动操作模式下，超过20秒无任何游戏界面操作，会自动结算并且下机。",
      },
      {
        question: "3.对于中奖时离线导致被“捡分”如何处理？",
        answer:
          "在游戏过程中，用户A因个人原因切出游戏、网络信号问题导致游戏离线退出房间，用户B进入该房间游戏，属于正常游戏范畴，不予处理。如因游戏bug原因出现无法下分情况，能核实捡分用户身份的，彩票或对应金币将会追回扣除并返回给损失的用户。",
      },
    ],
  },
  {
    type: "",
    text: "宝石爆爆乐",
    datas: [
      {
        question: "1.宝石爆爆乐怎么玩？如何结算积分",
        answer: `点开房间内右上角扩展图标再点击玩法，即可查看完整的游戏说明。
结算以10币房间为例：1000credit结算为1000彩券，低于1000credit部分不参与结算。如1234credit，结算彩券为1000彩券。`,
      },
      {
        question: "2.为什么连爆完了自动结算下机",
        answer: `在手动操作模式下，超过360秒无任何游戏界面操作，或者在自动模式下，超过360秒无任何游戏界面操作，火药均会自动结算并且下机。`,
      },
    ],
  },
  {
    type: "",
    text: "幸运财神",
    datas: [
      {
        question: "1.幸运财神怎么玩？如何结算积分",
        answer: `点开房间内右上角扩展图标再点击玩法，即可查看完整的游戏说明。
幸运财神结算以5币房间，10credit，结算为50 彩券，10币房间，10credit，结算为100 彩券。低于1000credit部分不参与结算。如1234credit，结算彩券为1000彩券。`,
      },
      {
        question: "2.对于中奖时离线导致被“捡分”如何处理？",
        answer:
          "在游戏过程中，用户A因个人原因切出游戏、网络信号问题导致游戏离线退出房间，用户B进入该房间游戏，属于正常游戏范畴，不予处理。如因游戏bug原因出现无法下分情况，能核实捡分用户身份的，彩票或对应金币将会追回扣除并返回给损失的用户。",
      },
    ],
  },

  {
    type: "",
    text: "娃娃机",
    datas: [
      {
        question: "1.娃娃机抓到的娃娃怎么结算？如何判断抓到娃娃?",
        answer:
          "房间内抓中的娃娃均为10币一局，获得娃娃一只积1000彩券，以此累计，不提供实物兑换。抓到的娃娃成功掉进洞里才算彩券得分，其余情况均为失败。一局游戏中如果抓到超过一个娃娃，也只按一个的数额来计算得1000彩券。",
      },
      {
        question: "2.进入房间没娃娃?娃娃机爪子卡住娃娃怎么办?",
        answer:
          "房间内出现没有娃娃的情况，如没有上机，可联系客服咨询处理。若已经上机，可在房间内报修，等待工作人员补娃娃，若在房间内时间超过了等待时间，可联系客服咨询处理。出现卡娃娃的情况也请及时截图保存，并联在线客服。",
      },
      {
        question: "3.爪子或者网络摄像头故障怎么办?",
        answer:
          "如没有上机，可联系客服咨询处理。若已经上机，可在房间内报修，等待工作人员处理，若在房间内时间超过了等待时间，可联系客服处理。",
      },
      {
        question: "4.游戏记录有问题、抓中娃娃积分未到账怎么办?",
        answer:
          "如果不是因为个人原因以及个人网络问题，可联系客服告知房间、时间及您的游戏UID进行处理。",
      },
    ],
  },
  {
    type: "",
    text: "其他须知",
    datas: [
      {
        question: "1.防诈骗警示",
        answer:
          "为保障游戏公平与玩家利益，极客竞技APP严禁任何第三方代充、利用游戏漏洞销售电玩币牟利等行为，请勿轻信谣言，避免上当。官方不对第三方行为负责，不受理任何因第三方诈骗发生纠纷而带来的申诉，请通过官方渠道获取游戏相关信息。如有疑问，请联系客服。",
      },
      {
        question: "2.游戏安全公告",
        answer: `平台一直致力于打造绿色公平的游戏环境，对任何破坏游戏秩序、破坏游戏平衡的行为均予以严厉打击和处罚。明确游戏内违规行为及处罚力度。 本规则适用的游戏行为包括但不限于:1.使用第三方插件2.使用非官方提供的游戏客户端3.在游戏内宣扬、售卖游戏外挂4.恶意利用游戏漏洞获取游戏收益5.恶意发言刷屏6.转币转号7.恶意卡bug严重影响游戏正常体验。对以上行为我们可能采取：不定时长封号、限制功能、法律手段维护权益等。
极客竞技竭力为各位玩家保证正常的游戏体验，也呼吁广大玩家规范自身游戏行为，共同维护公平有序的游戏环境。`,
      },
      {
        question: "3.游戏温馨提示",
        answer: `
          1.故障申报规则须知，明显可见故障机器，继续上机游戏的玩家导致的任何损失，均不做任何补回。机器故障后请在房间内稍等机修处理完机器故障，再继续进行游戏。如知道机器故障仍继续投币 这种情况不在规则内。故障期间投的币无法核实。很抱歉！后续遇到同样的问题 请暂停游戏，等待机修处理 或者联系客服处理。
          2.须知：游戏内坐下没有任何操作90秒会触发自动结算并退出房间，但是我们有明显提示，会在屏幕上倒数。或者是您接电话 ，回微信，您切除游戏60秒 游戏也是会离线的。
          3.遇到游戏卡了，黑屏类的网络波动。先尝试稍等个几秒钟。再去看按钮是否正常，可以的话就正常右上角操作退出，这样就会正常下分。 假如已经闪退了，重新进入对应的房间，发现座位上卡着您自己的头像（不管是否在玩，分也在卡住的自己身上）。这个情况直接退出游戏APP一分钟后再上线。卡住的就会离线了，分也会下回到您自己号里
          4.游戏时请勿在中奖过程里中途退出，如果中途退出造成无法计算下分，或分数丢失，一律不予处理。请勿在游戏中奖时点击结算退出，待奖励结束后再操作退出下分。
        `,
      },
    ],
  },
]);

const handleServiceClick = () => {
  const _UId = parseInt(store.userInfo.uid);
  const userInfo = {
    u_cust_id: _UId,
    u_cust_name: _UId,
    u_custom_info: _UId,
  };

  let instruct = Object.entries(userInfo)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  const aDom = document.createElement("a");
  const _href = "/dialog_1.htm?" + instruct;
  aDom.href = _href;

  aDom.click();
};

const imageUrl = ref("");
const imageShow = ref(false);
const add_img_icon = store.imgBaseUrl + "/myview/add_img.png";
const phoneNumber = ref("");
const mytext = ref("");

function dataURLtoFile(dataurl, filename) {
  //将base64转换为文件
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime,
  });
}

const whenUserSelectImges = async () => {
  const res = await asyncSendInstructions({
    msg_type: "get_fankui_pic",
  });

  if (res) {
    imageUrl.value = "data:image/png;base64," + res;
    imageShow.value = true;
  }
};

// 清空图片
const clearImgUrl = () => {
  imageUrl.value = "";
  imageShow.value = false;
};
// 预览图片
const handleImagePreview = () => {
  showImagePreview([imageUrl.value]);
};

// 提交
const submit = async () => {
  if (phoneNumber.value == "84562") {
    router.push("/test");
    return;
  }

  if (imageUrl.value == "") {
    showToast({
      type: "fail",
      message: "未选择图片",
    });
    return;
  }

  if (mytext.value == "") {
    showToast({
      type: "fail",
      message: "留言不能为空",
    });

    return;
  }

  if (phoneNumber.value == "") {
    showToast({
      type: "fail",
      message: "手机号不能为空",
    });

    return;
  }

  const regex = /^1[3-9]\d{9}$/;

  if (!regex.test(phoneNumber.value)) {
    showToast({
      type: "fail",
      message: "请检查手机号",
    });

    return;
  }

  const fileData = dataURLtoFile(
    imageUrl.value,
    `feedback_${new Date().getTime()}.png`
  );
  const params = {
    uid: parseInt(store.userInfo.uid).toString(),
    phone: phoneNumber.value,
    mytext: mytext.value,
    file: fileData,
  };

  showToast({
    type: "loading",
    message: "提交中...",
    forbidClick: true,
  });

  // const { Message } = await

  addFeedBack(params)
    .then((res) => {
      if (res.Message == "ok!") {
        showToast({
          type: "success",
          message: "提交成功！",
        });

        mytext.value = "";
        phoneNumber.value = "";
        imageUrl.value = "";
      } else {
        showToast({
          type: "success",
          message: res.Message,
        });
      }
    })
    .catch((err) => {});
};
</script>

<style lang="scss" scoped>
.question_view_wrapper {
  min-height: 100vh;
  background-color: #90b1f2;

  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/question/bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;

  padding: 176px 15px 0 15px;
}

._n_container {
  // height: 460px;
  // background-color: pink;

  .type_outer_wrapper {
    position: relative;
    width: 100%;
    height: 25px;
    background-color: #eaecfe;
    .type_active_block {
      position: absolute;
      top: -6px;
      left: -4px;
      width: 178px;
      height: 46px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/question/type_active.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: translateX(0);
      transition: transform 0.3s;
      z-index: 1;
    }

    .type_wrapper {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;

      line-height: 25px;
      display: flex;
      text-align: center;

      .type_itme {
        flex: 1;
        color: #8f8f8f;

        transition: color 0.3s;
      }
    }
  }

  .message_box_wrapper {
    height: 480px;
    // height: fit-content;
    display: flex;
    margin-top: 10px;
    .left_type_nav {
      width: 80px;
      height: 100%;
      overflow: auto;
      background-color: #5f55ff;

      &::-webkit-scrollbar {
        width: 0;
      }
      .nav_item {
        position: relative;
        width: 80px;
        height: 60px;
        text-align: center;
        // line-height: 60px;
        .text {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          max-height: 100%;
        }
      }

      .nav_item_active {
        background-color: #eaecfe;
        color: #000000;
      }
    }

    .right_block_content {
      flex: 1;
      height: 100%;
      overflow: auto;
      background-color: #eaecfe;
      padding: 0 12px;
      color: #000000;
      font-size: 12px;
      &::-webkit-scrollbar {
        width: 0;
      }
      .dialogue_item {
        padding: 16px 0;
        border-bottom: 1px dotted #000000;

        .question_wrapper {
          display: flex;

          .wen_icon {
            height: 18px;
            width: 18px;
            background-image: url("https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/images/question/w_icon.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }

          .question_text {
            padding-left: 8px;
          }
        }

        .answer_wrapper {
          display: flex;
          margin-top: 16px;
          .d_icon {
            height: 18px;
            width: 18px;
            background-image: url("https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/images/question/d_icon.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            // min-width: 18px;
            flex-shrink: 0;
          }

          .answer_text {
            padding-left: 8px;
          }
        }
      }
    }
  }

  .feedback_wrapper {
    margin-top: 10px;
    // height: 480px;
    background-color: #eaecfe;
    padding: 18px;

    .title {
      font-size: 16px;
      color: #000000;
      padding-bottom: 14px;
    }

    .textarea_wrapper {
      // background: none;
      margin-bottom: 14px;
      background-color: #dee1ff;
      border-radius: 10px;
      height: 116px;
      .van-cell {
        background: none;
      }
    }
    .uploader_wrapper {
      // margin-top: 20px;
      // display: block;
      background-color: #dee1ff;
      border-radius: 10px;
      overflow: hidden;
      width: 100px;
      height: 100px;
      margin-bottom: 16px;
      // display: flex;

      position: relative;

      .add_img_wrapper {
        width: 48px;
        height: 62px;
        // margin: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .select_img_wrapper {
        width: 100%;
        height: 100%;
        position: relative;

        .delect_icon_wrapper {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
        }
        .eye_icon_wrapper {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
        }
      }

      // position: relative;

      // .add_img_wrapper{
      //   po
      // }
    }

    .input_wrapper {
      background-color: #dee1ff;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 14px;
      .van-cell {
        background: none;
      }
    }

    .confirm_btn {
      margin: 0 auto;
      // margin-top: 40px;
      width: 230px;
      height: 48px;
      background: linear-gradient(90deg, #81c3ff 0%, #5034ff 100%);
      border-radius: 24px;
      opacity: 1;
      color: #fff;
      font-size: 18px;
      line-height: 50px;
      text-align: center;
      font-weight: bold;
    }
  }

  .bottom_block {
    padding: 28px 0 40px 0;
    text-align: center;
    color: #00000060;
    .service_text {
      color: #000000;

      .service_enter {
        text-decoration: underline;
      }
    }
  }
}
</style>
