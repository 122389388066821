<template>
  <!-- 等待游戏结果动画 -->
  <div class="await_loding_result">
    <div class="result_content">
      <div class="w-141 h-141 m-auto relative">
        <div class="w-79 h-79 absolute-center">
          <van-image
            width="100%"
            height="auto"
            fit="fill"
            position="center"
            :show-loading="false"
            :src="await_tiaozhan_text"
          />
        </div>
        <div class="await_tiaozhan_loding width height">
          <van-image
            width="100%"
            height="auto"
            fit="fill"
            position="center"
            :show-loading="false"
            :src="await_tiaozhan_loding"
          />
        </div>
      </div>
      <div
        class="mt-12 h-20 f-13 t-center width"
        style="color: #fffab9; text-shadow: 1px 1px #945f00"
      >
        {{
          store.settlementResult.type == "normal"
            ? "游戏数据正在结算中，请稍后..."
            : "比赛数据正在结算中，请稍等..."
        }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
const store = useStore();
const await_tiaozhan_text = store.imgBaseUrl + "/game/await_tiaozhan_text.png";
const await_tiaozhan_loding =
  store.imgBaseUrl + "/game/await_tiaozhan_loding.png";
</script>

<style scoped lang="scss">
// 结算结果
.await_loding_result {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  z-index: 99;

  .result_content {
    // w-185 absolute-center
    width: 185px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .await_tiaozhan_loding {
      width: 100%;
      height: 100%;
      animation: tiaozhan_result_loding 2s;
      animation-timing-function: linear;
      // animation-iteration-count: 1;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes tiaozhan_result_loding {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes tiaozhan_result_loding

/* Safari 与 Chrome */ {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
