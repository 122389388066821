<template>
  <div
    class="magpie_wrapper"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <NavBar />

    <div class="gif_bg_wrapper">
      <van-image width="100%" height="auto" block :src="qix_bg_gg" />
    </div>

    <div class="container">
      <div class="my_card_wrapper">
        <div class="aixin_wrapper">
          <div class="num">X{{ _Data.my_card.aixin }}</div>
        </div>
        <div class="longka_wrapper">
          <div class="num">X{{ _Data.my_card.longka }}</div>
        </div>
      </div>

      <div class="exchagne_block_wrapper">
        <div class="exchange_gold">
          <div class="exchange_gold_item" v-for="gold_item in _Data.gold_list">
            <div class="exchange_gold_item_block">
              <div class="gold_num_wrapper">
                <div class="gold_icon"></div>
                <div class="gold_num">x{{ gold_item.gold }}</div>
              </div>
              <div class="text">兑换条件</div>

              <div class="exchange_complete">
                <div class="wrapper" v-if="gold_item.aixin > 0">
                  <div class="complete_icon"></div>
                  <div class="complete_num">x{{ gold_item.aixin }}</div>
                </div>
                <div
                  class="and_icon"
                  v-if="gold_item.aixin > 0 && gold_item.longka > 0"
                >
                  +
                </div>
                <div class="wrapper" v-if="gold_item.longka > 0">
                  <div class="complete_icon2"></div>
                  <div class="complete_num">x{{ gold_item.longka }}</div>
                </div>
              </div>
              <div class="exchange_num">
                可兑次数
                <span style="color: #fd74ff">{{ gold_item.exchange_num }}</span>
              </div>
            </div>
            <div
              class="exchange_gold_item_btn"
              @click="exchangeItemData(gold_item)"
              :style="{
                opacity: gold_item.exchange_num == 0 ? 0.4 : 1,
              }"
            >
              {{ gold_item.exchange_num == 0 ? "今日上限" : "立即兑换" }}
            </div>
          </div>
        </div>

        <div class="exchange_achievement">
          <div
            class="exchange_achievement_item"
            v-for="achievement_item in _Data.achievement_list"
          >
            <div class="achievement_item_exchange_block">
              <div class="achievement_cover">
                <van-image
                  width="100%"
                  height="100%"
                  :src="achievement_item.type == 'longka' ? l_h : y_h"
                />
              </div>
              <div class="text">兑换条件</div>

              <div class="exchange_complete">
                <div class="wrapper" v-if="achievement_item.aixin > 0">
                  <div class="complete_icon"></div>
                  <div class="complete_num">x{{ achievement_item.aixin }}</div>
                </div>
                <div
                  class="and_icon"
                  v-if="achievement_item.longka > 0 && achievement_item.aixin"
                >
                  +
                </div>
                <div class="wrapper" v-if="achievement_item.longka > 0">
                  <div class="complete_icon2"></div>
                  <div class="complete_num">x{{ achievement_item.longka }}</div>
                </div>
              </div>
            </div>
            <div
              class="exchange_gold_item_btn"
              @click="exchangeItemData(achievement_item)"
              :style="{
                opacity: achievement_item.is_get ? 0.4 : 1,
              }"
            >
              {{ achievement_item.is_get ? "已兑换" : "立即兑换" }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="textcc"
        style="color: #ffffff60; text-align: center; margin-top: 20px"
      >
        活动兑换截止时间为：8月11日 11点59分 <br />
        成就奖励兑换成功后在“我的”-“我的成就”中选择佩戴
      </div>
    </div>
  </div>
</template>

<script setup>
import { showToast, closeToast } from "vant";
import { asyncSendInstructions } from "@/api/sendData";
import { useStore } from "@/store/store";
import NavBar from "@/components/NavBar.vue";
import { reactive, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
const store = useStore();
const router = useRouter();

const y_h =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_10.png";
const l_h =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_11.png";

const qix_bg_gg =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/qixi_gg.gif";

const _Data = reactive({
  my_card: {
    aixin: 0,
    longka: 0,
  },
  gold_list: [
    {
      gold: 188,
      longka: 0,
      aixin: 0,
      index: 1,
      exchange_num: 0,
    },
    {
      gold: 388,
      longka: 0,
      aixin: 0,
      index: 1,
      exchange_num: 0,
    },
    {
      gold: 588,
      longka: 0,
      aixin: 1,
      index: 1,
      exchange_num: 0,
    },
  ],
  achievement_list: [
    {
      type: "longka",
      longka: 0,
      aixin: 0,
      index: 4,
      is_get: false,
    },
    {
      type: "aixin",
      longka: 0,
      aixin: 0,
      index: 5,
      is_get: false,
    },
  ],
});

const initdata = (info) => {
  const _info = info;
  let _index = 0;
  const reward_data = info.reward_data;

  _Data.my_card.longka = _info.collect_data.hw3[13];
  _Data.my_card.aixin = _info.collect_data.hw3[17];

  for (const key in reward_data) {
    const item = reward_data[key];

    if (item.reward.type == "gold" && _index < 3) {
      _Data.gold_list[_index].gold = item.reward.num;
      _Data.gold_list[_index].index = key;

      _Data.gold_list[_index].exchange_num =
        item.reward.max_exchange_num - item.reward.exchange_num;

      for (const _j in item.condition) {
        const _jtem = item.condition[_j];

        if (_jtem.class_id == 13) {
          _Data.gold_list[_index].longka = _jtem.complete;
        }

        if (_jtem.class_id == 17) {
          _Data.gold_list[_index].aixin = _jtem.complete;
        }
      }

      _index++;
    }

    if (key == 4 || key == 5) {
      if (item.reward.is_get) {
        _Data.achievement_list[key == 4 ? 0 : 1].is_get = true;
      }

      for (const _j in item.condition) {
        const _jtem = item.condition[_j];

        if (_jtem.class_id == 13) {
          _Data.achievement_list[key == 4 ? 0 : 1].longka = _jtem.complete;
        }

        if (_jtem.class_id == 17) {
          _Data.achievement_list[key == 4 ? 0 : 1].aixin = _jtem.complete;
        }
      }
    }
  }
};

const getData = async () => {
  showToast({
    type: "loading",
    duration: 0,
    forbidClick: true,
  });

  try {
    const res = await asyncSendInstructions({
      msg_type: "get_player_magpie",
    });

    console.log("resresres", res);
    initdata(res.info);
    closeToast();

    if (res.error == "ok") {
    } else {
      showToast({
        type: "fail",
        message: "活动已结束",
        onClose() {
          router.back();
        },
      });
    }
  } catch (error) {
    console.log("errorerror", error);
    closeToast();
  }
};

const exchangeItemData = async (item) => {
  // console.log("11111111111111111", index);

  if ((item.index == 4 || item.index == 5) && item.is_get) return;

  if (
    (item.index == 1 || item.index == 2 || item.index == 3) &&
    item.exchange_num == 0
  ) {
    showToast({
      type: "fail",
      message: "今日上限",
    });

    return;
  }

  if (item.aixin > _Data.my_card.aixin || item.longka > _Data.my_card.longka) {
    showToast({
      type: "fail",
      message: "材料不足",
    });

    return;
  }

  const res = await asyncSendInstructions({
    msg_type: "get_player_magpie_reward",
    index: item.index,
  });

  if (res.error == "ok") {
    showToast({
      message: "兑换成功",
      type: "success",
    });
    if (item.gold) {
      store.$patch((state) => {
        state.userInfo.gold = state.userInfo.gold + item.gold;
      });
    }

    initdata(res.info);
  } else {
    showToast({
      message: res.error,
      type: "fail",
    });
  }
};

onBeforeMount(() => {
  getData();
});
</script>

<style lang="scss" scoped>
.magpie_wrapper {
  position: relative;
  min-height: 100vh;
  background-color: #312763;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/qixi_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;

  .gif_bg_wrapper {
    position: absolute;
    left: 50%;
    top: 0;
    width: 280px;
    transform: translateX(-50%);
  }

  .container {
    position: relative;
    padding: 344px 22px 22px 22px;
    z-index: 3;
  }

  .my_card_wrapper {
    height: 66px;
    // background-color: pink;
    padding: 0 30px;
    display: flex;
    justify-content: space-around;

    .aixin_wrapper {
      position: relative;
      width: 120px;

      height: 66px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/aixin.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .num {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 14px;
        color: #ffffff;
      }
    }
    .longka_wrapper {
      position: relative;
      width: 120px;

      height: 66px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/longka.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .num {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }

  .exchagne_block_wrapper {
    margin-top: 30px;
    height: 374px;
    padding: 48px 10px 10px 10px;

    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/exchange_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .exchange_gold {
      display: flex;
      justify-content: space-between;
      .exchange_gold_item {
        .exchange_gold_item_block {
          width: 100px;
          height: 110px;
          border-radius: 10px;
          background-color: #1e1259;
          padding: 10px 0;
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-self: center;
          .gold_num_wrapper {
            display: flex;
            line-height: 24px;
            justify-content: center;
            .gold_icon {
              width: 26px;
              height: 26px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            .gold_num {
              font-size: 16px;
            }
          }
        }
      }
    }

    .exchange_achievement {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      .exchange_achievement_item {
        .achievement_item_exchange_block {
          width: 150px;
          height: 114px;
          border-radius: 10px;
          background-color: #1e1259;
          padding: 4px 0;

          .achievement_cover {
            width: 64px;
            height: 64px;
            margin: 0 auto;
          }
        }
      }
    }

    .text {
      margin-top: 4px;
      font-size: 10px;
      color: rgba(255, 255, 255, 0.5);
      text-align: center;
    }

    .exchange_complete {
      margin-top: 4px;
      display: flex;
      justify-content: center;
      .wrapper {
        display: flex;
        // background-color: ;
        .complete_icon {
          width: 16px;
          height: 16px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/aixin_icon.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .complete_icon2 {
          width: 16px;
          height: 16px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/longhun_icon.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .complete_num {
          font-size: 12px;
        }
      }
      .and_icon {
        color: #fd74ff;
        margin: 0 4px;
      }
    }

    .exchange_num {
      // margin-top: 12px;
      text-align: center;
      font-size: 10px;
      background-color: #090035;
      border-radius: 12px;
      width: 60px;
      height: 20px;
      line-height: 20px;
      margin: 4px auto;
    }

    .exchange_gold_item_btn {
      margin: 10px auto;
      width: 64px;
      height: 24px;
      text-align: center;
      background: linear-gradient(180deg, #f3b0db 0%, #e875b5 100%);
      border-radius: 5px 5px 5px 5px;
      color: #ffffff;
      text-shadow: 0px 2px 2px #83014c;
      line-height: 24px;
    }
  }
}
</style>
