const get_nangua_rank_result = {
  msg_type: "get_nangua_rank_result",
  info: [
    {
      player_name: "324000001",
      num: parseInt(Math.random() * 10000),
      uid: 1,
      level: parseInt(Math.random() * 20),
      recharge_level: parseInt(Math.random() * 20),
    },
    {
      player_name: "570010005",
      num: parseInt(Math.random() * 100000),
      uid: 5,
      level: 20,
      recharge_level: parseInt(Math.random() * 20),
    },
    {
      player_name: "923050003",
      num: parseInt(Math.random() * 100000),
      uid: 3,
      level: parseInt(Math.random() * 20),
      recharge_level: parseInt(Math.random() * 20),
    },
    {
      player_name: "587004004",
      num: parseInt(Math.random() * 10000),
      uid: 4,
      level: parseInt(Math.random() * 20),
      recharge_level: parseInt(Math.random() * 20),
    },
    {
      player_name: "587003004",
      num: parseInt(Math.random() * 10000),
      uid: 6,
      level: parseInt(Math.random() * 20),
      recharge_level: parseInt(Math.random() * 20),
    },
    {
      player_name: "587000104",
      num: parseInt(Math.random() * 100000),
      uid: 7,
      level: parseInt(Math.random() * 20),
      recharge_level: parseInt(Math.random() * 20),
    },
  ],
};

const exchange_collect_result = {
  msg_type: "exchange_collect_result",
  info: {
    error: "ok",
    add_gold: "500",
  },
};

const engine_init_end = {
  msg_type: "engine_init_end",
};

//#region 收集数据 新版
const get_player_collect_result = {
  msg_type: "get_player_collect_result",
  info: {
    player_collect: {
      bbl: {
        0: {
          num: 100,
          class_id: 0,
          uid: 8,
          game_name: "bbl",
        },
        1: {
          num: 100,
          class_id: 1,
          uid: 8,
          game_name: "bbl",
        },
        2: {
          num: 130,
          class_id: 2,
          uid: 8,
          game_name: "bbl",
        },
        3: {
          num: 120,
          class_id: 3,
          uid: 8,
          game_name: "bbl",
        },
        4: {
          num: 120,
          class_id: 4,
          uid: 8,
          game_name: "bbl",
        },
      },
      mgc: {
        0: {
          num: 0,
          class_id: 0,
          uid: 8,
          game_name: "mgc",
        },
        1: {
          num: 1,
          class_id: 1,
          uid: 8,
          game_name: "mgc",
        },
        2: {
          num: 2,
          class_id: 2,
          uid: 8,
          game_name: "mgc",
        },
        3: {
          num: 3,
          class_id: 3,
          uid: 8,
          game_name: "mgc",
        },
        4: {
          num: 4,
          class_id: 4,
          uid: 8,
          game_name: "mgc",
        },
        5: {
          num: 5,
          class_id: 5,
          uid: 8,
          game_name: "mgc",
        },
        6: {
          num: 0,
          class_id: 6,
          uid: 8,
          game_name: "mgc",
        },
        7: {
          num: 12,
          class_id: 7,
          uid: 8,
          game_name: "mgc",
        },
        8: {
          num: 13,
          class_id: 8,
          uid: 8,
          game_name: "mgc",
        },
        9: {
          num: 41,
          class_id: 9,
          uid: 8,
          game_name: "mgc",
        },
        10: {
          num: 5,
          class_id: 10,
          uid: 8,
          game_name: "mgc",
        },
        11: {
          num: 120,
          class_id: 11,
          uid: 8,
          game_name: "mgc",
        },
        12: {
          num: 99,
          class_id: 12,
          uid: 8,
          game_name: "mgc",
        },
      },
      hw3: {
        0: {
          num: 0,
          class_id: 0,
          uid: 8,
          game_name: "hw3",
        },
        1: {
          num: 1,
          class_id: 1,
          uid: 8,
          game_name: "hw3",
        },
        2: {
          num: 2,
          class_id: 2,
          uid: 8,
          game_name: "hw3",
        },
        3: {
          num: 3,
          class_id: 3,
          uid: 8,
          game_name: "hw3",
        },
        4: {
          num: 4,
          class_id: 4,
          uid: 8,
          game_name: "hw3",
        },
        5: {
          num: 5,
          class_id: 5,
          uid: 8,
          game_name: "hw3",
        },
        6: {
          num: 0,
          class_id: 6,
          uid: 8,
          game_name: "hw3",
        },
        7: {
          num: 12,
          class_id: 7,
          uid: 8,
          game_name: "hw3",
        },
        8: {
          num: 13,
          class_id: 8,
          uid: 8,
          game_name: "hw3",
        },
        9: {
          num: 41,
          class_id: 9,
          uid: 8,
          game_name: "hw3",
        },
        10: {
          num: 5,
          class_id: 10,
          uid: 8,
          game_name: "hw3",
        },
        11: {
          num: 120,
          class_id: 11,
          uid: 8,
          game_name: "hw3",
        },
        12: {
          num: 99,
          class_id: 12,
          uid: 8,
          game_name: "hw3",
        },
        13: {
          num: 99,
          class_id: 13,
          uid: 8,
          game_name: "hw3",
        },
        14: {
          num: 99,
          class_id: 14,
          uid: 8,
          game_name: "hw3",
        },
        15: {
          num: 98,
          class_id: 15,
          uid: 8,
          game_name: "hw3",
        },
        16: {
          num: 97,
          class_id: 16,
          uid: 8,
          game_name: "hw3",
        },
        17: {
          num: 96,
          class_id: 17,
          uid: 8,
          game_name: "hw3",
        },
        18: {
          num: 95,
          class_id: 18,
          uid: 8,
          game_name: "hw3",
        },
      },
      mxt: {
        0: {
          num: 2,
          class_id: 0,
          uid: 8,
          game_name: "mxt",
        },
        1: {
          num: 3,
          class_id: 1,
          uid: 8,
          game_name: "mxt",
        },
        2: {
          num: 2,
          class_id: 2,
          uid: 8,
          game_name: "mxt",
        },
        3: {
          num: 3,
          class_id: 3,
          uid: 8,
          game_name: "mxt",
        },
        4: {
          num: 4,
          class_id: 4,
          uid: 8,
          game_name: "mxt",
        },
        5: {
          num: 5,
          class_id: 5,
          uid: 8,
          game_name: "mxt",
        },
      },
      msqy: {
        1: {
          num: 3,
          class_id: 1,
          uid: 8,
          game_name: "mxt",
        },
        2: {
          num: 2,
          class_id: 2,
          uid: 8,
          game_name: "mxt",
        },
        3: {
          num: 3,
          class_id: 3,
          uid: 8,
          game_name: "mxt",
        },
        4: {
          num: 4,
          class_id: 4,
          uid: 8,
          game_name: "mxt",
        },
        5: {
          num: 5,
          class_id: 5,
          uid: 8,
          game_name: "mxt",
        },
        6: {
          num: 5,
          class_id: 6,
          uid: 8,
          game_name: "mxt",
        },
        7: {
          num: 5,
          class_id: 7,
          uid: 8,
          game_name: "mxt",
        },
        8: {
          num: 5,
          class_id: 8,
          uid: 8,
          game_name: "mxt",
        },
      },
    },
    collect_def: {
      bbl: {
        bbl_reward: [
          2000, 888, 666, 500, 400, 300, 200, 100, 100, 100, 88, 88, 88, 88, 88,
          88, 88, 88, 88, 88,
        ],
        class_ids: [0, 1, 2, 3, 4],
        collect_reward: [
          {
            complete: [
              {
                class_id: 2,
                num: 100,
              },
              {
                class_id: 3,
                num: 70,
              },
              {
                class_id: 0,
                num: 20,
              },
              {
                class_id: 4,
                num: 4,
              },
            ],
            reward: 888,
          },
          {
            complete: [
              {
                class_id: 2,
                num: 100,
              },
              {
                class_id: 3,
                num: 70,
              },
              {
                class_id: 0,
                num: 20,
              },
              {
                class_id: 1,
                num: 20,
              },
              {
                class_id: 4,
                num: 4,
              },
            ],
            reward: 1666,
          },
          {
            complete: [
              {
                class_id: 2,
                num: 150,
              },
              {
                class_id: 3,
                num: 70,
              },
              {
                class_id: 0,
                num: 70,
              },
              {
                class_id: 1,
                num: 20,
              },
              {
                class_id: 4,
                num: 8,
              },
            ],
            reward: 2666,
          },
          {
            complete: [
              {
                class_id: 2,
                num: 230,
              },
              {
                class_id: 3,
                num: 150,
              },
              {
                class_id: 0,
                num: 78,
              },
              {
                class_id: 1,
                num: 100,
              },
              {
                class_id: 4,
                num: 15,
              },
            ],
            reward: 6666,
          },
          {
            complete: [
              {
                class_id: 2,
                num: 470,
              },
              {
                class_id: 3,
                num: 300,
              },
              {
                class_id: 0,
                num: 78,
              },
              {
                class_id: 1,
                num: 150,
              },
              {
                class_id: 4,
                num: 20,
              },
            ],
            reward: 9999,
          },
        ],
      },
      mgc: {
        nangua_reward: [
          2000, 888, 666, 500, 400, 300, 200, 100, 100, 100, 88, 88, 88, 88, 88,
          88, 88, 88, 88, 88,
        ],
        dashi_reward: [
          {
            complete: [
              {
                class_id: 11,
                num: 1,
              },
            ],
            reward: 1,
          },
          {
            complete: [
              {
                class_id: 7,
                num: 1,
              },
            ],
            reward: 1,
          },
          {
            complete: [
              {
                class_id: 5,
                num: 1,
              },
            ],
            reward: 1,
          },
          {
            complete: [
              {
                class_id: 8,
                num: 1,
              },
            ],
            reward: 1,
          },
          {
            complete: [
              {
                class_id: 12,
                num: 1,
              },
            ],
            reward: 1,
          },
          {
            complete: [
              {
                class_id: 9,
                num: 1,
              },
            ],
            reward: 1,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 1,
              },
            ],
            reward: 1,
          },
          {
            complete: [
              {
                class_id: 3,
                num: 1,
              },
            ],
            reward: 1,
          },
          {
            complete: [
              {
                class_id: 10,
                num: 1,
              },
            ],
            reward: 1,
          },
          {
            complete: [
              {
                class_id: 2,
                num: 1,
              },
            ],
            reward: 1,
          },
          {
            complete: [
              {
                class_id: 4,
                num: 1,
              },
            ],
            reward: 1,
          },
          {
            complete: [
              {
                class_id: 1,
                num: 1,
              },
            ],
            reward: 1,
          },
          {
            complete: [
              {
                class_id: 11,
                num: 1,
              },
              {
                class_id: 7,
                num: 1,
              },
              {
                class_id: 5,
                num: 1,
              },
              {
                class_id: 8,
                num: 1,
              },
              {
                class_id: 12,
                num: 1,
              },
              {
                class_id: 9,
                num: 1,
              },
            ],
            reward: 8,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 1,
              },
              {
                class_id: 3,
                num: 1,
              },
              {
                class_id: 10,
                num: 1,
              },
              {
                class_id: 2,
                num: 1,
              },
              {
                class_id: 4,
                num: 1,
              },
              {
                class_id: 1,
                num: 1,
              },
            ],
            reward: 8,
          },
          {
            complete: [
              {
                class_id: 11,
                num: 2,
              },
              {
                class_id: 7,
                num: 2,
              },
              {
                class_id: 5,
                num: 2,
              },
              {
                class_id: 8,
                num: 2,
              },
              {
                class_id: 12,
                num: 2,
              },
              {
                class_id: 9,
                num: 2,
              },
            ],
            reward: 20,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 2,
              },
              {
                class_id: 3,
                num: 2,
              },
              {
                class_id: 10,
                num: 2,
              },
              {
                class_id: 2,
                num: 2,
              },
              {
                class_id: 4,
                num: 2,
              },
              {
                class_id: 1,
                num: 2,
              },
            ],
            reward: 20,
          },
          {
            complete: [
              {
                class_id: 11,
                num: 1,
              },
              {
                class_id: 7,
                num: 1,
              },
              {
                class_id: 5,
                num: 1,
              },
              {
                class_id: 8,
                num: 1,
              },
              {
                class_id: 12,
                num: 1,
              },
              {
                class_id: 9,
                num: 1,
              },
              {
                class_id: 0,
                num: 1,
              },
              {
                class_id: 3,
                num: 1,
              },
              {
                class_id: 10,
                num: 1,
              },
              {
                class_id: 2,
                num: 1,
              },
              {
                class_id: 4,
                num: 1,
              },
              {
                class_id: 1,
                num: 1,
              },
            ],
            reward: 20,
          },
          {
            complete: [
              {
                class_id: 11,
                num: 2,
              },
              {
                class_id: 7,
                num: 2,
              },
              {
                class_id: 5,
                num: 2,
              },
              {
                class_id: 8,
                num: 2,
              },
              {
                class_id: 12,
                num: 2,
              },
              {
                class_id: 9,
                num: 2,
              },
              {
                class_id: 0,
                num: 2,
              },
              {
                class_id: 3,
                num: 2,
              },
              {
                class_id: 10,
                num: 2,
              },
              {
                class_id: 2,
                num: 2,
              },
              {
                class_id: 4,
                num: 2,
              },
              {
                class_id: 1,
                num: 2,
              },
            ],
            reward: 50,
          },
        ],
        class_ids: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        collect_reward: [
          {
            complete: [
              {
                class_id: 11,
                num: 18,
              },
              {
                class_id: 7,
                num: 28,
              },
              {
                class_id: 5,
                num: 18,
              },
              {
                class_id: 8,
                num: 58,
              },
              {
                class_id: 12,
                num: 58,
              },
              {
                class_id: 9,
                num: 1,
              },
            ],
            reward: 1888,
          },
          {
            complete: [
              {
                class_id: 11,
                num: 18,
              },
              {
                class_id: 7,
                num: 8,
              },
              {
                class_id: 5,
                num: 28,
              },
              {
                class_id: 8,
                num: 8,
              },
              {
                class_id: 12,
                num: 8,
              },
              {
                class_id: 9,
                num: 3,
              },
            ],
            reward: 999,
          },
          {
            complete: [
              {
                class_id: 1,
                num: 1,
              },
            ],
            reward: 60,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 3,
                num: 8,
              },
              {
                class_id: 10,
                num: 8,
              },
              {
                class_id: 2,
                num: 8,
              },
              {
                class_id: 4,
                num: 8,
              },
              {
                class_id: 1,
                num: 28,
              },
            ],
            reward: 2666,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 3,
                num: 28,
              },
              {
                class_id: 10,
                num: 8,
              },
              {
                class_id: 2,
                num: 8,
              },
              {
                class_id: 4,
                num: 8,
              },
              {
                class_id: 1,
                num: 8,
              },
            ],
            reward: 2666,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 18,
              },
              {
                class_id: 3,
                num: 8,
              },
              {
                class_id: 10,
                num: 8,
              },
              {
                class_id: 2,
                num: 18,
              },
              {
                class_id: 4,
                num: 8,
              },
              {
                class_id: 1,
                num: 8,
              },
            ],
            reward: 2666,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 3,
                num: 8,
              },
              {
                class_id: 10,
                num: 18,
              },
              {
                class_id: 2,
                num: 8,
              },
              {
                class_id: 4,
                num: 18,
              },
              {
                class_id: 1,
                num: 8,
              },
            ],
            reward: 2666,
          },
        ],
      },
      hw3: {
        hw3_reward: [
          2000, 888, 666, 500, 400, 300, 200, 100, 100, 100, 88, 88, 88, 88, 88,
          88, 88, 88, 88, 88,
        ],
        collect_reward: [
          {
            complete: [
              {
                class_id: 11,
                num: 18,
              },
              {
                class_id: 7,
                num: 28,
              },
              {
                class_id: 5,
                num: 18,
              },
              {
                class_id: 8,
                num: 58,
              },
              {
                class_id: 12,
                num: 58,
              },
              {
                class_id: 9,
                num: 1,
              },
            ],
            reward: 1888,
          },
          {
            complete: [
              {
                class_id: 11,
                num: 18,
              },
              {
                class_id: 7,
                num: 8,
              },
              {
                class_id: 5,
                num: 28,
              },
              {
                class_id: 8,
                num: 8,
              },
              {
                class_id: 12,
                num: 8,
              },
              {
                class_id: 9,
                num: 3,
              },
            ],
            reward: 999,
          },
          {
            complete: [
              {
                class_id: 1,
                num: 1,
              },
            ],
            reward: 60,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 3,
                num: 8,
              },
              {
                class_id: 10,
                num: 8,
              },
              {
                class_id: 2,
                num: 8,
              },
              {
                class_id: 4,
                num: 8,
              },
              {
                class_id: 1,
                num: 28,
              },
            ],
            reward: 2666,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 3,
                num: 28,
              },
              {
                class_id: 10,
                num: 8,
              },
              {
                class_id: 2,
                num: 8,
              },
              {
                class_id: 4,
                num: 8,
              },
              {
                class_id: 1,
                num: 8,
              },
            ],
            reward: 2666,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 18,
              },
              {
                class_id: 3,
                num: 8,
              },
              {
                class_id: 10,
                num: 8,
              },
              {
                class_id: 2,
                num: 18,
              },
              {
                class_id: 4,
                num: 8,
              },
              {
                class_id: 1,
                num: 8,
              },
            ],
            reward: 2666,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 3,
                num: 8,
              },
              {
                class_id: 10,
                num: 18,
              },
              {
                class_id: 2,
                num: 8,
              },
              {
                class_id: 4,
                num: 18,
              },
              {
                class_id: 1,
                num: 8,
              },
            ],
            reward: 2666,
          },
        ],
      },
      mxt: {
        mxt_reward: [
          2000, 888, 666, 500, 400, 300, 200, 100, 100, 100, 88, 88, 88, 88, 88,
          88, 88, 88, 88, 88,
        ],
        class_ids: [0, 1, 2, 3, 4, 5, 6],
        collect_reward: [
          {
            complete: [
              {
                class_id: 3,
                num: 70,
              },
            ],
            reward: 888,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 1,
                num: 5,
              },
              {
                class_id: 2,
                num: 3,
              },
              {
                class_id: 3,
                num: 2,
              },
              {
                class_id: 4,
                num: 18,
              },
              {
                class_id: 5,
                num: 18,
              },
            ],
            reward: 1666,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 1,
                num: 5,
              },
              {
                class_id: 2,
                num: 3,
              },
              {
                class_id: 3,
                num: 2,
              },
              {
                class_id: 4,
                num: 18,
              },
              {
                class_id: 5,
                num: 18,
              },
            ],
            reward: 2666,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 1,
                num: 5,
              },
              {
                class_id: 2,
                num: 3,
              },
              {
                class_id: 3,
                num: 2,
              },
              {
                class_id: 4,
                num: 18,
              },
              {
                class_id: 5,
                num: 18,
              },
            ],
            reward: 6666,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 1,
                num: 5,
              },
              {
                class_id: 2,
                num: 3,
              },
              {
                class_id: 3,
                num: 2,
              },
              {
                class_id: 4,
                num: 18,
              },
              {
                class_id: 5,
                num: 18,
              },
            ],
            reward: 2024,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 1,
                num: 5,
              },
              {
                class_id: 2,
                num: 3,
              },
              {
                class_id: 3,
                num: 2,
              },
              {
                class_id: 4,
                num: 18,
              },
              {
                class_id: 5,
                num: 18,
              },
            ],
            reward: 9999,
          },
        ],
      },
      msqy: {
        mxt_reward: [
          2000, 888, 666, 500, 400, 300, 200, 100, 100, 100, 88, 88, 88, 88, 88,
          88, 88, 88, 88, 88,
        ],
        class_ids: [1, 2, 3, 4, 5, 6, 7, 8],
        collect_reward: [
          {
            complete: [
              {
                class_id: 3,
                num: 70,
              },
            ],
            reward: 888,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 1,
                num: 5,
              },
              {
                class_id: 2,
                num: 3,
              },
              {
                class_id: 3,
                num: 2,
              },
              {
                class_id: 4,
                num: 18,
              },
              {
                class_id: 5,
                num: 18,
              },
            ],
            reward: 1666,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 1,
                num: 5,
              },
              {
                class_id: 2,
                num: 3,
              },
              {
                class_id: 3,
                num: 2,
              },
              {
                class_id: 4,
                num: 18,
              },
              {
                class_id: 5,
                num: 18,
              },
            ],
            reward: 2666,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 1,
                num: 5,
              },
              {
                class_id: 2,
                num: 3,
              },
              {
                class_id: 3,
                num: 2,
              },
              {
                class_id: 4,
                num: 18,
              },
              {
                class_id: 5,
                num: 18,
              },
            ],
            reward: 6666,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 1,
                num: 5,
              },
              {
                class_id: 2,
                num: 3,
              },
              {
                class_id: 3,
                num: 2,
              },
              {
                class_id: 4,
                num: 18,
              },
              {
                class_id: 5,
                num: 18,
              },
            ],
            reward: 2024,
          },
          {
            complete: [
              {
                class_id: 0,
                num: 8,
              },
              {
                class_id: 1,
                num: 5,
              },
              {
                class_id: 2,
                num: 3,
              },
              {
                class_id: 3,
                num: 2,
              },
              {
                class_id: 4,
                num: 18,
              },
              {
                class_id: 5,
                num: 18,
              },
            ],
            reward: 9999,
          },
        ],
      },
    },
  },
};

//#endregion

//#region 全部游戏
const get_machine_info = {
  msg_type: "get_machine_info",
  info: {
    // 马戏团
    1: {
      // sp_url: "webrtc://webrtc.weipinwl.com:1991/live/mxt3n",
      sp_url: "webrtc://webrtc.weipinwl.com:1991/live/msqy1",
      return_time: 30,
      visit_time: 60,
      site_limit: 10,
      product_id: "447CSARW13",
      up_coin: 10,
      exchange: 20,
      product_name: "mxt",
      level: 1,
      device_info: [
        {
          check_num: 0,
          occupy: {
            cost: 0,
            lottery: 0,
          },
          device_index: 1,
          net_line_index: "webrtc://webrtc.weipinwl.com:1991/live/mxt3n",
          machine_index: "1",
          device_name: "mxt11",
          server_time: 1711270440,
          status: "1",
          bbl_win_num: 0,
          check_num_free_time: 1,
          product_name: "mxt",
        },
      ],
      machine_index: "1",
      tiaozhan_index: 0,
      status: "1",
      visits: [],
      occupy: [],
      child_name: "mxt",
      group: "",
    },
    // 魔鬼城
    2: {
      sp_url: "webrtc://yisunew.weipinwl.com:1991/live/mgc1n",
      coder_index: "2",
      site_limit: 200,
      status: "1",
      level: 1,
      product_id: "447CSARW13",
      product_name: "mgc",
      tiaozhan_index: 0,
      up_coin: 200,
      exchange: 10,
      machine_index: "2",
      visit_time: 60,
      site_sign_num: 1,
      device_info: [
        {
          product_name: "mgc",
          occupy: {
            player_id: 100861,
            select_title: "3",
          },
          check_num: 0,
          machine_index: "2",
          device_name: "mgc11",
          check_num_free_time: 1,
          net_line_index: "mgc11",
          status: "1",
          bbl_win_num: 0,
          device_index: 1,
          server_time: 1711177500,
        },
        {
          product_name: "mgc",
          occupy: {},
          check_num: 0,
          machine_index: "2",
          device_name: "mgc12",
          check_num_free_time: 1,
          net_line_index: "mgc12",
          status: "1",
          bbl_win_num: 0,
          device_index: 2,
          server_time: 1711177500,
        },
        {
          product_name: "mgc",
          occupy: {},
          check_num: 400,
          machine_index: "2",
          device_name: "mgc13",
          check_num_free_time: 2,
          net_line_index: "mgc13",
          status: "1",
          bbl_win_num: 0,
          device_index: 3,
          server_time: 1711177500,
        },
        {
          product_name: "mgc",
          occupy: {},
          check_num: 250,
          machine_index: "2",
          device_name: "mgc14",
          check_num_free_time: 2,
          net_line_index: "mgc14",
          status: "1",
          bbl_win_num: 0,
          device_index: 4,
          server_time: 1711177500,
        },
      ],
      net_line_index: "2",
      up_coin_sign_num: 1,
      return_time: 300,
      child_name: "mgc",
      group: "",
    },
    // 娃娃机
    3: {
      return_time: 1,
      group: "",
      product_id: "XG3S27HBPD",
      visit_time: 60,
      status: "1",
      level: 1,
      up_coin_sign_num: 1,
      tiaozhan_index: 0,
      up_coin: 10,
      exchange: 22,
      net_line_index: "1",
      machine_index: "3",
      sp_url: "webrtc://webrtc.weipinwl.com:1991/live/wp_zww1",
      device_info: [
        {
          check_num_free_time: 2,
          bbl_win_num: 0,
          check_num: 0,
          device_index: 1,
          machine_index: "1",
          device_name: "zww11",
          occupy: {
            cost: 0,
            lottery: 0,
          },
          status: "1",
          net_line_index: "zww11",
          server_time: 1711696924,
          product_name: "zww",
        },
      ],
      product_name: "zww",
      visits: {},
      coder_index: "1",
      site_limit: 10,
      site_sign_num: 1,
      child_name: "zww",
    },
    //打茑
    4: {
      group: "",
      site_limit: 100,
      device_info: [
        {
          occupy: {
            cost: 0,
            lottery: 0,
          },
          device_index: 1,
          check_num_free_time: 2,
          machine_index: "1",
          device_name: "other11",
          product_name: "other",
          net_line_index: "other11",
          status: "1",
          check_num: 0,
          bbl_win_num: 0,
          server_time: 1712023220,
        },
        {
          occupy: {
            cost: 0,
            lottery: 0,
          },
          device_index: 1,
          check_num_free_time: 2,
          machine_index: "1",
          device_name: "other11",
          product_name: "other",
          net_line_index: "other11",
          status: "1",
          check_num: 0,
          bbl_win_num: 0,
          server_time: 1712023220,
        },
        {
          occupy: {
            cost: 0,
            lottery: 0,
          },
          device_index: 1,
          check_num_free_time: 2,
          machine_index: "1",
          device_name: "other11",
          product_name: "other",
          net_line_index: "other11",
          status: "1",
          check_num: 0,
          bbl_win_num: 0,
          server_time: 1712023220,
        },
        {
          occupy: {
            cost: 0,
            lottery: 0,
          },
          device_index: 1,
          check_num_free_time: 2,
          machine_index: "1",
          device_name: "other11",
          product_name: "other",
          net_line_index: "other11",
          status: "1",
          check_num: 0,
          bbl_win_num: 0,
          server_time: 1712023220,
        },
      ],
      net_line_index: "1",
      product_name: "other",
      status: "1",
      level: 1,
      child_name: "nw",
      product_id: "P4MEM5OBLZ",
      site_sign_num: 1,
      up_coin: 100,
      exchange: 22,
      up_coin_sign_num: 1,
      machine_index: "4",
      return_time: 300,
      tiaozhan_index: 0,
      visit_time: 60,
      tiaozhan_num: 0,
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/csfff",
      coder_index: "1",
      visits: {},
    },
    //打虫
    5: {
      group: "",
      site_limit: 50,
      device_info: [
        {
          occupy: {
            cost: 0,
            lottery: 0,
          },
          device_index: 1,
          check_num_free_time: 1,
          machine_index: "2",
          device_name: "other21",
          product_name: "other",
          net_line_index: "other21",
          status: "1",
          check_num: 0,
          bbl_win_num: 0,
          server_time: 1712023220,
        },
      ],
      net_line_index: "2",
      product_name: "other",
      status: "1",
      level: 1,
      child_name: "ccly",
      product_id: "P4MEM5OBLZ",
      site_sign_num: 1,
      up_coin: 50,
      exchange: 5,
      up_coin_sign_num: 1,
      machine_index: "5",
      return_time: 300,
      tiaozhan_index: 0,
      visit_time: 60,
      tiaozhan_num: 0,
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/csfff",
      coder_index: "2",
      visits: {},
    },
    //海王
    6: {
      group: "",
      site_limit: 50,
      device_info: [
        {
          occupy: {
            cost: 0,
            lottery: 0,
          },
          device_index: 1,
          check_num_free_time: 1,
          machine_index: "2",
          device_name: "hw321",
          product_name: "hw3",
          net_line_index: "hw321",
          status: "1",
          check_num: 0,
          bbl_win_num: 0,
          server_time: 1712023220,
        },
      ],
      net_line_index: "2",
      product_name: "hw3",
      status: "1",
      level: 1,
      child_name: "yhfh",
      product_id: "P4MEM5OBLZ",
      site_sign_num: 1,
      up_coin: 50,
      exchange: 5,
      up_coin_sign_num: 1,
      machine_index: "6",
      return_time: 300,
      tiaozhan_index: 0,
      visit_time: 60,
      tiaozhan_num: 0,
      sp_url: "webrtc://yisunew.weipinwl.com:1991/live/testhw3",
      coder_index: "2",
      visits: {},
    },
    // 跳跳球
    7: {
      group: "",
      site_limit: 50,
      device_info: [
        {
          occupy: {
            cost: 0,
            lottery: 0,
          },
          device_index: 1,
          check_num_free_time: 1,
          machine_index: "2",
          device_name: "other21",
          product_name: "bbl",
          net_line_index: "bbl21",
          status: "1",
          check_num: 0,
          bbl_win_num: 0,
          server_time: 1712023220,
        },
      ],
      net_line_index: "2",
      product_name: "other",
      status: "1",
      level: 1,
      child_name: "ttq",
      product_id: "P4MEM5OBLZ",
      site_sign_num: 1,
      up_coin: 50,
      exchange: 5,
      up_coin_sign_num: 1,
      machine_index: "7",
      return_time: 300,
      tiaozhan_index: 0,
      visit_time: 60,
      tiaozhan_num: 0,
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/ttq",
      coder_index: "2",
      visits: {},
    },
    // 幸运财神
    8: {
      group: "",
      site_limit: 50,
      device_info: [
        {
          occupy: {
            cost: 0,
            lottery: 0,
            player_id: 9,
          },
          device_index: 1,
          check_num_free_time: 1,
          machine_index: "2",
          device_name: "other21",
          product_name: "bbl",
          net_line_index: "bbl21",
          status: "1",
          check_num: 0,
          bbl_win_num: 0,
          server_time: 1712023220,
        },
        {
          occupy: {
            cost: 0,
            lottery: 0,
            player_id: 9,
          },
          device_index: 1,
          check_num_free_time: 1,
          machine_index: "2",
          device_name: "other21",
          product_name: "bbl",
          net_line_index: "bbl21",
          status: "1",
          check_num: 0,
          bbl_win_num: 0,
          server_time: 1712023220,
        },
        {
          occupy: {
            cost: 0,
            lottery: 0,
            player_id: 9,
          },
          device_index: 1,
          check_num_free_time: 1,
          machine_index: "2",
          device_name: "other21",
          product_name: "bbl",
          net_line_index: "bbl21",
          status: "1",
          check_num: 0,
          bbl_win_num: 0,
          server_time: 1712023220,
        },
        {
          occupy: {
            cost: 0,
            lottery: 0,
          },
          device_index: 1,
          check_num_free_time: 1,
          machine_index: "2",
          device_name: "other21",
          product_name: "bbl",
          net_line_index: "bbl21",
          status: "1",
          check_num: 0,
          bbl_win_num: 0,
          server_time: 1712023220,
        },
      ],
      net_line_index: "2",
      product_name: "other",
      status: "1",
      level: 1,
      child_name: "xycs",
      product_id: "P4MEM5OBLZ",
      site_sign_num: 1,
      up_coin: 50,
      exchange: 5,
      up_coin_sign_num: 1,
      machine_index: "8",
      return_time: 300,
      tiaozhan_index: 0,
      visit_time: 60,
      tiaozhan_num: 0,
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/xycs",
      coder_index: "2",
      visits: {},
    },
    // 爆爆乐
    9: {
      group: "",
      site_limit: 50,
      device_info: [
        {
          occupy: {
            // cost: 0,
            // lottery: 0,
            // player_id: 9,
            // head: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/default_avatar_6.png",
          },
          device_index: 1,
          check_num_free_time: 1,
          machine_index: "2",
          device_name: "other21",
          product_name: "bbl",
          net_line_index: "bbl21",
          status: "1",
          check_num: 0,
          bbl_win_num: 0,
          server_time: 1712023220,
        },
      ],
      net_line_index: "2",
      product_name: "bbl",
      status: "1",
      level: 1,
      child_name: "bbl",
      product_id: "P4MEM5OBLZ",
      site_sign_num: 1,
      up_coin: 50,
      exchange: 5,
      up_coin_sign_num: 1,
      machine_index: "9",
      return_time: 300,
      tiaozhan_index: 0,
      visit_time: 60,
      tiaozhan_num: 0,
      sp_url: "webrtc://yisunew.weipinwl.com:1991/live/bbl5",
      coder_index: "2",
      visits: {},
    },
    // 星球大战
    10: {
      group: "",
      site_limit: 50,
      device_info: [
        {
          occupy: {
            // cost: 0,
            // lottery: 0,
            // player_id: 9,
            // head: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/default_avatar_6.png",
          },
          device_index: 1,
          check_num_free_time: 1,
          machine_index: "2",
          device_name: "other21",
          product_name: "bbl",
          net_line_index: "bbl21",
          status: "1",
          check_num: 0,
          bbl_win_num: 0,
          server_time: 1712023220,
        },
      ],
      net_line_index: "2",
      product_name: "bird",
      status: "1",
      level: 1,
      child_name: "xqdz",
      product_id: "P4MEM5OBLZ",
      site_sign_num: 1,
      up_coin: 50,
      exchange: 5,
      up_coin_sign_num: 1,
      machine_index: "10",
      return_time: 300,
      tiaozhan_index: 0,
      visit_time: 60,
      tiaozhan_num: 0,
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/xqdz",
      coder_index: "2",
      visits: {},
    },
  },
};

//#endregion

//#region  参加挑战

const get_tiaozhan_info_result = {
  msg_type: "get_tiaozhan_info_result",
  info: {
    tiaozhan_def: {
      reward: [10000, 5000, 2500, 2000, 1500, 1000, 500, 500, 500, 500],
    },
    tiaozhan_info: [
      {
        gold_info: {
          1: {
            sign_num: 1,
            gold: 100,
          },
          2: {
            sign_num: 3,
            gold: 300,
          },
          3: {
            sign_num: 5,
            gold: 500,
          },
        },
        log: {
          player_name: "6auY5bWp",
          uid: 22,
          time: 1700314715,
          tiaozhan_index: 1,
          type: "success",
          level: 4,
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/83cenYt3ON5xGxHtwX7ZzwrCETYbZbKY1dWV7If52p2SXmRXxTqHPGrN1iaNnQlKVjR812urHKtjGE5Yu1IoblQ/132",
          reward: 500,
        },
        index: 1,
        exchange: 10,
        ticket_num: 1,
        game_time: 15,
        complete: 10000,
        product_name: "mgc",
        type: "normal",
        reward: 500,
      },
      {
        gold_info: {
          1: {
            sign_num: 1,
            gold: 200,
          },
          2: {
            sign_num: 3,
            gold: 600,
          },
          3: {
            sign_num: 5,
            gold: 1000,
          },
        },
        log: {
          player_name: "5ZKU5Y+u5Y2h5LiB5YS/56ul5LmQ5Zut5YWo5qGI562W5YiS",
          uid: 18,
          time: 1700199517,
          tiaozhan_index: 2,
          type: "success",
          level: 4,
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
          reward: 1000,
        },
        index: 2,
        exchange: 20,
        ticket_num: 2,
        game_time: 15,
        complete: 2000,
        product_name: "mgc",
        type: "normal",
        reward: 1000,
      },
      {
        gold_info: {
          1: {
            sign_num: 1,
            gold: 100,
          },
          2: {
            sign_num: 3,
            gold: 300,
          },
          3: {
            sign_num: 5,
            gold: 500,
          },
        },
        log: {
          player_name: "6auY5bWp",
          uid: 22,
          time: 1700549332,
          tiaozhan_index: 3,
          type: "success",
          level: 4,
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/83cenYt3ON5xGxHtwX7ZzwrCETYbZbKY1dWV7If52p2SXmRXxTqHPGrN1iaNnQlKVjR812urHKtjGE5Yu1IoblQ/132",
          reward: 500,
        },
        index: 3,
        exchange: 10,
        ticket_num: 1,
        game_time: 15,
        complete: 10000,
        product_name: "bbl",
        type: "normal",
        reward: 500,
      },
      {
        gold_info: {
          1: {
            sign_num: 1,
            gold: 200,
          },
          2: {
            sign_num: 3,
            gold: 600,
          },
          3: {
            sign_num: 5,
            gold: 1000,
          },
        },
        log: {
          player_name: "6auY5bWp",
          uid: 22,
          time: 1700030075,
          tiaozhan_index: 4,
          type: "success",
          level: 4,
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/83cenYt3ON5xGxHtwX7ZzwrCETYbZbKY1dWV7If52p2SXmRXxTqHPGrN1iaNnQlKVjR812urHKtjGE5Yu1IoblQ/132",
          reward: 1000,
        },
        index: 4,
        exchange: 20,
        ticket_num: 2,
        game_time: 15,
        complete: 20000,
        product_name: "bbl",
        type: "normal",
        reward: 1000,
      },
      {
        gold_info: {
          1: {
            sign_num: 1,
            gold: 300,
          },
          2: {
            sign_num: 3,
            gold: 900,
          },
          3: {
            sign_num: 5,
            gold: 1500,
          },
        },
        log: {
          player_name: "5ZKU5Y+u5Y2h5LiB5YS/56ul5LmQ5Zut5YWo5qGI562W5YiS",
          uid: 18,
          time: 1700293964,
          tiaozhan_index: 5,
          type: "success",
          level: 4,
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
          reward: 1500,
        },
        index: 5,
        exchange: 30,
        ticket_num: 3,
        game_time: 15,
        complete: 30000,
        product_name: "mgc",
        type: "normal",
        reward: 1500,
      },
      {
        gold_info: {
          1: {
            sign_num: 1,
            gold: 300,
          },
          2: {
            sign_num: 3,
            gold: 900,
          },
          3: {
            sign_num: 5,
            gold: 1500,
          },
        },
        type: "normal",
        index: 6,
        exchange: 30,
        game_time: 15,
        complete: 30000,
        product_name: "bbl",
        ticket_num: 3,
        reward: 1500,
      },
      {
        gold_info: {
          1: {
            sign_num: 1,
            gold: 300,
          },
          2: {
            sign_num: 3,
            gold: 900,
          },
          3: {
            sign_num: 5,
            gold: 1500,
          },
        },
        log: {
          player_name: "6auY5bWp",
          uid: 22,
          time: 1700145415,
          tiaozhan_index: 7,
          type: "success",
          level: 4,
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/83cenYt3ON5xGxHtwX7ZzwrCETYbZbKY1dWV7If52p2SXmRXxTqHPGrN1iaNnQlKVjR812urHKtjGE5Yu1IoblQ/132",
          reward: 1500,
        },
        index: 7,
        exchange: 30,
        ticket_num: 4,
        game_time: 10,
        complete: 90000,
        product_name: "mgc",
        type: "dashi",
        reward: 1500,
      },
      {
        gold_info: {
          1: {
            sign_num: 1,
            gold: 300,
          },
          2: {
            sign_num: 3,
            gold: 900,
          },
          3: {
            sign_num: 5,
            gold: 1500,
          },
        },
        type: "zongshi",
        index: 8,
        exchange: 30,
        game_time: 10,
        complete: 150000,
        product_name: "mgc",
        ticket_num: 5,
        reward: 1500,
      },

      {
        gold_info: {
          1: {
            sign_num: 1,
            gold: 300,
          },
          2: {
            sign_num: 3,
            gold: 900,
          },
          3: {
            sign_num: 5,
            gold: 1500,
          },
        },
        type: "normal",
        index: 8,
        exchange: 30,
        game_time: 10,
        complete: 150000,
        product_name: "hw3",
        ticket_num: 5,
        reward: 1500,
      },
      {
        gold_info: {
          1: {
            sign_num: 1,
            gold: 300,
          },
          2: {
            sign_num: 3,
            gold: 900,
          },
          3: {
            sign_num: 5,
            gold: 1500,
          },
        },
        type: "zongshi",
        index: 8,
        exchange: 30,
        game_time: 10,
        complete: 150000,
        product_name: "hw3",
        ticket_num: 5,
        reward: 1500,
      },
      {
        gold_info: {
          1: {
            sign_num: 1,
            gold: 300,
          },
          2: {
            sign_num: 3,
            gold: 900,
          },
          3: {
            sign_num: 5,
            gold: 1500,
          },
        },
        type: "dashi",
        index: 8,
        exchange: 30,
        game_time: 10,
        complete: 150000,
        product_name: "hw3",
        ticket_num: 5,
        reward: 1500,
      },
    ],
    my_info: {
      xunzhang: 1,
      success_num: 2,
      tiaozhan_num: 3,
      totoal_tiaozhan_num: 4,
      total_xunzhang: 6,
      total_success_num: 7,
      total_reward: 5,
      reward: 8,
    },
  },
};

const get_player_tiaozhan_info_result = {
  msg_type: "get_player_tiaozhan_info_result",
  info: {
    is_end: false,
    uid: 18,
    is_start: false,
    tiaozhan_index: 1,
    exchange: 10,
    select_gold_index: "1",
    product_name: "mgc",
  },
};

//#endregion

//#region 奖励列表
const get_game_reward_result = {
  msg_type: "get_game_reward_result",
  info: [],
};

//#endregion

const enter_game_result = {
  msg_type: "enter_game_result",
  info: {
    error: "ok",
    game_name: "bbl",
    machine_index: "1",
    device_name: "bblt12",
    dec_gold: "0",
  },
};

const get_tianti_rank_result = {
  msg_type: "get_tianti_rank_result",
  info: {
    rank_info: [
      {
        player_name: "6auY5bWp",
        zongshi_card: 0,
        month_exp: 2667,
        head: "https://thirdwx.qlogo.cn/mmopen/vi_32/83cenYt3ON5xGxHtwX7ZzwrCETYbZbKY1dWV7If52p2SXmRXxTqHPGrN1iaNnQlKVjR812urHKtjGE5Yu1IoblQ/132",
        week_exp: 2667,
        uid: 22,
        level: 2,
        recharge_level: parseInt(Math.random() * 20),
        dashi_card: 1,
        jidu_exp: 2667,
      },
      {
        player_name: "5ZKU5Y+u5Y2h5LiB5YS/56ul5LmQ5Zut5YWo5qGI562W5YiS",
        zongshi_card: 0,
        month_exp: 7078,
        head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
        week_exp: 357,
        uid: 18,
        level: 4,
        recharge_level: parseInt(Math.random() * 20),
        dashi_card: 0,
        jidu_exp: 7078,
      },
      {
        week_exp: 141,
        player_name: "NTU1MDAwMDA0",
        dashi_card: 0,
        uid: 4,
        zongshi_card: 0,
        jidu_exp: 145369,
        level: 1,
        recharge_level: parseInt(Math.random() * 20),
        month_exp: 169,
      },
      {
        player_name: "X1Rfb18wX1Rf",
        zongshi_card: 0,
        month_exp: 138,
        head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIULBNxNG3UCCxfE5eQDBdaGL9NUiaxKJwibicn9OHk8Be87ibsktHI0FnpVRkcZtB9TqwL6Uv8DWiamog/132",
        week_exp: 138,
        uid: 21,
        level: 1,
        recharge_level: parseInt(Math.random() * 20),
        dashi_card: 0,
        jidu_exp: 138,
      },
      {
        player_name: "5pifKO+9oe+9pc+J772l772hKe++iQ==",
        zongshi_card: 0,
        month_exp: 13430,
        head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKfThicOerYcK6ic1xFzVFEWOakAsfDFjGmRKpPqxEKwIXM6ibXSN68icCicuXGFKicic7bZSQeydqyEiacsw/132",
        week_exp: 131,
        uid: 20,
        level: 5,
        recharge_level: parseInt(Math.random() * 20),
        dashi_card: 0,
        jidu_exp: 13430,
      },
      {
        player_name: "5p6c54eD5Yqo5YqbIOWSlOWPruWNoeS4gS1NYWdnaWXmiJo=",
        zongshi_card: 0,
        month_exp: 76840,
        head: "https://thirdwx.qlogo.cn/mmopen/vi_32/4gSyyCLsunozs4JJUIg1tqgGeJe0h5VZibV2AsRAk4HHkJ28UKPc0gaR786mCQ28wHxIXD8UatgEcVZuE5V2cqg/132",
        week_exp: 13,
        uid: 19,
        level: 7,
        recharge_level: parseInt(Math.random() * 20),
        dashi_card: 0,
        jidu_exp: 76840,
      },
      {
        week_exp: 10,
        player_name: "NDU1MDAwMDAx",
        dashi_card: 0,
        uid: 1,
        zongshi_card: 0,
        jidu_exp: 131710,
        level: 1,
        recharge_level: parseInt(Math.random() * 20),
        month_exp: 10,
      },
      {
        week_exp: 0,
        player_name: "NDY4MDAwMDA3",
        dashi_card: 0,
        uid: 7,
        zongshi_card: 0,
        jidu_exp: 4062600,
        level: 3,
        recharge_level: parseInt(Math.random() * 20),
        month_exp: 4000,
      },
      {
        week_exp: 0,
        player_name: "MjM4MDAwMDA5",
        dashi_card: 0,
        uid: 9,
        zongshi_card: 0,
        jidu_exp: 15600,
        level: 5,
        recharge_level: parseInt(Math.random() * 20),
        month_exp: 0,
      },
      {
        player_name: "OTkyMDAwMDA4",
        dashi_card: 0,
        uid: 9,
        zongshi_card: 0,
        jidu_exp: 15600,
        level: 5,
        recharge_level: parseInt(Math.random() * 20),
        month_exp: 0,
      },
      {
        week_exp: 0,
        player_name: "MTgxMDAwMDA1",
        dashi_card: 0,
        uid: 5,
        zongshi_card: 0,
        jidu_exp: 15400,
        level: 5,
        month_exp: 0,
        recharge_level: parseInt(Math.random() * 20),
      },
      {
        week_exp: 0,
        player_name: "ODQwMDAwMDA2",
        dashi_card: 0,
        uid: 6,
        zongshi_card: 0,
        jidu_exp: 1373800,
        level: 3,
        month_exp: 3000,
        recharge_level: parseInt(Math.random() * 20),
      },
      {
        week_exp: 0,
        uid: 10,
        zongshi_card: 0,
        dashi_card: 0,
        jidu_exp: 0,
        level: 1,
        month_exp: 0,
        recharge_level: parseInt(Math.random() * 20),
      },
      {
        week_exp: 0,
        player_name: "OTI5MDAwMDAz",
        dashi_card: 0,
        uid: 3,
        zongshi_card: 0,
        jidu_exp: 15400,
        level: 5,
        month_exp: 10800,
        recharge_level: parseInt(Math.random() * 20),
      },
      {
        week_exp: 0,
        player_name: "Mzk0MDAwMDAy",
        jidu_exp: 0,
        uid: 2,
        month_exp: 0,
        recharge_level: parseInt(Math.random() * 20),
      },
      {
        player_name: "6aOY6JC955qE5Y+25a2Q",
        zongshi_card: 0,
        month_exp: 0,
        head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Eq4JZsLlZA6LYeEhm7THexMCibYf4ibG6yibJwNiaHpWFmOiasW07ydhCM9wdGicrhCKzBSN9m9XEA1HOLBbgOShjB1zkJ4nbS0H1LET5vZquIPTg/132",
        week_exp: 0,
        uid: 16,
        level: 1,
        dashi_card: 0,
        jidu_exp: 0,
      },
      {
        player_name: "5ZKU5Y+u5Y2h5LiBIOWumOaWuei/kOiQpeS4k+WRmA==",
        zongshi_card: 0,
        month_exp: 180,
        head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Y72Z18Yt2vmTBjvQN43cYpmGYjw1B9HibdNEvzibZOicnZd5nvnSDZIPUqoK5HtiayvLCm8aqib169IjC4IM9Iic2Lww/132",
        week_exp: 0,
        uid: 17,
        level: 1,
        dashi_card: 0,
        jidu_exp: 180,
      },
    ],
    my_info: {
      week_exp: 141,
      uid: 4,
      zongshi_card: 0,
      dashi_card: true,
      jidu_exp: 145369,
      level: 1,
      month_exp: 169,
    },
    tianti_def: {
      level_exp: [
        {
          dashi_card: 0,
          exp: [0, 999],
          zongshi_card: 0,
          dashi_score: 0,
        },
        {
          dashi_card: 0,
          exp: [1000, 2999],
          zongshi_card: 0,
          dashi_score: 0,
        },
        {
          dashi_card: 0,
          exp: [3000, 4999],
          zongshi_card: 0,
          dashi_score: 0,
        },
        {
          dashi_card: 0,
          exp: [5000, 9999],
          zongshi_card: 0,
          dashi_score: 0,
        },
        {
          dashi_card: 0,
          exp: [10000, 29999],
          zongshi_card: 0,
          dashi_score: 0,
        },
        {
          dashi_card: 0,
          exp: [30000, 49999],
          zongshi_card: 0,
          dashi_score: 0,
        },
        {
          dashi_card: 0,
          exp: [50000, 99999],
          zongshi_card: 0,
          dashi_score: 0,
        },
        {
          dashi_card: 0,
          exp: [80000, 159999],
          zongshi_card: 0,
          dashi_score: 0,
        },
        {
          dashi_card: 0,
          exp: [160000, 259999],
          zongshi_card: 0,
          dashi_score: 0,
        },
        {
          dashi_card: 1,
          exp: [260000, 399999],
          zongshi_card: 0,
          dashi_score: 200,
        },
        {
          dashi_card: 1,
          exp: [400000, 599999],
          zongshi_card: 0,
          dashi_score: 500,
        },
        {
          dashi_card: 1,
          exp: [600000, 8499999],
          zongshi_card: 0,
          dashi_score: 1000,
        },
        {
          dashi_card: 1,
          exp: [850000, 1149999],
          zongshi_card: 0,
          dashi_score: 1000,
        },
        {
          dashi_card: 1,
          exp: [1150000, 1649999],
          zongshi_card: 0,
          dashi_score: 1000,
        },
        {
          dashi_card: 1,
          exp: [1650000, 2249999],
          zongshi_card: 1,
          dashi_score: 7000,
        },
        {
          dashi_card: 1,
          exp: [2250000, 3049999],
          zongshi_card: 1,
          dashi_score: 10000,
        },
        {
          dashi_card: 1,
          exp: [3050000, 4049999],
          zongshi_card: 1,
          dashi_score: 14000,
        },
        {
          dashi_card: 1,
          exp: [4050000, 5549999],
          zongshi_card: 1,
          dashi_score: 18000,
        },
        {
          dashi_card: 1,
          exp: [5550000, 99999999],
          zongshi_card: 1,
          dashi_score: 25000,
        },
      ],
      month_reward: [
        18888, 10888, 6888, 3588, 2288, 1188, 1188, 1188, 1188, 1188, 999, 999,
        999, 999, 999, 999, 999, 999, 999, 999, 666, 666, 666, 666, 666, 666,
        666, 666, 666, 666, 666, 666, 666, 666, 666, 666, 666, 666, 666, 666,
        666, 666, 666, 666, 666, 666, 666, 666, 666, 666, 388, 388, 388, 388,
        388, 388, 388, 388, 388, 388, 388, 388, 388, 388, 388, 388, 388, 388,
        388, 388, 388, 388, 388, 388, 388, 388, 388, 388, 388, 388, 388, 388,
        388, 388, 388, 388, 388, 388, 388, 388, 388, 388, 388, 388, 388, 388,
        388, 388, 388, 388,
      ],
      week_reward: [
        6666, 3388, 2288, 1188, 988, 688, 688, 688, 688, 688, 388, 388, 388,
        388, 388, 388, 388, 388, 388, 388, 188, 188, 188, 188, 188, 188, 188,
        188, 188, 188, 188, 188, 188, 188, 188, 188, 188, 188, 188, 188, 188,
        188, 188, 188, 188, 188, 188, 188, 188, 188, 88, 88, 88, 88, 88, 88, 88,
        88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88,
        88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88,
        88, 88, 88, 88, 88, 88, 88,
      ],
      ji_reward: [
        99999, 66666, 33388, 22288, 11188, 8888, 8888, 8888, 8888, 8888, 6666,
        6666, 6666, 6666, 6666, 6666, 6666, 6666, 6666, 6666, 3888, 3888, 3888,
        3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888,
        3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888,
        3888, 3888, 3888, 1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888,
        1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888,
        1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888,
        1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888, 1888,
        1888, 1888, 1888, 1888, 1888,
      ],
      jidu_time: [
        {
          begin: {
            day: 1,
            month: 12,
          },
          stop: {
            day: 1,
            month: 3,
          },
        },
        {
          begin: {
            day: 1,
            month: 3,
          },
          stop: {
            day: 1,
            month: 6,
          },
        },
        {
          begin: {
            day: 1,
            month: 6,
          },
          stop: {
            day: 1,
            month: 9,
          },
        },
        {
          begin: {
            day: 1,
            month: 9,
          },
          stop: {
            day: 1,
            month: 12,
          },
        },
      ],
    },
  },
};

//#region 充值
const get_player_recharge_status_result = {
  msg_type: "get_player_recharge_status_result",
  info: {
    player_recharge_status: {
      total_recharge: 487,
      vip_exp: 12,
      update_time: 1715961600,
      foot: {
        1: true,
      },
      first: {
        4: true,
        5: true,
        6: true,
      },
      card: {
        1: {
          free_day: 21,
          is_get: true,
        },
        2: {
          free_day: 7,
          is_get: false,
        },
        3: {
          free_day: 30,
          is_get: true,
        },
        4: {
          free_day: 21,
          is_get: false,
        },
      },
      guoqing: {},
      lspmdci: {
        1: 1,
      },
      uid: 9,
      day: {},
      level: 5,
      gift_info: {
        update_time: 1715995833,
        is_buy: false,
        index: "1",
        buff: 0,
      },
    },
    recharge_def: {
      android: {
        guoqing: {
          1: {
            use_item: false,
            stone_info: {
              type: "rand",
              stone: {
                stone_blue: {
                  pro: 75000,
                  num: 1,
                },
                stone_green: {
                  pro: 25000,
                  num: 1,
                },
              },
            },
            gold: 300,
            max: 10000,
            product_id: "jk_1Hwveu4n2xIMaxDq",
            rmb: 18,
          },
          2: {
            use_item: false,
            stone_info: {
              type: "rand",
              stone: {
                stone_blue: {
                  pro: 80000,
                  num: 1,
                },
                stone_red: {
                  pro: 20000,
                  num: 1,
                },
              },
            },
            gold: 788,
            max: 10000,
            product_id: "jk_T5BSXS7d7H2XrKu7",
            rmb: 46,
          },
          3: {
            use_item: false,
            stone_info: {
              type: "rand",
              stone: {
                stone_gree: {
                  pro: 50000,
                  num: 1,
                },
                stone_red: {
                  pro: 50000,
                  num: 1,
                },
              },
            },
            gold: 1680,
            max: 10000,
            product_id: "jk_WX8kjkytvgiYSxOF",
            rmb: 99,
          },
          4: {
            use_item: false,
            stone_info: {
              type: "normal",
              stone: {
                stone_green: {
                  num: 1,
                },
                stone_red: {
                  num: 1,
                },
                stone_blue: {
                  num: 1,
                },
              },
            },
            gold: 4100,
            max: 10000,
            product_id: "jk_b80YylbOpvKAETDO",
            rmb: 188,
          },
          5: {
            use_item: false,
            stone_info: {
              type: "normal",
              stone: {
                stone_green: {
                  num: 5,
                },
                stone_red: {
                  num: 5,
                },
                stone_blue: {
                  num: 5,
                },
              },
            },
            gold: 23888,
            max: 10000,
            product_id: "jk_8g0F3MketqCz2U1R",
            rmb: 899,
          },
        },
        lspmdci: {
          1: {
            use_item: false,
            rmb: 158,
            gold: 2666,
            max: 1,
            item_info: {
              num: 1.1,
              buff_end_time: 86400,
              use_limit: 259200,
              type: "tianti_buff_card",
            },
            product_id: "FeXAieZuu8fCzlEz",
          },
          2: {
            use_item: false,
            rmb: 398,
            gold: 7266,
            max: 1,
            item_info: {
              num: 1.15,
              buff_end_time: 86400,
              use_limit: 259200,
              type: "tianti_buff_card",
            },
            product_id: "XF0i8Z0ppKBTxBbc",
          },
          3: {
            use_item: false,
            rmb: 888,
            gold: 17088,
            max: 1,
            item_info: {
              num: 1.25,
              buff_end_time: 86400,
              use_limit: 259200,
              type: "tianti_buff_card",
            },
            product_id: "5VYkbz3XGVE1IMhL",
          },
        },
        gift: {
          1: {
            gold: 650,
            buff: 0.05,
            rmb: 29.9,
            product_id: "jk_cFalKs3CzrrHJgZQ",
          },
          2: {
            gold: 800,
            buff: 0.1,
            rmb: 29.9,
            product_id: "jk_HJQsHxZTTm6mogqP",
          },
          3: {
            gold: 1500,
            buff: 0.25,
            rmb: 29.9,
            product_id: "jk_5cGq0hg1RrcQdJ3B",
          },
        },
        day: {
          1: {
            max: 3,
            gold: 645,
            product_id: "jk_RVYw8gHm6WHcBb4N",
            rmb: 30,
          },
          2: {
            max: 3,
            gold: 1462,
            product_id: "jk_tGztBowtJ001HndV",
            rmb: 68,
          },
          3: {
            max: 3,
            gold: 2170,
            product_id: "jk_aYluvjNGriw46jkq",
            rmb: 98,
          },
          4: {
            max: 3,
            gold: 2750,
            product_id: "jk_QfXUEJo9SBkphtsU",
            rmb: 128,
          },
          5: {
            max: 3,
            gold: 7052,
            product_id: "jk_WjDfpWDkKDNnr61i",
            rmb: 328,
          },
          6: {
            max: 3,
            gold: 13999,
            product_id: "jk_BjC5Ez9fHRhlqusK",
            rmb: 568,
          },
          7: {
            max: 3,
            gold: 26666,
            product_id: "jk_k7qKJKu5A2IB90VZ",
            rmb: 998,
          },
          8: {
            max: 3,
            gold: 36666,
            product_id: "jk_xUped5eVzwCVjcen",
            rmb: 1288,
          },
          9: {
            max: 3,
            gold: 99999,
            product_id: "jk_8iUars3DmJsxa0Sq",
            rmb: 2048,
          },
        },
        tz_card: {
          1: {
            num: 3,
            product_id: "jk_nggeRdoOydEhGP8S",
            rmb: 6,
          },
          2: {
            num: 5,
            product_id: "jk_DOubD17bP3geAVCH",
            rmb: 10,
          },
          3: {
            num: 8,
            product_id: "jk_h1t40L5zX8Sk9Prc",
            rmb: 16,
          },
        },
        foot: {
          1: {
            use_item: false,
            gold: 84960,
            max: 1,
            product_id: "jk_utlmUjX1cJQvVOss",
            item_info: {
              type: "jiacheng_card",
              use_limit: 1209600,
              num: 1.3,
            },
            rmb: 1888,
          },
          2: {
            use_item: false,
            gold: 9380,
            max: 1,
            product_id: "jk_utv6Vyj3wtdrDHKG",
            rmb: 268,
          },
          3: {
            use_item: false,
            gold: 540,
            max: 1,
            product_id: "jk_c8qKyn2MVnfjQy23",
            rmb: 18,
          },
        },
        first: {
          1: {
            gold: 1520,
            product_id: "jk_E4kExitmpVMRVdUf",
            rmb: 38,
          },
          2: {
            gold: 2320,
            product_id: "jk_LE8nkrsUtv0TBKBq",
            rmb: 58,
          },
          3: {
            gold: 5120,
            product_id: "jk_6lU4fKQ7WwZqp7wO",
            rmb: 128,
          },
          4: {
            gold: 8720,
            product_id: "jk_GUtYtJmjji2dc1Ea",
            rmb: 218,
          },
          5: {
            gold: 27120,
            product_id: "jk_weDrcg0NQJLDD5sJ",
            rmb: 678,
          },
          6: {
            gold: 38720,
            product_id: "jk_cWbavPcV0c21knFy",
            rmb: 968,
          },
          7: {
            gold: 63520,
            product_id: "jk_gsygQqWp0qdVYsv2",
            rmb: 1588,
          },
          8: {
            gold: 95520,
            product_id: "jk_CZpbP7QIPkzE1zhO",
            rmb: 2688,
          },
        },
        base: {
          1: {
            gold: 60,
            product_id: "jk_OMNtrDov3hvUdpot",
            rmb: 6,
          },
          2: {
            gold: 180,
            product_id: "jk_QHDxAGSUjx3Jq40R",
            rmb: 18,
          },
          3: {
            gold: 380,
            product_id: "jk_rfuQWO0jwAeCsjVN",
            rmb: 28,
          },
          4: {
            gold: 570,
            product_id: "jk_zqJ7QtHFLqYe1xkA",
            rmb: 38,
          },
          5: {
            gold: 920,
            product_id: "jk_OC2IC7FyV3NCKbJk",
            rmb: 58,
          },
          6: {
            gold: 2200,
            product_id: "jk_AVnaS9msK4d5Oqn7",
            rmb: 128,
          },
          7: {
            gold: 3900,
            product_id: "jk_cCsOa8lZp11HzW7m",
            rmb: 218,
          },
          10: {
            gold: 13500,
            product_id: "jk_LGuIuxedDc21pwhY",
            rmb: 678,
          },
          12: {
            gold: 22000,
            product_id: "jk_xbAoR3BqYuNNwptE",
            rmb: 968,
          },
        },
        card: {
          1: {
            day_gold: 100,
            tiaozhan: 20,
            product_id: "jk_LNfk0cQOrOw0AdRj",
            free_day: 7,
            rmb: 30,
            gold: 300,
          },
          2: {
            day_gold: 210,
            tiaozhan: 35,
            product_id: "jk_0uieK8VlXrXtXuBW",
            free_day: 7,
            rmb: 99,
            gold: 1350,
          },
          3: {
            day_gold: 100,
            tiaozhan: 88,
            product_id: "jk_HR4omxwYip179aO7",
            free_day: 30,
            rmb: 128,
            gold: 2000,
          },
          4: {
            day_gold: 250,
            tiaozhan: 600,
            product_id: "jk_6We1aQ5tjOmtjKCJ",
            free_day: 30,
            rmb: 299,
            gold: 4485,
          },
        },
      },
      ios: {
        guoqing: {
          1: {
            use_item: false,
            stone_info: {
              type: "rand",
              stone: {
                stone_blue: {
                  pro: 75000,
                  num: 1,
                },
                stone_green: {
                  pro: 25000,
                  num: 1,
                },
              },
            },
            gold: 300,
            max: 10000,
            product_id: "jk_1Hwveu4n2xIMaxDq",
            rmb: 18,
          },
          2: {
            use_item: false,
            stone_info: {
              type: "rand",
              stone: {
                stone_blue: {
                  pro: 80000,
                  num: 1,
                },
                stone_red: {
                  pro: 20000,
                  num: 1,
                },
              },
            },
            gold: 788,
            max: 10000,
            product_id: "jk_T5BSXS7d7H2XrKu7",
            rmb: 46,
          },
          3: {
            use_item: false,
            stone_info: {
              type: "rand",
              stone: {
                stone_gree: {
                  pro: 50000,
                  num: 1,
                },
                stone_red: {
                  pro: 50000,
                  num: 1,
                },
              },
            },
            gold: 1680,
            max: 10000,
            product_id: "jk_WX8kjkytvgiYSxOF",
            rmb: 99,
          },
          4: {
            use_item: false,
            stone_info: {
              type: "normal",
              stone: {
                stone_green: {
                  num: 1,
                },
                stone_red: {
                  num: 1,
                },
                stone_blue: {
                  num: 1,
                },
              },
            },
            gold: 4100,
            max: 10000,
            product_id: "jk_b80YylbOpvKAETDO",
            rmb: 188,
          },
          5: {
            use_item: false,
            stone_info: {
              type: "normal",
              stone: {
                stone_green: {
                  num: 5,
                },
                stone_red: {
                  num: 5,
                },
                stone_blue: {
                  num: 5,
                },
              },
            },
            gold: 23888,
            max: 10000,
            product_id: "jk_8g0F3MketqCz2U1R",
            rmb: 899,
          },
        },
        lspmdci: {
          1: {
            use_item: false,
            rmb: 158,
            gold: 2666,
            max: 1,
            item_info: {
              num: 1.1,
              buff_end_time: 86400,
              use_limit: 259200,
              type: "tianti_buff_card",
            },
            product_id: "FeXAieZuu8fCzlEz",
          },
          2: {
            use_item: false,
            rmb: 398,
            gold: 7266,
            max: 1,
            item_info: {
              num: 1.15,
              buff_end_time: 86400,
              use_limit: 259200,
              type: "tianti_buff_card",
            },
            product_id: "XF0i8Z0ppKBTxBbc",
          },
          3: {
            use_item: false,
            rmb: 888,
            gold: 17088,
            max: 1,
            item_info: {
              num: 1.25,
              buff_end_time: 86400,
              use_limit: 259200,
              type: "tianti_buff_card",
            },
            product_id: "5VYkbz3XGVE1IMhL",
          },
        },
        gift: {
          1: {
            gold: 650,
            buff: 0.05,
            rmb: 29.9,
            product_id: "jk_cFalKs3CzrrHJgZQ",
          },
          2: {
            gold: 800,
            buff: 0.1,
            rmb: 29.9,
            product_id: "jk_HJQsHxZTTm6mogqP",
          },
          3: {
            gold: 1500,
            buff: 0.25,
            rmb: 29.9,
            product_id: "jk_5cGq0hg1RrcQdJ3B",
          },
        },
        day: {
          1: {
            max: 3,
            gold: 645,
            product_id: "jk_RVYw8gHm6WHcBb4N",
            rmb: 30,
          },
          2: {
            max: 3,
            gold: 1462,
            product_id: "jk_tGztBowtJ001HndV",
            rmb: 68,
          },
          3: {
            max: 3,
            gold: 2170,
            product_id: "jk_aYluvjNGriw46jkq",
            rmb: 98,
          },
          4: {
            max: 3,
            gold: 2750,
            product_id: "jk_QfXUEJo9SBkphtsU",
            rmb: 128,
          },
          5: {
            max: 3,
            gold: 7052,
            product_id: "jk_WjDfpWDkKDNnr61i",
            rmb: 328,
          },
          6: {
            max: 3,
            gold: 13999,
            product_id: "jk_BjC5Ez9fHRhlqusK",
            rmb: 568,
          },
          7: {
            max: 3,
            gold: 26666,
            product_id: "jk_k7qKJKu5A2IB90VZ",
            rmb: 998,
          },
          8: {
            max: 3,
            gold: 36666,
            product_id: "jk_xUped5eVzwCVjcen",
            rmb: 1288,
          },
          9: {
            max: 3,
            gold: 99999,
            product_id: "jk_8iUars3DmJsxa0Sq",
            rmb: 2048,
          },
        },
        tz_card: {
          1: {
            num: 3,
            product_id: "jk_nggeRdoOydEhGP8S",
            rmb: 6,
          },
          2: {
            num: 5,
            product_id: "jk_DOubD17bP3geAVCH",
            rmb: 10,
          },
          3: {
            num: 8,
            product_id: "jk_h1t40L5zX8Sk9Prc",
            rmb: 16,
          },
        },
        foot: {
          1: {
            use_item: false,
            gold: 84960,
            max: 1,
            product_id: "jk_utlmUjX1cJQvVOss",
            item_info: {
              type: "jiacheng_card",
              use_limit: 1209600,
              num: 1.3,
            },
            rmb: 1888,
          },
          2: {
            use_item: false,
            gold: 9380,
            max: 1,
            product_id: "jk_utv6Vyj3wtdrDHKG",
            rmb: 268,
          },
          3: {
            use_item: false,
            gold: 540,
            max: 1,
            product_id: "jk_c8qKyn2MVnfjQy23",
            rmb: 18,
          },
        },
        first: {
          1: {
            gold: 1520,
            product_id: "jk_E4kExitmpVMRVdUf",
            rmb: 38,
          },
          2: {
            gold: 2320,
            product_id: "jk_LE8nkrsUtv0TBKBq",
            rmb: 58,
          },
          3: {
            gold: 8720,
            product_id: "jk_GUtYtJmjji2dc1Ea",
            rmb: 218,
          },
          4: {
            gold: 27120,
            product_id: "jk_weDrcg0NQJLDD5sJ",
            rmb: 678,
          },
          5: {
            gold: 38720,
            product_id: "jk_cWbavPcV0c21knFy",
            rmb: 968,
          },
          6: {
            gold: 63520,
            product_id: "jk_gsygQqWp0qdVYsv2",
            rmb: 1588,
          },
          7: {
            gold: 95520,
            product_id: "jk_CZpbP7QIPkzE1zhO",
            rmb: 2688,
          },
        },
        base: {
          1: {
            gold: 60,
            product_id: "jk_OMNtrDov3hvUdpot",
            rmb: 6,
          },
          2: {
            gold: 180,
            product_id: "jk_QHDxAGSUjx3Jq40R",
            rmb: 18,
          },
          3: {
            gold: 380,
            product_id: "jk_rfuQWO0jwAeCsjVN",
            rmb: 28,
          },
          4: {
            gold: 570,
            product_id: "jk_zzZCfZZV6Gg6xHALK",
            rmb: 38,
          },
          5: {
            gold: 920,
            product_id: "jk_WgdWOjgt2sY7tPeh",
            rmb: 58,
          },
          6: {
            gold: 2200,
            product_id: "jk_aVTH3HuVPOt746oD",
            rmb: 128,
          },
          7: {
            gold: 3900,
            product_id: "jk_Tt1MCzGLkPWuI8sN",
            rmb: 218,
          },
          10: {
            gold: 13500,
            product_id: "jk_DTxJ1X2OJh6nSIA7",
            rmb: 678,
          },
          12: {
            gold: 22000,
            product_id: "jk_OCYQ7yDb8J25a1QL",
            rmb: 968,
          },
        },
        card: {
          1: {
            day_gold: 100,
            tiaozhan: 20,
            product_id: "jk_LNfk0cQOrOw0AdRj",
            free_day: 7,
            rmb: 30,
            gold: 300,
          },
          2: {
            day_gold: 210,
            tiaozhan: 35,
            product_id: "jk_0uieK8VlXrXtXuBW",
            free_day: 7,
            rmb: 99,
            gold: 1350,
          },
          3: {
            day_gold: 100,
            tiaozhan: 88,
            product_id: "jk_HR4omxwYip179aO7",
            free_day: 30,
            rmb: 128,
            gold: 2000,
          },
          4: {
            day_gold: 250,
            tiaozhan: 600,
            product_id: "jk_6We1aQ5tjOmtjKCJ",
            free_day: 30,
            rmb: 299,
            gold: 4485,
          },
        },
      },
      level_info: [
        {
          total_recharge: 0,
          recharge_add_radio: 0,
          complete_gold: 0,
          exchange_radio: 11,
          week_gold: 0,
        },
        {
          total_recharge: 300,
          recharge_add_radio: 0.01,
          complete_gold: 0,
          exchange_radio: 11,
          week_gold: 100,
        },
        {
          total_recharge: 500,
          recharge_add_radio: 0.02,
          complete_gold: 0,
          exchange_radio: 11,
          week_gold: 200,
        },
        {
          total_recharge: 1000,
          recharge_add_radio: 0.03,
          complete_gold: 0,
          exchange_radio: 10.9,
          week_gold: 300,
        },
        {
          total_recharge: 3000,
          recharge_add_radio: 0.04,
          complete_gold: 0,
          exchange_radio: 10.9,
          week_gold: 400,
        },
        {
          total_recharge: 5000,
          recharge_add_radio: 0.05,
          complete_gold: 500,
          exchange_radio: 10.9,
          week_gold: 500,
        },
        {
          total_recharge: 10000,
          recharge_add_radio: 0.06,
          complete_gold: 1000,
          exchange_radio: 10.8,
          week_gold: 600,
        },
        {
          total_recharge: 20000,
          recharge_add_radio: 0.07,
          complete_gold: 2000,
          exchange_radio: 10.8,
          week_gold: 700,
        },
        {
          total_recharge: 30000,
          recharge_add_radio: 0.08,
          complete_gold: 3000,
          exchange_radio: 10.8,
          week_gold: 800,
        },
        {
          total_recharge: 50000,
          recharge_add_radio: 0.09,
          complete_gold: 5000,
          exchange_radio: 10.8,
          week_gold: 900,
        },
        {
          total_recharge: 100000,
          recharge_add_radio: 0.1,
          complete_gold: 10000,
          exchange_radio: 10.7,
          week_gold: 1000,
        },
        {
          total_recharge: 200000,
          recharge_add_radio: 0.11,
          complete_gold: 20000,
          exchange_radio: 10.6,
          week_gold: 1100,
        },
        {
          total_recharge: 300000,
          recharge_add_radio: 0.12,
          complete_gold: 30000,
          exchange_radio: 10.5,
          week_gold: 1200,
        },
        {
          total_recharge: 500000,
          recharge_add_radio: 0.13,
          complete_gold: 50000,
          exchange_radio: 10.4,
          week_gold: 1300,
        },
        {
          total_recharge: 800000,
          recharge_add_radio: 0.14,
          complete_gold: 80000,
          exchange_radio: 10.3,
          week_gold: 1400,
        },
        {
          total_recharge: 1000000,
          recharge_add_radio: 0.15,
          complete_gold: 100000,
          exchange_radio: 10.2,
          week_gold: 1500,
        },
        {
          total_recharge: 1800000,
          recharge_add_radio: 0.16,
          complete_gold: 180000,
          exchange_radio: 10.1,
          week_gold: 1600,
        },
        {
          total_recharge: 2500000,
          recharge_add_radio: 0.17,
          complete_gold: 250000,
          exchange_radio: 10,
          week_gold: 1700,
        },
        {
          total_recharge: 3000000,
          recharge_add_radio: 0.18,
          complete_gold: 300000,
          exchange_radio: 10,
          week_gold: 1800,
        },
        {
          total_recharge: 5000000,
          recharge_add_radio: 0.19,
          complete_gold: 500000,
          exchange_radio: 10,
          week_gold: 1900,
        },
        {
          total_recharge: 10000000,
          recharge_add_radio: 0.2,
          complete_gold: 1000000,
          exchange_radio: 10,
          week_gold: 2000,
        },
      ],
    },
  },
};

//#endregion

const extract_game_reward_result = {
  msg_type: "extract_game_reward_result",
  info: {
    gold: "111",
    key: "1699891200788503",
    error: "",
  },
};

const exchange_lottery_result = {
  msg_type: "exchange_lottery_result",
  info: {
    error: "ok",
    gold: "600",
  },
};
const get_recharge_card_reward_result = {
  msg_type: "get_recharge_card_reward_result",
  info: {
    error: "ok",
    gold: 100,
  },
};

//#region  任务系统
const get_task_info_result = {
  msg_type: "get_task_info_result",
  info: {
    is_get_all: false,
    task_info: [
      {
        cur_num: 15200,
        update_type: "week",
        task_type: "cost_gold",
        complete: 250000,
        uid: 18,
        is_get: false,
        task_id: "100612",
        reward: {
          type: "lottery",
          num: 2500,
        },
      },
      {
        cur_num: 15200,
        update_type: "week",
        task_type: "cost_gold",
        complete: 1000000,
        uid: 18,
        is_get: false,
        task_id: "100627",
        reward: {
          type: "lottery",
          num: 10000,
        },
      },
      {
        cur_num: 15200,
        update_type: "week",
        task_type: "cost_gold",
        complete: 6000000,
        uid: 18,
        is_get: false,
        task_id: "100632",
        reward: {
          type: 620000,
        },
      },
      {
        cur_num: 0,
        update_type: "week",
        task_type: "recharge",
        complete: 760,
        uid: 18,
        is_get: false,
        task_id: "100221",
        reward: {
          type: "lottery",
          num: 6080,
        },
      },
      {
        cur_num: 0,
        update_type: "week",
        task_type: "recharge",
        complete: 700,
        uid: 18,
        is_get: false,
        task_id: "100218",
        reward: {
          type: "lottery",
          num: 4200,
        },
      },
      {
        cur_num: 0,
        update_type: "week",
        task_type: "recharge",
        complete: 560,
        uid: 18,
        is_get: false,
        task_id: "100215",
        reward: {
          type: "lottery",
          num: 3360,
        },
      },
      {
        cur_num: 1286300,
        update_type: "week",
        task_type: "lottery",
        complete: 950000,
        uid: 18,
        is_get: false,
        task_id: "101032",
        reward: {
          type: "lottery",
          num: 4750,
        },
      },
      {
        cur_num: 1286300,
        update_type: "week",
        task_type: "lottery",
        complete: 150000,
        uid: 18,
        is_get: false,
        task_id: "101012",
        reward: {
          type: "lottery",
          num: 750,
        },
      },
      {
        cur_num: 1286300,
        update_type: "week",
        task_type: "lottery",
        complete: 850000,
        uid: 18,
        is_get: false,
        task_id: "101030",
        reward: {
          type: "lottery",
          num: 4250,
        },
      },
      {
        exchange_limit: 0,
        task_id: "102609",
        uid: 18,
        task_type: "bbl_tz_win",
        complete: 40,
        is_get: false,
        update_type: "week",
        cur_num: 0,
        reward: {
          type: "dashi",
          num: 120,
        },
      },
      {
        exchange_limit: 30,
        task_id: "101817",
        uid: 18,
        task_type: "mgc_tz_win",
        complete: 20,
        is_get: false,
        update_type: "week",
        cur_num: 1,
        reward: {
          type: "dashi",
          num: 100,
        },
      },
      {
        exchange_limit: 30,
        task_id: "102216",
        uid: 18,
        task_type: "bbl_tz",
        complete: 25,
        is_get: false,
        update_type: "week",
        cur_num: 1,
        reward: {
          type: "dashi",
          num: 75,
        },
      },
      {
        class_id: 9,
        cur_num: 2,
        task_id: "103035",
        uid: 18,
        task_type: "mgc_game",
        complete: 40,
        is_get: false,
        exchange_limit: 0,
        update_type: "week",
        reward: {
          type: "dashi_mul",
          num: 1,
        },
      },
      {
        class_id: 10,
        cur_num: 1,
        task_id: "103036",
        uid: 18,
        task_type: "mgc_game",
        complete: 40,
        is_get: false,
        exchange_limit: 0,
        update_type: "week",
        reward: {
          type: "dashi_mul",
          num: 1,
        },
      },
      {
        class_id: 6,
        cur_num: 0,
        task_id: "103032",
        uid: 18,
        task_type: "mgc_game",
        complete: 40,
        is_get: false,
        exchange_limit: 0,
        update_type: "week",
        reward: {
          type: "dashi_mul",
          num: 1,
        },
      },
      {
        reward: {
          num: 50,
          type: "gold",
        },
        uid: 18,
        is_get: false,
        cur_num: 21,
        task_type: "login",
        next_task_id: "200003",
        task_id: "200002",
        complete: 30,
      },
      {
        cur_num: 9300,
        update_type: "day",
        task_type: "cost_gold",
        complete: 6000,
        uid: 18,
        is_get: false,
        task_id: "100414",
        reward: {
          type: "lottery",
          num: 1200,
        },
      },
      {
        cur_num: 9300,
        update_type: "day",
        task_type: "cost_gold",
        complete: 8000,
        uid: 18,
        is_get: false,
        task_id: "100416",
        reward: {
          type: "lottery",
          num: 1600,
        },
      },
      {
        cur_num: 9300,
        update_type: "day",
        task_type: "cost_gold",
        complete: 9000,
        uid: 18,
        is_get: false,
        task_id: "100417",
        reward: {
          type: "lottery",
          num: 1800,
        },
      },
      {
        cur_num: 0,
        update_type: "day",
        task_type: "recharge",
        complete: 200,
        uid: 18,
        is_get: false,
        task_id: "100018",
        reward: {
          type: "lottery",
          num: 2000,
        },
      },
      {
        cur_num: 0,
        update_type: "day",
        task_type: "recharge",
        complete: 170,
        uid: 18,
        is_get: false,
        task_id: "100015",
        reward: {
          type: "lottery",
          num: 1700,
        },
      },
      {
        cur_num: 0,
        update_type: "day",
        task_type: "recharge",
        complete: 340,
        uid: 18,
        is_get: false,
        task_id: "100032",
        reward: {
          type: "lottery",
          num: 3400,
        },
      },
      {
        cur_num: 20300,
        update_type: "day",
        task_type: "lottery",
        complete: 85000,
        uid: 18,
        is_get: false,
        task_id: "100830",
        reward: {
          type: "lottery",
          num: 4250,
        },
      },
      {
        cur_num: 20300,
        update_type: "day",
        task_type: "lottery",
        complete: 60000,
        uid: 18,
        is_get: false,
        task_id: "100825",
        reward: {
          type: "lottery",
          num: 3000,
        },
      },
      {
        cur_num: 20300,
        update_type: "day",
        task_type: "lottery",
        complete: 19000,
        uid: 18,
        is_get: false,
        task_id: "100816",
        reward: {
          type: "lottery",
          num: 380,
        },
      },
      {
        exchange_limit: 50,
        task_id: "102024",
        uid: 18,
        task_type: "bbl_tz",
        complete: 5,
        is_get: false,
        update_type: "day",
        cur_num: 0,
        reward: {
          type: "dashi",
          num: 25,
        },
      },
      {
        exchange_limit: 30,
        task_id: "102012",
        uid: 18,
        task_type: "bbl_tz",
        complete: 3,
        is_get: false,
        update_type: "day",
        cur_num: 0,
        reward: {
          type: "dashi",
          num: 9,
        },
      },
      {
        exchange_limit: 0,
        task_id: "101209",
        uid: 18,
        task_type: "mgc_tz",
        complete: 5,
        is_get: false,
        update_type: "day",
        cur_num: 0,
        reward: {
          type: "dashi",
          num: 5,
        },
      },
      {
        class_id: 0,
        cur_num: 0,
        task_id: "103212",
        uid: 18,
        task_type: "bbl_game",
        complete: 8,
        is_get: false,
        exchange_limit: 0,
        update_type: "day",
        reward: {
          type: "dashi_mul",
          num: 4,
        },
      },
      {
        class_id: 9,
        cur_num: 0,
        task_id: "102835",
        uid: 18,
        task_type: "mgc_game",
        complete: 10,
        is_get: false,
        exchange_limit: 0,
        update_type: "day",
        reward: {
          type: "dashi_mul",
          num: 1,
        },
      },
      {
        class_id: 4,
        cur_num: 7,
        task_id: "102830",
        uid: 18,
        task_type: "mgc_game",
        complete: 5,
        is_get: false,
        exchange_limit: 0,
        update_type: "day",
        reward: {
          type: "dashi_mul",
          num: 1,
        },
      },
      {
        cur_num: 0,
        uid: 9,
        update_type: "day",
        reward: {
          type: "tz_card",
          num: 3,
        },
        complete: 0,
        is_get: false,
        task_id: "103500",
        task_type: "tz_card",
      },
    ],
  },
};

//#endregion

//#region 部落排行榜

const get_tribe_rank_result = {
  msg_type: "get_tribe_rank_result",
  info: {
    tribe_rank_def: {
      bbl: [2000, 1500, 1000, 800, 600, 500, 400, 300, 200, 100],
      mxt: [2000, 1500, 1000, 800, 600, 500, 400, 300, 200, 100],
      mgc: [2000, 1500, 1000, 800, 600, 500, 400, 300, 200, 100],
      hw3: [2000, 1500, 1000, 800, 600, 500, 400, 300, 200, 100],
      bird: [2000, 1500, 1000, 800, 600, 500, 400, 300, 200, 100],
    },
    rank: [
      {
        player_name: "撒旦发射点看见发哈开始开发计划会计师大后方",
        num: 178500,
        uid: 7,
      },
      {
        player_name: "犬瘟热手动阀手动阀",
        num: 83700,
        uid: 6,
      },
      {
        player_name: "555000004",
        num: 11200,
        uid: 1,
      },
      {
        player_name: "阿萨的内部调查v你",
        num: 13500,
        uid: 2,
      },
      {
        player_name: "同一天日日同意",
        num: 13700,
        uid: 3,
      },
      {
        player_name: "耳听为uv不能",
        num: 4700,
        uid: 4,
      },
      {
        player_name: "说的不错v你",
        num: 23200,
        uid: 9,
      },
    ],
  },
};

//#endregion

// 任务领奖
const get_task_reward_result = {
  msg_type: "get_task_reward_result",
  info: {
    error: "ok",
    complete: 250,
    task_id: "103500",
    reward: {
      type: "tz_card",
      num: 2500,
    },
  },
};

const get_server_time_result = {
  msg_type: "get_server_time_result",
  info: {
    server_time: 1697527139,
  },
};
const return_lottery_result = {
  msg_type: "return_lottery_result",
  info: {
    lottery: 0,
  },
};

//#region  登录签到
const get_login_reward_result = {
  msg_type: "get_login_reward_result",
  info: {
    sign_data: {
      index: 5,
      server_time: 1725638400,
      time: 1725984000,
      reward: [
        {
          is_get: true,
        },
        {
          is_get: true,
        },
        {
          is_get: true,
        },
        {
          is_get: true,
        },
        {
          is_get: false,
        },
        {
          is_get: false,
        },
        {
          is_get: false,
        },
      ],
    },
    def: [
      [
        {
          level: [1, 3],
          reward: [
            {
              type: "gold",
              num: 30,
              mul: 1,
            },
          ],
        },
        {
          level: [4, 6],
          reward: [
            {
              type: "gold",
              num: 60,
              mul: 1,
            },
          ],
        },
        {
          level: [7, 10],
          reward: [
            {
              type: "gold",
              num: 90,
              mul: 1,
            },
          ],
        },
        {
          level: [11, 14],
          reward: [
            {
              type: "gold",
              num: 120,
              mul: 1,
            },
          ],
        },
        {
          level: [15, 18],
          reward: [
            {
              type: "gold",
              num: 150,
              mul: 1,
            },
          ],
        },
        {
          level: [19, 21],
          reward: [
            {
              type: "gold",
              num: 180,
              mul: 1,
            },
          ],
        },
      ],
      [
        {
          level: [1, 3],
          reward: [
            {
              type: "gold",
              num: 50,
              mul: 1,
            },
          ],
        },
        {
          level: [4, 6],
          reward: [
            {
              type: "gold",
              num: 100,
              mul: 1,
            },
          ],
        },
        {
          level: [7, 10],
          reward: [
            {
              type: "gold",
              num: 150,
              mul: 1,
            },
          ],
        },
        {
          level: [11, 14],
          reward: [
            {
              type: "gold",
              num: 200,
              mul: 1,
            },
          ],
        },
        {
          level: [15, 18],
          reward: [
            {
              type: "gold",
              num: 250,
              mul: 1,
            },
          ],
        },
        {
          level: [19, 21],
          reward: [
            {
              type: "gold",
              num: 300,
              mul: 1,
            },
          ],
        },
      ],
      [
        {
          level: [1, 3],
          reward: [
            {
              type: "gold",
              num: 88,
              mul: 1,
            },
            {
              type: "tiaozhan_ticket",
              num: 8,
              mul: 1,
            },
          ],
        },
        {
          level: [4, 6],
          reward: [
            {
              type: "gold",
              num: 176,
              mul: 1,
            },
            {
              type: "tiaozhan_ticket",
              num: 16,
              mul: 1,
            },
          ],
        },
        {
          level: [7, 10],
          reward: [
            {
              type: "gold",
              num: 264,
              mul: 1,
            },
            {
              type: "tiaozhan_ticket",
              num: 24,
              mul: 1,
            },
          ],
        },
        {
          level: [11, 14],
          reward: [
            {
              type: "gold",
              num: 352,
              mul: 1,
            },
            {
              type: "tiaozhan_ticket",
              num: 32,
              mul: 1,
            },
          ],
        },
        {
          level: [15, 18],
          reward: [
            {
              type: "gold",
              num: 440,
              mul: 1,
            },
            {
              type: "tiaozhan_ticket",
              num: 40,
              mul: 1,
            },
          ],
        },
        {
          level: [19, 21],
          reward: [
            {
              type: "gold",
              num: 528,
              mul: 1,
            },
            {
              type: "tiaozhan_ticket",
              num: 48,
              mul: 1,
            },
          ],
        },
      ],
      [
        {
          level: [1, 3],
          reward: [
            {
              type: "gold",
              num: 116,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 1,
              mul: 1,
            },
          ],
        },
        {
          level: [4, 6],
          reward: [
            {
              type: "gold",
              num: 232,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 2,
              mul: 1,
            },
          ],
        },
        {
          level: [7, 10],
          reward: [
            {
              type: "gold",
              num: 348,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 3,
              mul: 1,
            },
          ],
        },
        {
          level: [11, 14],
          reward: [
            {
              type: "gold",
              num: 464,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 4,
              mul: 1,
            },
          ],
        },
        {
          level: [15, 18],
          reward: [
            {
              type: "gold",
              num: 580,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 5,
              mul: 1,
            },
          ],
        },
        {
          level: [19, 21],
          reward: [
            {
              type: "gold",
              num: 696,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 6,
              mul: 1,
            },
          ],
        },
      ],
      [
        {
          level: [1, 3],
          reward: [
            {
              type: "gold",
              num: 138,
              mul: 1,
            },
            {
              type: "tiaozhan_ticket",
              num: 25,
              mul: 1,
            },
          ],
        },
        {
          level: [4, 6],
          reward: [
            {
              type: "gold",
              num: 276,
              mul: 1,
            },
            {
              type: "tiaozhan_ticket",
              num: 50,
              mul: 1,
            },
          ],
        },
        {
          level: [7, 10],
          reward: [
            {
              type: "gold",
              num: 414,
              mul: 1,
            },
            {
              type: "tiaozhan_ticket",
              num: 75,
              mul: 1,
            },
          ],
        },
        {
          level: [11, 14],
          reward: [
            {
              type: "gold",
              num: 552,
              mul: 1,
            },
            {
              type: "tiaozhan_ticket",
              num: 100,
              mul: 1,
            },
          ],
        },
        {
          level: [15, 18],
          reward: [
            {
              type: "gold",
              num: 690,
              mul: 1,
            },
            {
              type: "tiaozhan_ticket",
              num: 125,
              mul: 1,
            },
          ],
        },
        {
          level: [19, 21],
          reward: [
            {
              type: "gold",
              num: 828,
              mul: 1,
            },
            {
              type: "tiaozhan_ticket",
              num: 150,
              mul: 1,
            },
          ],
        },
      ],
      [
        {
          level: [1, 3],
          reward: [
            {
              type: "gold",
              num: 188,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 2,
              mul: 1,
            },
          ],
        },
        {
          level: [4, 6],
          reward: [
            {
              type: "gold",
              num: 376,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 4,
              mul: 1,
            },
          ],
        },
        {
          level: [7, 10],
          reward: [
            {
              type: "gold",
              num: 564,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 6,
              mul: 1,
            },
          ],
        },
        {
          level: [11, 14],
          reward: [
            {
              type: "gold",
              num: 752,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 8,
              mul: 1,
            },
          ],
        },
        {
          level: [15, 18],
          reward: [
            {
              type: "gold",
              num: 940,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 10,
              mul: 1,
            },
          ],
        },
        {
          level: [19, 21],
          reward: [
            {
              type: "gold",
              num: 1128,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 12,
              mul: 1,
            },
          ],
        },
      ],
      [
        {
          level: [1, 3],
          reward: [
            {
              type: "gold",
              num: 236,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 3,
              mul: 1,
            },
          ],
        },
        {
          level: [4, 6],
          reward: [
            {
              type: "gold",
              num: 472,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 6,
              mul: 1,
            },
          ],
        },
        {
          level: [7, 10],
          reward: [
            {
              type: "gold",
              num: 708,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 9,
              mul: 1,
            },
          ],
        },
        {
          level: [11, 14],
          reward: [
            {
              type: "gold",
              num: 944,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 12,
              mul: 1,
            },
          ],
        },
        {
          level: [15, 18],
          reward: [
            {
              type: "gold",
              num: 1180,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 15,
              mul: 1,
            },
          ],
        },
        {
          level: [19, 21],
          reward: [
            {
              type: "gold",
              num: 1416,
              mul: 1,
            },
            {
              type: "vip_exp",
              num: 18,
              mul: 1,
            },
          ],
        },
      ],
    ],
  },
};
const get_login_reward_result2 = {
  msg_type: "get_login_reward_result",
  info: {
    index: 6,
    time: 1706544000,
    server_time: 1706544000,
    reward: [
      {
        gold: 50,
        is_get: true,
        tiaozhan_ticket: 3,
      },
      {
        gold: 70,
        is_get: true,
        tiaozhan_ticket: 0,
      },
      {
        gold: 90,
        is_get: true,
        tiaozhan_ticket: 3,
      },
      {
        gold: 120,
        is_get: true,
        tiaozhan_ticket: 3,
      },
      {
        gold: 150,
        is_get: true,
        tiaozhan_ticket: 0,
      },
      {
        gold: 180,
        is_get: true,
        tiaozhan_ticket: 1,
      },
      {
        gold: 300,
        is_get: false,
        tiaozhan_ticket: 3,
      },
    ],
  },
};

//#endregion

//#region 邀请好友

const get_invite_reward_log_result = {
  msg_type: "get_invite_reward_log_result",
  info: [
    {
      player_name: "5550000012",
      reward: 388,
      uid: 2,
    },
    {
      player_name: "5550000204",
      reward: 388,
      uid: 3,
    },
    {
      player_name: "55500204",
      reward: 388,
      uid: 4,
    },
    {
      player_name: "55500310004",
      reward: 388,
      uid: 5,
    },
  ],
};

//#endregion

//#region 存钱罐

const get_player_weal_box_log_result = {
  msg_type: "get_player_weal_box_log_result",
  info: [
    {
      level: 2,
      uid: 4,
      time: 1697425939,
      gold: 600,
    },
    {
      level: 3,
      uid: 5,
      time: 1697425939,
      gold: 600,
    },
    {
      level: 7,
      uid: 1,
      time: 1697425939,
      gold: 600,
    },
  ],
};

const get_player_weal_box_result = {
  msg_type: "get_player_weal_box_result",
  info: {
    is_get: false,
    uid: 4,
    update_time: 1698595200,
    next_gold: 600,
    gold: 600,
  },
};

const exchange_player_weal_box_result = {
  msg_type: "exchange_player_weal_box_result",
  info: {
    error: "ok",
    gold: 600,
  },
};

//#endregion

//#region 记录

const get_all_log_result = {
  msg_type: "get_all_log_result",
  info: [
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722593317,
      gold: -2510,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bbxc",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722590530,
      gold: -3210,
      device_index: 1,
      s_type: "up_coin",
      game_name: "hdcz",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722590259,
      gold: -910,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bbxc",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722590098,
      gold: -1910,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bbxc",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722589771,
      gold: -1410,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bjwg",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722589567,
      gold: -2730,
      device_index: 1,
      s_type: "up_coin",
      game_name: "hdcz",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722589363,
      gold: -3060,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bbxc",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722589125,
      gold: -370,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bjwg",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722585268,
      gold: -110,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bbxc",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722585231,
      gold: -1650,
      device_index: 1,
      s_type: "up_coin",
      game_name: "hdcz",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722584407,
      gold: -10,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bbxc",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722581331,
      gold: -100,
      device_index: 2,
      s_type: "up_coin",
      game_name: "mgc",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722581132,
      gold: -100,
      device_index: 2,
      s_type: "up_coin",
      game_name: "mgc",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722580919,
      gold: -100,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bbl",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722580551,
      gold: 100,
      device_index: 1,
      s_type: "device_up_coin_error",
      game_name: "bbl",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722580516,
      gold: -10,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bjl",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722580494,
      gold: -1110,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bjwg",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722578225,
      gold: -100,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bbl",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722577856,
      gold: 100,
      device_index: 1,
      s_type: "device_up_coin_error",
      game_name: "bbl",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722577829,
      gold: -100,
      device_index: 1,
      s_type: "up_coin",
      game_name: "mgc",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722505033,
      gold: -100,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bbl",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722503306,
      gold: -10,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bjwg",
      uid: 9,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722496430,
      gold: -110,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bjwg",
      uid: 9,
    },
    {
      type: "gold_log",
      index: "2",
      recharge_type: "lspmdci",
      s_type: "recharge",
      base_gold: 7266,
      uid: 9,
      time: 1722483312,
      gold: 7919,
      add_mul: 1,
      add_radio: 0.09,
    },
    {
      type: "gold_log",
      index: "1",
      recharge_type: "lspmdci",
      s_type: "recharge",
      base_gold: 2666,
      uid: 9,
      time: 1722483241,
      gold: 2905,
      add_mul: 1,
      add_radio: 0.09,
    },
    {
      machine_index: "1",
      type: "gold_log",
      time: 1722416458,
      gold: -10,
      device_index: 1,
      s_type: "up_coin",
      game_name: "bjwg",
      uid: 9,
    },
  ],
};
//#endregion

// 用户选择图片

// 收集反馈
const collect_event_result = {
  msg_type: "collect_event_result",
  info: {
    game_name: "mgc",
    class_id: 3,
    num: 1,
  },
};

const change_name_result = {
  msg_type: "change_name_result",
  info: {
    error: "ok",
  },
};

// 登录
const get_player_common = {
  msg_type: "get_player_common",
  info: {
    // uid: "888888",
    uid: "1",
    gold: 200,
    level: 1, // 天梯等级
    lottery: 1000,
    add_lottery: 0,
    player_name: "模拟玩家",
    headimgurl: "",
    select_head_kuang: 1,
    share_code: "cctv999",
    id_verification: false,
    is_gm: true,
    tiaozhan_ticket: 123,
    dashi_score: 2,
    register_time: "1708573151",
    select_title: 5,
  },
};

const get_channel_result = {
  msg_type: "get_channel_result",
  info: "baidu",
};

const shipin_game_notice_result = {
  msg_type: "shipin_game_notice_result",
  info: {
    machine_index: "3",
    player_name: "5p2O5omsMQ==",
    head: "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/headSculpture/2.jpg",
    lottery: 3600,
    game_name: "mgc",
  },
};

const get_sys_notice_resultsys = {
  msg_type: "get_sys_notice_result",
  info: {
    info: [
      {
        _id: "\u0000\u0007eÑs5l#\u001fç@KB",
        time: 1708225333,
        param: {
          device_index: 1,
          player_name: "NDA2MDAwMDQw",
          head: "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/headSculpture/40.jpg",
          head_frame: 1,
          uid: 40,
          class_id: 1,
          game_name: "mxt",
          machine_index: "2",
          type: "collect",
        },
      },
      {
        _id: "\u0000\u0007eÑs/l#\u001fç@KA",
        time: 1708225327,
        param: {
          device_index: 1,
          player_name: "NDA2MDAwMDQw",
          head: "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/headSculpture/40.jpg",
          head_frame: 1,
          uid: 40,
          class_id: 2,
          game_name: "mxt",
          machine_index: "2",
          type: "collect",
        },
      },
      {
        _id: "\u0000\u0007eÑqfl#\u001fç@K\u0004",
        time: 1708224870,
        param: {
          device_index: 1,
          player_name: "cnI=",
          head: "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/headSculpture/1292.jpg",
          head_frame: 1,
          uid: 1292,
          class_id: 1,
          game_name: "mxt",
          machine_index: "4",
          type: "collect",
        },
      },
    ],
    type: "sys",
  },
};

const get_sys_notice_result = {
  msg_type: "get_sys_notice_result",
  info: {
    info: [
      {
        _id: "\u0000\u0007eÑpÄl#\u001fç@Kì",
        time: 1708224708,
        param: {
          head_frame: 1,
          uid: 40,
          head: "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/headSculpture/40.jpg",
          lottery: 420,
          game_name: "mxt",
          player_name: "NDA2MDAwMDQw",
          machine_index: "5",
        },
      },
      {
        _id: "\u0000\u0007eÑoól#\u001fç@Kã",
        time: 1708224499,
        param: {
          head_frame: 5,
          uid: 1353,
          head: "",
          lottery: 3050,
          game_name: "mxt",
          player_name: "5bCPeXVhbnlhbmc=",
          machine_index: "2",
        },
      },
      {
        _id: "\u0000\u0007eÑo=l#\u001fç@KÐ",
        time: 1708224317,
        param: {
          head_frame: 5,
          uid: 1353,
          head: "",
          lottery: 7580,
          game_name: "mxt",
          player_name: "5bCPeXVhbnlhbmc=",
          machine_index: "5",
        },
      },
      {
        _id: "\u0000\u0007eÑn@l#\u001fç@K¿",
        time: 1708224064,
        param: {
          head_frame: 1,
          uid: 349,
          head: "",
          lottery: 1950,
          game_name: "mxt",
          player_name: "Nzc4OA==",
          machine_index: "2",
        },
      },
      {
        _id: "\u0000\u0007eÑmMl#\u001fç@K\u001a",
        time: 1708223821,
        param: {
          head_frame: 1,
          uid: 349,
          head: "",
          lottery: 2950,
          game_name: "hw3",
          player_name: "Nzc4OA==",
          machine_index: "3",
        },
      },
      {
        _id: "\u0000\u0007eÑlÜl#\u001fç@K\u0016",
        time: 1708223708,
        param: {
          head_frame: 1,
          uid: 349,
          head: "",
          lottery: 11200,
          game_name: "hw3",
          player_name: "Nzc4OA==",
          machine_index: "2",
        },
      },
      {
        _id: "\u0000\u0007eÑkñl#\u001fç@K\f",
        time: 1708223473,
        param: {
          head_frame: 4,
          uid: 1330,
          head: "",
          lottery: 2360,
          game_name: "mxt",
          player_name: "5pmv55Cz",
          machine_index: "5",
        },
      },
    ],
    type: "game",
  },
};

const get_sys_notice_result11 = {
  msg_type: "get_sys_notice_result",
  info: {
    info: [
      {
        _id: "\u0000\u0007f #ÅÄÎõm\u001b°\u001c",
        time: 1713408035,
        param: {
          head_frame: 3,
          uid: 9,
          info: {
            normal_reward_index: "1",
            reward_info: {
              num: 188,
              type: "gold",
            },
          },
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
          player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
        },
      },
      {
        _id: "\u0000\u0007f\u001f²æÅÄÎõm\u001b¯",
        time: 1713353446,
        param: {
          head_frame: 3,
          uid: 9,
          info: {
            normal_reward_index: "1",
            reward_info: {
              num: 188,
              type: "gold",
            },
          },
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
          player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
        },
      },
      {
        _id: "\u0000\u0007f\u001f²ÏÅÄÎõm\u001b¯",
        time: 1713353423,
        param: {
          head_frame: 3,
          uid: 9,
          info: {
            normal_reward_index: "10",
            reward_info: {
              num: 1,
              type: "add_num",
            },
          },
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
          player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
        },
      },
      {
        _id: "\u0000\u0007f\u001fnÅÄÎ GxfÝ",
        time: 1713346414,
        param: {
          head_frame: 3,
          uid: 9,
          info: {
            normal_reward_index: "1",
            reward_info: {
              num: 188,
              type: "gold",
            },
          },
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
          player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
        },
      },
      {
        _id: "\u0000\u0007f\u001fw1ÅÄÎõ\u0010pé'",
        time: 1713338161,
        param: {
          head_frame: 3,
          uid: 9,
          info: {
            normal_reward_index: "5",
            reward_info: {
              num: 1888,
              type: "lottery",
            },
          },
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
          player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
        },
      },
      {
        _id: '\u0000\u0007f\u001fc\u0012ÅÄÎõ\u0010pé"',
        time: 1713333010,
        param: {
          head_frame: 3,
          uid: 9,
          info: {
            normal_reward_index: "2",
            reward_info: {
              num: 388,
              type: "gold",
            },
          },
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
          player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
        },
      },
      {
        _id: "\u0000\u0007f\u001fbÅÄÎõ\u0010pé\u001d",
        time: 1713332893,
        param: {
          head_frame: 3,
          uid: 9,
          info: {
            normal_reward_index: "9",
            reward_info: {
              use_limit: 604800,
              type: "jiacheng_card",
              num: 2,
            },
          },
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
          player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
        },
      },
      {
        _id: "\u0000\u0007f\u001f^5ÅÄÎõ\u0010pé\u0018",
        time: 1713331765,
        param: {
          head_frame: 3,
          uid: 9,
          info: {
            normal_reward_index: "5",
            reward_info: {
              num: 1888,
              type: "lottery",
            },
          },
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
          player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
        },
      },
      {
        _id: "\u0000\u0007f\u001fJÂÅÄÎõ\u0010pé\u000f",
        time: 1713326786,
        param: {
          head_frame: 3,
          uid: 9,
          info: {
            normal_reward_index: "3",
            reward_info: {
              num: 888,
              type: "gold",
            },
          },
          head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
          player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
        },
      },
    ],
    type: "mali",
  },
};

// 游戏任务

const get_player_game_task_result = {
  msg_type: "get_player_game_task_result",
  info: [
    {
      max_step: 6,
      num: 0,
      index: 1,
      step: 1,
      uid: 9,
      game_name: "mxt",
      data: [
        {
          is_get: false,
          reward_type: "lottery",
          complete: 500,
          reward: 200,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 1000,
          reward: 400,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 2000,
          reward: 1000,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 3000,
          reward: 1800,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 4000,
          reward: 2400,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 5000,
          reward: 3000,
        },
      ],
      type_name: "up_coin",
    },
    {
      class_id: 0,
      type_name: "collect",
      data: [
        {
          is_get: false,
          reward_type: "lottery",
          complete: 1,
          reward: 100,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 2,
          reward: 200,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 3,
          reward: 300,
        },
      ],
      uid: 9,
      max_step: 3,
      num: 0,
      game_name: "mxt",
      index: 2,
      step: 1,
    },
    {
      class_id: 1,
      type_name: "collect",
      data: [
        {
          is_get: false,
          reward_type: "lottery",
          complete: 1,
          reward: 100,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 2,
          reward: 200,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 3,
          reward: 300,
        },
      ],
      uid: 9,
      max_step: 3,
      num: 1,
      game_name: "mxt",
      index: 3,
      step: 1,
    },
    {
      class_id: 2,
      type_name: "collect",
      data: [
        {
          is_get: false,
          reward_type: "lottery",
          complete: 1,
          reward: 100,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 2,
          reward: 200,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 3,
          reward: 300,
        },
      ],
      uid: 9,
      max_step: 3,
      num: 0,
      game_name: "mxt",
      index: 4,
      step: 1,
    },
    {
      class_id: 3,
      type_name: "collect",
      data: [
        {
          is_get: false,
          reward_type: "lottery",
          complete: 1,
          reward: 100,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 2,
          reward: 200,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 3,
          reward: 300,
        },
      ],
      uid: 9,
      max_step: 3,
      num: 0,
      game_name: "mxt",
      index: 5,
      step: 1,
    },
    {
      class_id: 4,
      type_name: "collect",
      data: [
        {
          is_get: false,
          reward_type: "lottery",
          complete: 1,
          reward: 100,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 2,
          reward: 200,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 3,
          reward: 300,
        },
      ],
      uid: 9,
      max_step: 3,
      num: 1,
      game_name: "mxt",
      index: 6,
      step: 2,
    },
    {
      class_id: 5,
      type_name: "collect",
      data: [
        {
          is_get: false,
          reward_type: "lottery",
          complete: 1,
          reward: 100,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 2,
          reward: 200,
        },
        {
          is_get: false,
          reward_type: "lottery",
          complete: 3,
          reward: 300,
        },
      ],
      uid: 9,
      max_step: 3,
      num: 0,
      game_name: "mxt",
      index: 7,
      step: 1,
    },
    {
      uid: 9,
      index: 8,
      type_name: "all_complete",
      reward_type: "lottery",
      game_name: "mxt",
      is_get: false,
      reward: 8888,
    },
  ],
};

const get_player_game_task_reward_result = {
  msg_type: "get_player_game_task_reward_result",
  info: {
    error: "ok",
    info: {
      reward_type: "lottery",
      reward: 100,
    },
  },
};

const get_shipin_active_config_result = {
  msg_type: "get_shipin_active_config_result",
  info: [
    {
      join_num: 1,
      active_type: "lottery_tz",
      status: "beginning",
      game_name: "mgc",
      config: {
        reward_data: {
          1: {
            reward: 100,
            reward_type: "gold",
          },
        },
        end_time: 1727663450,
        is_up_coin: false,
        enter_num_limit: -1,
        exchange: 10,
        level_limit: -1,
        record_class: {
          1: 0,
          2: 1,
          3: 2,
          4: 3,
          5: 4,
          6: 5,
          7: 6,
          8: 7,
          9: 8,
          10: 9,
          11: 10,
          12: 11,
          13: 12,
        },
        machine_index: -1,
        settle_accounts_time: 1727665200,
        sign_up_info: {
          ticket: -1,
          site_gold: {
            1: {
              sign_num: 1,
              gold: 100,
            },
            2: {
              sign_num: 2,
              gold: 200,
            },
          },
          sign_up_gold: -1,
        },
        join_num_limit: -1,
        rank_num: 10,
        start_time: 1715827010,
        match_time: 900,
        active_name: "5rW3546LMzAw6YeR5biB56ev5YiG5oyR5oiY6LWb",
      },
      active_index: 202,
    },
    {
      join_num: 0,
      active_type: "tiaozhan_tz",
      status: "beginning",
      game_name: "mgc",
      config: {
        match_time: 900,
        is_up_coin: false,
        enter_num_limit: -1,
        record_class: {
          1: 1,
          2: 2,
          3: 3,
        },
        sign_up_info: {
          ticket: 1,
          site_gold: {
            1: {
              sign_num: 1,
              gold: 100,
            },
            2: {
              sign_num: 3,
              gold: 300,
            },
            3: {
              sign_num: 5,
              gold: 500,
            },
          },
          sign_up_gold: -1,
        },
        end_time: 1843899631,
        join_num_limit: -1,
        exchange: 10,
        tiaozhan_tz: "normal",
        machine_index: -1,
        complete: 10000,
        settle_accounts_time: 1843986031,
        active_name: "6a2U6ay85Z+OMTDluIHluLjop4TmjJHmiJjotZs=",
        rank_num: 10,
        start_time: 1717669231,
        tiaozhan_reward: 500,
        level_limit: 11,
      },
      active_index: 266,
    },
  ],
};
const get_shipin_active_rank_result = {
  msg_type: "get_shipin_active_rank_result",
  info: [
    {
      tianti_level: 1,
      player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
      head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
      enter_num: 10,
      recharge_level: 10,
      head_frame: 3,
      uid: 9,
      active_type: "lottery_tz",
      num: 0,
      select_title: "3",
      game_name: "mgc",
      record_class: [0, 0, 0],
      active_index: 268,
    },
  ],
};

const enter_active_game_result = {
  msg_type: "enter_active_game_result",
  info: {
    error: "ok",
    is_active: true,
  },
};

const get_item_result = {
  msg_type: "get_item_result",
  info: [
    {
      use_limit: 86400,
      uid: 9,
      item_type: "zhekou_card",
      item_id: "17114148199527792",
      add_mul: 0.5,
      start_time: 1711414819,
      status: "waitting",
    },
    {
      item_type: "tianti_buff_card",
      uid: 9,
      status: "using",
      buff_end_time: 1722297600,
      item_id: "172223556226080039",
      start_time: 1722235564,
      use_limit: 259200,
      add_mul: 1.1,
    },
    {
      item_type: "tianti_buff_card",
      uid: 9,
      status: "using",
      buff_end_time: 1722506692,
      item_id: "17222355646080039",
      start_time: 1722235564,
      use_limit: 259200,
      add_mul: 1.1,
    },
    {
      use_limit: 86400,
      uid: 9,
      item_type: "zhekou_card",
      item_id: "17114164052197442",
      add_mul: 0.5,
      start_time: 1711416405,
      status: "expired",
    },
    {
      use_limit: 86400,
      uid: 9,
      item_type: "zhekou_card",
      item_id: "17114164191968222",
      add_mul: 0.5,
      start_time: 1711416419,
      status: "waitting",
    },
    {
      use_limit: 86400,
      uid: 9,
      item_type: "jifen_card",
      item_id: "17114164191968212",
      add_mul: 1.5,
      start_time: 1711416419,
      status: "waitting",
    },
    {
      use_limit: 86400,
      uid: 9,
      item_type: "jiacheng_card",
      item_id: "17114164121968212",
      add_mul: 1.5,
      start_time: 1711416419,
      status: "waitting",
    },
    {
      use_limit: 86400,
      uid: 9,
      item_type: "jiacheng_card",
      item_id: "1711164121968212",
      add_mul: 1.6,
      start_time: 1711416419,
      status: "waitting",
    },
    {
      use_limit: 86400,
      uid: 9,
      item_type: "jiacheng_card",
      item_id: "1711264121968212",
      add_mul: 1.7,
      start_time: 1711416419,
      status: "waitting",
    },
  ],
};

// const get_item_notify_result = {
//   msg_type: "get_item_notify_result",
//   info: [
//     {
//       item_id: "17114148199527792",
//       uid: 9,
//     },
//   ],
// };

const get_player_mali_result = {
  msg_type: "get_player_mali_result",
  info: {
    player_mali: {
      uid: 9,
      total_recharge: 0,
      recharge: 0,
      free_num: parseInt(Math.random() * 10),
    },
    jipan: {
      1: {
        type: "gold",
        num: 188,
      },
      2: {
        type: "gold",
        num: 388,
      },
      3: {
        type: "gold",
        num: 888,
      },
      4: {
        type: "lottery",
        num: 1000,
      },
      5: {
        type: "lottery",
        num: 1888,
      },
      6: {
        type: "lottery",
        num: 5888,
      },
      7: {
        type: "jiacheng_card",
        num: 1.3,
        use_limit: 604800,
      },
      8: {
        type: "jiacheng_card",
        num: 1.5,
        use_limit: 604800,
      },
      9: {
        type: "jiacheng_card",
        num: 2,
        use_limit: 604800,
      },
      10: {
        type: "add_num",
        num: 1,
      },
      11: {
        type: "light",
        num: [2, 4],
      },
    },
    error: "ok",
  },
};

// 玛莉结果
const spin_mali_result1 = {
  msg_type: "spin_mali_result",
  info: {
    player_mali: {
      uid: 9,
      total_recharge: 24,
      recharge: 0,
      free_num: 1,
    },
    info: {
      normal_reward_index: "3",
      reward_info: {
        type: "add_num",
        num: 1,
      },
    },
    error: "ok",
  },
};

const get_player_active_info_result = {
  msg_type: "get_player_active_info_result",
  info: {
    game_name: "mgc",
    active_index: 1,
    active_type: "active",
  },
};

// 射灯结果
const spin_mali_result = {
  msg_type: "spin_mali_result",
  info: {
    info: {
      normal_reward_index: 11,
      light_num: 3,
      light_info: {
        1: {
          light_reward_index: 7,
          reward_info: {
            num: 1.3,
            type: "jiacheng_card",
          },
        },
        2: {
          light_reward_index: 2,
          reward_info: {
            num: 388,
            type: "gold",
          },
        },
      },
    },
    player_mali: {
      uid: 9,
      total_recharge: 24,
      recharge: 0,
      free_num: 3,
    },
  },
};

// 玩家log
const get_player_mali_log_result = {
  msg_type: "get_player_mali_log_result",
  info: [
    {
      foot_index: 197,
      config: {
        stop_bet_time: 1715576761,
        is_add_time: true,
        vs: ["1", "2"],
        start_bet_time: 1715594761,
        group: "",
        vs_time: 1715529600,
        vs_win_lost: {
          0: {
            max_bet: 10000,
            extract: 0.1,
            odds: 1,
            is_bet: true,
          },
          1: {
            max_bet: 10000,
            extract: 0.1,
            odds: 1,
            is_bet: true,
          },
          2: {
            max_bet: 10000,
            extract: 0.1,
            odds: 1,
            is_bet: true,
          },
        },
      },
      vs_data: {
        vs_win_lost_count: 0,
        vs_win_lost_data: {},
      },
      settle_accounts: {
        vs_add_time_score_result: "2",
        vs_score_result: "2",
        vs_point_score_result: "2",
        vs_point_win_lost_result: "1",
        vs_win_lost_result: "0",
      },
    },
    {
      foot_index: 198,
      config: {
        stop_bet_time: 1715741694,
        is_add_time: true,
        vs: ["10", "9"],
        start_bet_time: 1715741694,
        group: "MS80",
        vs_time: 1715741694,
        vs_win_lost: {
          0: {
            max_bet: 20000,
            extract: 0.1,
            odds: 1.1,
            is_bet: true,
          },
          9: {
            max_bet: 20000,
            extract: 0.1,
            odds: 1.2,
            is_bet: true,
          },
          10: {
            max_bet: 20000,
            extract: 0.1,
            odds: 0.9,
            is_bet: true,
          },
        },
      },
      vs_data: {
        vs_win_lost_count: 0,
        vs_win_lost_data: {},
      },
      settle_accounts: false,
    },
  ],
};

const get_foot_vs_win_lost_config_result = {
  msg_type: "get_foot_vs_win_lost_config_result",
  info: [
    {
      settle_accounts: {
        vs_point_win_lost_result: "1",
        vs_score_result: "2",
        vs_point_score_result: "2",
        vs_win_lost_result: "0",
        vs_add_time_score_result: "2",
      },
      config: {
        stop_bet_time: 1715576761,
        vs_time: 1718193600,
        vs_win_lost: {
          0: {
            extract: 0.1,
            is_bet: true,
            max_bet: 10000,
            odds: 1,
          },
          1: {
            extract: 0.1,
            is_bet: true,
            max_bet: 10000,
            odds: 1,
          },
          2: {
            extract: 0.1,
            is_bet: true,
            max_bet: 10000,
            odds: 1,
          },
        },
        vs: ["1", "2"],
        start_bet_time: 1715594761,
        is_add_time: true,
        group: "",
      },
      foot_index: 197,
      vs_data: {
        vs_win_lost_data: {},
        vs_win_lost_count: 0,
      },
    },
    {
      settle_accounts: false,
      config: {
        stop_bet_time: 1715741694,
        vs_time: 1718280000,
        vs_win_lost: {
          0: {
            extract: 0.1,
            is_bet: true,
            max_bet: 20000,
            odds: 1.1,
          },
          9: {
            extract: 0.1,
            is_bet: true,
            max_bet: 20000,
            odds: 1.2,
          },
          10: {
            extract: 0.1,
            is_bet: true,
            max_bet: 20000,
            odds: 0.9,
          },
        },
        vs: ["10", "9"],
        start_bet_time: 1715741694,
        is_add_time: false,
        group: "MS80",
      },
      foot_index: 198,
      vs_data: {
        vs_win_lost_data: {},
        vs_win_lost_count: 0,
      },
    },
    {
      settle_accounts: {
        vs_point_win_lost_result: "12",
        vs_score_result: "99:1",
        vs_point_score_result: "",
        vs_win_lost_result: "11",
        vs_add_time_score_result: "",
      },
      config: {
        stop_bet_time: 1715875190,
        vs_time: 1718366400,
        vs_win_lost: {
          0: {
            extract: 0.1,
            is_bet: true,
            max_bet: 10000,
            odds: 1,
          },
          11: {
            extract: 0.1,
            is_bet: true,
            max_bet: 10000,
            odds: 1,
          },
          12: {
            extract: 0.1,
            is_bet: true,
            max_bet: 10000,
            odds: 1,
          },
        },
        vs: ["12", "11"],
        start_bet_time: 1715827010,
        is_add_time: false,
        group: "MS84",
      },
      foot_index: 199,
      vs_data: {
        vs_win_lost_data: {
          0: {
            bet_count: 2,
          },
          11: {
            bet_count: 2,
          },
          12: {
            bet_count: 2,
          },
        },
        vs_win_lost_count: 6,
      },
    },
    {
      settle_accounts: false,
      config: {
        stop_bet_time: 1915940000,
        vs_time: 1715913410,
        vs_win_lost: {
          0: {
            extract: 0.1,
            is_bet: true,
            max_bet: 10000,
            odds: 1,
          },
          19: {
            extract: 0.1,
            is_bet: true,
            max_bet: 10000,
            odds: 1,
          },
          24: {
            extract: 0.1,
            is_bet: true,
            max_bet: 10000,
            odds: 1,
          },
        },
        vs: ["24", "19"],
        start_bet_time: 1715907600,
        is_add_time: true,
        group: "",
      },
      foot_index: 200,
      vs_data: {
        vs_win_lost_data: {
          24: {
            bet_count: 1,
          },
        },
        vs_win_lost_count: 1,
      },
    },
  ],
};

const foot_bet_vs_win_lost_result = {
  msg_type: "foot_bet_vs_win_lost_result",
  info: {
    team_id: 2,
    uid: 9,
    foot_index: 200,
    error: "ok",
    bet: 6688,
  },
};

const get_foot_vs_score_config_result = {
  msg_type: "get_foot_vs_score_config_result",
  info: {
    vs_data: {
      vs_add_time_score_data: {},
      vs_add_time_score_count: 0,
    },
    config: {
      vs_add_time_score: {
        1: {
          odds: 99,
          extract: 0.1,
          is_bet: true,
          max_bet: 90000,
          vs: [0, 0],
        },
        2: {
          odds: 88,
          extract: 0.1,
          is_bet: true,
          max_bet: 80000,
          vs: [0, 1],
        },
        3: {
          odds: 77,
          extract: 0.1,
          is_bet: true,
          max_bet: 70000,
          vs: [0, 2],
        },
        4: {
          odds: 1,
          extract: 0.1,
          is_bet: true,
          max_bet: 10000,
          vs: [0, 3],
        },
        5: {
          odds: 1,
          extract: 0.1,
          is_bet: true,
          max_bet: 10000,
          vs: [0, 4],
        },
        6: {
          odds: 1,
          extract: 0.1,
          is_bet: true,
          max_bet: 10000,
          vs: [0, 5],
        },
        7: {
          odds: 1,
          extract: 0.1,
          is_bet: true,
          max_bet: 10000,
          vs: [0, 6],
        },
        8: {
          odds: 1,
          extract: 0.1,
          is_bet: true,
          max_bet: 10000,
          vs: [0, 7],
        },
        9: {
          odds: 1,
          extract: 0.1,
          is_bet: true,
          max_bet: 10000,
          vs: [0, 8],
        },
        10: {
          odds: 1,
          extract: 0.1,
          is_bet: true,
          max_bet: 10000,
          vs: [0, 9],
        },
      },
    },
  },
};

const get_foot_point_win_lost_config_result = {
  msg_type: "get_foot_point_win_lost_config_result",
  info: {
    foot_index: 209,
    config: {
      vs_time: 1716544800,
      vs_point_win_lost: {
        0: {
          odds: 1,
          is_bet: true,
          extract: 0.1,
          max_bet: 10000,
        },
        4: {
          odds: 1,
          is_bet: true,
          extract: 0.1,
          max_bet: 10000,
        },
        12: {
          odds: 1,
          is_bet: true,
          extract: 0.1,
          max_bet: 10000,
        },
      },
      stop_bet_time: 1716544200,
      group: "Q0ND",
      start_bet_time: 1716282406,
      is_add_time: true,
      vs: ["4", "12"],
    },
    vs_data: {
      vs_point_win_lost_count: 0,
      vs_point_win_lost_data: {},
    },
    settle_accounts: false,
  },
};

const get_foot_rank_result = {
  msg_type: "get_foot_rank_result",
  info: {
    reward: {
      1: 10086,
      2: 10085,
      3: 10084,
      4: 10083,
      5: 10082,
      6: 10081,
      7: 10080,
    },
    rank: [
      {
        head_frame: 3,
        uid: 9,
        score: 21,
        head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
        level: 2,
        player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
        recharge_level: 10,
      },
      {
        head_frame: 3,
        uid: 9,
        score: 21,
        head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
        level: 2,
        player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
        recharge_level: 10,
      },
      {
        head_frame: 3,
        uid: 9,
        score: 21,
        head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
        level: 2,
        player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
        recharge_level: 10,
      },
      {
        head_frame: 3,
        uid: 9,
        score: 21,
        head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
        level: 2,
        player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
        recharge_level: 10,
      },
      {
        head_frame: 3,
        uid: 9,
        score: 21,
        head: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLqaoUOOWRh7Ric0sp8jeO5LPECQp0D4gUBSQdhG5FicB3cDVXicFxCaQTd7uwb8pSDSx8hciaibm5pY0w/132",
        level: 2,
        player_name: "5Y2h5LiB5ZKU5Y+u5YS/",
        recharge_level: 10,
      },
    ],
  },
};

const get_player_foot_vs_score_result = {
  msg_type: "get_player_foot_vs_score_result",
  info: [
    {
      bet: 300,
      type: "vs_win_lost",
      foot_index: 230,
      time: 1717053021,
      select_index: "6",
      uid: 9,
    },
    {
      bet: 10086,
      type: "vs_win_lost",
      foot_index: 230,
      time: 1717053021,
      select_index: "5",
      uid: 9,
    },
  ],
};

const get_player_title_result = {
  msg_type: "get_player_title_result",
  info: {
    data: {
      1: {
        complete: 10,
        num: 4,
        task: [
          {
            game_name: "mgc",
            class_id: 6,
          },
        ],
      },
      2: {
        complete: 10,
        num: 0,
        task: [
          {
            game_name: "hw3",
            class_id: 16,
          },
        ],
      },
      3: {
        complete: 5,
        num: 0,
        task: [
          {
            game_name: "bbl",
            class_id: 1,
          },
        ],
      },
      4: {
        complete: 10,
        num: 0,
        task: {
          1: {
            game_name: "mxt",
            class_id: 3,
          },
          2: {
            game_name: "msqy",
            class_id: 7,
          },
        },
      },
      5: {
        complete: 4,
        num: 0,
      },
      6: {
        complete: 2,
        num: 0,
        task: [
          {
            game_name: "tiaozhan",
            class_id: 1,
          },
        ],
      },
      7: {
        complete: 1,
        num: 1,
        task: [
          {
            game_name: "tianti",
            class_id: 1,
          },
        ],
      },
      8: {
        complete: 1,
        num: 0,
        task: [
          {
            game_name: "tianti",
            class_id: 2,
          },
        ],
      },
      9: {
        complete: 1,
        num: 0,
        task: [
          {
            game_name: "tianti",
            class_id: 3,
          },
        ],
      },
      10: {
        complete: 1,
        num: 0,
        task: [
          {
            game_name: "aixin",
            class_id: 17,
          },
        ],
      },
      11: {
        complete: 1,
        num: 0,
        task: [
          {
            game_name: "longka",
            class_id: 13,
          },
        ],
      },
      12: {
        complete: 1,
        num: 1,
        task: [
          {
            game_name: "zhongguoxin",
            class_id: 1,
          },
        ],
      },
      13: {
        complete: 1,
        num: 0,
        task: [
          {
            game_name: "zhanshi",
            class_id: 1,
          },
        ],
      },
      14: {
        complete: 1,
        num: 0,
        task: [
          {
            game_name: "gaoshou",
            class_id: 1,
          },
        ],
      },
      15: {
        complete: 1,
        num: 0,
        task: [
          {
            game_name: "wangzhe",
            class_id: 1,
          },
        ],
      },
      16: {
        complete: 1,
        num: 0,
        task: [
          {
            game_name: "xiaobawang",
            class_id: 1,
          },
        ],
      },
      17: {
        complete: 10,
        num: 0,
        task: [
          {
            class_id: 6,
            game_name: "mgc",
          },
        ],
        reward: {
          num: 188,
          type: "gold",
        },
      },
      18: {
        complete: 20,
        num: 0,
        task: [
          {
            class_id: 6,
            game_name: "mgc",
          },
        ],
        reward: {
          num: 388,
          type: "gold",
        },
      },
      19: {
        complete: 35,
        num: 0,
        task: [
          {
            class_id: 6,
            game_name: "mgc",
          },
        ],
        reward: {
          num: 888,
          type: "gold",
        },
      },
      20: {
        complete: 10,
        num: 0,
        task: [
          {
            class_id: 16,
            game_name: "hw3",
          },
        ],
        reward: {
          num: 188,
          type: "gold",
        },
      },
      21: {
        complete: 20,
        num: 0,
        task: [
          {
            class_id: 16,
            game_name: "hw3",
          },
        ],
        reward: {
          num: 388,
          type: "gold",
        },
      },
      22: {
        complete: 35,
        num: 0,
        task: [
          {
            class_id: 16,
            game_name: "hw3",
          },
        ],
        reward: {
          num: 888,
          type: "gold",
        },
      },
      23: {
        complete: 10,
        num: 0,
        task: [
          {
            class_id: 1,
            game_name: "bbl",
          },
        ],
        reward: {
          num: 188,
          type: "gold",
        },
      },
      24: {
        complete: 20,
        num: 0,
        task: [
          {
            class_id: 1,
            game_name: "bbl",
          },
        ],
        reward: {
          num: 388,
          type: "gold",
        },
      },
      25: {
        complete: 35,
        num: 0,
        task: [
          {
            class_id: 1,
            game_name: "bbl",
          },
        ],
        reward: {
          num: 888,
          type: "gold",
        },
      },
      26: {
        complete: 10,
        num: 0,
        task: {
          1: {
            class_id: 3,
            game_name: "mxt",
          },
          2: {
            class_id: 7,
            game_name: "msqy",
          },
        },
        reward: {
          num: 188,
          type: "gold",
        },
      },
      27: {
        complete: 20,
        num: 0,
        task: {
          1: {
            class_id: 3,
            game_name: "mxt",
          },
          2: {
            class_id: 7,
            game_name: "msqy",
          },
        },
        reward: {
          num: 388,
          type: "gold",
        },
      },
      28: {
        complete: 35,
        num: 0,
        task: {
          1: {
            class_id: 3,
            game_name: "mxt",
          },
          2: {
            class_id: 7,
            game_name: "msqy",
          },
        },
        reward: {
          num: 888,
          type: "gold",
        },
      },
    },
    uid: 1,
  },
};

const send_marquee_result = {
  msg_type: "send_marquee_result",
  info: {
    end_time: 1719035447,
    font_color: "green",
    font: "Arial",
    is_check_time: false,
    delay_time: 5,
    content:
      "å³å°å¨ 6æ25æ¥10:35:31åæºç»´æ¤ï¼è¯·ææç©å®¶åçå®ææ¸¸ææ¶é´ï¼åæ¶ç»ç®ä¸æºï¼",
    speet: 3000,
    free_num: 86,
    start_time: 1719035447,
    free_time: 5,
    repeat_num: 99,
  },
};

const get_machine_info_by_group_result2 = {
  msg_type: "get_machine_info_by_group_result",
  info: [
    {
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/bxwg1",
      group: "vip_tbj",
      line_reward_index: "",
      device_info: [
        {
          check_num_free_time: 2,
          device_index: 1,
          net_line_index: "bjwg11",
          occupy: {
            cost: 0,
            lottery: 0,
          },
          machine_index: "1",
          device_name: "bjwg11",
          server_time: 1722413776,
          product_name: "bjwg",
          status: "1",
          bbl_win_num: 0,
          check_num: 0,
        },
      ],
      site_limit: 10,
      visit_time: 60,
      product_name: "bjwg",
      status: "1",
      level: 4,
      tiaozhan_index: 0,
      up_coin: 10,
      exchange: 10,
      site_sign_num: 1,
      return_time: 30,
      coder_index: "1",
      title: "",
      machine_index: "1",
      net_line_index: "1",
      up_coin_sign_num: 1,
      product_id: "N8POIFA02O",
      child_name: "bjwg",
    },
    {
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/bxwg2",
      group: "vip_tbj",
      line_reward_index: "",
      device_info: [
        {
          check_num_free_time: 1,
          device_index: 1,
          net_line_index: "bjwg21",
          occupy: {
            cost: 0,
            lottery: 0,
          },
          machine_index: "2",
          device_name: "bjwg21",
          server_time: 1722413776,
          product_name: "bjwg",
          status: "1",
          bbl_win_num: 0,
          check_num: 0,
        },
      ],
      site_limit: 10,
      visit_time: 60,
      product_name: "bjwg",
      status: "1",
      level: 4,
      tiaozhan_index: 0,
      up_coin: 10,
      exchange: 10,
      site_sign_num: 1,
      return_time: 30,
      coder_index: "2",
      title: "",
      machine_index: "2",
      net_line_index: "2",
      up_coin_sign_num: 1,
      product_id: "N8POIFA02O",
      child_name: "bjwg",
    },
    {
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/bxwg3",
      group: "vip_tbj",
      line_reward_index: "",
      device_info: [
        {
          check_num_free_time: 1,
          device_index: 1,
          net_line_index: "bjwg31",
          occupy: {
            cost: 0,
            lottery: 0,
          },
          machine_index: "3",
          device_name: "bjwg31",
          server_time: 1722413776,
          product_name: "bjwg",
          status: "1",
          bbl_win_num: 0,
          check_num: 0,
        },
      ],
      site_limit: 10,
      visit_time: 60,
      product_name: "bjwg",
      status: "1",
      level: 4,
      tiaozhan_index: 0,
      up_coin: 10,
      exchange: 10,
      site_sign_num: 1,
      return_time: 30,
      coder_index: "3",
      title: "",
      machine_index: "3",
      net_line_index: "3",
      up_coin_sign_num: 1,
      product_id: "N8POIFA02O",
      child_name: "bjwg",
    },
    {
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/bxwg1",
      group: "vip_tbj",
      net_line_index: "1",
      device_info: {},
      site_limit: 30,
      visit_time: 60,
      product_name: "bjl",
      status: "1",
      level: 4,
      tiaozhan_index: 0,
      up_coin: 10,
      exchange: 10,
      machine_index: "1",
      name: "ac_create_machine",
      return_time: 30,
      up_coin_sign_num: 0,
      title: "",
      site_sign_num: 1,
      coder_index: "1",
      line_reward_index: "",
      product_id: "DIXTOVBHLZ",
      child_name: "bjwg",
    },
    {
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/bxwg1",
      group: "vip_tbj",
      net_line_index: "1",
      device_info: {},
      site_limit: 10,
      visit_time: 60,
      product_name: "hdcz",
      status: "1",
      level: 4,
      tiaozhan_index: 0,
      up_coin: 10,
      exchange: 10,
      machine_index: "1",
      name: "ac_create_machine",
      return_time: 30,
      up_coin_sign_num: 1,
      title: "",
      site_sign_num: 1,
      coder_index: "1",
      line_reward_index: "",
      product_id: "WKE7YNMI11",
      child_name: "hdcz",
    },
    {
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/bxwg1",
      group: "vip_tbj",
      net_line_index: "1",
      device_info: {},
      site_limit: 10,
      visit_time: 60,
      product_name: "bbxc",
      status: "1",
      level: 4,
      tiaozhan_index: 0,
      up_coin: 10,
      exchange: 10,
      machine_index: "1",
      name: "ac_create_machine",
      return_time: 30,
      up_coin_sign_num: 1,
      title: "",
      site_sign_num: 1,
      coder_index: "1",
      line_reward_index: "",
      product_id: "RN7XV23NUH",
      child_name: "bbxc",
    },
  ],
};

const get_machine_info_by_group_result = {
  msg_type: "get_machine_info_by_group_result",
  info: [
    {
      up_coin_sign_num: 1,
      group: "msqy_line",
      site_sign_num: 1,
      product_name: "msqy",
      status: "1",
      level: 1,
      child_name: "msqy_line",
      net_line_index: "1",
      line_reward_data: {
        quanpan: 3001,
        jp1: 521,
        jp2: 1067,
        jp3: 2027,
      },
      device_info: [
        {
          check_num_free_time: 1,
          bbl_win_num: 0,
          check_num: 0,
          device_index: 1,
          occupy: {
            lottery: 0,
            cost: 0,

            player_id: 9,
            head: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/default_avatar_6.png",
          },
          machine_index: "1",
          device_name: "msqy11",
          server_time: 1719396715,
          product_name: "msqy",
          status: "1",
          net_line_index: "msqy11",
        },
      ],
      tiaozhan_index: 0,
      product_id: "A90TLUGHZ9",
      exchange: 10,
      return_time: 30,
      machine_index: "1",
      site_limit: 10,
      title: "A_title",
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/msqy28",
      up_coin: 10,
      coder_index: "1",
      line_reward_index: "1",
      visit_time: 60,
    },
    {
      up_coin_sign_num: 1,
      group: "msqy_line",
      site_sign_num: 1,
      product_name: "msqy",
      status: "1",
      level: 1,
      child_name: "msqy_line",
      net_line_index: "2",
      line_reward_data: {
        quanpan: 3001,
        jp1: 521,
        jp2: 1067,
        jp3: 2027,
      },
      device_info: [
        {
          check_num_free_time: 1,
          bbl_win_num: 0,
          check_num: 0,
          device_index: 1,
          occupy: {
            lottery: 0,
            cost: 0,
          },
          machine_index: "2",
          device_name: "msqy21",
          server_time: 1719396715,
          product_name: "msqy",
          status: "1",
          net_line_index: "msqy21",
        },
      ],
      tiaozhan_index: 0,
      product_id: "A90TLUGHZ9",
      exchange: 10,
      return_time: 30,
      machine_index: "2",
      site_limit: 10,
      title: "A_title",
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/msqy29c",
      up_coin: 10,
      coder_index: "2",
      line_reward_index: "1",
      visit_time: 60,
    },
    {
      up_coin_sign_num: 1,
      group: "msqy_line",
      site_sign_num: 1,
      product_name: "msqy",
      status: "1",
      level: 1,
      child_name: "msqy_line",
      net_line_index: "3",
      line_reward_data: {
        quanpan: 3153,
        jp1: 617,
        jp2: 1077,
        jp3: 2128,
      },
      device_info: [
        {
          check_num_free_time: 1,
          bbl_win_num: 0,
          check_num: 0,
          device_index: 1,
          occupy: {
            player_name: "X1Rlc3Rf",
            head: "",
            recharge_level: 9,
            player_id: 13,
            head_frame: 1,
            select_title: "0",
            start_time: 1719485749,
            lottery: 0,
            cost: 10,
          },
          machine_index: "3",
          device_name: "msqy31",
          server_time: 1719485855,
          product_name: "msqy",
          status: "1",
          net_line_index: "msqy31",
        },
      ],
      tiaozhan_index: 0,
      up_coin: 10,
      exchange: 10,
      return_time: 30,
      site_limit: 10,
      machine_index: "3",
      visits: {},
      title: "A_title",
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/msqy30c",
      coder_index: "3",
      visit_time: 60,
      line_reward_index: "1",
      product_id: "A90TLUGHZ9",
    },
    {
      coder_index: "1",
      group: "msqy_line",
      product_id: "A90TLUGHZ9",
      product_name: "msqy",
      status: "1",
      level: 1,
      line_reward_index: "4",
      net_line_index: "8",
      up_coin_sign_num: 1,
      tiaozhan_index: 0,
      up_coin: 10,
      exchange: 10,
      visit_time: 10,
      name: "ac_create_machine",
      machine_index: "8",
      child_name: "msqy",
      site_limit: 60,
      device_info: [
        {
          net_line_index: "msqy81",
          bbl_win_num: 0,
          status: "1",
          product_name: "msqy",
          machine_index: "8",
          device_name: "msqy81",
          occupy: {
            cost: 0,
            lottery: 0,
          },
          check_num: 0,
          check_num_free_time: 2,
          name: "ac_create_device",
          server_time: 1723604367,
          device_index: 1,
        },
      ],
      title: "",
      return_time: 30,
      site_sign_num: 1,
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/msqy28",
    },
    {
      coder_index: "9",
      group: "msqy_line",
      product_id: "A90TLUGHZ9",
      product_name: "msqy",
      status: "1",
      level: 1,
      line_reward_index: "4",
      net_line_index: "9",
      up_coin_sign_num: 1,
      tiaozhan_index: 0,
      up_coin: 10,
      exchange: 10,
      visit_time: 60,
      name: "ac_create_machine",
      machine_index: "9",
      child_name: "msqy",
      site_limit: 10,
      device_info: [
        {
          net_line_index: "msqy91",
          bbl_win_num: 0,
          status: "1",
          product_name: "msqy",
          machine_index: "9",
          device_name: "msqy91",
          occupy: {
            cost: 0,
            lottery: 0,
          },
          check_num: 0,
          check_num_free_time: 1,
          name: "ac_create_device",
          server_time: 1723604367,
          device_index: 1,
        },
      ],
      title: "",
      return_time: 30,
      site_sign_num: 1,
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/msqy28",
    },
    {
      coder_index: "10",
      group: "msqy_line",
      product_id: "A90TLUGHZ9",
      product_name: "msqy",
      status: "1",
      level: 1,
      line_reward_index: "4",
      net_line_index: "10",
      up_coin_sign_num: 10,
      tiaozhan_index: 0,
      up_coin: 10,
      exchange: 10,
      visit_time: 60,
      name: "ac_create_machine",
      machine_index: "10",
      child_name: "msqy",
      site_limit: 1,
      device_info: [
        {
          net_line_index: "msqy101",
          bbl_win_num: 0,
          status: "1",
          product_name: "msqy",
          machine_index: "10",
          device_name: "msqy101",
          occupy: {
            cost: 0,
            lottery: 0,
          },
          check_num: 0,
          check_num_free_time: 2,
          name: "ac_create_device",
          server_time: 1723604367,
          device_index: 1,
        },
      ],
      title: "",
      return_time: 30,
      site_sign_num: 1,
      sp_url: "webrtc://yisu.weipinwl.com:1991/live/msqy28",
    },
  ],
};

const get_player_magpie_result = {
  msg_type: "get_player_magpie_result",
  info: {
    error: "ok",
    info: {
      reward_data: {
        1: {
          reward: {
            type: "gold",
            exchange_num: 1,
            check_exchange: true,
            num: 88,
            max_exchange_num: 3,
          },
          condition: {
            1: {
              game_name: "hw3",
              complete: 1,
              class_id: 17,
            },
            2: {
              game_name: "hw3",
              complete: 1,
              class_id: 13,
            },
          },
        },
        2: {
          reward: {
            type: "gold",
            exchange_num: 2,
            check_exchange: true,
            num: 268,
            max_exchange_num: 3,
          },
          condition: [
            {
              game_name: "hw3",
              complete: 5,
              class_id: 13,
            },
          ],
        },
        3: {
          reward: {
            type: "gold",
            exchange_num: 3,
            check_exchange: true,
            num: 268,
            max_exchange_num: 3,
          },
          condition: [
            {
              game_name: "hw3",
              complete: 5,
              class_id: 17,
            },
          ],
        },
        4: {
          reward: {
            type: "chenghao",
            class_id: 13,
            name: "longka",
            num: 1,
          },
          condition: {
            1: {
              game_name: "hw3",
              complete: 10,
              class_id: 17,
            },
            2: {
              game_name: "hw3",
              complete: 20,
              class_id: 13,
            },
          },
        },
        5: {
          reward: {
            type: "chenghao",
            class_id: 17,
            name: "aixin",
            num: 1,
          },
          condition: {
            1: {
              game_name: "hw3",
              complete: 20,
              class_id: 17,
            },
            2: {
              game_name: "hw3",
              complete: 10,
              class_id: 13,
            },
          },
        },
      },
      uid: 9,
      collect_data: {
        hw3: {
          13: 0,
          17: 0,
        },
      },
      update_time: 1721404800,
    },
  },
};

const get_player_turntable_result = {
  msg_type: "get_player_turntable_result",
  info: {
    player_turntable: {
      score: 5,
      uid: 9,
    },
    turn_info: {
      1: {
        pro_info: [
          {
            index: ["1", "2", "3", "4", "5"],
            pro: 60000,
          },
          {
            index: ["6", "7", "8"],
            pro: 30000,
          },
          {
            index: ["9", "10"],
            pro: 10000,
          },
        ],
        level_limit: [3, 21],
        reward: {
          1: {
            type: "lottery",
            reward_num: 8,
          },
          2: {
            type: "lottery",
            reward_num: 1000,
          },
          3: {
            type: "lottery",
            reward_num: 1500,
          },
          4: {
            type: "lottery",
            reward_num: 2000,
          },
          5: {
            type: "lottery",
            reward_num: 2500,
          },
          6: {
            type: "lottery",
            reward_num: 3000,
          },
          7: {
            type: "lottery",
            reward_num: 3500,
          },
          8: {
            type: "lottery",
            reward_num: 4000,
          },
          9: {
            type: "lottery",
            reward_num: 4500,
          },
          10: {
            type: "lottery",
            reward_num: 5000,
          },
        },
      },
      2: {
        pro_info: [
          {
            index: ["2", "3", "4", "5", "6"],
            pro: 70000,
          },
          {
            index: ["7", "8", "9"],
            pro: 20000,
          },
          {
            index: ["1", "10"],
            pro: 10000,
          },
        ],
        level_limit: [3, 19],
        reward: {
          1: {
            type: "jp",
            reward_num: 0,
          },
          2: {
            type: "lottery",
            reward_num: 1000,
          },
          3: {
            type: "lottery",
            reward_num: 2000,
          },
          4: {
            type: "lottery",
            reward_num: 2500,
          },
          5: {
            type: "lottery",
            reward_num: 3000,
          },
          6: {
            type: "lottery",
            reward_num: 4000,
          },
          7: {
            type: "lottery",
            reward_num: 4500,
          },
          8: {
            type: "lottery",
            reward_num: 5000,
          },
          9: {
            type: "lottery",
            reward_num: 6000,
          },
          10: {
            type: "lottery",
            reward_num: 8000,
          },
        },
      },
      3: {
        pro_info: [
          {
            index: ["2", "3", "4", "5", "6"],
            pro: 80000,
          },
          {
            index: ["7", "8", "9"],
            pro: 15000,
          },
          {
            index: ["1", "10"],
            pro: 5000,
          },
        ],
        level_limit: [3, 18],
        reward: {
          1: {
            type: "jp",
            reward_num: 0,
          },
          2: {
            type: "lottery",
            reward_num: 1688,
          },
          3: {
            type: "lottery",
            reward_num: 2000,
          },
          4: {
            type: "lottery",
            reward_num: 3000,
          },
          5: {
            type: "lottery",
            reward_num: 4000,
          },
          6: {
            type: "lottery",
            reward_num: 5000,
          },
          7: {
            type: "lottery",
            reward_num: 6000,
          },
          8: {
            type: "lottery",
            reward_num: 7000,
          },
          9: {
            type: "lottery",
            reward_num: 8000,
          },
          10: {
            type: "lottery",
            reward_num: 18888,
          },
        },
      },
    },
    spin_log: [
      {
        player_name: "NzE2MDAwMDAz",
        select_head_kuang: {
          level: 4,
          type: "recharge",
        },
        reward_num: 5000,
        head_frame: 2,
        uid: 3,
        time: 1723107395,
        select_title: "0",
        reward_type: "lottery",
        _id: "\u0000\u0007f´CÅÄÎ\u001a~¿\u000bÔ",
        head: "",
      },
      {
        player_name: "NzE2MDAwMDAz",
        select_head_kuang: {
          level: 4,
          type: "recharge",
        },
        reward_num: 1688,
        head_frame: 2,
        uid: 3,
        time: 1723107394,
        select_title: "0",
        reward_type: "lottery",
        _id: "\u0000\u0007f´BÅÄÎ\u001a~¿\u000bÒ",
        head: "",
      },
      {
        player_name: "NzE2MDAwMDAz",
        select_head_kuang: {
          level: 4,
          type: "recharge",
        },
        reward_num: 4000,
        head_frame: 2,
        uid: 3,
        time: 1723107393,
        select_title: "0",
        reward_type: "lottery",
        _id: "\u0000\u0007f´AÅÄÎ\u001a~¿\u000bÐ",
        head: "",
      },
      {
        player_name: "NzE2MDAwMDAz",
        select_head_kuang: {
          level: 4,
          type: "recharge",
        },
        reward_num: 1688,
        head_frame: 2,
        uid: 3,
        time: 1723107392,
        select_title: "0",
        reward_type: "lottery",
        _id: "\u0000\u0007f´@ÅÄÎ\u001a~¿\u000bÎ",
        head: "",
      },
      {
        player_name: "NzE2MDAwMDAz",
        select_head_kuang: {
          level: 4,
          type: "recharge",
        },
        reward_num: 2000,
        head_frame: 2,
        uid: 3,
        time: 1723107391,
        select_title: "0",
        reward_type: "lottery",
        _id: "\u0000\u0007f´?ÅÄÎ\u001a~¿\u000bÌ",
        head: "",
      },
      {
        player_name: "NzE2MDAwMDAz",
        select_head_kuang: {
          level: 4,
          type: "recharge",
        },
        reward_num: 2000,
        head_frame: 2,
        uid: 3,
        time: 1723107390,
        select_title: "0",
        reward_type: "lottery",
        _id: "\u0000\u0007f´>ÅÄÎ\u001a~¿\u000bÊ",
        head: "",
      },
      {
        player_name: "NzE2MDAwMDAz",
        select_head_kuang: {
          level: 4,
          type: "recharge",
        },
        reward_num: 6000,
        head_frame: 2,
        uid: 3,
        time: 1723107389,
        select_title: "0",
        reward_type: "lottery",
        _id: "\u0000\u0007f´=ÅÄÎ\u001a~¿\u000bÈ",
        head: "",
      },
      {
        player_name: "NzE2MDAwMDAz",
        select_head_kuang: {
          level: 4,
          type: "recharge",
        },
        reward_num: 3000,
        head_frame: 2,
        uid: 3,
        time: 1723107388,
        select_title: "0",
        reward_type: "lottery",
        _id: "\u0000\u0007f´<ÅÄÎ\u001a~¿\u000bÆ",
        head: "",
      },
    ],
    spin_jp_log: [
      {
        player_name: "NzE2MDAwMDAz",
        select_head_kuang: {
          level: 4,
          type: "recharge",
        },
        reward_num: 1688,
        head_frame: 2,
        uid: 3,
        time: 1723107392,
        select_title: "0",
        reward_type: "lottery",
        _id: "\u0000\u0007f´@ÅÄÎ\u001a~¿\u000bÎ",
        head: "",
      },
      {
        player_name: "NzE2MDAwMDAz",
        select_head_kuang: {
          level: 4,
          type: "recharge",
        },
        reward_num: 2000,
        head_frame: 2,
        uid: 3,
        time: 1723107391,
        select_title: "0",
        reward_type: "lottery",
        _id: "\u0000\u0007f´?ÅÄÎ\u001a~¿\u000bÌ",
        head: "",
      },
      {
        player_name: "NzE2MDAwMDAz",
        select_head_kuang: {
          level: 4,
          type: "recharge",
        },
        reward_num: 2000,
        head_frame: 2,
        uid: 3,
        time: 1723107390,
        select_title: "0",
        reward_type: "lottery",
        _id: "\u0000\u0007f´>ÅÄÎ\u001a~¿\u000bÊ",
        head: "",
      },
      {
        player_name: "NzE2MDAwMDAz",
        select_head_kuang: {
          level: 4,
          type: "recharge",
        },
        reward_num: 6000,
        head_frame: 2,
        uid: 3,
        time: 1723107389,
        select_title: "0",
        reward_type: "lottery",
        _id: "\u0000\u0007f´=ÅÄÎ\u001a~¿\u000bÈ",
        head: "",
      },
      {
        player_name: "NzE2MDAwMDAz",
        select_head_kuang: {
          level: 4,
          type: "recharge",
        },
        reward_num: 3000,
        head_frame: 2,
        uid: 3,
        time: 1723107388,
        select_title: "0",
        reward_type: "lottery",
        _id: "\u0000\u0007f´<ÅÄÎ\u001a~¿\u000bÆ",
        head: "",
      },
    ],
    spin_num: 5,
    error: "ok",
    jp_num: 20035,
  },
};

const spin_player_turntable_result2 = {
  msg_type: "spin_player_turntable_result",
  info: {
    reward_info: {
      type: "lottery",
      reward_num: 3000,
    },
    jp_num: 20035,
    error: "ok",
    reward_index: "6",
  },
};

const spin_player_turntable_result = {
  msg_type: "spin_player_turntable_result",
  info: {
    jp_num: 20112,
    reward_info: {
      type: "jp",
      reward_num: 10056,
    },
    error: "ok",
    reward_index: "1",
  },
};

const get_player_guoqing_result = {
  msg_type: "get_player_guoqing_result",
  info: {
    stone_green: 44,
    uid: 1,
    update_time: 1725292800,
    stone_red: 96,
    stone_blue: 29,
    total_box_info: {
      1: 7,
      2: 1,
      3: 8,
      4: 48,
      5: 99,
    },
    yuebing: 98589,
    box_info: {
      1: 2,
      2: 1,
      3: 1,
      4: 7,
      5: 4,
    },
  },
};

const get_guoqing_reward_result = {
  msg_type: "get_guoqing_reward_result",
  info: {
    error: "ok",
    reward_data: [
      {
        type: "lottery",
        num: 857,
      },
      {
        type: "gold",
        num: 666,
      },
    ],
    must_title: {
      max_num: 100,
      type: "chenghao",
      title_name: "wangzhe",
      class_id: 1,
    },
  },
};

const get_game_table_list_result = {
  msg_type: "get_game_table_list_result",
  info: [
    {
      level_limit: 1,
      table_lock: false,
      gold_limit: 0,
      max_bet: 1000,
      table_id: 4,
      min_bet: 10,
      exchange_bet: 10,
    },
    {
      level_limit: 1,
      table_lock: false,
      gold_limit: 0,
      max_bet: 1000,
      table_id: 5,
      min_bet: 10,
      exchange_bet: 10,
    },
    {
      level_limit: 1,
      table_lock: false,
      gold_limit: 0,
      max_bet: 1000,
      table_id: 6,
      min_bet: 10,
      exchange_bet: 10,
    },
    {
      player_head_info: {
        head_frame: 3,
        player_name: "dGVzdDAwNg==",
        head: "",
        select_title: "0",
        recharge_level: 1,
        select_head_kuang: {
          level: 1,
          type: "recharge",
        },
        level: 1,
      },
      gold_limit: 0,
      exchange_bet: 10,
      uid: 27,
      min_bet: 10,
      level_limit: 1,
      table_id: 7,
      table_lock: false,
      max_bet: 1000,
    },
  ],
};

// 新手任务
const get_player_sprog_task_result = {
  msg_type: "get_player_sprog_task_result",
  info: {
    task_def: [
      {
        reward: [
          {
            num: 66,
            reward_type: "gold",
          },
        ],
        game_name: "mxt",
      },
      {
        reward: [
          {
            num: 88,
            reward_type: "gold",
          },
        ],
        game_name: "mgc",
      },
      {
        reward: [
          {
            num: 128,
            reward_type: "gold",
          },
        ],
        game_name: "msqy",
      },
      {
        reward: [
          {
            complete_num: 1688,
            reward_type: "lottery",
          },
          {
            item_type: "jiacheng_card",
            num: 1.15,
            use_limit: 604800,
            reward_type: "item",
          },
        ],
        game_name: "box",
      },
      {
        reward: [
          {
            num: 238,
            reward_type: "gold",
          },
        ],
        game_name: "bbl",
      },
      {
        reward: [
          {
            num: 418,
            reward_type: "gold",
          },
          {
            num: 30,
            reward_type: "tz_card",
          },
        ],
        game_name: "hw3",
      },
      {
        reward: [
          {
            num: 666,
            reward_type: "gold",
          },
          {
            item_type: "jiacheng_card",
            num: 1.3,
            use_limit: 604800,
            reward_type: "item",
          },
        ],
        game_name: "box",
      },
      {
        reward: [
          {
            num: 1000,
            reward_type: "gold",
          },
          {
            class_id: 1,
            title_name: "xiaobawang",
            reward_type: "chenghao",
          },
        ],
        game_name: "mgc",
      },
    ],
    player_sprog_task: {
      step: 1,
      end_time: 1726541138,
      task: [
        {
          1: {
            complete_num: 50,
            task_type: "cost_gold",
            num: 0,
          },
          2: {
            complete_num: 1000,
            task_type: "lottery",
            num: 0,
          },
        },
        {
          1: {
            complete_num: 1,
            class_id: 12,
            task_type: "collect",
            num: 0,
          },
          2: {
            complete_num: 1,
            class_id: 10,
            task_type: "collect",
            num: 0,
          },
        },
        {
          1: {
            complete_num: 300,
            task_type: "cost_gold",
            num: 0,
          },
          2: {
            complete_num: 1,
            class_id: 6,
            task_type: "collect",
            num: 0,
          },
        },
        {},
        {
          1: {
            complete_num: 1,
            class_id: 2,
            task_type: "collect",
            num: 0,
          },
          2: {
            complete_num: 1,
            class_id: 3,
            task_type: "collect",
            num: 0,
          },
        },
        {
          1: {
            complete_num: 1,
            class_id: 6,
            task_type: "collect",
            num: 0,
          },
          2: {
            complete_num: 1,
            class_id: 13,
            task_type: "collect",
            num: 0,
          },
          3: {
            complete_num: 1,
            class_id: 18,
            task_type: "collect",
            num: 0,
          },
        },
        {},
        {
          1: {
            task_type: "mgc_tz",
            num: 0,
            complete_num: 1,
          },
        },
      ],
      uid: 51,
    },
  },
};

const mockList = {
  get_game_table_list_result,
  get_guoqing_reward_result,
  get_player_guoqing_result,
  spin_player_turntable_result,
  get_player_magpie_result,
  get_machine_info_by_group_result,
  get_player_title_result,
  get_player_foot_vs_score_result,
  get_foot_vs_win_lost_config_result,
  foot_bet_vs_win_lost_result,
  get_foot_rank_result,
  get_foot_point_win_lost_config_result,
  get_foot_vs_score_config_result,
  exchange_player_weal_box_result,
  get_nangua_rank_result,
  exchange_collect_result,
  get_player_collect_result,
  get_machine_info,
  get_tiaozhan_info_result,
  enter_game_result,
  get_tianti_rank_result,
  extract_game_reward_result,
  get_player_recharge_status_result,
  exchange_lottery_result,
  get_recharge_card_reward_result,
  get_task_info_result,
  get_task_reward_result,
  get_tribe_rank_result,
  engine_init_end,
  get_server_time_result,
  return_lottery_result,
  get_login_reward_result,
  get_invite_reward_log_result,
  get_player_weal_box_log_result,
  get_player_weal_box_result,
  get_all_log_result,
  get_game_reward_result,
  get_player_tiaozhan_info_result,
  collect_event_result,
  change_name_result,
  get_player_common,
  get_channel_result,
  shipin_game_notice_result,
  get_sys_notice_result,
  get_player_game_task_result,
  get_player_game_task_reward_result,
  get_shipin_active_config_result,
  get_shipin_active_rank_result,
  enter_active_game_result,
  get_item_result,
  get_player_mali_result,
  spin_mali_result,
  get_player_mali_log_result,
  get_player_turntable_result,
  get_player_sprog_task_result,
};

export function mockBackResult(msg_type, timeout = 3000) {
  if (!mockList[msg_type]) {
    console.log(`current msg_type:${msg_type} no mock data`);
    return;
  }

  if (msg_type == "return_lottery_result") return;
  setTimeout(() => {
    window.set_data(mockList[msg_type]);
  }, timeout);
}
