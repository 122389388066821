<template>
  <div class="mxt_view_wrapper" @click="golbalClickEvent($event)">
    <GameLoding v-if="!isReady" />

    <Player ref="_Player" :landscape="false" @ready-change="readyChange" />

    <MenuLc
      ref="menud"
      @menuSoundClick="menuSoundClick"
      @handleExitEvent="handleExitEvent"
      @handle_update="handle_update_player"
      @handle_display="ui_control_show_toggle"
    />

    <UserWallet
      style="position: absolute; top: 20.9895vh; left: 15px; z-index: 2"
      v-show="ui_control_show"
    />
    <UserWallet
      style="position: absolute; top: 25.4879vh; left: 15px; z-index: 2"
      type="lottery"
      v-show="ui_control_show"
    />

    <!-- <UserGold /> -->
    <GameRoom v-show="ui_control_show" />

    <!-- 时间 -->
    <ServerTime :right="15" :top="80" />

    <!-- 观战计时 -->
    <WatchTime
      :watch_time="Number(store.current_machine.visit_time)"
      @onFinish="exitGameRequest"
      v-if="store.gameIsWatch && isReady && !store.userInfo.is_gm"
    />

    <!-- 占位者 -->
    <div
      class="occupy_info_wrapper"
      v-if="
        store.gameIsWatch &&
        store.current_machine.occupy.length > 0 &&
        ui_control_show
      "
    >
      <div class="user_portrait">
        <HeadPortrait
          width="100%"
          height="100%"
          :src="
            store.current_machine.occupy[0].head ||
            String(store.current_machine.occupy[0].head_frame)
          "
          :level="Number(store.userInfo.recharge_level)"
        />
      </div>
      <div class="user_name">
        {{ decodeBase64String(store.current_machine.occupy[0].player_name) }}
        &nbsp;&nbsp;
        <span style="color: red; font-weight: bold" v-if="store.userInfo.is_gm"
          >UID: {{ store.current_machine.occupy[0].player_id }}</span
        >
      </div>
    </div>
    <!-- 围观者 -->
    <div
      class="visits_list_wrapper"
      v-if="store.current_machine.visits.length > 0 && ui_control_show"
    >
      <div class="visits_text_wrapper">围观：</div>

      <div
        class="visit_item_user_header"
        v-for="(visitsItem, vIndex) in store.current_machine.visits"
        :key="'visits_' + visitsItem.uid"
        :style="{
          transform: `translateX(-${vIndex * 25}%)`,
        }"
      >
        <HeadPortrait
          width="100%"
          height="100%"
          :ae_id="visitsItem.select_title"
          ae
          fe
          :src="visitsItem.head ? visitsItem.head : visitsItem.head_frame"
        />
      </div>
    </div>

    <TopInfo v-if="!store.gameIsWatch && isReady && ui_control_show" />

    <!-- 坐下-- -->
    <Seats
      v-if="
        store.gameIsWatch && isReady && store.current_machine.occupy.length == 0
      "
    />

    <!-- 控制台 -->
    <div class="mxt_control_wrapper" v-if="!store.gameIsWatch && isReady">
      <div class="mxt_control_left">
        <div class="mxt_coin_wrapper">
          <div
            :class="
              mxt_funtion_btns.coin2 && !store.await_up_coin_result
                ? 'mxt_coin_btn_active2'
                : 'mxt_coin_btn2'
            "
            @click="handleMxtCoinBtnEvent($event, 1)"
          ></div>
        </div>
      </div>
      <div class="mxt_control_center">
        <div class="mxt_auto_wrapper">
          <div
            :class="mxt_funtion_btns.autoCoin ? 'mxt_auto_active' : 'mxt_auto'"
            @click="handleMxtAuto"
          ></div>
        </div>
      </div>
      <div class="mxt_control_right">
        <div class="mxt_coin_wrapper">
          <div
            :class="
              mxt_funtion_btns.coin && !store.await_up_coin_result
                ? 'mxt_coin_btn_active'
                : 'mxt_coin_btn'
            "
            @click="handleMxtCoinBtnEvent($event, 10)"
          ></div>
        </div>
      </div>
    </div>

    <!-- 马戏团高分提示 -->
    <transition name="van-fade">
      <div class="super_lottery_hint" v-show="store.mxtSuperGoloHint">
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          block
          :src="super_gold"
        />
      </div>
    </transition>

    <!-- 马戏团赚的币提示 -->
    <div class="mxt_hint_wrapper">
      <div
        class="mxt_hint_item"
        v-for="item in store.mxtHintDataList"
        :key="item.key"
      >
        +{{ item.lottery }}
      </div>
    </div>

    <!-- 最后几秒倒计时 v-if="mxtNoOperationBackCount <= 6" -->
    <div class="count_down_time_wrapper">
      <div
        class="count_down_time_img"
        v-for="(
          count_down_time_item, count_down_time_index
        ) in count_down_time_arr"
      >
        <transition name="van-fade">
          <van-image
            v-show="mxtNoOperationBackCount - 1 == count_down_time_index"
            width="100%"
            height="auto"
            fit="fill"
            position="center"
            block
            :src="count_down_time_item"
          />
        </transition>
      </div>
    </div>

    <!-- 消息通知 -->
    <MyNoticeBar :top="20" left="auto" right="auto" />

    <Sprites
      :right="15"
      :top="200"
      :bg_x="-52"
      @_click="collectCardShowToggle(true)"
      v-show="
        ui_control_show &&
        (store.current_machine.product_name == 'mxt' ||
          store.current_machine.product_name == 'msqy')
      "
    />
    <Sprites
      :right="15"
      :top="260"
      :bg_x="-104"
      @_click="rankPupShowToggle(true)"
      v-show="
        ui_control_show &&
        (store.current_machine.product_name == 'mxt' ||
          store.current_machine.product_name == 'msqy')
      "
    />

    <!-- 马戏团无操作返回 -->
    <div
      class="operation_timer_back_wrapper"
      v-if="!store.gameIsWatch && isReady"
    >
      <van-circle
        :style="{
          position: 'relative',
          zIndex: '2',
        }"
        v-model:current-rate="currentRate"
        rate="100"
        stroke-width="100"
        color="#A1EF5A"
        layer-color="#757A71"
        size="100%"
        text=""
      />
      <div class="operation_timer_bg"></div>
      <div class="operation_timer">
        <span class="timer_number">{{ mxtNoOperationBackCount }}s</span>
      </div>
    </div>

    <!-- 收集提示 -->
    <CollectHint />

    <!-- 推币任务 -->
    <transition name="van-fade">
      <div
        class="norem_task_icon_wrapper"
        v-show="
          !taskPupShow &&
          !store.gameIsWatch &&
          ui_control_show &&
          (store.current_machine.product_name == 'mxt' ||
            store.current_machine.product_name == 'msqy')
        "
        @click="taskPupShowChange($event, true)"
      >
        <div class="task_hot_icon" v-if="store.gameTaskData.getCount > 0">
          {{ store.gameTaskData.getCount }}
        </div>
      </div>
    </transition>

    <transition name="van-slide-right">
      <div class="task_content_wrapper" v-show="taskPupShow && ui_control_show">
        <div class="task_head_title">
          <div class="task_title_icon"></div>
          推币任务
          <div
            class="task_back_icon"
            @click="taskPupShowChange($event, false)"
          ></div>
        </div>
        <div class="task_container">
          <div class="task_all_wrapper">
            <div class="task_all_task_name">
              {{ store.gameTaskData.allTaskName }}
            </div>
            <div
              class="task_all_task_reward"
              v-if="store.gameTaskData.allTaskStatu == 0"
            >
              <span>奖励:</span>
              <div class="reward_icon">
                <van-image
                  width="100%"
                  height="100%"
                  block
                  :src="ticket_icon"
                />
              </div>
              <span>+{{ store.gameTaskData.allTaskReward }}</span>
            </div>

            <div
              class="task_all_task_get"
              v-else-if="store.gameTaskData.allTaskStatu == 1"
              @click="getGameViewTaskDone(store.gameTaskData.allTaskIndex)"
            >
              领取
            </div>

            <div class="task_all_task_done" v-else>已领取</div>
          </div>

          <div class="task_list_wrapper">
            <div
              class="task_list_item"
              v-for="taskItem in store.gameTaskData.taskList"
            >
              <div class="task_name">{{ taskItem.taskName }}</div>
              <div class="task_reward">
                <span>奖励:</span>
                <div class="reward_icon">
                  <van-image
                    width="100%"
                    height="100%"
                    block
                    :src="ticket_icon"
                  />
                </div>
                <span>+{{ taskItem.reward }}</span>
              </div>
              <div class="task_btn_wrapper">
                <div class="task_num" v-if="taskItem.statu === 0">
                  {{ `${taskItem.currentNumer}/${taskItem.complete}` }}
                </div>
                <div
                  class="task_get_btn"
                  v-else-if="taskItem.statu === 1"
                  @click="getGameViewTaskDone(taskItem.taskIndex)"
                >
                  领取
                </div>
                <div class="task_done" v-else>已领取</div>
              </div>
            </div>
            <div class="task_footer">任务每天0点开始重新计算</div>
          </div>
        </div>
      </div>
    </transition>

    <WheelEntrance :right="15" :top="320" v-show="ui_control_show" />
    <!-- 雨刷 -->
    <div
      class="norem_wiper_btn_wrapper"
      @click="handleMxtWiper"
      v-if="isReady && !store.gameIsWatch"
    >
      <div
        :class="[
          'wiper_icon',
          mxt_funtion_btns.wiper && 'wiper_active_animation',
        ]"
      ></div>
    </div>

    <!-- 设置自动投币速度 -->
    <van-popup
      v-model:show="setAutoSpeedShow"
      round
      :close-on-click-overlay="false"
    >
      <div class="logout_wrapper">
        <p class="logout_title">设置自动投币速度</p>
        <div class="logout_content">
          <div class="number_s">
            <div
              class="number_item"
              v-for="(autoSpeedItem, autoSpeedIndex) in autoSpeedList"
              @click="controlProgress(autoSpeedIndex + 1)"
            >
              {{ autoSpeedIndex + 1 }}次/秒
            </div>
          </div>

          <div
            class="number_bar_wrapper"
            ref="bar_wrappper"
            @touchstart="handleSliderTouchStart"
            @touchmove="handleSliderTouchMove"
            @touchend="handleSliderTouchEnd"
          >
            <div class="slider_active_bar" ref="s_active">
              <div class="slider_block" ref="s_block"></div>
            </div>
            <div class="speed_wrapper">
              <div
                class="speed_item"
                v-for="(autoSpeedItem, autoSpeedIndex) in autoSpeedList"
                ref="speed_item_d"
                @click="controlProgress(autoSpeedIndex + 1)"
              ></div>
            </div>
          </div>
        </div>
        <div class="logout_btns">
          <div
            class="logout_btn"
            @click="
              () => {
                setAutoSpeedShow = false;
              }
            "
          >
            取消
          </div>
          <div
            class="logout_btn"
            style="
              background-image: linear-gradient(
                90deg,
                #ed81ff 0%,
                #5034ff 100%
              );
            "
            @click="confirmStartAuto"
          >
            开启自动
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 收集 待优化的组件 -->
    <CollectCardPup
      :pupshow="collectCardShow"
      :game_name="store.current_machine.product_name"
      :islandscape="false"
      @pup-close-icon-click="collectCardShowToggle(false)"
    />
    <!-- 排行 待优化的组件 -->
    <PumpkinPup
      :pupshow="rankPupShow"
      :islandscape="false"
      @close-icon-click="rankPupShowToggle(false)"
    />
  </div>
</template>

<script setup>
import GameLoding from "@/components/GameLoding.vue";
import Player from "@/components/Player.vue";
import MenuLc from "@/components/MenuLc.vue";
import WatchTime from "@/components/WatchTime.vue";
import GameRoom from "@/components/GameRoom.vue";
import HeadPortrait from "@/components/HeadPortrait.vue";
import Seats from "@/components/Seats.vue";
import MyNoticeBar from "@/components/MyNoticeBar.vue";
import TopInfo from "@/components/TopInfo.vue";
import WheelEntrance from "@/components/WheelEntrance.vue";
import Sprites from "@/components/Sprites.vue";
import CollectCardPup from "@/components/CollectCardPup.vue";
import PumpkinPup from "@/components/PumpkinPup.vue";
import CollectHint from "@/components/CollectHint.vue";
import UserWallet from "@/components/UserWallet.vue";
import ServerTime from "@/components/ServerTime.vue";

import { controlRequest } from "@/api";
import { sendInstructions, pollingGameinfo } from "@/api/sendData";
import { useStore } from "@/store/store";
import { useRouter } from "vue-router";
import { decodeBase64String } from "@/utils/utils";
import { showToast } from "vant";
import {
  ref,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  watch,
  computed,
  onMounted,
} from "vue";

const router = useRouter();
const store = useStore();

const super_gold = store.imgBaseUrl + "/mxt/super_gold.gif";

const count_down_time_1 = store.imgBaseUrl + "/mxt/count_down_time_1.png";
const count_down_time_2 = store.imgBaseUrl + "/mxt/count_down_time_2.png";
const count_down_time_3 = store.imgBaseUrl + "/mxt/count_down_time_3.png";
const count_down_time_4 = store.imgBaseUrl + "/mxt/count_down_time_4.png";
const count_down_time_5 = store.imgBaseUrl + "/mxt/count_down_time_5.png";
const count_down_time_6 = store.imgBaseUrl + "/mxt/count_down_time_6.png";

const ticket_icon = store.imgBaseUrl + "/ticket.png";

//#region 播放器
const _Player = ref(null);
const isReady = ref(false);
let Onlookers = false;
const readyChange = (statu) => {
  isReady.value = statu;
  if (statu && !Onlookers) {
    Onlookers = true;
    sendInstructions(
      {
        msg_type: "enter_machine",
        game_name: store.current_machine.product_name,
        machine_index: store.current_machine.machine_index,
      },
      100
    );
  }
  store.channelType == "ios" && _Player.value && _Player.value.videoMuted();
};
const handle_update_player = () => {
  _Player.value?.handlePlayUpdata();
};
onMounted(() => {
  _Player.value && _Player.value.initPlayerVideo(store.current_machine.sp_url);
});
//#endregion

const count_down_time_arr = [
  count_down_time_1,
  count_down_time_2,
  count_down_time_3,
  count_down_time_4,
  count_down_time_5,
  count_down_time_6,
];

//#region 菜单
const menud = ref();
// 声音
const menuSoundClick = (statu) => {
  _Player.value && _Player.value.videoMuted(!statu);
};
// 退出

// 退分/退出观战 协议
const exitGameRequest = (type = "visit") => {
  const backType = type;

  // 退出观战
  if (backType == "visit") {
    const leave_params = {
      msg_type: "leave_machine",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
    };
    sendInstructions(leave_params, 0);
    if (store.channelType == "ios") {
      sendInstructions(leave_params, 50);
      sendInstructions(leave_params, 100);
    }
  }
  // 退分
  if (backType == "exit") {
    // 退分 params
    const params = {
      msg_type: "return_lottery",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
      device_name: store.current_device.device_name,
    };

    // 第一次
    sendInstructions(params, 0);
    // 第二次
    sendInstructions(params, 1000);
    // 第三次
    sendInstructions(params, 1800);
  }
};

// 退出游戏重置
const exitRoom = () => {
  store.initStatu();
  router.replace({
    name: "home",
  });
};

//#endregion

// 退出事件
const handleExitEvent = () => {
  // 观战直接退
  if (store.gameIsWatch) {
    isReady.value && exitGameRequest();
    router.replace("/home");

    return;
  }

  // 结算退出
  exitGameRequest("exit");
  if (store.mxtHintDataNumberCount > 0) {
    menud.value && menud.value.exitPupShowToggle(false);
    window.set_data({
      msg_type: "return_lottery_result",
      info: {
        lottery: store.mxtHintDataNumberCount,
        game_name: store.current_machine.product_name,
        machine_index: store.current_machine.machine_index,
        device_name: store.current_device.device_name,
      },
    });
  } else exitRoom();
};

//#endgion

const mxt_funtion_btns = reactive({
  coin: false,
  coin2: false,
  autoCoin: false,
  wiper: false,
});

// 马戏团无操作返回
const mxtNoOperationBackCount = ref(90);
const MxtBackTime = 90; // 无操作的时间

// 雨刷

const handleMxtWiper = (evt) => {
  const evtType = evt.type;
  evt && evt.preventDefault();

  if (mxt_funtion_btns.wiper) return;

  mxt_funtion_btns.wiper = true;
  const params = {
    device: store.current_device.device_name,
    product_id: store.current_machine.product_id,
    channel: 5,
    type: 2,
    times: 1,
    width: 50,
    interval: 100,
    uid: parseInt(store.userInfo.uid),
  };

  setTimeout(() => {
    mxt_funtion_btns.wiper = false;
  }, 2100);

  controlRequest(params);
};

// 自动
let mxtAutoCoinTimer = null;
const setAutoSpeedShow = ref(false);
const autoSpeed = ref(1);
const autoSpeedList = [
  {
    speed: 1000,
  },
  {
    speed: 500,
  },
  {
    speed: 300,
  },
];

// 处理自动
const handleMxtAuto = () => {
  if (!mxt_funtion_btns.autoCoin) {
    setAutoSpeedShow.value = true;
    return;
  }

  clearInterval(mxtAutoCoinTimer);

  mxt_funtion_btns.autoCoin = false;
};

// 投币
const handleMxtCoinBtnEvent = (evt, num) => {
  const evtType = evt.type;
  const params = {
    msg_type: "up_coin",
    machine_index: store.current_machine.machine_index,
    game_name: store.current_machine.product_name,
    device_name: store.current_device.device_name,
    up_num: num,
  };

  let btnLockTime = num == 1 ? 1000 : 3500;

  if (store.await_up_coin_result) return;

  if (num == 1 && mxt_funtion_btns.coin2) return;
  if (num == 10 && mxt_funtion_btns.coin) return;

  num == 1 ? (mxt_funtion_btns.coin2 = true) : (mxt_funtion_btns.coin = true);

  store.$patch((state) => {
    state.await_up_coin_result = true;
  });

  setTimeout(() => {
    mxt_funtion_btns.coin == true && (mxt_funtion_btns.coin = false);
    mxt_funtion_btns.coin2 == true && (mxt_funtion_btns.coin2 = false);
  }, btnLockTime);

  setTimeout(() => {
    store.await_up_coin_result && (store.await_up_coin_result = false);
  }, 5000);

  if (mxt_funtion_btns.autoCoin) {
    mxt_funtion_btns.autoCoin = false;
    mxtAutoCoinTimer != null && clearInterval(mxtAutoCoinTimer);
  }

  mxtNoOperationBackCountReset();
  if (
    Number(store.userInfo.gold) >=
    Number(store.current_machine.up_coin) * num
  ) {
    // 上分
    sendInstructions(params, 0);
  } else {
    mxt_funtion_btns.autoCoin = false;
    mxtAutoCoinTimer != null && clearInterval(mxtAutoCoinTimer);
    showToast({
      type: "fail",
      message: "币量不足,请充值。",
      onClose() {
        store.$patch((state) => {
          state.await_up_coin_result = false;
        });
        store.payPupToggle(true);
      },
    });
  }
};

// 控制进度  自动投币的进度条
const s_block = ref();
const s_active = ref();
const bar_wrappper = ref();
const speed_item_d = ref();
const controlProgress = (index) => {
  if (!s_block.value || !s_active.value || !bar_wrappper.value) return;
  let _w = 0;
  if (index == 1) _w = 0;
  if (index == 2) _w = 50;
  if (index == 3) _w = 100;

  s_active.value.style.width = `${_w}%`;

  autoSpeed.value = index;
};

let isCorrectTarget = false;
let silderStartX = 0;
let silderEndX = 0;
const handleSliderTouchStart = (evt) => {
  const target = evt.target;
  isCorrectTarget =
    target.classList.contains("slider_block") ||
    target.className.indexOf("slider_block") > -1;

  if (
    speed_item_d.value &&
    (evt.target.classList.contains("slider_active_bar") ||
      target.className.indexOf("slider_active_bar") > -1)
  ) {
    const cX = evt.touches[0].clientX;
    speed_item_d.value.some((dom, domIndex) => {
      const { x, width } = dom.getBoundingClientRect();
      const sX = x;
      const eX = sX + width;
      if (cX >= sX && cX < eX) {
        controlProgress(domIndex + 1);
        return true;
      } else return false;
    });
  }

  if (!isCorrectTarget) return;
  silderStartX = evt.touches[0].clientX;
};
const handleSliderTouchMove = (evt) => {
  if (!isCorrectTarget) return;
  silderEndX = evt.touches[0].clientX - silderStartX;
};
const handleSliderTouchEnd = (evt) => {
  if (!isCorrectTarget) return;

  if (silderEndX > 25) {
    controlProgress(autoSpeed.value == 1 ? 2 : 3);
  } else if (silderEndX < -25) {
    controlProgress(autoSpeed.value == 3 ? 2 : 1);
  }
};

// 确认开启自动
let oneSettimeout = true;
const confirmStartAuto = () => {
  setAutoSpeedShow.value = false;
  mxt_funtion_btns.autoCoin = true;
  mxtAutoCoinTimer != null && clearInterval(mxtAutoCoinTimer);
  mxtAutoCoinTimer = setInterval(() => {
    if (
      Number(store.userInfo.gold) >=
      Number(store.current_machine.up_coin) * autoSpeed.value
    ) {
      // 上分
      const params = {
        msg_type: "up_coin",
        machine_index: store.current_machine.machine_index,
        game_name: store.current_machine.product_name,
        device_name: store.current_device.device_name,
        up_num: autoSpeed.value,
      };

      if (!store.await_up_coin_result) {
        store.$patch((state) => {
          state.await_up_coin_result = true;
        });

        sendInstructions(params, 0);
        if (oneSettimeout) {
          oneSettimeout = false;
          setTimeout(() => {
            oneSettimeout = true;
            store.await_up_coin_result && (store.await_up_coin_result = false);
          }, 5000);
        }
      }

      // window.set_data({
      //   msg_type: "mxt_return_lottery_result",
      //   info: {
      //     lottery: Math.random() > 0.5 ? 100 : 200,
      //   },
      // });
    } else {
      mxt_funtion_btns.autoCoin = false;
      mxtAutoCoinTimer != null && clearInterval(mxtAutoCoinTimer);
      showToast({
        type: "fail",
        message: "币量不足,请充值。",
        onClose() {
          store.$patch((state) => {
            state.await_up_coin_result = false;
          });
          store.payPupToggle(true);
        },
      });
    }
  }, 1000);
};

watch(
  () => mxt_funtion_btns.autoCoin,
  (newV) => {
    if (newV) {
      mxtNoOperationBackCountReset();
      mxtNoOperationBackCountEnd();
    } else mxtNoOperationBackCountStart();
  }
);

// 监听马戏团退票信息

watch(
  () => store.mxtHintDataList.length,
  (newV) => {
    mxtNoOperationBackCount.value < MxtBackTime &&
      mxtNoOperationBackCountReset();
    // 清除多余数据 虚拟DOM
    if (newV > 10) {
      store.$patch((state) => {
        state.mxtHintDataList = [state.mxtHintDataList[9]];
      });
    }
  }
);

const currentRate = ref(100);

// computed(() => {
//   return ;
// });

let mxtNoOperationBackTimer = null;

const mxtNoOperationBackCountStart = () => {
  mxtNoOperationBackCountEnd();
  mxtNoOperationBackTimer = setInterval(() => {
    mxtNoOperationBackCount.value = mxtNoOperationBackCount.value - 1;

    currentRate.value = parseInt(
      (mxtNoOperationBackCount.value / MxtBackTime) * 100
    );

    if (mxtNoOperationBackCount.value - 1 < 0) {
      handleExitEvent();
      mxtNoOperationBackCountEnd();
    }
  }, 1000);
};

const mxtNoOperationBackCountReset = () => {
  mxtNoOperationBackCount.value = MxtBackTime;
};
const mxtNoOperationBackCountEnd = () => {
  mxtNoOperationBackTimer != null && clearInterval(mxtNoOperationBackTimer);
};

watch(
  () => store.gameIsWatch,
  (newV) => {
    if (!newV) {
      mxtNoOperationBackCountStart();
    }
  }
);

//#region 每日推币任务
const taskPupShow = ref(false);

const taskPupShowChange = (evt, statu) => {
  evt.stopPropagation && evt.stopPropagation();
  evt.preventDefault && evt.preventDefault();
  if (!isReady.value) return;

  statu && store.getGameViewTaskData();
  taskPupShow.value = statu;
};

const getGameViewTaskDone = (index) => {
  sendInstructions(
    {
      msg_type: "get_player_game_task_reward",
      game_name: store.current_machine.product_name,
      index,
    },
    0
  );
};

//#endregion

onBeforeMount(() => {
  store.getGameViewTaskData();
});

onBeforeUnmount(() => {
  mxtAutoCoinTimer != null && clearInterval(mxtAutoCoinTimer);
  mxtNoOperationBackCountEnd();
});

// 全局点击
const golbalClickEvent = (evt) => {
  evt.stopPropagation && evt.stopPropagation();
  evt.preventDefault && evt.preventDefault();
  // mxtNoOperationBackCountReset();

  menud.value && menud.value.menuOpenStatu && menud.value.menuStatuToggle(evt);
  // taskPupShow.value && taskPupShowChange(false);
};

//#region 部分ui控制
const ui_control_show = ref(true);
let ui_flag = false;
const ui_control_show_toggle = () => {
  if (ui_flag) return;
  ui_flag = true;

  ui_control_show.value = !ui_control_show.value;
  setTimeout(() => {
    ui_flag = false;
  }, 5e2);
};

//#endregion

//#region 数据同步
onMounted(() => {
  setTimeout(() => {
    pollingGameinfo({
      game_name: store.current_machine.product_name,
      statu: "start",
    });
  }, 1500);
});

onBeforeUnmount(() => {
  pollingGameinfo({
    game_name: store.current_machine.product_name,
    statu: "close",
  });
});
//#endregion

//#region 收集识别
const collectCardShow = ref(false);
const collectCardShowToggle = (statu) => {
  collectCardShow.value = statu;
};

const rankPupShow = ref(false);
const rankPupShowToggle = (statu) => {
  rankPupShow.value = statu;
};

//#endregion
</script>

<style scoped lang="scss">
.mxt_view_wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .occupy_info_wrapper {
    position: absolute;
    top: 50px;
    left: 15px;
    background-color: rgba($color: #000, $alpha: 0.4);
    border-radius: 40px;
    display: flex;
    .user_portrait {
      width: 50px;
      height: 50px;
    }
    .user_name {
      //   width: ;
      line-height: 50px;
      padding: 0 10px;
    }
  }
}

// 测试元素
.test_box {
  width: 100%;
  padding: 10px;
  position: absolute;
  left: 0;
  bottom: 120px;
  color: 14px;
  text-align: center;
  background-color: rgba($color: #000, $alpha: 0.4);
  display: flex;
  justify-content: center;

  .btne {
    padding-left: 10px;
  }
}

.mxt_control_wrapper {
  position: absolute;
  bottom: 12px;
  left: 0;
  width: 100%;
  height: 84px;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  padding: 0 27px;
  font-weight: bold;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/control/mxt_btns_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .mxt_control_left {
    .mxt_coin_wrapper {
      width: 60px;
      height: 66px;

      .mxt_coin_btn2 {
        width: 100%;
        height: 100%;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/control/upcoin_1.png");
        background-size: 100% 100%;
        color: #8a4e00;
        font-size: 12px;
        text-align: center;
        padding-top: 8px;
        background-repeat: no-repeat;
      }

      .mxt_coin_btn_active2 {
        width: 100%;
        height: 100%;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/control/upcoin_1_active.png");
        background-size: 100% 100%;
        color: #8a4e00;
        font-size: 12px;
        text-align: center;
        padding-top: 12px;
        background-repeat: no-repeat;
      }
    }
  }

  .mxt_control_center {
    .mxt_auto_wrapper {
      width: 165px;
      height: 65px;

      .mxt_auto {
        width: 100%;
        height: 100%;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/control/mxt_btn.png");
        background-size: 100% 100%;
        color: #004661;
        font-size: 20px;
        text-align: center;
        padding-top: 16px;
      }

      .mxt_auto_active {
        width: 100%;
        height: 100%;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/control/mxt_btn_active.png");
        background-size: 100% 100%;
        color: #004661;
        font-size: 20px;
        text-align: center;
        padding-top: 16px;
      }
    }
  }

  .mxt_control_right {
    position: relative;

    .mxt_coin_wrapper {
      width: 60px;
      height: 66px;

      .mxt_coin_btn {
        width: 100%;
        height: 100%;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/control/upcoin_10.png");
        background-size: 100% 100%;
        color: #8a4e00;
        font-size: 12px;
        text-align: center;
        padding-top: 8px;
        background-repeat: no-repeat;
      }

      .mxt_coin_btn_active {
        width: 100%;
        height: 100%;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/control/upcoin_10_active.png");
        background-size: 100% 100%;
        color: #8a4e00;
        font-size: 12px;
        text-align: center;
        padding-top: 12px;
        background-repeat: no-repeat;
      }
    }
  }
}

.logout_wrapper {
  width: 90vw;
  padding-top: 20px;
  padding-bottom: 20px;
  // height: 200px;
  background: linear-gradient(180deg, #bbd8f9 0%, #ffffff 58%, #eef6f9 100%);
  text-align: center;

  .logout_title {
    font-size: 22px;
    background: linear-gradient(90deg, #3967ff 0%, #7091ff 44%, #8c85ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 1000;
  }

  .logout_content {
    margin-top: 20px;

    font-size: 16px;
    margin-bottom: 34px;
    padding: 0 26px;

    .number_s {
      width: 100%;
      display: flex;
      .number_item {
        flex: 1;
        color: #6843ff;
        font-size: 14px;
        font-weight: 1000;
        &:nth-child(1) {
          text-align: left;
        }
        &:nth-child(3) {
          text-align: right;
        }
      }
    }

    .number_bar_wrapper {
      position: relative;
      margin-top: 10px;
      width: 100%;
      height: 14px;
      border-radius: 8px;
      background-color: #c8c8fd;
      box-shadow: 0px 0px 4px 1px rgba(41, 9, 172, 0.4);
      // overflow: hidden;

      .slider_active_bar {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 8px;
        width: 0%;
        background-color: #6843ff;
        z-index: 10;
        transition: width 0.5s;
        .slider_block {
          background-color: #d977ff;
          width: 22px;
          height: 22px;
          position: absolute;
          right: 0;
          transform: translateX(50%);
          top: -4px;
          transition: transform 0.4s;
          z-index: 5;
          border-radius: 12px;
        }
      }

      .speed_wrapper {
        position: relative;
        z-index: 3;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        display: flex;
        overflow: hidden;
        .speed_item {
          flex: 1;
        }
      }
    }
  }

  .logout_btns {
    display: flex;
    justify-content: center;
    .logout_btn {
      width: 40%;
      height: 40px;
      background: linear-gradient(180deg, #81c3ff 0%, #5034ff 100%);
      margin-right: 10px;
      line-height: 40px;
      border-radius: 20px;
    }
  }
}

.super_lottery_hint {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 300px;
  z-index: 5;
}

.mxt_hint_wrapper {
  width: 40vw;
  height: 320px;
  position: absolute;
  left: 0;
  bottom: 120px;

  z-index: 3;

  .mxt_hint_item {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0) scale(1);
    display: inline-block;
    color: #fff;
    font-weight: 800;
    font-size: 28px;
    padding: 4px 0px 4px 32px;
    opacity: 0;
    text-shadow: -1px 2px 0px rgba(20, 0, 38, 0.1);
    background: linear-gradient(90deg, #fea431 0%, #fee99e 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: mxt_hint 4s 1;
    // animation-duration: 3s;
    animation-timing-function: ease-in;

    &::before {
      content: "";
      position: absolute;
      left: 2px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/ticket.png");
      background-size: 100% 100%;
    }
  }
}

.operation_timer_back_wrapper {
  position: absolute;
  right: 20px;
  bottom: 132px;
  z-index: 3;
  width: 52px;
  height: 52px;
  padding: 4px;
  // background-color: orange;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/operation_timer_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 50%;

  .operation_timer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    font-size: 16px;
    font-weight: bold;

    .timer_number {
      display: inline-block;
      background: radial-gradient(ellipse, #f5f35b, #f2a03f);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      text-shadow: 2px 2px 4px rgba($color: #9e233f, $alpha: 0.6);
      // text-stroke: 2px #f5f35b;
      // -webkit-text-stroke: 2px #f5f35b;
    }
  }

  .operation_timer_bg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80%;
    height: 80%;
    background-color: #9843e5;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 1;
  }
}

// 倒计时
.count_down_time_wrapper {
  position: absolute;
  left: 50%;
  top: 42%;
  width: 190px;
  height: 190px;
  transform: translate(-50%, -50%);
  z-index: 3;
  .count_down_time_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

// 围观者
.visits_list_wrapper {
  // display: flex;
  z-index: 4;
  position: absolute;
  top: 92px;
  right: 15px;
  // width: 110px;
  height: 26px;
  padding: 0;
  margin: 0;
  display: flex;

  .visits_text_wrapper {
    height: 100%;
    line-height: 26px;
  }
  .visit_item_user_header {
    width: 26px;
    height: 100%;
    border-radius: 50%;
  }
}

// 推币任务
.norem_task_icon_wrapper {
  position: absolute;
  right: 15px;
  top: 140px;
  width: 52px;
  height: 52px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/collect_icons.png");
  background-size: auto 52px;

  z-index: 6;
  .task_hot_icon {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 14px;
    height: 14px;
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    background-color: #fc0000;
    border-radius: 50%;
  }

  .task_icon {
    width: 34px;
    height: 34px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/task_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    //
  }

  .task_icon_animation {
    // animation: task_hint_shake 0.3s infinite;
    animation-name: task_hint_shake;

    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    // animation-direction: alternate;
    // animation-play-state: running;
  }

  .task_icon_name {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // transform: translate(-5%, 110%);
    // width: 110%;
    height: 14px;
    line-height: 14px;
    font-size: 8px;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    box-shadow: inset 0px 0px 1px 1px #7d22c6;
    border-radius: 10px;
    white-space: nowrap;
    text-wrap: nowrap;
    // border: 1px solid;
    // border-image: linear-gradient(
    //     180deg,
    //     rgba(215, 91, 192, 1),
    //     rgba(141, 89, 223, 1)
    //   )
    //   1 1;
  }
}

.norem_wiper_btn_wrapper {
  position: absolute;
  top: 390px;
  right: 15px;
  width: 52px;
  height: 52px;
  z-index: 2;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/wiper_icon.png");
  background-size: auto 52px;
  background-repeat: no-repeat;
  .wiper_icon {
    position: absolute;
    left: 50%;
    bottom: 10px;
    width: 8px;
    height: 40px;
    background-size: 100%;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/control/wiper_icon.png");
    background-repeat: no-repeat;
    transform-origin: bottom center;
    transform: rotate(-30deg) translateX(-50%);
    // transition: transform 1s;
  }

  .wiper_active_animation {
    animation: wiper_active_animation 2s 1;
  }
}

.task_content_wrapper {
  position: absolute;
  right: 15px;
  top: 21.5892vh;
  width: 224px;
  z-index: 6;

  .task_head_title {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background: linear-gradient(79deg, #ffb757 0%, #fed6a2 100%);
    height: 24px;
    line-height: 24px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8);

    padding-left: 42px;

    position: relative;

    .task_title_icon {
      position: absolute;
      top: -10px;
      left: 8px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/task_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 34px;
      height: 34px;
    }
    .task_back_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      background-image: url("@/assets/images/task_pup_back_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 22px;
      height: 22px;
    }
  }

  .task_container {
    background-color: rgba($color: #502cb5, $alpha: 0.6);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: hidden;

    .task_all_wrapper {
      background-color: #6a66de;
      height: 34px;
      line-height: 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 6px;
      .task_all_task_reward {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fee22a;
        .reward_icon {
          width: 16px;
          height: 16px;
        }
      }

      .task_all_task_get {
        width: 66px;
        height: 20px;
        border-radius: 10px;
        background: linear-gradient(90deg, #ffd884 0%, #ffc018 100%);
        color: #280923;
        font-size: 12px;
        text-shadow: none;
        height: 20px;
        text-align: center;
        line-height: 20px;
      }
      .task_all_task_done {
        color: #fee22a;
      }
    }

    .task_list_wrapper {
      // max-height: 276px;
      // overflow-y: auto;
      // overflow: auto;
      padding: 0 4px;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8);
      &::-webkit-scrollbar {
        width: 0;
      }
      .task_list_item {
        display: flex;
        background-color: rgba($color: #4f5b90, $alpha: 0.8);
        padding: 8px 0;

        margin-top: 6px;

        .task_name {
          width: 50px;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .task_reward {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: start;
          color: #fee22a;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0 5px;
          .reward_icon {
            width: 16px;
            height: 16px;
            margin: 0 2px;
          }
        }

        .task_btn_wrapper {
          width: 66px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          .task_num {
            background-color: #5048a3;
            border-radius: 6px;
            height: 20px;
          }

          .task_get_btn {
            border-radius: 10px;
            background: linear-gradient(90deg, #ffd884 0%, #ffc018 100%);
            color: #280923;
            font-size: 12px;
            text-shadow: none;
            height: 20px;
          }

          .task_done {
            height: 20px;
            color: #fee22a;
          }
        }
      }

      .task_footer {
        height: 30px;
        line-height: 30px;
        text-align: center;
      }
    }
  }
}

@keyframes task_hint_shake {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes task_hint_shake {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes wiper_active_animation {
  0% {
    transform: translateX(-50%) rotate(-30deg);
  }

  50% {
    transform: translateX(-50%) rotate(30deg);
  }

  100% {
    transform: translateX(-50%) rotate(-30deg);
  }
}

@-webkit-keyframes wiper_active_animation

/* Safari 与 Chrome */ {
  0% {
    transform: translateX(-50%) rotate(-30deg);
  }

  50% {
    transform: translateX(-50%) rotate(30deg);
  }

  100% {
    transform: translateX(-50%) rotate(-30deg);
  }
}

@keyframes mxt_hint {
  0% {
    transform: translate(-50%, 0) scale(1);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }

  20% {
    transform: translate(-50%, -100%) scale(1);
  }

  35% {
    transform: translate(-50%, -210%) scale(1.4);
  }

  50% {
    transform: translate(-50%, -300%) scale(1);
    opacity: 0.8;
  }

  100% {
    transform: translate(-50%, -600%) scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes mxt_hint

/* Safari 与 Chrome */ {
  0% {
    transform: translate(-50%, 0) scale(1);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }

  20% {
    transform: translate(-50%, -100%) scale(1);
  }

  35% {
    transform: translate(-50%, -210%) scale(1.4);
  }

  50% {
    transform: translate(-50%, -300%) scale(1);
    opacity: 0.8;
  }

  100% {
    transform: translate(-50%, -600%) scale(1);
    opacity: 0;
  }
}
</style>
