<template>
  <div
    class="mary_view"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <NavBar title="" />
    <div class="user_info_wrapper">
      <div class="user_info">
        <div class="info">
          <div class="head">
            <HeadPortrait
              :level="Number(store.userInfo.recharge_level)"
              :src="store.userInfo.headimgurl"
              width="100%"
              height="100%"
              :ae_id="store.userInfo.select_title"
              ae
              fe
            />
          </div>
          <div class="user_name">{{ store.userInfo.player_name }}</div>
        </div>
        <div class="lottery_wrapper">
          {{ addCommas(store.userInfo.lottery) }}
        </div>
        <div class="gold_wrapper">
          {{ addCommas(store.userInfo.gold) }}
        </div>
      </div>
    </div>

    <div class="game_outer_wrapper">
      <div class="game_wrapper">
        <div class="outer_layer_light">
          <div class="outer_layer_light1"></div>
          <div class="outer_layer_light2"></div>
        </div>

        <div class="win_notify_log_wrapper">
          <div class="notify_icon"></div>
          <div class="notify_list">
            <van-swipe
              style="height: 28px"
              vertical
              :autoplay="3000"
              :show-indicators="false"
            >
              <van-swipe-item v-for="noticeItem in maryNotify">
                <div class="notify_list_item">
                  恭喜
                  <span style="color: #ffdf7c">{{ noticeItem.uesrName }} </span>
                  获得了
                  <span style="color: #ffdf7c">{{ noticeItem.text }}</span>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>

        <van-image
          class="game_logo"
          width="132"
          height="auto"
          fit="fill"
          :show-loading="false"
          :src="game_logo"
        />

        <div class="spin_wrapper">
          <div class="spin_wrapper_light_1" v-show="starting"></div>
          <div class="spin_wrapper_light_2" v-show="starting"></div>
          <div
            class="spin_item"
            v-for="(spinItem, spinIndex) in spinDataList"
            :style="{
              width: spinItem.style.width + 'rem',
              height: spinItem.style.height + 'rem',
              left: spinItem.style.left + 'rem',
              top: spinItem.style.top + 'rem',
            }"
            :key="spinItem.normal_reward_index"
          >
            <div class="spin_icon">
              <van-image
                width="100%"
                height="100%"
                fit="fill"
                :src="spinItem.iconUrl"
                :show-loading="false"
              />
            </div>

            <div
              class="select_boder_box"
              v-show="winIndexs.includes(spinIndex) || spinIndex == selectIndex"
            ></div>
            <div
              class="win_spin_bg"
              v-show="winIndexs.includes(spinIndex) || spinIndex == selectIndex"
            ></div>
          </div>

          <div class="spin_centent_wrapper">
            <van-image
              width="100%"
              height="auto"
              fit="fill"
              :src="endothecium_light"
              :show-loading="false"
            />
            <div class="centent_light_1" v-show="starting">
              <van-image
                width="100%"
                height="auto"
                fit="fill"
                :src="endothecium_light_2"
                :show-loading="false"
              />
            </div>
            <div class="centent_light_2" v-show="starting">
              <van-image
                width="100%"
                height="auto"
                fit="fill"
                :src="endothecium_light_3"
                :show-loading="false"
              />
            </div>
            <div
              :class="['centent_pp_nomo', starting && 'centent_pp_nomo_active']"
            ></div>
            <van-image
              v-if="!starting"
              class="dialog_box"
              width="90"
              height="auto"
              fit="fill"
              :show-loading="false"
              :src="dialog_good_luck"
            />
          </div>
        </div>

        <div
          :class="['start_btn', starting && 'start_btn_active']"
          @click="start"
        ></div>

        <div class="play_num">
          <div class="play_num_value">{{ playerMaryData.free_num }}</div>
        </div>

        <div class="win_log_btn" @click="winLogShowToggle(true)"></div>
      </div>

      <div class="container">
        <div class="rule_wrapper">
          <div class="rule_title">
            活动时间:
            <span class="active_time"
              >{{ mary_start_time }} - {{ mary_end_time }}</span
            >
          </div>

          <div class="rule_content">
            每天累计充值金额满100元，获得一次幸运小玛莉机会。
            例如：当日分别充值58元礼包、68元礼包，则累计充值满126元，获得一次幸运小玛丽机会。当日一次充值218元礼包，获得两次幸运小玛丽机会。
            <br />
            奖项说明：
            <br />
            当跑灯停止在+1次奖项位置，则游戏会进行一次射灯，获取一个奖项。同时，获得游戏次数加1次。
            <br />
            当跑灯停止在财神位置时，则开启射灯玩法，随机产生2-4个奖项
          </div>
        </div>
      </div>
    </div>

    <van-popup
      v-model:show="resultShow"
      style="background: none; max-width: 100vw; min-width: 100vw"
      round
      :close-on-click-overlay="false"
      :duration="0"
    >
      <div class="result_wrapper">
        <div class="win_reult_list">
          <div class="win_reult_item" v-for="winItem in spinResult">
            <van-image
              width="100%"
              height="auto"
              fit="fill"
              :show-loading="false"
              :src="winItem.iconUrl"
            />
          </div>
        </div>

        <div class="bottom_btns">
          <div class="result_btn" @click="reset"></div>
          <div class="again_btn" @click="again"></div>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model:show="winLogShow"
      style="background: none"
      round
      :close-on-click-overlay="false"
    >
      <div class="win_log_wrapper">
        <div class="log_title">
          <div class="log_icon"></div>
          <span class="title">中奖记录</span>
          <div class="close_icon" @click="winLogShowToggle(false)"></div>
        </div>
        <div class="log_list">
          <div
            class="log_item"
            v-for="logItem in playerWinLog"
            :key="logItem.key"
          >
            <div class="reward">
              <div class="reward_item" v-for="rewardItem in logItem.reward">
                <van-image
                  width="100%"
                  height="auto"
                  fit="fill"
                  :show-loading="false"
                  :src="rewardItem"
                />
              </div>
            </div>
            <div class="time">{{ logItem.time }}</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import { asyncSendInstructions, sendInstructions } from "@/api/sendData";
import {
  onBeforeMount,
  reactive,
  ref,
  onMounted,
  onBeforeUnmount,
  watch,
} from "vue";
import { useStore } from "@/store/store";
import { addCommas, decodeBase64String, formatTimestamp } from "@/utils/utils";
import { showToast, closeToast } from "vant";

import { useRouter, useRoute } from "vue-router";

import HeadPortrait from "@/components/HeadPortrait.vue";
import { Howl, Howler } from "howler";
import Level from "@/components/Level.vue";

// import win_item_bg from "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/win_item_bg.png";
import maryBgMusicMp3 from "@/assets/audios/mary_bg_music.mp3";
const game_logo =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/game_logo.png";
const dialog_good_luck =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/dialog_good_luck.png";
const endothecium_light =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/endothecium_light.png";
const endothecium_light_2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/endothecium_light_2.png";
const endothecium_light_3 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/endothecium_light_3.png";
const spinItemImg_1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spinItemImg_1.png";
const spinItemImg_2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spinItemImg_2.png";
const spinItemImg_3 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spinItemImg_3.png";
const spinItemImg_4 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spinItemImg_4.png";
const spinItemImg_5 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spinItemImg_5.png";
const spinItemImg_6 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spinItemImg_6.png";
const spinItemImg_7 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spinItemImg_7.png";
const spinItemImg_8 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spinItemImg_8.png";
const spinItemImg_9 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spinItemImg_9.png";
const spinItemImg_10 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spinItemImg_10.png";
const spinItemImg_11 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spinItemImg_11.png";

const store = useStore();
const router = useRouter();
const route = useRoute();

const mary_start_time = ref("");
const mary_end_time = ref("");

mary_start_time.value = formatTimestamp(
  route.query.start_time,
  "mm-dd hh:mi:ss"
);

mary_end_time.value = formatTimestamp(route.query.end_time, "mm-dd hh:mi:ss");

//#region 音乐音效

import mary_start from "@/assets/audios/mary_start.mp3";
import mary_light from "@/assets/audios/mary_light.mp3";
import mary_light_cc from "@/assets/audios/mary_light_cc.mp3";

import super_gold_hint from "@/assets/audios/super_gold_hint.mp3";

const maryStartSound = new Howl({
  src: [mary_start],
  autoplay: false,
  loop: false,
});
const maryLightSound = new Howl({
  src: [mary_light],
  autoplay: false,
  loop: false,
});
const maryCcSound = new Howl({
  src: [mary_light_cc],
  autoplay: false,
  loop: false,
});
const resultSound = new Howl({
  src: [super_gold_hint],
  autoplay: false,
  loop: false,
});

const bgSound = new Howl({
  src: [maryBgMusicMp3],
  autoplay: true,
  loop: true,
  volume: 1,
  onplayerror() {
    bgSound.once("unlock", function () {
      bgSound.play();
    });
  },
});

//#endregion

//#region 游戏
const spinDataList = ref([]);
const playerMaryData = reactive({
  free_num: 0,
  recharge: 0,
  total_recharge: 0,
  uid: -1,
});

const coveImg = {
  1: spinItemImg_1,
  2: spinItemImg_2,
  3: spinItemImg_3,
  4: spinItemImg_4,
  5: spinItemImg_5,
  6: spinItemImg_6,
  7: spinItemImg_7,
  8: spinItemImg_8,
  9: spinItemImg_9,
  10: spinItemImg_10,
  11: spinItemImg_11,
  12: spinItemImg_1,
  13: spinItemImg_2,
  14: spinItemImg_3,
  15: spinItemImg_4,
  16: spinItemImg_5,
  17: spinItemImg_6,
  18: spinItemImg_7,
  19: spinItemImg_8,
  20: spinItemImg_9,
};
const resultShow = ref(false);
const selectIndex = ref(0);
const starting = ref(false);
const winIndexs = ref([]);

const spinResult = reactive([]);

const resultShowToggle = (statu) => {
  statu && resultSound.play();

  resultShow.value = statu;
};

const getTargeData = (normal_reward_index) => {
  let _normal_reward_index = Number(normal_reward_index);

  if (normal_reward_index < 10 && Math.random() > 0.45) {
    _normal_reward_index += 11;
  }

  let targeSpin = null;
  let targeIndex = -1;

  const isFind = spinDataList.value.some((spinItem, spinIndex) => {
    if (spinItem.normal_reward_index == _normal_reward_index) {
      targeSpin = spinItem;
      targeIndex = spinIndex;
      return true;
    } else return false;
  });

  if (isFind) {
    return {
      targeSpin,
      targeIndex,
    };
  } else {
    new Error("No corresponding data !");
    return {
      targeSpin,
      targeIndex,
    };
  }
};

// 随机圈数
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const start = async () => {
  if (starting.value) return;

  if (playerMaryData.free_num == 0) {
    store.payPupToggle(true);
    return;
  }

  starting.value = true;

  const resultData = await asyncSendInstructions({
    msg_type: "spin_mali",
  });

  const laps = 3;
  maryStartSound.play();

  const { info, player_mali } = resultData;

  playerMaryData.free_num = player_mali.free_num;
  playerMaryData.total_recharge = player_mali.total_recharge;

  const { targeIndex, targeSpin } = getTargeData(info.normal_reward_index);
  // 普通灯
  if (info.normal_reward_index != 11) {
    spinResult.push(targeSpin);
    await runing(targeIndex, laps);
    await flicker(targeIndex);
    if (info.normal_reward_index == 10) {
      starting.value = false;
      start();
      return;
    }

    setTimeout(() => {
      resultShowToggle(true);
    }, 1000);
    return;
  }
  // 射灯
  await runing(targeIndex, laps);

  maryLightSound.play();
  await flicker(targeIndex);

  if (info.light_info[1]) {
    const { targeIndex, targeSpin } = getTargeData(
      info.light_info[1].light_reward_index
    );
    await runing(targeIndex, 1, 20, false);
    maryCcSound.play();
    await flicker(targeIndex);
    spinResult.push(targeSpin);
  }
  if (info.light_info[2]) {
    const { targeIndex, targeSpin } = getTargeData(
      info.light_info[2].light_reward_index
    );
    await runing(targeIndex, 1, 20, false);
    maryCcSound.play();
    await flicker(targeIndex);
    spinResult.push(targeSpin);
  }
  if (info.light_info[3]) {
    const { targeIndex, targeSpin } = getTargeData(
      info.light_info[3].light_reward_index
    );
    await runing(targeIndex, 1, 20, false);
    maryCcSound.play();
    await flicker(targeIndex);
    spinResult.push(targeSpin);
  }
  if (info.light_info[4]) {
    const { targeIndex, targeSpin } = getTargeData(
      info.light_info[4].light_reward_index
    );
    await runing(targeIndex, 1, 20, false);
    maryCcSound.play();
    await flicker(targeIndex);
    spinResult.push(targeSpin);
  }

  setTimeout(() => {
    resultShowToggle(true);
  }, 1000);
};

const flicker = (index) => {
  let step = 100;
  let couu = 0;
  let lightTimer = null;
  return new Promise((resolve, reject) => {
    function handleLight() {
      couu++;
      selectIndex.value = selectIndex.value == index ? -1 : index;
      if (couu == 10) {
        selectIndex.value = index;
        winIndexs.value.push(index);
        clearInterval(lightTimer);
        resolve();
      }
    }

    lightTimer = setInterval(handleLight, step);
  });
};

const addUserWallet = () => {
  let gold = 0;
  let lottery = 0;
  let num = 0;
  spinResult.forEach((item) => {
    if (item.type == "gold") {
      gold += Number(item.num);
    }

    if (item.type == "lottery") {
      lottery += Number(item.num);
    }

    if (item.type == "add_num") {
      num = Number(item.num);
    }
  });

  if (num > 0 && winIndexs.value.includes(13)) {
    playerMaryData.free_num = playerMaryData.free_num + 1;
  }

  store.$patch((state) => {
    state.userInfo.gold = state.userInfo.gold + gold;
    state.userInfo.lottery = state.userInfo.lottery + lottery;
  });
};

const reset = async () => {
  addUserWallet();
  winIndexs.value = [];
  starting.value = false;
  resultShowToggle(false);

  // await getMaryNotify();
  spinResult.length > 0 && (spinResult.length = 0);
};

let againBtnThrottle = false;
const again = async () => {
  if (againBtnThrottle) return;
  againBtnThrottle = true;
  setTimeout(() => {
    againBtnThrottle = false;
  }, 1000);
  await reset();
  start();
};

const runing = (targe = 0, _laps = 2, _step = 380, normal = true) => {
  let _curLap = 0;
  let runTimer = null;
  let step = _step;

  return new Promise((resolve, reject) => {
    function handleIncrement() {
      selectIndex.value++;
      selectIndex.value > 19 && (selectIndex.value = 0);
      selectIndex.value == targe && _curLap++;

      if (selectIndex.value == targe && _curLap == _laps) {
        clearTimeout(runTimer);

        setTimeout(() => {
          resolve();
        }, 1000);

        return;
      }

      if (_curLap + 1 == _laps && normal) {
        // 最后一圈
        step += 6;
      } else if (step > 20) {
        step = step - 100 <= 20 ? 20 : step - 100;
      }

      clearTimeout(runTimer);
      runTimer = setTimeout(handleIncrement, step);
    }

    runTimer = setTimeout(handleIncrement, step);
  });
};

const createSpinItemElement = (list) => {
  const elementList = [...list];
  const paddingStart = 0.4266;
  const SpinWidth = 9.6 - paddingStart * 2;
  const SpinHeight = 6.8266 - paddingStart * 2;
  const itemW = SpinWidth / 7;
  const itemH = SpinHeight / 5;

  let direction = "right";
  const count = elementList.length;

  let _left = 0;
  let _top = paddingStart;

  for (let i = 0; i < count; i++) {
    elementList[i].style.width = itemW;
    elementList[i].style.height = itemH;
    i == 7 && (direction = "down");
    i == 11 && (direction = "left");
    i == 17 && (direction = "top");
    if (direction == "right" && i < 7) {
      _left = paddingStart + itemW * i;
      elementList[i].style.left = paddingStart + itemW * i;
      elementList[i].style.top = _top;
    }

    if (direction == "down" && i < 11) {
      elementList[i].style.left = _left;
      elementList[i].style.top = _top + (i - 6) * itemH;
      i == 10 && (_top = _top + (i - 6) * itemH);
    }

    if (direction == "left" && i < 18) {
      elementList[i].style.left = _left - itemW * (i - 10);
      elementList[i].style.top = _top;
      i == 17 && (_left = _left - itemW * (i - 10));
    }

    if (direction == "top" && i < 21) {
      elementList[i].style.top = _top - itemH * (i - 16);
      elementList[i].style.left = paddingStart;
    }
  }
  return elementList;
};
const get_player_mali = ref({});

const sortSpin = (list) => {
  const sort = [
    9, 1, 7, 10, 8, 5, 3, 4, 6, 2, 20, 12, 18, 11, 19, 16, 14, 15, 17, 13,
  ];

  const sortSpinList = [];

  sort.forEach((index) => {
    sortSpinList.push(list[index - 1]);
  });

  return sortSpinList;
};

const initGameData = async () => {
  const maryData = await asyncSendInstructions({
    msg_type: "get_player_mali",
  });

  get_player_mali.value = maryData;

  if (maryData.error !== "ok") {
    showToast({
      type: "fail",
      message: "活动已结束",
      duration: 3500,
      onClose() {
        router.back();
      },
    });

    return;
  }

  const { jipan, player_mali } = maryData;
  playerMaryData.free_num = player_mali.free_num;
  playerMaryData.recharge = player_mali.recharge;
  playerMaryData.total_recharge = player_mali.total_recharge;
  playerMaryData.uid = player_mali.uid;

  const normal_reward_indexs = Object.keys(jipan);

  const spinList = [];
  const spinListClone = [];

  normal_reward_indexs.forEach((indexkey) => {
    const _normal_reward_index = Number(indexkey);
    const item = {
      ...jipan[indexkey],
      normal_reward_index: _normal_reward_index,
      iconUrl: coveImg[_normal_reward_index],
      style: {},
    };
    spinList.push(item);
    if (_normal_reward_index != 10 && _normal_reward_index != 11) {
      const cloneItem = JSON.parse(JSON.stringify(item));
      cloneItem.normal_reward_index = cloneItem.normal_reward_index + 11;
      spinListClone.push(cloneItem);
    }
  });

  const concatArr = spinList.concat(spinListClone);

  spinDataList.value = createSpinItemElement(sortSpin(concatArr));
};
//#endregion

//#region  公告和玩家记录
const maryNotify = ref({});

const playerWinLog = ref([]);

const getMaryNotify = async () => {
  const res = await asyncSendInstructions({
    msg_type: "get_sys_notice",
    type: "mali",
  });

  if (res.type != "mali") return;

  if (res.info.length > 0) {
    const initNotfyList = [];
    res.info.forEach((item) => {
      const obj = {
        uesrName: decodeBase64String(item.param.player_name),
        text: "",
      };

      if (item.param.info.normal_reward_index == 11) {
        obj.text = "射灯大奖";
        initNotfyList.push(obj);
        return;
      }

      switch (item.param.info.reward_info.type) {
        case "add_num":
          obj.text = "次数+1";
          break;
        case "gold":
          obj.text = `${item.param.info.reward_info.num} 金币`;
          break;
        case "lottery":
          obj.text = `${item.param.info.reward_info.num} 彩券`;
          break;
        case "jiacheng_card":
          let percentage = parseInt(
            item.param.info.reward_info.num * 100 - 100
          );
          obj.text = `${percentage}% 加成卡`;
          break;
      }

      initNotfyList.push(obj);
    });

    maryNotify.value = initNotfyList;
  }
};

const winLogShow = ref(false);

const winLogShowToggle = (statu) => {
  statu && getPlayerWinLog();

  winLogShow.value = statu;
};

const getPlayerWinLog = async () => {
  const res = await asyncSendInstructions({
    msg_type: "get_player_mali_log",
  });

  const initLog = [];

  res.forEach((item) => {
    const obj = {
      key: item.time,
      time: formatTimestamp(item.time, "mm-dd hh:mi:ss"),
      reward: [],
    };

    if (item.param.info.normal_reward_index != 11) {
      const { targeSpin } = getTargeData(item.param.info.normal_reward_index);
      obj.reward.push(targeSpin.iconUrl);
    }

    if (
      item.param.info.normal_reward_index == 11 ||
      item.param.info.light_info
    ) {
      try {
        for (const key in item.param.info.light_info) {
          let _index = item.param.info.light_info[key].light_reward_index;
          const { targeSpin } = getTargeData(_index);
          obj.reward.push(targeSpin.iconUrl);
        }
      } catch (error) {}
    }

    initLog.unshift(obj);
  });

  playerWinLog.value = initLog;
};

//#endregion

watch(
  () => store.payPupStatu.show,
  (newV) => {
    if (!newV) {
      initGameData();
    }
  }
);

onBeforeMount(async () => {
  await initGameData();
  await getMaryNotify();
});

onMounted(() => {});

onBeforeUnmount(() => {
  Howler.unload();
});
</script>

<style lang="scss" scoped>
.mary_view {
  // width: 100vw;
  min-height: 100vh;
}

.user_info_wrapper {
  padding: 0 15px;
  // height: 28px;
  margin-bottom: 10px;
  .user_info {
    display: flex;
    margin-top: 14px;
    align-items: center;
    .info {
      margin-right: auto;
      display: flex;
      .head {
        width: 28px;
        height: 28px;
      }
      .user_name {
        font-size: 14px;
        padding-left: 8px;
        line-height: 28px;
        font-weight: bold;
      }
    }

    .lottery_wrapper,
    .gold_wrapper {
      position: relative;
      min-width: 70px;
      text-align: right;
      padding: 0 6px 0 22px;
      height: 20px;
      background-color: #00000040;
      border-radius: 10px;
      margin-left: 6px;
      line-height: 20px;
      font-size: 14px;
      font-weight: bold;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -2px;
        width: 20px;
        height: 20px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    .lottery_wrapper {
      &::after {
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/ticket.png");
      }
    }
    .gold_wrapper {
      &::after {
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
      }
    }
  }
}

.game_outer_wrapper {
  padding-top: 6px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/mary_view_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 20px;
}

.game_wrapper {
  margin-top: 20px;
  position: relative;
  height: 360px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/wrapper_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  // background-color: red;

  .win_notify_log_wrapper {
    position: absolute;
    top: 26px;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    height: 28px;
    border-radius: 4px;
    border: 1px solid #ffffff60;
    background-color: rgba($color: #000000, $alpha: 0.7);
    display: flex;
    flex-wrap: nowrap;
    .notify_icon {
      width: 28px;
      height: 28px;
      min-width: 28px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/notify_icon.png");
      background-size: 70% 70%;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .notify_list {
      flex: 1;
      padding-left: 4px;
      .notify_list_item {
        height: 28px;
        line-height: 28px;
        white-space: nowrap;
      }
    }
  }

  .game_logo {
    position: absolute;
    left: 50%;
    top: -10px;
    transform: translate(-50%, -50%);
    z-index: 4;
  }

  .outer_layer_light {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .outer_layer_light1 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/outer_layer_light.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      animation-name: lighting;
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-delay: 0;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
      z-index: 1;
    }
    .outer_layer_light2 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/outer_layer_light_2.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      animation-name: lighting;
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-delay: 0;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      z-index: 1;
    }
  }

  .spin_wrapper {
    position: absolute;
    width: 360px;
    height: 256px;
    left: 8px;
    bottom: 58px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spin_wrapper_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 2;

    .spin_wrapper_light_1 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spin_wrapper_light_1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-size: 100% auto;
      animation-name: lighting;
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-delay: 0;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      z-index: 11;
    }
    .spin_wrapper_light_2 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spin_wrapper_light_2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      animation-name: lighting;
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-delay: 0;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
      z-index: 11;
    }

    .spin_item {
      position: absolute;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/spin_item_bg.png");
      background-size: 100% auto;

      .spin_icon {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 10;
      }

      .select_boder_box {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: scale(1.3);
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/select_box.png");
        background-size: 100% 100%;
        z-index: 10;
      }

      .win_spin_bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/win_item_bg.png");
        background-size: 100% 100%;
      }
    }

    .spin_centent_wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 248px;
      z-index: 2;
      overflow: hidden;

      .centent_light_1 {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 248px;
        height: auto;
        transform: translate(-50%, -50%);
        animation-name: lighting;
        animation-duration: 0.5;
        animation-timing-function: linear;
        animation-delay: 0;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        z-index: 4;
      }
      .centent_light_2 {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 248px;
        height: auto;
        transform: translate(-50%, -50%);
        // background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/endothecium_light_3.png");
        background-size: 100% auto;
        animation-name: lighting;
        animation-duration: 0.5s;
        animation-timing-function: linear;
        animation-delay: 0;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        z-index: 4;
      }
      .centent_pp_nomo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform-origin: left bottom;
        transform: rotate(0) translate(-50%, -50%);
        width: 152px;
        height: 118px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/pp_nomo.png");
        background-size: 100% auto;
        overflow: hidden;
        z-index: -1;
      }

      .dialog_box {
        position: absolute;
        right: 8px;
        top: 12px;
        width: 90px;
        animation-name: good_luck;
        animation-duration: 5s;
        transform-origin: left bottom;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }

      .centent_pp_nomo_active {
        animation-name: pp_nomo;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }
    }
  }

  .start_btn {
    position: absolute;
    right: 36px;
    bottom: 20px;
    width: 80px;
    height: 42px;
    // transform: translateX(-50%);
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/start_btn.png");
    background-size: 100% auto;
    z-index: 10;
  }

  .start_btn_active {
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/start_btn_active.png") !important;
  }

  .play_num {
    position: absolute;
    left: 50%;
    bottom: 20px;
    width: 80px;
    height: 42px;
    transform: translateX(-50%);
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/play_num_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .play_num_value {
      position: absolute;
      left: 0;
      bottom: 6px;
      width: 100%;
      height: 20px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .win_log_btn {
    position: absolute;
    left: 36px;
    bottom: 32px;
    width: 72px;
    height: 22px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/win_log_btn.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: 10;
  }
}

.rule_wrapper {
  position: relative;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/rule_wrapper_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 190px;

  .rule_title {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    font-size: 14px;
    text-align: center;
    .active_time {
      color: #faef85;
    }
  }

  .rule_content {
    position: absolute;
    left: 0%;
    top: 50px;
    font-size: 10px;
    padding: 10px;
  }
}

.result_wrapper {
  // position: absolute;
  // top: 50%;
  // left: 0;
  // transform: translateY(-50%);
  width: 100vw;
  height: 234px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/result_wrapper_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .win_reult_list {
    position: absolute;
    width: 100%;
    left: 0;
    top: 36%;
    padding: 0 10px;
    display: flex;
    justify-content: space-around;

    .win_reult_item {
      width: 76px;
      height: 76px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/resultWinSpin.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .bottom_btns {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-around;
    padding: 0 20px;
    .result_btn {
      width: 144px;
      height: 40px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/result_btn.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .again_btn {
      width: 144px;
      height: 40px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/again_btn.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}

.win_log_wrapper {
  width: 300px;
  height: 60vh;

  border-radius: 8px;

  overflow: hidden;
  .log_title {
    position: relative;
    background-color: #ffcb49;
    height: 40px;
    line-height: 40px;
    text-align: center;
    .log_icon {
      position: absolute;
      left: 10px;
      top: 2px;
      width: 32px;
      height: 36px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/log_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      text-shadow: 1px 2px #00000060;
    }

    .close_icon {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 22px;
      height: 22px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mary/close_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .log_list {
    padding: 10px;
    background-color: rgba($color: #501d95, $alpha: 0.6);
    overflow: auto;
    user-select: auto;
    height: calc(100% - 40px);
    -webkit-user-select: auto;
    -ms-user-select: auto;
    touch-callout: auto;
    -webkit-touch-callout: auto;
    .log_item {
      margin-top: 10px;
      border-radius: 4px;
      background-color: #444878;
      // padding: 10px 4px 10px 20px;
      box-shadow: 2px 1px #00000060;
      display: flex;
      height: 38px;
      padding: 4px;
      justify-content: space-between;
      .time {
        line-height: 30px;
        padding-right: 20px;
      }
      .reward {
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        .reward_item {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

@keyframes pp_nomo {
  0% {
    transform: rotate(-5deg) translate(-50%, -50%);
  }

  100% {
    transform: rotate(5deg) translate(-50%, -50%);
  }
}

@-webkit-keyframes pp_nomo

/* Safari 与 Chrome */ {
  0% {
    transform: rotate(-5deg) translate(-50%, -50%);
  }

  100% {
    transform: rotate(5deg) translate(-50%, -50%);
  }
}

@keyframes good_luck {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes good_luck

/* Safari 与 Chrome */ {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes win_lighting {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes win_lighting

/* Safari 与 Chrome */ {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes lighting {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes lighting

/* Safari 与 Chrome */ {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
