<template>
  <div
    class="head_wrapper"
    :style="{
      width: _w,
      height: _h,
    }"
  >
    <div class="avatar_wrapper">
      <van-image
        width="100%"
        height="100%"
        fit="contain"
        position="center"
        :src="avatarSrc"
        block
        :show-loading="false"
      />
    </div>

    <div class="frame_wrapper" v-if="props.fe && props.level > 3">
      <van-image
        width="100%"
        height="100%"
        fit="contain"
        position="center"
        :src="frameSrc"
        block
        :show-loading="false"
      />
    </div>
    <div
      class="achievement_wrapper"
      v-if="props.ae && props.ae_id && props.ae_id >= 1"
    >
      <van-image
        v-for="(aeidsrc, _id) in aeList"
        width="100%"
        height="100%"
        fit="contain"
        position="center"
        :show-loading="false"
        :src="aeidsrc"
        v-show="aeidsrc != '' && _id == props.ae_id"
        block
      />
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
const store = useStore();
import { propValueUnificationString } from "@/utils/utils";
import { computed } from "vue";

const props = defineProps({
  width: {
    type: [String, Number],
    default: "auto",
  },
  height: {
    type: [String, Number],
    default: "auto",
  },
  src: {
    type: [String, Number],
    default:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/default_avatar_6.png",
  },
  level: {
    type: Number,
    default: 1,
  },
  frame_type: {
    type: String,
    default: "level",
  },
  fe: {
    type: Boolean,
    default: false,
  },
  ae: {
    type: Boolean,
    default: false,
  },
  ae_id: {
    type: [String, Number],
    default: 0,
  },
});

const level_1_frame =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/frame_silver.png";
const level_2_frame =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/frame_gold.png";
const level_3_frame =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/frame_platinum.png";
const level_4_frame =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/frame_diamond.png";
const level_5_frame =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/frame_max.png";
const level_6_frame =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/frame_unparalleled.png";
const level_7_frame =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/grade/frame_glory.png";

const default_avatar_1 =
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/headSculpture/default_avatar_1.png";
const default_avatar_2 =
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/headSculpture/default_avatar_2.png";
const default_avatar_3 =
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/headSculpture/default_avatar_3.png";
const default_avatar_4 =
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/headSculpture/default_avatar_4.png";
const default_avatar_5 =
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/headSculpture/default_avatar_5.png";
const default_avatar_6 =
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/headSculpture/default_avatar_6.png";

const frameArr = [
  level_1_frame,
  level_2_frame,
  level_3_frame,
  level_4_frame,
  level_5_frame,
  level_6_frame,
  level_7_frame,
];

const avatarSrcList = [
  default_avatar_1,
  default_avatar_2,
  default_avatar_3,
  default_avatar_4,
  default_avatar_5,
];

const aeList = {
  0: "",
  1: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_1.png",
  2: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_2.png",
  3: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_3.png",
  4: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_4.png",
  5: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_5.png",
  6: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_6.png",
  7: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_7.png",
  8: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_8.png",
  9: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_9.png",
  10: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_10.png",
  11: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_11.png",
  12: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_12.png",
  13: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_13.png",
  14: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_14.png",
  15: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_15.png",
  16: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_16.png",
  17: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_17.png",
  18: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_18.png",
  19: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_19.png",
  20: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_20.png",
  21: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_21.png",
  22: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_22.png",
  23: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_23.png",
  24: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_24.png",
  25: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_25.png",
  26: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_26.png",
  27: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_27.png",
  28: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/h_28.png",
  98: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_10_frame.png",
  99: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_15_frame.png",
};

const avatarSrc = computed(() => {
  const srcIndex = [1, 2, 3, 4, 5];

  if (
    props.src != "" &&
    !isNaN(Number(props.src)) &&
    srcIndex.includes(Number(props.src))
  )
    return avatarSrcList[Number(props.src) - 1];

  if (props.src != "" && props.src != null && props.src != undefined)
    return props.src;

  return default_avatar_6;
});

const frameSrc = computed(() => {
  let index = 0;

  if (props.frame_type == "tianti") {
    return props.level >= 15
      ? "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_15_frame.png"
      : "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/level/level_10_frame.png";
  }

  // if (props.level <= 3) index = 0;
  if (props.level > 3 && props.level <= 6) index = 0;
  if (props.level > 6 && props.level <= 10) index = 1;
  if (props.level > 10 && props.level <= 14) index = 2;
  if (props.level > 14 && props.level <= 18) index = 3;
  if (props.level == 19) index = 4;
  if (props.level == 20) index = 5;
  if (props.level == 21) index = 6;

  return frameArr[index];
});

const _w = computed(() => propValueUnificationString(props.width));
const _h = computed(() => propValueUnificationString(props.height));
</script>

<style scoped lang="scss">
.head_wrapper {
  position: relative;

  .avatar_wrapper {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }

  .frame_wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .achievement_wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    width: 100%;
    height: 100%;
    z-index: 3;
  }
}

@keyframes cing_hint {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes cing_hint

/* Safari 与 Chrome */ {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
