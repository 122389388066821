<template>
  <div
    class="nav_bar_wrapper"
    :style="{
      position: props.fixed ? 'fixed' : 'relative',
      top: props.fixed ? store.areaInsetTopValue + 'px' : 0,
      left: 0,
    }"
  >
    <div class="nav_bar_back_wrapper" @click="router.back()"></div>
    <div class="nav_bar_text">{{ props.title }}</div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useStore } from "@/store/store";
const store = useStore();
const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  fixed: {
    type: Boolean,
    default: false,
  },
});
const router = useRouter();
</script>

<style lang="scss" scoped>
.nav_bar_wrapper {
  position: relative;
  height: 40px;
  z-index: 999;
  .nav_bar_text {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    // color: #ccecfb;
    font-size: 20px;
    font-weight: bold;
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 0%, #ccecfb 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 1;
    // text-shadow: 0px 4px #002562;
  }

  .nav_bar_back_wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 40px;
    background-size: 100% 100%;
    background-image: url("@/assets/images/navbar_back_icon.png");
    background-repeat: no-repeat;
    z-index: 3;
  }
}
</style>
