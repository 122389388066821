<template>
  <transition name="van-fade">
    <div class="notice_bar_wrapper" :style="pStyle" v-if="nShow">
      <van-config-provider :theme-vars="themeVars">
        <van-notice-bar
          scrollable
          background="none"
          :color="store.send_marquee_config.color"
          :text="store.send_marquee_config.content"
          delay="0"
        />
      </van-config-provider>
    </div>
  </transition>
</template>

<script setup>
import { useStore } from "@/store/store";
import { onBeforeUnmount, ref, computed, nextTick, watch } from "vue";
import { sendInstructions } from "@/api/sendData";
import { propValueUnificationString } from "@/utils/utils";
import { reactive } from "vue";
const store = useStore();

const props = defineProps({
  left: {
    type: [String, Number],
    default: "auto",
  },
  top: {
    type: [String, Number],
    default: "auto",
  },
  right: {
    type: [String, Number],
    default: "auto",
  },
  bottom: {
    type: [String, Number],
    default: "auto",
  },
});

const themeVars = {
  noticeBarFontSize: "12px",
  noticeBarHeight: "30px",
};
const pStyle = reactive({
  top: "50%",
  left: "50%",
  right: "auto",
  transform: "translate(-50%,-50%)",
  opacity: 0,
});

// top: "38px",
// left: "50%",
// right: "auto",
// transform: "translateX(-50%)",

const nShow = computed(() => {
  if (store.isLandscape && store.send_marquee_config.show) {
    nextTick(() => {
      setTimeout(() => {
        pStyle.transform = "translateY(-100%) rotate(90deg)";
        pStyle.top = "44vh";
        pStyle.left = "auto";
        pStyle.right = "-250px";
        pStyle.opacity = 1;
      }, 0);
    });
  } else pStyle.opacity = 1;

  return store.send_marquee_config.show;
});

watch(
  () => store.send_marquee_config.show,
  (newV) => {
    nShow.value = newV;
    if (store.isLandscape && newV) {
    } else if (!newV) {
      pStyle.opacity = 0;
      pStyle.top = "50%";
      pStyle.left = "50%";
      pStyle.right = "auto";
      pStyle.transform = "translate(-50%,-50%)";

      store.$patch((state) => {
        state.send_marquee_config.show = false;
        state.send_marquee_config.content = "";
        state.send_marquee_config.color = "white";
      });
    }
  }
);

onBeforeUnmount(() => {});
</script>

<style lang="scss" scoped>
.notice_bar_wrapper {
  position: absolute;
  z-index: 11;
  width: 250px;
  min-height: 68px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/notice_bar_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  transform-origin: left top;
  padding: 32px 0px 0 0px;

  .content_wrapper {
    overflow: hidden;
    display: flex;
    .slider_text_block {
      background-color: pink;
      text-wrap: nowrap;
      white-space: nowrap;
      line-height: 30px;
    }
  }
}
</style>
