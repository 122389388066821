<template>
  <van-popup
    v-model:show="props.pupshow"
    :style="{
      background: 'none',
      padding: '22px',
    }"
    :close-on-click-overlay="false"
    @open="whenPupOpen"
  >
    <div class="repairs_wrapper">
      <div class="top_title_wrapper">
        <van-image
          width="120px"
          height="auto"
          fit="contain"
          position="center"
          :src="toTitle"
          :show-loading="false"
        />

        <div class="top_title_icon"></div>
        <div class="close_icon" @click="closeIconClick"></div>
      </div>
      <div class="repairs_list_wrapper">
        <div class="repairs_list">
          <van-config-provider :theme-vars="themeVars">
            <van-radio-group v-model="cur_failure">
              <van-cell-group :border="false" inset>
                <van-cell
                  clickable
                  :border="false"
                  v-for="failure_item in _f_list"
                  :key="failure_item.id"
                  :title="failure_item.describe"
                  @click="failureChange(failure_item.id)"
                >
                  <template #right-icon>
                    <van-radio
                      :name="failure_item.id"
                      checked-color="#36E5FE"
                    />
                  </template>
                </van-cell>
              </van-cell-group> </van-radio-group
          ></van-config-provider>
        </div>
      </div>
      <div class="repairs_btn" @click="handleSubmit"></div>
    </div>
  </van-popup>
</template>

<script setup>
import { reactive, ref, onBeforeMount, computed } from "vue";
import { useStore } from "@/store/store";

import { showToast, closeToast } from "vant";

import { addRepairReport } from "@/api";

const props = defineProps({
  pupshow: {
    type: Boolean,
    default: () => false,
  },
});

const emits = defineEmits(["pupClose"]);
const store = useStore();
const toTitle = store.imgBaseUrl + "/repairs_title.png";

const all_list = [
  {
    id: 1,
    describe: "中奖出故障,紧急！谢谢！",
  },
  {
    id: 2,
    describe: "机器异常/卡币",
  },
  {
    id: 3,
    describe: "机器离线/无法上机",
  },
  {
    id: 4,
    describe: "无法正常下机",
  },
  {
    id: 5,
    describe: "投币不出币/不加积分",
  },
  {
    id: 6,
    describe: "视频黑屏/不正",
  },
  {
    id: 7,
    describe: "玻璃污浊,请清洁",
  },

  {
    id: 8,
    describe: "无法添加火药",
  },
  {
    id: 10,
    describe: "其他问题",
  },
];

const _f_list = computed(() => {
  const tbj_names = ["mxt", "bjwg", "bql", "bjl", "msqy", "bbxc", "hdcz"];

  const is_burr = tbj_names.includes(store.current_machine.product_name);

  return is_burr
    ? [
        {
          id: 1,
          describe: "中奖出故障,紧急！谢谢！",
        },
        {
          id: 2,
          describe: "机器异常/卡币",
        },
        {
          id: 3,
          describe: "机器离线/无法上机",
        },
        {
          id: 4,
          describe: "无法正常下机",
        },
        {
          id: 5,
          describe: "投币不出币/不加积分",
        },
        {
          id: 6,
          describe: "视频黑屏/不正",
        },
        {
          id: 7,
          describe: "玻璃污浊,请清洁",
        },
      ]
    : [
        {
          id: 3,
          describe: "机器离线/无法上机",
        },
        {
          id: 8,
          describe: "无法添加火药",
        },
        {
          id: 10,
          describe: "其他问题",
        },
      ];
});

const themeVars = reactive({
  cellGroupInsetPadding: "0",
  cellBackground: "transparent",
  cellGroupBackground: "transparent",
});

const cur_failure = ref(-1);

const failureChange = (id) => {
  console.log("cur_failure????", id);
  cur_failure.value = id;
};

const closeIconClick = () => {
  emits("pupClose");
};

const whenPupOpen = () => {};

// 提交
const handleSubmit = async () => {
  if (cur_failure.value == -1) return;

  const describe = all_list.find(
    (item) => item.id == cur_failure.value
  ).describe;

  const device_index = store.gameIsWatch
    ? ""
    : store.current_device.device_index;

  const params = {
    uid: parseInt(store.userInfo.uid),
    product_name: store.current_machine.product_name,
    machine_index: store.current_machine.machine_index,
    device_index,
    describe,
  };
  showToast({
    type: "loading",
    forbidClick: true,
  });

  try {
    const res = await addRepairReport(params);
    closeToast();
    showToast({
      type: "success",
      message: "提交成功",
      onClose() {
        emits("pupClose");
      },
    });
  } catch (error) {
    closeToast();

    showToast({
      type: "fail",
      message: "提交失败",
    });
  }
};

onBeforeMount(() => {});
</script>

<style lang="scss" scoped>
.repairs_wrapper {
  width: 300px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/repairs_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 16px;
  padding-bottom: 20px;
  .top_title_wrapper {
    padding: 34px 0 22px 0;
    position: relative;
    .top_title_icon {
      position: absolute;
      right: 15px;
      width: 98px;
      // height: calc(100% + 22px);
      height: 98px;
      top: -22px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/repairs_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .close_icon {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
      width: 40px;
      height: 40px;

      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .repairs_list_wrapper {
    border-radius: 10px;
    overflow: hidden;
    .repairs_list {
      padding: 16px 0;
      background: rgba(255, 255, 255, 0.5);
    }
  }

  .repairs_btn {
    width: 164px;
    height: 42px;
    margin: 0 auto;
    margin-top: 16px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/repairs_btn.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
</style>
