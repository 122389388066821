<template>
  <van-popup
    v-model:show="props.tutoShow"
    :position="pupPosition"
    :style="pupStyle"
    @click-overlay="handleClickOverlay"
    round
  >
    <div class="tutorial_wrapper">
      <div class="tutorial_close_icon" @click="handleClickOverlay"></div>
      <div class="tutorial_content">
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          :src="tutoImgeUrl"
          :show-loading="false"
        />
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import { useStore } from "@/store/store";
import { ref, computed } from "vue";

const store = useStore();
const props = defineProps({
  tutoShow: {
    type: Boolean,
    default: () => false,
  },
});
const emits = defineEmits(["handleCloseIconClick", "handleClickOverlay"]);

import findGameInfoData from "@/utils/gameInfoMap";

const pupPosition = computed(() => {
  const arr = [
    "zww",
    "msqy",
    "mxt",
    "vip_tbj",
    "hdcz",
    "bjl",
    "bql",
    "bbxc",
    "bjwg",
  ];
  return arr.includes(store.current_machine.product_name) ? "bottom" : "right";
});

const pupStyle = computed(() => {
  const arr = ["zww", "msqy", "mxt"];
  return arr.includes(store.current_machine.product_name)
    ? {
        background: "none",
        height: "70vh",
      }
    : {
        background: "none",
        width: "37.4812vh",
        height: "100vh",
      };
});

const tutoImgeUrl = computed(() => {
  return findGameInfoData(
    store.current_machine.child_name || store.current_machine.product_name
  ).tuto;
});

const closeIconClose = () => {
  emits("handleCloseIconClick");
};
const handleClickOverlay = () => {
  emits("handleClickOverlay");
};
</script>

<style scoped lang="scss">
//游戏说明
.tutorial_wrapper {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  padding: 58px 18px 30px 18px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/tutor_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  .tutorial_close_icon {
    position: absolute;
    left: 18px;
    top: 18px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    z-index: 999;
  }

  .tutorial_content {
    height: 100%;
    width: 100%;
    overflow: auto;
    // overflow-y: auto;
  }
}
</style>
