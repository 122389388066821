<template>
  <div class="plus-wrapper">
    <div
      class="plus-btn"
      @touchend="handlePower($event)"
      @touchstart="handlePower($event)"
    >
      <van-image
        :show-loading="false"
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        v-show="btnStatu"
        :src="btnUi[store.current_machine.product_name][1]"
      />
      <van-image
        :show-loading="false"
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        v-show="!btnStatu"
        :src="btnUi[store.current_machine.product_name][0]"
      />
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { controlRequest } from "@/api";
import { reactive, ref } from "vue";
const store = useStore();

const mgc_plus_btn = store.imgBaseUrl + "/control/plus_btn.png";
const mgc_plus_btn_active = store.imgBaseUrl + "/control/plus_btn_active.png";

const bbl_plus_btn = store.imgBaseUrl + "/control/bbl_plus_btn.png";
const bbl_plus_btn_active =
  store.imgBaseUrl + "/control/bbl_plus_btn_active.png";

const btnUi = {
  mgc: [mgc_plus_btn, mgc_plus_btn_active],
  hw3: [mgc_plus_btn, mgc_plus_btn_active],
  other: [mgc_plus_btn, mgc_plus_btn_active],
  bird: [mgc_plus_btn, mgc_plus_btn_active],
  bbl: [bbl_plus_btn, bbl_plus_btn_active],
};

const emits = defineEmits(["btnsound"]);

const props = defineProps({
  isChallenge: {
    type: Boolean,
    default: () => false,
  },
  countTime: {
    type: String,
    default() {
      return "15:00";
    },
  },
});

const btnStatu = ref(false);

// 加强
const handlePower = (evt) => {
  const evtType = evt.type;
  evt.stopPropagation();
  evt.preventDefault();
  const params = {
    device: store.current_device.device_name,
    product_id: store.current_machine.product_id,
    channel: 6,
    type: 2,
    times: 1,
    width: 50,
    interval: 100,
    uid: parseInt(store.userInfo.uid),
  };

  if (evtType === "touchstart") btnStatu.value = true;

  if (evtType === "touchend") {
    btnStatu.value = false;
    emits("btnsound");
    controlRequest(params);
  }
};
</script>

<style lang="scss" scoped>
.plus-wrapper {
  position: absolute;
  right: 34px;
  top: 110px;
  width: 48px;
  height: 48px;
  z-index: 4;
  .plus-btn {
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  .plus-btn-active {
    width: 100%;
    height: 100%;
  }
}
</style>
