<template>
    <div class="privacy_policy" :style="{
        paddingTop: store.areaInsetTopValue + 'px',
    }">
        <van-nav-bar title="隐私政策" left-arrow @click-left="router.back()" :border="false" :placeholder="true" />
        <div class="content">
            <h1 style="text-align: center;">极客竞技隐私政策</h1>
            <p>隐私政策更新日期:2023-12-20</p>
            <p>极客竞技App接入的第三方SDK清单如下: 第三方SDK 隐私数据的使用及说明</p>
            <p>尊敬的极客竞技APP用户(以下简称“您”，广西广西蔚品网络科技有限公司(以下简称“蔚品网络”》重视用户的隐私，您在使用极客竞技APP时，蔚品网络将按照《极客竞技APP用户隐私政策》
                (以下简称“本政策”收集、存储、使用及对外提供您的个人信息。为了保证对您的个人隐私信息合法、合理、适度的收集、使用，并在安全、可控的情况下进行传输、存储，蔚品网络制定了本政策，请您仔细阅读本政策并确定了解蔚品网络对您个人信息的处理规则。阅读过程中，如您有任何疑问，可联系蔚品网络的客服咨询。对本政策中与您的权益存在重大关系的条款和个人敏感信息，蔚品网络采用粗体字进行标注以提示您注意。如您点击或勾选“同意”并确认提交，即视为您同意本隐私政策并同意蔚品网络将按照本政策来收集、使用、存储和共享您的相关信息。
            </p>

            <p>本政策将帮助您了解以下内容:</p>

            <p>一．蔚品网络如何收集和使用您的个人信息</p>
            <p>二．蔚品网络如何公开披露您的个人信息</p>
            <p>三．蔚品网络如何存储和保护您的个人信息</p>
            <p>四．您如何管理您的个人信息</p>
            <p>五．蔚品网络如何保护未成年人信息</p>
            <p>六．本政策如何更新</p>
            <p>七．如何联系蔚品网络</p>

            <p>一．蔚品网络如何收集和使用您的个人信息</p>

            <p>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括通信通讯联系方式、住址、账户信息、等。个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的信息，主要包括:身份证件信息
                (身份证、护照等)、交易信息、订单信息等。</p>
            <p>(一)蔚品网络如何收集您的个人信息。</p>
            <p>1、当您在极客竞技APP上注册时，蔚品网络会使用您提供的第三方授权账号进行注册，并验证该账号尚未与“极客竞技”账号绑定、并未被蔚品网络根据《极客竞技APP用户协议》封禁。以帮助您完成极客竞技用户注册，如果您拒绝提供这些信息，您可能无法完成注册或无法正常使用蔚品网络的服务。
            </p>
            <p>2、当您使用极客竞技APP功能或服务时，在下列情形中，您可能需要向蔚品网络提供或授权蔚品网络收集相应服务所零的如你拒绝提供部分功能或服务所需信息，您可能无法使用部分功能或服务，但这不影响您正常使用极客竞技APP的其他功能或服务。
            </p>

            <p>(1)为了给您提供更好的使用体验，蔚品网络会根据您的设备信息、服务日志，经过去标识化处理后，推送相关内容和服务。请注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。如果蔚品网络将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，蔚品网络会将这类信息做匿名化、去标识化处理。
            </p>

            <p>(2)您需要根据国家法律法规及监管的要求提供您的实名信息，这些实名信息可能包括您的姓名电话号码等身份信息。</p>

            <p>(3)为满足相关法律法规政策及相关主管部门的要求，您需要进行实名认证以继续使用和享受本游戏软件及相关服务。我们会在获得您同意或您主动提供的情况下收集您的实名身份信息，该信息属于敏感信息，拒绝提供实名身份信息可能会导致您在使用本游戏软件及相关服务过程中受到相应限制。
            </p>

            <p>(4)我们严禁未成年人使用蔚品网络服务，为更好地保护未成年人身心健康，促使未成年人健康上网，我们可能在国家有关未成年人网络游戏相关政策规定的基础上，最大限度防止未成年人冒用他人身份信息，我们可能在部分游戏或针对部分用户(以具体游戏策略为准)启用人脸识别验证。上述人脸识别验证主要是将用户真实面部信息与公安权威数据平台数据源进行比对，如二者比对结果一致，即成功通过认证，并按用户实际年龄段匹配相应的游戏时限。如比对结果不符或用户拒绝验证，我们将统一视作18周岁及以下未成年人，纳入相应的游戏监管。人脸识别的相关验证数据，在加密后仅用于与公安权威数据平台进行比对，我们不会对其留存。
            </p>

            <p>3、当您使用极客竞技APP服务时，为了维护服务的安全稳定运行，预防交易和资金风险，蔚品网络会收集以下信息，包括您的设备型号、操作系统、唯一设备标识符、极客竞技APP软件版本号、登录IP地址、接入网络的方式、类型和状态、网络质量数据、与极客竞技APP操作日志及服务日志相关的信息。如您不同意蔚品网络收集前述信息，可能导致抓取游戏体验出现部分异常，但不影响您正常使用极客竞技APP的其他功能或服务。
            </p>

            <p>4、为向您提供更加准确、便捷的服务，提升服务体验、改进服务质量，或为防范风险，蔚品网络会收集您反馈意见、建议时提供的信息，收集您使用极客竞技APP功能或服务的类别、方式和操作信息，蔚品网络会对这些信息进行综合统计、加工分析，并会根据上述信息向您提供相应服务或产品。
            </p>

            <p>5、以下情形中，您可选择是否授权蔚品网络收集、使用您的个人信息:</p>

            <p>(1)IMEIIMSI，用于确定设备识别码，以保证账号登录的安全性与唯一性。</p>
            <p>(2) mac地址，用于确定设备识别码，以保证账号登录的安全性与唯一性。</p>
            <p>(3)ANDROIDID，用于确定设备识别码，以保证账号登录的拿年与唯一性。</p>
            <p>(4)软件安装列表，用于查找是否安装微信，从而获得更好的用户体验。</p>
            <p>(5)设备状态，用于定设备识别码，以保证账号登录的安全性。拒绝授权后，将无法正常使用极客竞技APP。</p>
            <p>(6)存储权限，用于缓存您在使用APP过程中产生的文本、图像、视频等内容，拒绝授权后，将无法正常使用极客竞技APP。</p>
            <p>(7) 像头，用于拍照，在实名认证时会采集您的身份信息进行实名认证。拒绝授权后，上述功能将无法使用。</p>
            <p>(8) 相册，用于上传照片设置您的头像、社区发布动态等用。蔚品网络获得的图片信息，加密后存储于数据库中。拒绝授权后，上述功能将无法使用。
            <p>(9)麦克风，用于实时语音聊天;用户游戏过程中与玩家即时沟通服务场景中使用。拒绝授权后，上述功能将无法使用。</p>
            <p>(10)第三方账户信息，我们将根据您的授权获取该第三方平台的账户(微信账号)下的相关信息(包括用户名、昵称、头等) 以身份验信息(个人敏感信息)。我们承诺，收集上述信息是用</p>
            于为您提供账号登录服务以及保障您的账号安全。如您拒绝授权此类信息，则您将无法使用第三方平台账号登录我们平台。
            <p>(11)手机号码，属于您的个人敏感信息，我们手机该类信息是基于法律法规的相关要求，如您拒绝提供可能导致您无法注册账号并使用相关产品功能，请您谨慎考虑后再选择是否提供。</p>
            <p>(12)网络日志及其他相关信息，用于解决服务故障为玩家提供更好的游戏体验。上述功能可能需要您在您的设备中向蔚品网络开启您的设备、存储相机(摄像头)、相册的访问权限和自启动权限，以实现这些功能所涉及的信息的收集和使用。请您注意，您开启这些权限即代表您授权蔚品网络可以收集和使用这些信息来实现上述功能，如您取消了这些授权，则蔚品网络将不再继续收集和使用您的这些信息，也无法为您提供上述与这些授权所对应的功能。
            </p>

            <p>6、基于业务需要，我们可能会将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、提供客户服务、支付便利。特别的，在涉及存储服务时，我们将对依照规定进行存储服务的内容采取严格的保密措施，如涉及租用第三方存储空间的，我们将严格要求第三方在最小范围对数据进行接触并严格保密。
            </p>

            <p>极客竞技App接入的第三方SDK如下:</p>
            <p>微信SDK:</p>
            <p>涉及个人信息类型:您在注册微信帐户时填写的信息(昵称、手机号)、使用服务时上传的信息 (头像、照片)、您通过微信客服或参加微信举办的活动时所提交的信息 (姓名、电话、性别、家庭地址)、设备信息
                (设备型号、操作系统版本IMEI/Mac地址/android
                ID/IDFA/OPENUDID/GUID/SIMEIMSL、电池信号强度)、软件信息，例如，软件的版本号、浏览器类型、收集有关您使用的移动应用和其他软件的信息、运行中的进程、IP地址、服务日志信息、通讯日志信息、位置信息、openid
            </p>

            <p>使用目的:用于微信支付、微信登录功能</p>
            <p>使用场景:用于购买电玩币和微信登录</p>
            <p>合作方主体:腾讯</p>
            <p>收集方式:SDK采集</p>
            <p>隐私政策链接:</p>
            <p>https://privacy.qq.com</p>

            <p>7.其他</p>

            <p>蔚品网络在向您提供其他业务功能时，会另行向您说明信息收集的范围与目的，并征得您的同意后方收集提供相应服务所必要的您的信息。蔚品网络会按照本政策以及相应的用户协议约定收集、使用存储、对外提供及保护您的信息;</p>
            <p>(二)蔚品网络如何使用您的个人信息蔚品网络为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，或保障您的账户安全，蔚品网络会在以下情形中使用您的信息</p>
            <p>1、蔚品网络会根据本隐私政策的约定并为实现蔚品网络的服务或功能对所收集的您的个人信息进行使用;</p>
            <p>2、为了使您知晓使用极客竞技APP服务的状态蔚品网络会向您发送服务提醒。您可以通过手机系统设置中的通知设置，关闭服务提醒，也可以通过通知设置重新开启服务提醒;</p>
            <p>3、根据法律法规或监管要求向相关部门进行报告;</p>
            <p>4、邀请您参与蔚品网络服务或功能有关的客户调研;</p>
            <p>5、蔚品网络会对您的信息进行综合统计、分析加工，以便为您提供更加准确、流畅及便捷的服务，或帮助蔚品网络评估、改善或设计产品、服务及运营活动等。蔚品网络可能根据前述信息向您提供营销活动通知、商业性电子信息，如您不希望接收此类信息，您可在用户设置界面选择关闭。
            </p>
            <p>6、在收集您的个人信息后，蔚品网络在通过技术手段对您的信息数据进行去标识化处理后，该等去标识化的信息将无法识别信息主体，有权在不经您同意的情况下直接使用，有权对用户数据库进行分析并予以商业化的利用。</p>
            <p>7、蔚品网络会对蔚品网络的服务或功能使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示蔚品网络的服务或功能的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</p>


            <p>二、蔚品网络如何公开披露您的个人信息</p>

            <p>根据相关法律法规、监管要求等规定，以下情形中遇到国家有权机关或者监管机关强制性要求的，或者出于对您的权利、权益进行充分保护的目的，或者此处约定的其他合理情形的，蔚品网络可能会共享、转让、公开披露用户信息无需事先征得您授权同意:
            </p>
            <p>1、与家安全国防安全直接相关的；</p>
            <p>2、与公共安全、公共卫生、重大公共利益直接相关的;</p>
            <p>3、与犯罪侦查、起诉、审判和判决执行等直接相关的;</p>
            <p>4、出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的;</p>
            <p>5、您自行向社会公众公开的个人信息;</p>
            <p>6、从合法公开披露的信息中收集的用户信息，如合法的新闻报道、政府信息公开等渠道</p>

            <p>三、蔚品网络如何存储和保护您的个人信息</p>

            <p>(一)存储</p>
            蔚品网络运营方会采取合适的安全措施和技术手段存储及保护您的个人信息，以防止丢失、被误用、受到未授权访问或泄漏、被篡改或毁坏。我们仅在实现本隐私政策所属目的必须的期限依照个人信息的不同等级对应的存储期限对个人信息进行存储，存储期限严格按照法律及相关法规规定。根据本条款的规定，我们仅允许有必要知晓这些信息的员工等第三方访问个人信息，并要求他们履行相应的保密务
            </p>
            <p>(二)个人信息的保护</p>
            <p>1、蔚品网络已使用符合业界标准的安全防护措施保护您提供的个人信息，蔚品网络将尽力防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。蔚品网络会采取一切合理可行的措施，保护您的个人信息。例如:蔚品网络会使用加密技术确保数据的保密性;蔚品网络会使用受信赖的保护机制防止数据遭到恶意攻击;蔚品网络会部署访问控制机制，确保只有授权人员才可访问个人信息。
            </p>
            <p>2、如蔚品网络提供的全部或部分极客竞技APP业务停止运营，蔚品网络相关产品或服务将通过公告等形式向您进行告知，同时停止相关产品或服务对您个人信息的收集等操作，保护您的个人信息安全。如因技术故障、网络攻击、自然灾害及事故人为因素等各种原因造成全部或部分极客竞技APP业务中断，蔚品网络将采取应急处置和恢复措施予以应对，尽快恢复服务。
            </p>
            <p> 3、蔚品网络会采取一切合理可行的措施，确保未收集与您所办理业务无关的个人信息。</p>
            <p>4、请您理解，由于各种因素有可能出现蔚品网络无法合理预见、防范、避免、控制的意外情况。互联网并非绝对安全的环境，请使用复杂密码，协助蔚品网络保证您的账号安全。</p>
            <p>5、如发生个人信息安全事件，蔚品网络将按照法律法规的要求，及时采取措施进行化解，同时将事件相关情况以邮件、信函、电话、推送通知等方式告知。同时，蔚品网络还将依据监管规定，上报个人信息安全事件处置情况。</p>


            <p>四、您如何管理您的个人信息</p>

            <p>按照中国相关的法律法规和监管规定，!蔚品网络保障您对自己的个人信息行使以下权利:</p>

            <p>(一)访问、更正及更新您的个人信息</p>

            <p>您有权通过极客竞技APP等渠道访问及更正、更新您的个人信息，法律法规另有规定的除外。您有责任及时更新您的个人信息。在您修改个人信息之前，蔚品网络会验证您的身份。</p>

            <p>1、您登录极客竞技APP后，可以在“个人资料”中，进行个人信息设置。</p>
            <p>个人信息设置--为客户提供用户昵称设置功能，支持查询个人基本信息及设置头像、绑定手机号等。</p>

            <p>(二)删除您的个人信息</p>
            <p>在以下情形中，您可以向蔚品网络提出删除个人信息的请求:</p>
            <p>1、如果蔚品网络处理个人信息的行为违反法律法;</p>
            <p>2、如果蔚品网络收集、使用您的个人信息，却未征得您的同意;</p>
            <p>3、如果蔚品网络处理个人信息的行为违反了与您的约定;</p>
            <p>4、如果你不再使用蔚品网络的产品或服务，或您注销了账号;</p>
            <p>5、如果蔚品网络不再为您提供产品或服务。</p>

            <p>如蔚品网络决定响应您的删除请求，蔚品网络还将同时通知从蔚品网络获得你的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
                当您从蔚品网络的服务中册除信息后，蔚品网络可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>

            <p>(三)改变您授权同意的范围或撤回您的授权</p>

            <p>您可以通过删除信息、关闭设备功能、在隐私设置等方式改变部分您授权蔚品网络继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式(具体注销说明您可以在个人中心-设置内进行查看并进行操作，注销即时生效)
                ，撤回蔚品网络继续收集您个人信息的全部授权。请您注意，您自主注销蔚品网络账号的同时，将视同您撤回了对本政策的同意。当你撤回同意后，蔚品网络将不再处理相应的个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
            </p>

            <p>(四)响应您的请求</p>

            <p>如果您无法通过上述方式访问、更正您的用户信息，或您需要访问、更正您在使用蔚品网络服务或功能时所产生的其他用户信息，或您认为蔚品网络存在任何违反法律法规或与您关于用户信息的收集或使用的约定，您均可通过本政策中的联系方式与蔚品网络取得联系。为了保障安全，蔚品网络可能需要您提供书面请求，或以其他方式证明您的身份，蔚品网络将在收到您反馈并验证您的身份后的15天内答复您的请求。对于您合理的请求，蔚品网络原则上不收取费用，但对多次重复、超出合理限度的请求，蔚品网络将视情况收取一定成本费用。对于非法、违规、无正当理由、可能无端重复、需要过多技术手段(例如，需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者非常不切实际的请求，蔚品网络可能会将予以拒绝。
            </p>

            <p>尽管有上述约定，但根据相关法律法规、
                监管要求等规定,以下情形中遇到国家有权机关或者监管机关要求的，
                或者存在以下约定其他情形的，蔚品网络将可能无法响应您的请求：</p>

            <p>1、与国家安全安全关的；</p>
            <p>2、与公共安全、公共卫生、重大公共利益相关的;</p>
            <p>3、于犯罪侦查、起诉、审判和判决执行等相关的；</p>
            <p>4、有充分证据表明您存在主观恶意或滥用权利的;</p>
            <p>5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的;</p>
            <p>6、涉及蔚品网络商业秘密的</p>

            <p>五、蔚品网络如何保护未成年人信息</p>

            <p>(一)未成年人禁止使用蔚品网络服务。如您为未成年人，请您的父母或监护人阅读本政策，并在征得您父母或监护人同意的前提下使用蔚品网络的服务或向蔚品网络提供您的信息。对于经父母或监护人同意而使用您的信息的情况，蔚品网络只会在受到法律允许、父母或监护人明确同意或者保护您的权益所必要的情况下使用或共享此信息。如您的监护人不同意您按照本政策使用蔚品网络的服务或向蔚品网络提供信息，请您立即终止使用蔚品网络的服务并及时通知蔚品网络，以便蔚品网络采取相应的措施。
            </p>

            <p>(二)如您为未成年人的父母或监护人，当您对您所监护的未成年人的个人信息处理存在疑问时，请通过本政策中的联系方式联系蔚品网络。</p>


            <p>六、本政策如何更新</p>

            <p>为给您提供更好的服务以及随着我们业务的发展，本政策也会随之更新。对于重大变更，我们还会提供更为显著的通知(我们会通过包括但不限于短信、系统消息或在浏览页面做特别提示方式，说明芳您同意新修订的政策本政策的具体变更内容)并继续使用我们的服务，即表示您已经充分阅读理解并同意我们的政策。
            </p>

            <p>根据国家法律法规变化及服务运营需要，蔚品网络将对本政策及相关规则不时地进行修改，修改后的内容会通过极客竞技APP公布，公布后即生效，并取代此前相关内容。您应不时关注相关公告、提示信息及协议、规则等相关内容的变动。您知悉并确认，如您不同意更新后的内容，应立即停止使用相应服务，并注销相关的用户，蔚品网络将停止收集您的相关个人信息。
            </p>

            <p>七、如何联系蔚品网络</p>

            <p>如有任何疑问，您可以通过本隐私政策公示的方式(可以将相关内容发送至：WeipinNetwork@163.com)或蔚品网络内提供的其他联系/反馈渠道联系我们，我们将尽快为您解答。一般情况下，蔚品网络将在15个工作日内给予答复。
            </p>
        </div>
    </div>
</template>



<script setup>
import { useRouter } from 'vue-router';
import { useStore } from "@/store/store";
const router = useRouter()
const store = useStore()


</script>



<style>
.privacy_policy .content {
    padding: 20px 15px;
}
</style>