import taskIDList from "./taskIDTable";
import Decimal from "decimal.js";
import { gradLevelIcon } from "@/utils/levelData";
import findGameInfoData from "@/utils/gameInfoMap";

import collectClassIDs from "./collectClassIDs";

export function addCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getCurrentDevice() {
  const userAgent = navigator.userAgent.toLowerCase();

  const androidIndexOf = userAgent.indexOf("android");
  const iphoneIndexOf = userAgent.indexOf("iphone");
  const ipadIndexOf = userAgent.indexOf("ipad");

  const androidIncludes = userAgent.includes("android");
  const iphoneIncludes = userAgent.includes("iphone");
  const ipadIncludes = userAgent.includes("ipad");

  if (androidIndexOf > -1 || androidIncludes) return "Android";
  if (iphoneIndexOf > -1 || iphoneIncludes) return "IOS";
  if (ipadIndexOf > -1 || ipadIncludes) return "IOS";

  return "Other";
}

const s_typeTexts = [
  {
    type: "login_reward",
    text: "登录奖励",
  },
  {
    type: "recharge",
    text: "充值",
  },
  {
    type: "up_coin",
    text: "投币",
  },
  {
    type: "base",
    text: "基础礼包",
  },
  {
    type: "day",
    text: "每日礼包",
  },
  {
    type: "first",
    text: "首冲礼包",
  },
  {
    type: "card",
    text: "周卡/月卡奖励",
  },
  {
    type: "apply_tiaozhan",
    text: "参加挑战",
  },
  {
    type: "recharge_card",
    text: "购买周卡/月卡",
  },
  {
    type: "exchange",
    text: "积分兑换",
  },
  {
    type: "gift",
    text: "特惠礼包",
  },
  {
    type: "code_reward",
    text: "兑换码奖励",
  },
  {
    type: "weal_box",
    text: "福利罐奖励",
  },
  {
    type: "task",
    text: "任务奖励",
  },
  {
    type: "tiaozhan",
    text: "挑战赛",
  },
  {
    type: "tiaozhan_success",
    text: "挑战成功",
  },
  {
    type: "nangua_rank",
    text: "南瓜榜奖励",
  },
  {
    type: "bbl_rank",
    text: "爆爆乐高分榜奖励",
  },
  {
    type: "mxt_rank",
    text: "马戏团小丑榜奖励",
  },
  {
    type: "recharge_level",
    text: "充值等级",
  },
  {
    type: "recharge_level",
    text: "充值等级",
  },
  {
    type: "invite_reward",
    text: "邀请奖励",
  },
  {
    type: "tianqi_week",
    text: "天梯周榜奖励",
  },
  {
    type: "tianqi_month",
    text: "天梯月榜奖励",
  },
  {
    type: "tianqi_jidu",
    text: "天梯季榜奖励",
  },
  {
    type: "tianzhan_rank",
    text: "挑战排行榜奖励",
  },
  {
    type: "mxttribe_rank",
    text: "马戏团排行榜奖励",
  },
  {
    type: "mgctribe_rank",
    text: "魔鬼城排行榜奖励",
  },
  {
    type: "hw3tribe_rank",
    text: "海王3排行榜奖励",
  },
  {
    type: "hw3_rank",
    text: "海王3觉醒榜奖励",
  },
  {
    type: "bbltribe_rank",
    text: "爆爆乐排行榜奖励",
  },
  {
    type: "msqy_rank",
    text: "魔术奇缘排行榜",
  },
  {
    type: "collect",
    text: "收集任务兑换奖励",
  },
  {
    type: "tz_card",
    text: "挑战卡",
  },
  {
    type: "lottery_tz_rank",
    text: "积分挑战赛",
  },
  {
    type: "mali",
    text: "幸运小玛莉",
  },
  {
    type: "baoji",
    text: "爆机奖励",
  },
  {
    type: "foot",
    text: "囤币活动",
  },
  {
    type: "foot_vs",
    text: "欧洲杯大竞猜",
  },
  {
    type: "mapgie",
    text: "七夕活动获得",
  },
  {
    type: "turntable",
    text: "幸运转盘获得",
  },
  {
    type: "guoqing",
    text: "中秋国庆活动获得",
  },
];

export function initGameData(dataList) {
  let gameDataList = [];
  // let gameTabsList = [];

  for (const _key in dataList) {
    const infoItem = dataList[_key];

    if (infoItem.group == "msqy_line") continue;

    let occupys = [];

    let visits = [];

    if (!Array.isArray(infoItem.device_info)) {
      infoItem.device_info = [];
    }

    if (infoItem.device_info.length == 0) {
      infoItem.device_info = [];
    }

    infoItem.device_info.forEach((devItem) => {
      if (devItem.occupy && devItem.occupy.player_id) {
        occupys.push(devItem.occupy);
      }
    });

    // JSON 对象形式
    // let visitsNumber = Object.keys(infoItem.visits);
    // if (visitsNumber.length > 4) {
    //   visitsNumber = infoItem.visits.slice(0, 4);
    // }
    // if (visitsNumber.length > 0) {
    //   visitsNumber.forEach((visit) => {
    //     const visitUserInfo = {
    //       uid: visit,
    //       head: infoItem.visits[visit].head || "",
    //     };
    //     visits.push(visitUserInfo);
    //   });
    // }
    // 数组data 形式
    if (infoItem.visits && infoItem.visits.length > 4) {
      visits = infoItem.visits.slice(0, 4);
    } else if (infoItem.visits && infoItem.visits.length > 0) {
      visits = infoItem.visits;
    }

    const gameItem = {
      sp_url: infoItem.sp_url,
      return_time: Number(infoItem.return_time) || 0,
      visit_time: infoItem.visit_time,
      site_limit: infoItem.site_limit,
      product_id: infoItem.product_id,
      up_coin: infoItem.up_coin,
      exchange: infoItem.exchange,
      product_name: infoItem.product_name,
      level: infoItem.level,
      device_info: infoItem.device_info,
      machine_index: infoItem.machine_index,
      tiaozhan_index: infoItem.tiaozhan_index,
      status: infoItem.status,
      child_name: infoItem.child_name,
      visits: visits || [],
      occupy: occupys || [],
      line_reward_index: infoItem.line_reward_index,
      group: infoItem.group,
    };
    gameDataList.push(gameItem);
  }

  // gameTabsList = Array.from(new Set(gameTabsList));
  gameDataList.sort((a, b) => Number(a.exchange) - Number(b.exchange));
  gameDataList.sort((a, b) => Number(a.level) - Number(b.level));
  return gameDataList;
}

export function initTianTiData(data, userInfo) {
  // debugger;
  const score = {
    weekScore: data.my_info.week_exp,
    monthScore: data.my_info.month_exp,
    seasonScore: data.my_info.jidu_exp,
    seasonTime: "2023.6 - 2023.8",
    stopTime: "",
    level: data.my_info.level ? Number(data.my_info.level) : 1,
    rank: "100+",
    jijie: "",
  };

  const dashi_card = !!data.my_info.dashi_card || false;
  const zongshi_card = !!data.my_info.zongshi_card || false;

  let year = new Date().getFullYear();
  let month = new Date().getMonth() + 1;

  let stopMonth = 0;

  // 这里显示是什么季赛
  score.jijie = getSeason(month);

  let seasonTime = "";

  data.tianti_def.jidu_time.some((jiTime) => {
    if (month == 12) {
      stopMonth =
        Number(jiTime.stop.month) - 1 < 1 ? 1 : Number(jiTime.stop.month) - 1;

      seasonTime = `${year}.${jiTime.begin.month} - ${year}.${stopMonth}`;
      return true;
    } else if (month < jiTime.stop.month) {
      stopMonth =
        Number(jiTime.stop.month) - 1 < 1 ? 1 : Number(jiTime.stop.month) - 1;

      seasonTime = `${year}.${jiTime.begin.month} - ${year}.${stopMonth}`;
      return true;
    } else return false;
  });

  score.seasonTime = seasonTime;

  let date = new Date(year, stopMonth, 1);

  let lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

  score.stopTime = `${year}-${stopMonth}-${lastDay.getDate()}`;

  const award = {
    weekAward: [],
    monthAward: [],
    seasonAward: [],
  };

  award.weekAward = getAwards(data.tianti_def.week_reward);
  award.monthAward = getAwards(data.tianti_def.month_reward);
  award.seasonAward = getAwards(data.tianti_def.ji_reward);

  const rankList = data.rank_info;
  rankList.length > 50 && (rankList.length = 50);
  rankList.forEach((rankItem, rankIndex) => {
    if (parseInt(rankItem.uid) === parseInt(userInfo.uid))
      score.rank = (rankIndex + 1).toString();
  });

  return {
    score,
    award,
    rankList,
    dashi_card,
    zongshi_card,
  };
}

function getSeason(month) {
  if (month >= 1 && month <= 3) {
    return "春季";
  } else if (month >= 4 && month <= 6) {
    return "夏季";
  } else if (month >= 7 && month <= 9) {
    return "秋季";
  } else if (month >= 10 && month <= 12) {
    return "冬季";
  } else {
    return "无效的月份";
  }
}

function getAwards(scores) {
  // 确保输入是数组且长度为100
  // if (!Array.isArray(scores) || scores.length !== 100) {
  //   return "Invalid input. Please provide a 100-element array.";
  // }
  // 对分数进行排序
  // scores.sort((a, b) => a - b);

  // 定义奖牌数量和对应的排名
  const awards = [
    { rank: "第1名", awardNumber: scores[0] },
    { rank: "第2名", awardNumber: scores[1] },
    { rank: "第3名", awardNumber: scores[2] },
    { rank: "第4名", awardNumber: scores[3] },
    { rank: "第5名", awardNumber: scores[4] },
    { rank: "第6~10名", awardNumber: scores[5] },
    { rank: "第11~20名", awardNumber: scores[10] },
    { rank: "第21~50名", awardNumber: scores[25] },
  ];

  return awards;
}
// timestamp : number

export function formatTimestamp(
  timestamp = new Date() / 1000,
  format = "yyyy-mm-dd hh:mi:ss"
) {
  const _timestamp = timestamp ?? new Date() / 1000;

  let date = new Date(_timestamp * 1000);
  const pad = (num) => `0${num}`.slice(-2);
  const map = {
    yyyy: date.getFullYear(),
    mm: pad(date.getMonth() + 1),
    dd: pad(date.getDate()),
    hh: pad(date.getHours()),
    mi: pad(date.getMinutes()),
    ss: pad(date.getSeconds()),
  };

  return format.replace(/(yyyy|mm|dd|hh|mi|ss)/g, (match) => map[match]);
}

function calculateTimeDifferenceInMinutes(timestamp1, timestamp2) {
  const date1 = new Date(timestamp1 * 1000); // 将时间戳转换为毫秒并创建Date对象
  const date2 = new Date(timestamp2 * 1000);

  const timeDiffInMs = Math.abs(date2 - date1); // 计算两个时间差的毫秒数
  const timeDiffInMinutes = timeDiffInMs / 1000 / 60; // 将毫秒转换为分钟

  return Math.floor(timeDiffInMinutes); // 向下取整并返回结果
}

export function initChallengeStatuResult() {
  // statu ing 进行中 end
  //
  // result : faild 失败 succeed 成功
  const result = {
    statu: "ing",
    result: "faild",
    start_time: 0,
    product_name: "test1",
    tiaozhan_index: "0",
  };

  return result;
}

export function initChallengeRanksListData(list, rewardList) {
  const playerUserList = list.length > 10 ? list.slice(0, 10) : list;

  const initNewArr = [];
  const newRewardList = rewardList;
  if (newRewardList.length < 10) {
    const differenceValue = 10 - newRewardList.length;

    for (let i = 0; i < differenceValue; i++) {
      newRewardList.push(500);
    }
  }
  playerUserList.forEach((item, index) => {
    const obj = {
      xunzhang: item.xunzhang,
      player_name: item.player_name,
      total_reward: item.total_reward,
      uid: item.uid,
      img_url: "",
      level: "1",
      expected_reward: newRewardList[index],
    };

    initNewArr.push(obj);
  });

  return initNewArr;
}

export function initCollectCardListData(info) {
  const cloneInfo = JSON.parse(JSON.stringify(info));
  const { player_collect, collect_def } = cloneInfo;

  const gameTypeList = Object.keys(player_collect);

  const initCollectPageData = {
    mxt: {
      gameName: "mxt",
      myCollectCards: [],
      collectCardTaskList: [],
    },
    msqy: {
      gameName: "msqy",
      myCollectCards: [],
      collectCardTaskList: [],
    },
    mgc: {
      gameName: "mgc",
      myCollectCards: [],
      collectCardTaskList: [],
    },
    bbl: {
      gameName: "bbl",
      myCollectCards: [],
      collectCardTaskList: [],
    },
    hw3: {
      gameName: "hw3",
      myCollectCards: [],
      collectCardTaskList: [],
    },
  };

  const collect_reward_list = {
    mxt: [],
    mgc: [],
    bbl: [],
    hw3: [],
    msqy: [],
  };

  gameTypeList.forEach((gameType) => {
    //#region  cardsList myCollectCards start
    let cardList = Object.values(player_collect[gameType]);

    initCollectPageData[gameType].myCollectCards = cardList.map((card) => {
      return {
        ...findCollectCardData(card.class_id, gameType),
        num: card.num,
      };
    });

    //#endregion  cardsList myCollectCards end

    //#region collectCardTaskList start
    collect_def[gameType].collect_reward.forEach((task, taskIndex) => {
      const obj = {
        taskName: findCollectCardTaskName(taskIndex, gameType),
        taskCardList: [],
        reward: task.reward,
        isExchange: false,
      };

      if (task.complete.class_id) {
        const taskObj = {
          ...findCollectCardData(task.complete.class_id, gameType),
          condition: task.complete.num,
          num: 0,
        };

        initCollectPageData[gameType].myCollectCards.some((card) => {
          if (card.classID == task.complete.class_id) {
            taskObj.num = card.num;
            obj.isExchange = card.num >= taskObj.condition;
            return true;
          } else return false;
        });
        obj.taskCardList.push(taskObj);
      } else {
        const flages = [];
        task.complete.forEach((completeItem) => {
          const taskcardObj = {
            ...findCollectCardData(completeItem.class_id, gameType),
            condition: completeItem.num,
            num: 0,
          };
          initCollectPageData[gameType].myCollectCards.some((card) => {
            if (card.classID == completeItem.class_id) {
              taskcardObj.num = card.num;
              flages.push(card.num >= taskcardObj.condition);
              return true;
            } else return false;
          });

          obj.taskCardList.push(taskcardObj);
        });

        // isExchange 判定 有一个未达成 即不可兑换
        obj.isExchange = !flages.some((ise) => {
          return ise == false;
        });
      }
      initCollectPageData[gameType].collectCardTaskList.push(obj);
    });

    //#endregion  collectCardTaskList end

    //#region reward start
    let rewardName = `nangua_reward`;

    if (gameType != "mgc") rewardName = `${gameType}_reward`;

    collect_reward_list[gameType] = collect_def[gameType][rewardName]
      ? collect_def[gameType][rewardName]
      : [];

    //#endregion  reward end
  });
  console.log("initCollectPageData", initCollectPageData);
  return { initCollectPageData, collect_reward_list };
}

export function findCollectCardName(id, gamaName) {
  const cardID = Number(id);
  if (isNaN(cardID) || cardID == undefined || cardID == null) return "none";

  const classIDs = collectClassIDs[gamaName];

  const card = classIDs.find((cardItem) => {
    return cardItem.classID == id;
  });

  if (card) return card.cardName;
  return "none";
}
function findCollectCardTaskName(indx, type) {
  const index = Number(indx);
  if (isNaN(index) || index == undefined || index == null) return "none";
  const taskMGCNames = [
    "魔法封印",
    "驱魔神剑",
    "千年南瓜怪",
    "南瓜捕手",
    "幻影猎人",
    "巨魔统领",
    "狂暴骷髅",
  ];
  const taskBBLNames = [
    "您太棒啦",
    "火山降临",
    "不可思议的挑战",
    "传奇爆破手",
    "宝石总动员",
  ];
  const taskMXTNames = ["全盘奖", "开心玛莉", "JP达人", "全盘猎手", "大魔术师"];

  const taskMSQYNames = [
    "全盘奖",
    "开心玛莉",
    "JP达人",
    "全盘猎手",
    "大魔术师",
  ];

  const taskHW3Names = [
    "蟹王之王",
    "连锁大师",
    "旋风快打",
    "电磁炮塔",
    "爆破专家",
    "命运之轮",
    "雷神之锤",
    "高爆钻头",
    "龙王传说",
    "巨兽猎人",
    "深海之王",
    "鳄鱼至尊",
    "霸王蟹甲",
  ];

  if (type == "mxt") {
    if (index > taskMXTNames.length) return "Over_length";
    return taskMXTNames[index];
  }
  if (type == "msqy") {
    if (index > taskMSQYNames.length) return "Over_length";
    return taskMSQYNames[index];
  }
  if (type == "mgc") {
    if (index > taskMGCNames.length) return "Over_length";
    return taskMGCNames[index];
  }
  if (type == "bbl") {
    if (index > taskBBLNames.length) return "Over_length";
    return taskBBLNames[index];
  }
  if (type == "hw3") {
    if (index > taskHW3Names.length) return "Over_length";
    return taskHW3Names[index];
  }
}

function findCollectCardData(id, gamaName) {
  const cardID = Number(id);
  if (isNaN(cardID) || cardID == undefined || cardID == null)
    return {
      classID: 0,
      cardName: "none",
      url: "",
    };

  const classIDs = collectClassIDs[gamaName];

  const card = classIDs.find((cardItem) => {
    return cardItem.classID == id;
  });

  if (card) return card;
  return {
    classID: 0,
    cardName: "none",
    url: "",
  };
}

// 收集反馈
export function initCollectResult(info) {
  const { game_name, class_id } = info;

  const collectInfo = collectClassIDs[game_name].find(
    (cItem) => cItem.classID == class_id
  );
  const collectResult = {
    text: "收集成功",
    timestamp: new Date().getTime(),
  };

  if (collectInfo) collectResult.text = collectInfo.cardName + "";
  return collectResult;
}

export function initPumpkinRankListData(info, rewardList) {
  const rewardListClone =
    rewardList.length > 0
      ? rewardList
      : [
          2023, 888, 666, 500, 400, 300, 200, 100, 100, 100, 88, 88, 88, 88, 88,
          88, 88, 88, 88, 88,
        ];

  const newList = [];

  info.forEach((item, index) => {
    const obj = {
      player_name: item.player_name,
      num: item.num,
      uid: item.uid,
      award: rewardListClone[index],
    };
    newList.push(obj);
  });

  return newList;
}

export function initPayPupData(info) {
  const { recharge_def, player_recharge_status } = info;

  const devType = getCurrentDevice() == "IOS" ? "ios" : "android";
  //
  const _dev_recharge = recharge_def[devType];

  const pinitData = {
    base: [],
    first: [],
    day: [],
    card: [],
    typeList: [],
    tz_card: [],
    userPayInfo: {
      level: 1,
      exchangeRatio: 10,
      extraGoldRatio: 8,
      total_recharge: player_recharge_status.total_recharge,
      vip_exp: player_recharge_status.vip_exp,
    },
  };

  // const
  let payTypeKeys = ["first", "card", "day", "base"];

  // payTypeKeys = Object.keys(_dev_recharge).filter(
  //   (key) =>
  //     key != "level_info" &&
  //     key != "gift" &&
  //     key != "tz_card" &&
  //     key != "foot" &&
  //     key != "lspmdci"
  // );

  payTypeKeys.forEach((typeItem) => {
    const libaoIndex = Object.keys(_dev_recharge[typeItem]);

    libaoIndex.forEach((keyIndex) => {
      const payListItem = _dev_recharge[typeItem][keyIndex];

      let payListItemObj = {
        yield: parseInt(
          (Number(payListItem.gold) / Number(payListItem.rmb)) * 10
        ),
        gold: payListItem.gold,
        price: Number(payListItem.rmb).toFixed(2),
        product_id: payListItem.product_id,
        payIndex: keyIndex,
        isPay: true,
      };

      let isPayKeys = [];

      if (typeItem == "first") {
        isPayKeys = Object.keys(player_recharge_status.first);
        isPayKeys.includes(keyIndex) && (payListItemObj.isPay = false);
        payListItemObj.yield = 400;
      }
      if (typeItem == "day") {
        payListItemObj.isPay = player_recharge_status.day[keyIndex]
          ? player_recharge_status.day[keyIndex] < 3
          : true;
      }

      if (typeItem == "base") payListItemObj.isPay = true;
      if (typeItem == "card") {
        payListItemObj = {
          yield: 0,
          gold: payListItem.gold,
          price: Number(payListItem.rmb).toFixed(2),
          challengeCard: Number(payListItem.tiaozhan),
          daily_gold: Number(payListItem.day_gold),
          days: Number(payListItem.free_day),
          product_id: payListItem.product_id,
          payIndex: keyIndex,
          type: "",
          isPay: true,
        };
        payListItemObj.type =
          Number(payListItem.free_day) == 7 ? "week" : "month";

        if (payListItemObj.type == "week" && payListItemObj.price > 30)
          payListItemObj.type = "superWeek";

        if (payListItemObj.type == "month" && payListItemObj.price > 128)
          payListItemObj.type = "superMonth";

        payListItemObj.yield =
          (payListItemObj.days * payListItemObj.daily_gold +
            Number(payListItemObj.gold)) /
          Number(payListItemObj.price);

        payListItemObj.yield = parseInt(payListItemObj.yield * 10);
      }

      pinitData[typeItem].push(payListItemObj);
    });
  });

  payTypeKeys = ["first", "card", "day", "base", "exchange"];

  // getCurrentDevice() == "IOS" && (payTypeKeys = ["base", "card", "exchange"]);
  pinitData.typeList = payTypeKeys;

  const levelIndex =
    Number(player_recharge_status.level) - 1 <= 0
      ? 0
      : Number(player_recharge_status.level) - 1;
  pinitData.userPayInfo.exchangeRatio =
    recharge_def.level_info[levelIndex].exchange_radio;
  pinitData.userPayInfo.extraGoldRatio = parseInt(
    Number(recharge_def.level_info[levelIndex].recharge_add_radio) * 100
  );

  pinitData.userPayInfo.level = Number(player_recharge_status.level);

  const giftList = [];

  const giftKeys = Object.keys(_dev_recharge.gift);

  giftKeys.forEach((gift) => {
    const obj = {
      p_index: gift,
      buff: 0,
      yield: 0,
      price: 0,
      product_id: _dev_recharge.gift[gift].product_id,
      payIndex: gift,
      gold: Number(_dev_recharge.gift[gift].gold),
    };

    obj.buff = Number(_dev_recharge.gift[gift].buff) * 100;
    obj.price = Number(_dev_recharge.gift[gift].rmb);
    obj.yield = parseInt(obj.gold / obj.price) * 10;

    giftList.push(obj);
  });

  const giftData = {
    currentIndex: player_recharge_status.gift_info.index - 1,
    current_product_id:
      giftList[player_recharge_status.gift_info.index - 1].product_id,
    giftList: giftList,
    isBuy: player_recharge_status.gift_info.is_buy,
  };

  const tz_card_indexs = Object.keys(_dev_recharge.tz_card);
  const tiaozhan_card_pay_list = [];

  tz_card_indexs.forEach((pay_index) => {
    tiaozhan_card_pay_list.push({
      payIndex: pay_index,
      ..._dev_recharge.tz_card[pay_index],
    });
  });
  pinitData.tz_card = tiaozhan_card_pay_list;

  const gradLevels = [];
  recharge_def.level_info.forEach((litem, lindex) => {
    const levelData = {
      ...litem,
      level_text: gradLevelIcon[lindex].level_text,
      iconUrl: gradLevelIcon[lindex].iconUrl,
    };

    gradLevels.push(levelData);
  });

  const cardTaskIndexList = Object.keys(player_recharge_status.card);
  const cardTaskList = [];

  if (cardTaskIndexList.length > 0) {
    const cardName = ["周卡奖励", "超级周卡奖励", "月卡奖励", "超级月卡奖励"];

    cardTaskIndexList.forEach((cardIndex) => {
      const cardTask = {
        taskName: cardName[Number(cardIndex) - 1],
        current: 0,
        complete: player_recharge_status.card[cardIndex].free_day,
        taskType: "card",
        taskID: `index_${cardIndex}`,
        awardType: "gold",
        awardNumber: _dev_recharge.card[cardIndex].day_gold,
        statu: player_recharge_status.card[cardIndex].is_get ? "done" : "get",
      };
      cardTaskList.push(cardTask);
    });
  }

  return { pinitData, giftData, gradLevels, cardTaskList };
}

const findTaskName = (id) => {
  const task = taskIDList.find((item) => {
    return item.taskID == id;
  });

  if (task) return task.taskName;
  return "no->" + id;
};

export function initTaskPageData(info) {
  const data = [
    {
      title: "每日任务",
      taskType: "day",
      isAllDone: "undone", // 是否已经领取所有日任务奖励
      taskCount: 0, // 日任务数量
      taskDoneCount: 0, // 完成的日任务数量
      tzCardNum: 0, // 挑战卡数量
      tzCardTaskId: "103500",
      tzCardIsGet: false,

      taskList: [
        {
          title: "充值任务",
          taskTypes: ["recharge"],
          taskList: [],
        },
        {
          title: "金币任务",
          taskTypes: ["cost_gold"],
          taskList: [],
        },
        {
          title: "彩券任务",
          taskTypes: ["lottery"],
          taskList: [],
        },
        {
          title: "比赛任务",
          taskTypes: ["mgc_tz_win", "bbl_tz_win", "mgc_tz", "bbl_tz"],
          taskList: [],
        },
        {
          title: "游戏任务",
          taskTypes: ["mgc_game", "bbl_game", "mxt_game"],
          taskList: [],
        },
      ],
    },
    {
      title: "每周任务",
      taskType: "week",
      taskList: [
        {
          title: "充值任务",
          taskTypes: ["recharge"],
          taskList: [],
        },
        {
          title: "金币任务",
          taskTypes: ["cost_gold"],
          taskList: [],
        },
        {
          title: "彩券任务",
          taskTypes: ["lottery"],
          taskList: [],
        },
        {
          title: "比赛任务",
          taskTypes: ["mgc_tz_win", "bbl_tz_win", "mgc_tz", "bbl_tz"],
          taskList: [],
        },
        {
          title: "游戏任务",
          taskTypes: ["mgc_game", "bbl_game", "mxt_game"],
          taskList: [],
        },
      ],
    },
    {
      title: "登录任务",
      taskType: "login",
      taskList: [
        {
          title: "登录任务",
          taskTypes: ["logins"],
          taskList: [
            {
              taskName: findTaskName("200000"),
              complete: 10,
              taskType: "login",
              taskID: "200000",
              awardType: "gold",
              awardNumber: 51,
              statu: "undone",
              current: 0,
            },
            {
              taskName: findTaskName("200001"),
              complete: 20,
              taskType: "login",
              taskID: "200001",
              awardType: "gold",
              awardNumber: 71,
              statu: "undone",
              current: 0,
            },
            {
              taskName: findTaskName("200002"),
              complete: 30,
              taskType: "login",
              taskID: "200002",
              awardType: "gold",
              awardNumber: 91,
              statu: "undone",
              current: 0,
            },
            {
              taskName: findTaskName("200003"),
              complete: 40,
              taskType: "login",
              taskID: "200003",
              awardType: "gold",
              awardNumber: 111,
              statu: "undone",
              current: 0,
            },
            {
              taskName: findTaskName("200004"),
              complete: 50,
              taskType: "login",
              taskID: "200004",
              awardType: "gold",
              awardNumber: 131,
              statu: "undone",
              current: 0,
            },
            {
              taskName: findTaskName("200005"),
              complete: 60,
              taskType: "login",
              taskID: "200005",
              awardType: "gold",
              awardNumber: 151,
              statu: "undone",
              current: 0,
            },
            {
              taskName: findTaskName("200006"),
              complete: 70,
              taskType: "login",
              taskID: "200006",
              awardType: "gold",
              awardNumber: 171,
              statu: "undone",
              current: 0,
            },
            {
              taskName: findTaskName("200007"),
              complete: 80,
              taskType: "login",
              taskID: "200007",
              awardType: "gold",
              awardNumber: 191,
              statu: "undone",
              current: 0,
            },
            {
              taskName: findTaskName("200008"),
              complete: 90,
              taskType: "login",
              taskID: "200008",
              awardType: "gold",
              awardNumber: 211,
              statu: "undone",
              current: 0,
            },
            {
              taskName: findTaskName("200009"),
              complete: 100,
              taskType: "login",
              taskID: "200009",
              awardType: "gold",
              awardNumber: 501,
              statu: "undone",
              current: 0,
            },
          ],
        },
      ],
    },
  ];

  let currentLoginDays = 0;
  let currentLoginID = 0;
  let currentLoginIsget = false;
  let currentLoginIndex = 0;

  let tiaozhan_card_task = {};

  info.task_info.forEach((task) => {
    const taskOBJ = {
      taskName: "",
      current: 0,
      complete: 3,
      taskType: "mgc_tz",
      taskID: "10021",
      awardType: "dashi",
      awardNumber: 9,
      statu: "undone",
    };

    if (task.update_type == "day" || task.update_type == "week") {
      task.update_type == "day" && data[0].taskCount++;
      taskOBJ.taskName = findTaskName(task.task_id); //  在疯狂魔鬼城30币房中击杀千年南瓜怪3次;
      taskOBJ.taskID = task.task_id;
      taskOBJ.current = task.cur_num;
      taskOBJ.complete = task.complete;
      taskOBJ.taskType = task.task_type;
      taskOBJ.awardType = task.reward.type;
      taskOBJ.awardNumber = task.reward.num;

      if (task.reward.type == "dashi_mul") {
        taskOBJ.awardNumber = Number(task.reward.num) * Number(task.complete);
      }

      taskOBJ.taskName.includes("xx") &&
        (taskOBJ.taskName = taskOBJ.taskName.replace(
          /xx/g,
          `${task.complete}`
        ));

      if (taskOBJ.taskName.includes("yy")) {
        if (task.exchange_limit && task.exchange_limit > 0) {
          taskOBJ.taskName = taskOBJ.taskName.replace(
            /yy/g,
            `${task.exchange_limit}`
          );
        } else {
          taskOBJ.taskName = taskOBJ.taskName.replace(/yy/g, `任意`);
        }
      }

      task.exchange_limit &&
        task.exchange_limit > 0 &&
        taskOBJ.taskName.includes("任意") &&
        (taskOBJ.taskName = taskOBJ.taskName.replace(
          /任意/g,
          `${task.exchange_limit}币`
        ));

      // taskOBJ.statu = task.is_get;
      if (task.is_get) {
        taskOBJ.statu = "done";
        task.update_type == "day" && data[0].taskDoneCount++;
      } else if (taskOBJ.current >= taskOBJ.complete) {
        taskOBJ.statu = "get";
      } else {
        taskOBJ.statu = "undone";
      }

      const _index = task.update_type == "day" ? 0 : 1;

      data[_index].taskList.some((taskTypeItem) => {
        if (taskTypeItem.taskTypes.includes(taskOBJ.taskType)) {
          taskTypeItem.taskList.push(taskOBJ);
          return true;
        } else return false;
      });

      // data[_index].taskList.push(taskOBJ);
    }
    if (task.task_type == "login") {
      currentLoginDays = task.cur_num;
      currentLoginID = task.task_id;
      currentLoginIsget = task.is_get;
    }

    if (task.task_type == "tz_card") {
      tiaozhan_card_task = task;
    }
  });

  // debugger;

  currentLoginIndex = data[2].taskList[0].taskList.findIndex(
    (lItem) => lItem.taskID == currentLoginID
  );

  // debugger;

  data[2].taskList[0].taskList.forEach((item, index) => {
    item.current = currentLoginDays;

    // 当前是否领取了
    item.statu = currentLoginIndex <= index ? "undone" : "done";

    if (currentLoginIndex == index) {
      currentLoginIsget && (item.statu = "done");
      !currentLoginIsget &&
        currentLoginDays >= item.complete &&
        (item.statu = "get");
    }
  });

  data.forEach((item) => {
    item.taskList.forEach((citem) => {
      citem.taskList.sort((a, b) => a.complete - b.complete);
    });
  });

  // 已经领取
  // if (info.is_get_all) {
  //   data[0].isAllDone = true;
  // }

  // 全部日任务完成 但没有领取
  if (data[0].taskCount == data[0].taskDoneCount && !info.is_get_all)
    data[0].isAllDone = "get";

  // 没有完成所有日任务 也没有领取
  if (data[0].taskCount > data[0].taskDoneCount && !info.is_get_all)
    data[0].isAllDone = "undone";

  // 全部日任务完成 也成功领取
  if (data[0].taskCount == data[0].taskDoneCount && info.is_get_all)
    data[0].isAllDone = "done";

  // 领取挑战卡
  data[0].tzCardIsGet = tiaozhan_card_task.is_get ? "done" : "get";
  data[0].tzCardNum = tiaozhan_card_task.reward.num;
  data[0].tzCardTaskId = tiaozhan_card_task.task_id;

  // console.log("tiaozhan_card_task", tiaozhan_card_task);

  // console.log("datadatadatadata", data);

  return data;
}

export function isToday(timestamp, currentTimestamp = 0) {
  const date = new Date(timestamp * 1000);
  const currentDate =
    currentTimestamp == 0 ? new Date() : new Date(currentTimestamp * 1000);

  const dateYear = date.getFullYear();
  const dateMonth = date.getMonth();
  const dateDay = date.getDate();

  const currentDateYear = currentDate.getFullYear();
  const currentDateMonth = currentDate.getMonth();
  const currentDateDay = currentDate.getDate();

  // 判断两个日期对象是否相等
  return (
    dateYear == currentDateYear &&
    dateMonth == currentDateMonth &&
    dateDay == currentDateDay
  );
}

export function initSignData(info) {
  const clinfo = JSON.parse(JSON.stringify(info));

  const { time, server_time, reward, index } = clinfo.sign_data;

  // reward

  const signData = {
    index: index,
    isToday: false,
    sign_list: [
      {
        url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/day_1.png",
        is_sign: false,
      },
      {
        url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/day_2.png",
        is_sign: false,
      },
      {
        url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/day_3.png",
        is_sign: false,
      },
      {
        url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/day_4.png",
        is_sign: false,
      },
      {
        url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/day_5.png",
        is_sign: false,
      },
      {
        url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/day_6.png",
        is_sign: false,
      },
      {
        url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sign/day_7.png",
        is_sign: false,
      },
    ],
  };

  signData.isToday = isToday(time, server_time);

  reward.forEach((it, indx) => {
    if (it.is_get) {
      signData.sign_list[indx].is_sign = it.is_get;
    }
  });

  if (index == 8) {
    signData.isToday = false;
  }

  return signData;
}

export function uidStringLengthFixed(uid) {
  // const lengthNumber = 6;
  // const uidString = uid.toString();
  // let newUID = "";
  // const uIDlength = uidString.length;
  // const Difference = lengthNumber - uIDlength;
  // const uidArr = uidString.split("");
  // for (let i = 0; i < Difference; i++) {
  //   uidArr.unshift("0");
  // }
  // newUID = uidArr.join("");

  // 最新写法
  const uidString = uid.toString();
  const newUID = uidString.padStart(6, "0");
  return newUID;
}

export function decodeBase64String(code) {
  if (code == undefined || code == null) return "_";
  try {
    const base64String = code;
    const decodedBytes = atob(base64String);
    const arrayBuffer = new Uint8Array(decodedBytes.length);
    for (let i = 0; i < decodedBytes.length; i++) {
      arrayBuffer[i] = decodedBytes.charCodeAt(i);
    }
    const decoder = new TextDecoder();
    const decodedString = decoder.decode(arrayBuffer);
    return decodedString;
  } catch (error) {
    return code;
  }
}

export function initRecordData(info, payData) {
  const cloneInfo = JSON.parse(JSON.stringify(info));
  // 只要五十条信息 模拟分页 !!!
  const spliceStart = 50;
  cloneInfo.length > spliceStart &&
    cloneInfo.splice(spliceStart, cloneInfo.length);

  const recordData = {
    type: "none",
    list: [],
  };

  if (cloneInfo.length == 0) return recordData;

  recordData.type = cloneInfo[0].type;
  cloneInfo.forEach((logItem) => {
    const logObj = {
      time: formatTimestamp(logItem.time),
      text: findRecordText(logItem, payData),
      text2: "",
      type: logItem.type,
      lottery: logItem.lottery || 0,
      gold: logItem.gold || 0,
      tiaozhan_ticket: logItem.tiaozhan_ticket || 0,
      dashi_score: logItem.dashi_score || 0,
      add_gold: 0,
      item_type: logItem.item_type || "",
      add_mul: 0,
      rmb_mul: logItem.rmb_mul || 0,
    };

    if (logItem.s_type && logItem.s_type == "tz_card") {
      logObj.tiaozhan_ticket = Math.abs(payData.tz_card[logItem.index - 1].num);
    }

    if (
      logItem.item_type &&
      logItem.item_type == "zhekou_card" &&
      logItem.rmb_mul < 1
    ) {
      const _parice = (
        payData[logItem.s_type][Number(logItem.index) - 1].price *
        logItem.rmb_mul
      ).toFixed(2);

      logObj.text2 = "使用了" + logItem.rmb_mul * 10 + "折卡 " + _parice + "￥";
    }

    if (
      logItem.item_type &&
      logItem.item_type == "jiacheng_card" &&
      logItem.add_mul > 1
    ) {
      logObj.text2 =
        "使用了" +
        parseInt((Number(logItem.add_mul) - 1) * 100) +
        "%" +
        "加成卡";

      logObj.add_mul = parseInt(
        Number(logItem.base_gold) * (Number(logItem.add_mul) - 1)
      );
    }

    if (logItem.base_gold && logItem.base_gold > 0) {
      logObj.add_gold = parseInt(logItem.base_gold * logItem.add_radio);
      logObj.gold = logItem.base_gold;
    }

    if (logItem.tz_gold && logItem.tz_gold != 0 && logItem.gold == 0) {
      logObj.gold = -Math.abs(logItem.tz_gold);
    }

    if (logItem.tz_gold && logItem.tz_gold != 0 && logItem.gold < 0) {
      logObj.gold = -Math.abs(logItem.tz_gold);
    }
    recordData.list.push(logObj);
  });
  return recordData;
}

const findRecordText = (item, payData) => {
  const type = item.type;
  const s_type = item.s_type ? item.s_type : "none";
  const gameName = findGameInfoData(item.game_name).game_name;

  const s_typeName = s_typeTexts.find(
    (s_typeItem) => s_typeItem.type == s_type
  );

  // 游戏记录
  if (type == "game_log") {
    const m_index = parseInt(item.machine_index) || "无";
    const d_index = parseInt(item.device_index) || "无";

    // 魔鬼城10币挑战赛1号房2号位;
    if (item.is_tiaozhan || item.tz_exchange)
      return `${gameName}${item.tz_exchange}币挑战赛${m_index}号房${d_index}号位`;

    return `${gameName}${m_index}号房${d_index}号位`;
  }

  // 金币记录
  if (type == "gold_log") {
    // 挑战成功
    if (s_type == "tiaozhan_success") {
      // 魔鬼城10币挑战赛挑战成功金币奖励500;
      return `${gameName}${item.tz_exchange}币挑战赛挑战成功`;
    }
    // 投币
    if (s_type == "up_coin") {
      const m_index = parseInt(item.machine_index) || "无";
      const d_index = parseInt(item.device_index) || "无";

      //  魔鬼城10币挑战赛1号房2号位投币 —500
      if (item.tz_gold || item.tz_exchange)
        return `${gameName}${item.tz_exchange}币挑战赛${m_index}号房${d_index}号位投币`;

      return `${gameName}${m_index}号房${d_index}号位${s_typeName.text}`;
    }

    // 投币失败
    if (s_type == "device_up_coin_error") return "投币失败返回";

    // 比赛
    if (s_type == "active")
      return `${decodeBase64String(item.active_name)}参赛费`;

    if (s_type == "duobao_exchange") return `秘境夺宝兑换`;
    if (s_type == "duobao_bet") return `秘境夺宝投币`;

    if (s_type == "apply_tiaozhan")
      return `${gameName}${item.tz_exchange}币挑战赛报名`;

    if (s_type == "recharge") {
      // console.log("payName.type----------------", payName);
      // if (item.recharge_type == "gift") return  + "29.9￥";

      if (item.recharge_type == "guoqing") {
        if (item.index == 1) return "购买18￥宝箱礼包";
        if (item.index == 2) return "购买46￥宝箱礼包";
        if (item.index == 3) return "购买99￥宝箱礼包";
        if (item.index == 4) return "购买188￥宝箱礼包";
        if (item.index == 5) return "购买899￥宝箱礼包";
      }
      if (item.recharge_type == "foot") {
        if (item.index == 1) return "购买1888￥囤币礼包";
        if (item.index == 2) return "购买268￥囤币礼包";
        if (item.index == 3) return "购买18￥囤币礼包";
      }

      if (item.recharge_type == "lspmdci") {
        if (item.index == 1) return "购买158￥联赛buff礼包";
        if (item.index == 2) return "购买398￥联赛buff礼包";
        if (item.index == 3) return "购买888￥联赛buff礼包";
      }

      const payName = s_typeTexts.find(
        (stitem) => stitem.type == item.recharge_type
      );
      if (!payName) return s_typeName.text;
      const payInfoData = payData[payName.type].find((pItem) => {
        return pItem.payIndex == item.index;
      });

      if (payInfoData) {
        return "购买" + s_typeName.text + payInfoData.price + "￥";
      }

      return "购买" + payName.text;
    }

    // 其他
    if (!s_typeName) return `记录s_type --> ${s_type}`;

    return s_typeName.text;
  }

  // 充值记录
  if (type == "recharge_log") {
    if (s_type == "gift") return "购买" + s_typeName.text + "29.9￥";

    if (s_type == "guoqing") {
      if (item.index == 1) return "购买18￥宝箱礼包";
      if (item.index == 2) return "购买46￥宝箱礼包";
      if (item.index == 3) return "购买99￥宝箱礼包";
      if (item.index == 4) return "购买188￥宝箱礼包";
      if (item.index == 5) return "购买899￥宝箱礼包";
    }

    if (s_type == "foot") {
      if (item.index == 1) return "购买1888￥囤币礼包";
      if (item.index == 2) return "购买268￥囤币礼包";
      if (item.index == 3) return "购买18￥囤币礼包";
    }

    if (s_type == "lspmdci") {
      if (item.index == 1) return "购买158￥联赛buff礼包";
      if (item.index == 2) return "购买398￥联赛buff礼包";
      if (item.index == 3) return "购买888￥联赛buff礼包";
    }

    if (s_type == "tz_card") {
      const pINdex = Number(item.index) - 1 < 0 ? 0 : Number(item.index) - 1;
      if (!payData.tz_card[pINdex].rmb)
        return JSON.stringify(payData.tz_card[pINdex]);

      return "购买挑战卡" + payData.tz_card[pINdex].rmb + "￥";
    }

    const payInfoData = payData[s_type].find((pItem) => {
      return pItem.payIndex == item.index;
    });

    if (payInfoData) return "购买" + s_typeName.text + payInfoData.price + "￥";

    if (!s_typeName) return `充值——>s_type ${s_type}`;
    return "购买" + s_typeName.text;
  }

  // 挑战卡记录
  if (type == "tiaozhan_ticket_log") {
    // 魔鬼城10币挑战赛报名;
    if (s_type == "apply_tiaozhan")
      return `${gameName}${item.tz_exchange}币挑战赛报名`;

    if (!s_typeName) return `挑战卡——>s_type ${s_type}`;

    return s_typeName.text;
  }

  // 积分记录
  if (type == "lottery_log") {
    const m_index = parseInt(item.machine_index) || "无";
    const d_index = parseInt(item.device_index) || "无";
    // 挑战
    // 魔鬼城10币挑战赛1号房2号位结算;
    if (item.is_tiaozhan || item.tz_gold || item.tz_exchange)
      return `${gameName}${item.tz_exchange}币挑战赛${m_index}号房${d_index}号位结算`;

    if (s_type == "duobao_exchang")
      return `${gameName}${m_index}号房${d_index}号位`;

    if (s_type == "game_log") return `${gameName}${m_index}号房${d_index}号位`;

    if (s_type == "game_task") return "游戏任务";

    if (!s_typeName) return `积分——>s_type ${s_type}`;

    return s_typeName.text;
  }

  //大师分记录
  if (type == "dashi_score_log") {
    // 魔鬼城10币挑战赛挑战成功大师分 + 1;
    if (s_type == "tiaozhan")
      return `${gameName}${item.tz_exchange}币挑战赛挑战成功`;

    if (!s_typeName) return `大师分——>s_type ${s_type}`;
    return s_typeName.text;
  }

  return `type_${type}_s_type_${s_type}`;
};

export const initGameRewardList = (info) => {
  if (!Array.isArray(info)) return [];
  if (info.length == 0) return [];

  const typeListText = [
    {
      type: "nangua_rank",
      text: "南瓜排行榜",
    },
    {
      type: "bbl_rank",
      text: "爆爆乐高分榜",
    },
    {
      type: "mgc_rank",
      text: "魔鬼城排行榜",
    },
    {
      type: "mxt_rank",
      text: "马戏团小丑榜",
    },
    {
      type: "tianzhan_rank",
      text: "挑战排行榜",
    },
    {
      type: "tianqi_jidu",
      text: "天梯赛季度排行榜",
    },
    {
      type: "tianqi_month",
      text: "天梯赛月排行榜",
    },
    {
      type: "tianqi_week",
      text: "天梯赛周排行榜",
    },
    {
      type: "tribe_rank",
      text: "游戏排行榜",
    },
    {
      type: "mxttribe_rank",
      text: "马戏团排行榜",
    },
    {
      type: "mgctribe_rank",
      text: "魔鬼城排行榜",
    },
    {
      type: "bbltribe_rank",
      text: "爆爆乐排行榜",
    },
    {
      type: "hw3tribe_rank",
      text: "海王III排行榜",
    },
    {
      type: "msqy_rank",
      text: "魔术奇缘排行榜",
    },
    {
      type: "hw3_rank",
      text: "海王III觉醒榜",
    },
    {
      type: "invite_reward",
      text: "邀请奖励",
    },
    {
      type: "active_reward",
      text: "比赛奖励",
    },
    {
      type: "lottery_tz_rank",
      text: "积分挑战赛",
    },
    {
      type: "foot_vs",
      text: "欧洲杯大竞猜",
    },
    {
      type: "foot_rank",
      text: "欧洲杯竞猜排行榜",
    },
    {
      type: "day_rank",
      text: "每日排行榜",
    },
    {
      type: "guoqing",
      text: "宝箱奖励",
    },
    {
      type: "title",
      text: "成就奖励",
    },
  ];

  function findTypeName(type, index) {
    if (type == "recharge_level") {
      return `充值等级达成奖励`;
    }
    if (type == "invite_reward") {
      return `邀请奖励`;
    }
    if (type == "foot_vs") {
      if (!Array.isArray(index)) return "欧洲杯大竞猜";

      const nation_config = {
        1: "德国",
        2: "苏格兰",
        3: "匈牙利",
        4: "瑞士",
        5: "西班牙",
        6: "克罗地亚",
        7: "意大利",
        8: "阿尔巴尼亚",
        9: "波兰",
        10: "荷兰",
        11: "斯洛文尼亚",
        12: "丹麦",
        13: "塞尔维亚",
        14: "英格兰",
        15: "罗马尼亚",
        16: "乌克兰",
        17: "比利时",
        18: "斯洛伐克",
        19: "奥地利",
        20: "法国",
        21: "土耳其",
        22: "格鲁吉亚",
        23: "葡萄牙",
        24: "捷克",
      };

      return `欧洲杯竞猜 ${nation_config[index[0]]} vs ${
        nation_config[index[1]]
      } `;
    }

    const curType = typeListText.find((tItem) => tItem.type == type);
    if (!curType) return type;

    if (!index) return curType.text;

    if (index) return curType.text + `第${index}名奖励`;
  }

  const list = [];

  info.forEach((item) => {
    const awardItem = {
      type: item.reward_type,
      num: item.gold,
      key: item.key,
      time: 1,
      typeText: findTypeName(item.type, item.index),
    };

    if (item.time) {
      awardItem.time = formatTimestamp(item.time);
    } else {
      awardItem.time = new Date().getMonth() + 1 + "/" + new Date().getDay();
    }

    list.push(awardItem);
  });

  return list;
};

/**
 *
 * @param {String} username
 * @returns {Boolean}
 */

export const validateUsername = (username) => {
  // 不能为空
  if (username === "") return false;

  // 不能有空格
  if (/\s/.test(username)) return false;

  // 不能以特殊字符开头
  const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/;
  if (specialCharacters.test(username)) return false;

  // 定义敏感词列表  后续 申请 接入第三方敏感词库
  const sensitiveWords = ["广告", "卖币", "微信", "他妈的", "傻逼"];

  // 检查敏感词
  for (let i = 0; i < sensitiveWords.length; i++) {
    if (username.includes(sensitiveWords[i])) return false; // 返回不合法
  }

  // 检查是否包含非法字符：
  // const illegalCharacters = /[^a-zA-Z0-9]/;
  // if (illegalCharacters.test(username)) return false;

  // 昵称验证通过
  return true; // 返回合法
};

// 游戏任务
export const initGameViewTaskData = (info) => {
  const initData = {
    gameName: info[0].game_name,
    allTaskIndex: -1,
    allTaskName: "完成今日所有任务",
    allTaskReward: 0,
    allTaskRewardType: "lottery",
    allTaskStatu: 1, // 0 未完成 1 待领取 2 已完成
    getCount: 0,
    taskList: [],
  };

  let allTaskData = {};

  if (info[info.length - 1].type_name == "all_complete") {
    allTaskData = info[info.length - 1];
  } else {
    const findData = info.find((task) => {
      return task.type_name == "all_complete";
    });

    allTaskData = findData;
  }

  initData.allTaskIndex = allTaskData.index;
  initData.allTaskReward = allTaskData.reward;
  initData.allTaskRewardType = allTaskData.reward_type;
  initData.allTaskStatu = allTaskData.is_get ? 2 : 1;

  info.forEach((task) => {
    if (task.type_name != "all_complete") {
      const obj = {
        taskName: "undefined",
        taskIndex: 1,
        task_type: task.type_name,
        reward: 100,
        rewardType: "lottery",
        currentNumer: 100,
        complete: 200,
        statu: 0,
      };

      if (task.type_name == "up_coin") {
        obj.taskName = "累计投币";
      }

      if (task.type_name == "collect") {
        obj.taskName = `${findCollectCardName(task.class_id, task.game_name)}`;
        obj.task_type = obj.task_type + "_" + task.class_id;
      }

      const step_index = Number(task.step) - 1;
      const step_data = task.data[step_index];

      obj.taskIndex = task.index;
      obj.currentNumer = task.num;
      obj.complete = step_data.complete;
      obj.reward = step_data.reward;
      obj.rewardType = step_data.reward_type;

      obj.statu = task.num >= step_data.complete && !step_data.is_get ? 1 : 0;

      step_data.is_get && (obj.statu = 2);

      if (obj.statu == 1) {
        initData.getCount++;
      }
      if ((obj.statu == 0 || obj.statu == 1) && initData.allTaskStatu == 1) {
        initData.allTaskStatu = 0;
      }

      initData.taskList.push(obj);
    }
  });

  return initData;
};

// 初始化比赛数据
export const initActiveConfigData = (info) => {
  const initActiveConfig = [];

  info.forEach((item) => {
    const initActiveConfigObj = {
      title: "魔鬼城3000金币积分挑战赛",
      statu: 1,
      gold_select_list: [
        // {
        //   gold: 100,
        //   sign_index: 1,
        //   sign_num: 1,
        // },
      ],
      exchange: item.config.exchange,
      join_num_limit: item.config.join_num_limit,
      join_num: item.join_num,
      enter_num_limit: item.config.enter_num_limit,
      match_time: Number(item.config.match_time) / 60,
      is_up_coin: item.config.is_up_coin,
      end_time: "03-05 17:00",
      start_time: "03-05 17:00",
      active_index: item.active_index,
      game_name: item.game_name,
      level_name:
        item.config.level_limit > 0
          ? gradLevelIcon[Number(item.config.level_limit) - 1].level_text
          : "",
      level_limit: item.config.level_limit,
      sign_up_gold: 0,
      ticket: 0,
      reward_data_list: [],
      active_type: item.active_type,
      rank_num: item.config.rank_num,
    };

    // 默认是开启的
    initActiveConfigObj.statu = 1;

    if (item.status == "waitting") initActiveConfigObj.statu = 0;
    // 等待结算
    if (item.status == "wait_settle_accounts") initActiveConfigObj.statu = 3;

    // 已经结算完毕 完全结束了
    if (item.status == "ending") initActiveConfigObj.statu = 2;

    const sign_up_gold = item.config.sign_up_info?.sign_up_gold ?? 0;
    const ticket = item.config.sign_up_info?.ticket ?? 0;

    if (sign_up_gold > 0) initActiveConfigObj.sign_up_gold = sign_up_gold;
    if (ticket > 0) initActiveConfigObj.ticket = ticket;
    // 入座携带费
    if (item.config.sign_up_info) {
      const sign_index_datas = Object.keys(item.config.sign_up_info.site_gold);
      sign_index_datas.forEach((signIndex) => {
        initActiveConfigObj.gold_select_list.push({
          ...item.config.sign_up_info.site_gold[signIndex],
          sign_index: Number(signIndex),
        });
      });
    }

    // 结束时间
    initActiveConfigObj.end_time = formatTimestamp(item.config.end_time);
    // 开始时间
    initActiveConfigObj.start_time = formatTimestamp(item.config.start_time);

    // 奖励列表
    const reward_data_list = [];
    if (item.config.reward_data) {
      const reward_data_keys = Object.keys(item.config.reward_data);

      reward_data_keys.forEach((rewardItem) => {
        const obj = {
          ...item.config.reward_data[rewardItem],
          reward_index: rewardItem,
        };

        if (obj.reward_type == "gift")
          obj.reward.name = decodeBase64String(obj.reward.name);
        reward_data_list.push(obj);
      });
    }

    initActiveConfigObj.title = decodeBase64String(item.config.active_name);
    initActiveConfigObj.reward_data_list = reward_data_list;
    initActiveConfig.unshift(initActiveConfigObj);
  });

  return initActiveConfig;
};

export const initActiveRankData = (info) => {
  const list = [];
  if (info.length == 0) return [null];

  info.forEach((item) => {
    const rankItem = {
      enter_num: item.enter_num,
      num: item.num,
      record_list: [],
      record_num: 0,
      head: item.head != "" ? item.head : item.head_frame,
      uid: item.uid,
      active_type: item.active_type,
      player_name: decodeBase64String(item.player_name),
      recharge_level: item.recharge_level,
      game_name: item.game_name,
      tianti_level: item.tianti_level,
      active_index: item.active_index,
    };

    const recordClassIDs = Object.keys(item.record_class);

    if (recordClassIDs.length > 0) {
      recordClassIDs.forEach((classId) => {
        rankItem.record_num += Number(item.record_class[classId]);
        rankItem.record_list.push({
          ...collectClassIDs[item.game_name][classId],
          num: item.record_class[classId],
        });
      });
    }

    list.push(rankItem);
  });

  return list;
};

export const initCardBagData = (info) => {
  const cardBagList = {
    waitting: [],
    expired: [],
    using: [],
  };
  if (!Array.isArray(info) || info.length == 0) return cardBagList;

  const cardNameList = [
    {
      type: "zhekou_card",
      name: "折扣卡",
    },
    {
      type: "jiacheng_card",
      name: "加成卡",
    },
    {
      type: "jifen_card",
      name: "积分卡",
    },
    {
      type: "tianti_buff_card",
      name: "联赛buff加成卡",
    },
  ];

  info.forEach((item) => {
    const cardItem = {
      type: item.item_type,
      card_use_name: "",
      card_name: "",
      item_id: item.item_id,
      start_time: formatTimestamp(item.start_time, "yyyy-mm-dd"),
      end_time: formatTimestamp(
        Number(item.start_time) + Number(item.use_limit),
        "yyyy-mm-dd"
      ),
      statu: item.status,
      buff_end_time:
        item.item_type == "tianti_buff_card" ? item.buff_end_time : 0,
      add_mul: Number(item.add_mul),
    };

    const cardName = cardNameList.find((card) => {
      return card.type == item.item_type;
    });

    if (cardName) cardItem.card_name = cardName.name;

    const _add_mul = new Decimal(cardItem.add_mul);

    const add_mul_type =
      _add_mul.toNumber() > 1
        ? _add_mul.sub(1).mul(100).toNumber() + "%"
        : _add_mul.mul(100).div(10).toNumber() + "折";

    if (cardItem.type == "jifen_card") {
      cardItem.card_use_name = "积分加成" + add_mul_type;
    }
    if (cardItem.type == "jiacheng_card") {
      cardItem.card_use_name = "充值加成" + add_mul_type;
    }
    if (cardItem.type == "zhekou_card") {
      cardItem.card_use_name = "充值" + add_mul_type + "卡";
    }
    if (cardItem.type == "tianti_buff_card") {
      cardItem.card_use_name = "联赛积分加成" + add_mul_type;
    }

    cardBagList[item.status].push(cardItem);
  });

  return cardBagList;
};

export function transformGameData(data) {
  const map = {};
  data.forEach((item) => {
    map[item.id] = { ...item, children: [] };
  });

  data.forEach((item) => {
    const parent = map[item.parent_id];
    if (parent) {
      parent.children.push(item);
    }
  });

  return Object.values(map).filter((item) => item.parent_id == 0);
}

export function propValueUnificationString(value) {
  if (typeof value === "number") return value + "px";
  return value;
}
