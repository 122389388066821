<template>
    <div class="user_agreement" :style="{
        paddingTop: store.areaInsetTopValue + 'px',
    }">
        <van-nav-bar title="用户协议" left-arrow @click-left="router.back()" :border="false" :placeholder="true" />
        <div class="content">
            <h1 style="text-align: center;">极客竞技用户协议</h1>
            <p>提示条款</p>
            <p>欢迎您(下称“您”或者“用户”) 与极客竞技APP平台共同签署本《极客竞技APP用户服务协议》 (下称“本协议”)并使用极客竞技APP平台服务!
                服务条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述</p>

            <p>本协议是您与极客竞技APP平台所有者之间就APP平台服务等相关事宜所订立的契约，请您仔细阅读本协议，本协议即构成对双方有约束力的法律文件。</p>

            <p>一．账号注册</p>
            <p>1、用户在使用本服务前需要注册一个“极客竞技”账号。“极客竞技”账号可使用微信或手机注册，请用户使用尚未与“极客竞技”账号绑定、并绑有实名认证银行卡的微信账号，以及未被极客竞技APP根据本协议封禁的微信账号注册“极客竞技”账号。极客竞技APP可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。
            </p>
            <p>2、鉴于“极客竞技”账号可能包含手机绑定注册方式，您同意极客竞技APP在注册时将使用您提供的手机号码及或自动提取您的手机号码及自动提取您的手机设备识别码等信息用于注册。</p>
            <p>3、在用户注册及使用本服务时，极客竞技APP票要搜集能识别用户身份的个人信息以便极客竞技APP可以在必要时联系用户，或为用户提供更好的使用体验。极客竞技APP搜集的信息包括但不限于用户的微信昵称、性别、年龄、地址、所属行业、兴趣爱好.常出没的地方、个人说明;极客竞技APP同意对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。
            </p>

            <p>二．服务内容</p>
            <p>1、本服务的具体内容由极客竞技APP根据实际情况提供，包括但不限于授权用户通过其账号进行在线游戏、直播、发布弹幕、接收邮寄内容。极客竞技APP可以对其提供的服务予以变更，且极客竞技APP提供的服务内容可能随时变更;用户将会收到极客竞技APP关于服务变更的通知。
            <p>2、极客竞技APP提供的服务有免费服务与收费服务用户可以通过付费方式购买收费服务，具体方式为:用户通过微信支付、支付宝或其他“极客竞技”平台提供的付费途径支付一定数额的人民币购买“极客竞技”平台的虚拟货币--极客竞技游戏币，然后根据极客竞技APP公布的资费标准以极客竞技币购买用户欲使用的收费服务，从而获得收费服务使用权限。对于收费服务，极客竞技APP会在用户使用之前给予用户明确的提示，只有用户根据提示确认其同意按照前述支付方式支付费用并完成了支付行为，用户才能使用该等收费服务。支付行为的完成以银行或第三方支付平台生成“支付已完成”的确认通知为准。
            </p>

            <p>三、用户个人隐私信息保护</p>
            <p>1、用户在注册账号或使用本服务的过程中，可能需要填写或提交一些必要的信息，如法律法规、规章规范性文件 (以下称“法律法规”)
                规定的需要填写的身份信息。如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。</p>
            <p>2、个人隐私信息是指涉及用户个人身份或个人院私的信息，比如，用户真实姓名、身份证号、手机号码、手机设备识别码、IP地址、用户聊天记录。非个人隐私信息是指用户对本服务的操作状态以及使用习惯等明确且客观反映在极客竞技APP服务器端的基本记录信息、个人隐私信息范围外的其它普通信息，以及用户同意公开的上述隐私信息。
            </p>
            <p>3、尊重用户个人隐私信息的私有性是极客竞技APP的一贯制度，极客竞技APP将采取技术措施和其他必要措施，确保用户个人隐私信息安全，防止在本服务中收集的用户个人隐私信息泄露、毁损或丢失。在发生前述情形或者极客竞技APP发现存在发生前述情形的可能时，将及时采取补救措施。
            </p>
            <p>4、极客竞技APP未经用户同意不向任何第三方公开、透露用户个人隐私信息。但以下特定情形除外:</p>
            <p>(1)极客竞技APP根据法律法规规定或有权机关的指示提供用户的个人隐私信息;</p>
            <p>(2)由于用户将其用户密码告知他人或与他人共享注册帐户与密码，由此导致的任何个人信息的泄漏，或其他非因极客竞技APP原因导致的个人隐私信息的泄露;</p>
            <p>(3)用户自行向第三方公开其个人隐私信息;</p>
            <p>(4)用户与极客竞技APP及合作单位之间就用户个人隐私信息的使用公开达成约定，极客竞技APP因此向合作单位公开用户个人隐私信息;</p>
            <p>(5)任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露。</p>
            <p>5、用户同意极客竞技APP可在以下事项中使用用户的个人隐私信息:</p>
            <p>(1)极客竞技APP向用户及时发送重要通知，如软件更新、本协议条款的变更;</p>
            <p>(2)极客竞技APP内部进行审计、数据分析和研究等，以改进极客竞技APP的产品、服务和与用户之间的沟通;</p>
            <p>(3)依本协议约定，极客竞技APP管理、审查用户信息及进行处理措施;</p>
            <p>(4)适用法律法规规定的其他事项。</p>
            除上述事项外，如未取得用户事先同意，极客竞技APP不会将用户个人隐私信息使用于任何其他用途。</p>
            <p>6、用户确认，其肖像及声音信息为非个人隐私信息，用户成功注册“极客竞技”账号并授权“极客竞技”app使用前置摄像头及麦克风权限，视为确认授权极客竞技APP提取、公开及使用用户的肖像及声音信息。用户肖像及声音信息将作为用户公开资料之一，由极客竞技APP向其他用户公开。如用户需要终止向其他用户公开其肖像或声音信息，可随时自行设置为不允许授权摄像头或麦克风。
            </p>

            <p>四、内容规范</p>
            <p>1、本条所述内容是指用户使用本服务过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音等发送、回复或自动回复消息，以及其他使用账号或本服务所产生的内容。
            </p>
            <p>2、用户不得利用“极客竞技”账号或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容:</p>
            <p>(1)反对宪法所确定的基本原则的;</p>
            <p>(2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的;</p>
            <p>(3)损害国家荣誉和利益的;</p>
            <p>(4)煽动民族仇恨、民族歧视，破坏民族团结的</p>
            <p>(5)破坏国家宗教政策，宣扬邪教和封建迷信的</p>
            <p>(6)散布谣言，扰乱社会秩序，破坏社会稳定的;</p>
            <p>(7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的;</p>
            <p>(8)侮辱或者诽谤他人，侵害他人合法权益的;</p>
            <p>(9)含有法律、行政法规禁止的其他内容的信息。</p>
            <p>(7)擅自强行更改极客竞技的计算机系统，或威胁侵入系统;</p>
            <p>(8)擅自传播谣言，用各种手段来毁损极客竞技的名誉与妨碍极客竞技的正常营业;</p>
            <p>(9)含有法律、行政法规禁止的其他内容的信息。</p>

            <p>五、使用规则</p>
            <p>1、用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表极客竞技APP的观点、立场或政策，极客竞技APP对此不承担任何责任。</p>
            <p>2、用户不得利用“极客竞技”账号或本服务进行如下行为:</p>
            <p>(1)提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的;</p>
            <p>(2)强制、诱导其他用户关注、点击链接页面或分享信息的;</p>
            <p>(3)盗刷游戏币、买卖游戏币、游戏造假、作弊的;</p>
            <p>(4)散布谣言、虚构事实、攻击官方、隐瞒真相以误导、欺骗他人的;</p>
            <p>(5)利用技术手段批量建立虚假账号的;</p>
            <p>(6)利用“极客竞技”账号或本服务从事任何违法犯罪活动的;</p>
            <p>(7)制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的;</p>
            <p>(8)其他违反法律法规规定、侵犯其他用户合法权益、干扰“极客竞技”正常运营或极客竞技APP未明示授权的行为。</p>
            <p>3、用户须对利用“极客竞技”账号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与极客竞技APP无关。如因此给极客竞技APP或第三方造成损害的，用户应当依法予以赔尝。
            </p>
            <p>4、极客竞技APP提供的服务中可能包括广告，用户同意在使用过程中显示极客竞技APP和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责，对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，极客竞技APP不承担任何责任
            </p>
            <p>5、极客竞技APP要求用户年龄必须达18周岁及以上，并且完成实名认证，不符合此条件的用户请不要下载并参与，用户因违反此规则而导致的任何后果均由用户自行承担，极客竞技APP不承担任何责任。</p>
            <p>6、用户选择充值电玩币并消费，属于用户的主观自愿行为，不论游戏结果如何均无法退还已经充值或者消费的电玩币，用户自愿承担参与游戏的任何后果，对于用户由此造成的损失极客竞技APP不承担任何责任。</p>
            <p>7、用户参与游戏时，依据游戏规则判定成功抓取到的公仔，可以兑换极客竞技APP提供的奖品，兑换成功后用户不得更改或退还，用户自愿承担参与游戏并兑换奖品的任何后果，对于用户由此造成的损失极客竞技APP不承担任何责任。
            </p>

            <p>六、帐户与频道管理</p>
            <p>1、“极客竞技”账号的所有权归极客竞技APP所有，用户完成申请注册手续后，获得“极客竞技”账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。极客竞技APP因经营需要，有权回收用户的“极客竞技”账号。
            </p>
            <p>2、用户可以更改、删除“极客竞技”帐户上的个人资料、传送内容等，但需注意，删除有关信息的同时也会删除用户储存在系统中的文字和图片。用户需承担该风险。</p>
            <p>3、用户有责任妥善保管注册账号信息及账号密码的安全，因用户保管不善可能导致遭受盗号或密码失窃，责任由用户自行承担。用户需要对注册账号以及密码下的行为承担法律责任。用户同意在任何情况下不使用其他用户的账号或密码。在用户怀疑他人使用其账号或密码时，用户同意立即通知极客竞技APP。
            </p>
            <p>4、用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，极客竞技APP在通知用户后有权依据协议中断或终止对违约用户“极客竞技”账号提供服务。同时，极客竞技APP保留在任何时候收回“极客竞技”账号、用户名的权利。
            </p>
            <p>5、“极客竞技”账号的所有权归极客竞技APP所有，用户完成申请注册手续后，获得“极客竞技”账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。</p>

            <p>七、数据储存</p>
            <p>1、极客竞技APP不对用户在本服务中相关数据的删除或储存失败负责。</p>
            <p>2、极客竞技APP可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本服务中的相关数据。</p>
            <p>3、如用户停止使用本服务或本服务终止，极客竞技APP可以从服务器上永久地删除用户的数据。本服务停止、终止后，极客竞技APP没有义务向用户返还任何数据。</p>

            <p>八、风险承担</p>
            <p>1、用户理解并同意，“极客竞技”仅为用户提供信息分享、传送及获取的平台，用户必须为自己注册账号下的一切行为负责，包括用户所传送的任何内容以及由此产生的任何后果。用户应对“极客竞技”及本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。极客竞技APP无法且不会对因用户行为而导致的任何损失或损害承担责任。
            </p>如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向极客竞技APP举报或投诉，极客竞技APP将依本协议约定进行处理。
            <p>2、用户理解并同意，因业务发展需要，极客竞技APP保留单方面对本服务的全部或部分服务内容变更、暂停、终止或撤销的权利，用户需承担此风险。</p>

            <p>九、知识产权声明</p>
            <p>1、除本服务中涉及广告的知识产权由相应广告商享有外，极客竞技APP在本服务中提供的内容
                (包括但不限于网页、文字、图片、音频、视频、图表等)的知识产权均归极客竞技APP所有，但用户在使用本服务前对自己发布的内容已合法取得知识产权的除外。</p>
            <p>2、除另有特别声明外，极客竞技APP提供本服务时所依托软件的著作权、专利权及其他知识产权均归极客竞技APP所有。</p>
            <p>3、极客竞技APP在本服务中所涉及的图形、文字或其组成，以及其他极客竞技APP标志及产品、服务名称(以下统称“极客竞技APP标识”)，其著作权或商标权归极客竞技APP所有。未经极客竞技APP事先书面同意，用户不得将极客竞技APP标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用、或其他有权处理极客竞技APP标识的行为。
            </p>
            <p>4、上述及其他任何极客竞技APP或相关广告商依法拥有的知识产权均受到法律保护，未经极客竞技APP或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品。</p>
            <p>5、用户在极客竞技上传或发布的内容，用户应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益，用户同意授予极客竞技所有上述内容在全球范围内的免费、不可撤销的、无期限限制的、可再许可或转让的独家使用权许可，据该许可极客竞技将有权以展示、推广及其他不为法律所禁止的方式使用前述内容。
            </p>

            <p>十、法律责任</p>
            <p>1、如果极客竞技APP发现或收到他人举报或投诉用户违反本协议约定的，极客竞技APP有权不经通知随时对相关内容，包括但不限于用户资料、弹幕内容、直播影像内容进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁、设备封禁、功能封禁的处罚，且通知用户处理结果。
            </p>
            <p>2、因违反用户协议被封禁的用户不会自动解封。可向极客竞技微信公众号或微博提交申诉，极客竞技APP将对申诉进行审查，并自行合理判断决定是否变更处罚措施。</p>
            <p>3、用户理解并同意，极客竞技APP有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。
            </p>
            <p>4、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿极客竞技APP与合作公司、关联公司，并使之免受损害。</p>

            <p>十一、不可抗力及其他免责事由</p>
            <p>1、用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，极客竞技APP将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，极客竞技APP及合作单位在法律允许的范围内免责。
            </p>
            <p>2、本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰，用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。
            </p>
            <p>3、用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，极客竞技APP不承担任何责任。
            </p>
            <p>4、用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，极客竞技APP不承担任何责任。
            </p>
            <p>5、用户理解并确认，极客竞技APP需要定期或不定期地对“极客竞技”平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，极客竞技APP无需为此承担任何责任，但极客竞技APP应事先进行通告。
            </p>
            <p>6、极客竞技APP依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成极客竞技APP的义务或承诺，极客竞技APP不能保证及时发现违法违规或违约行为或进行相应处理。</p>
            <p>7、用户理解并确认，对于极客竞技APP向用户提供的下列产品或者服务的质量缺陷及其引发的任何损失，极客竞技APP无需承担任何责任:</p>
            <p>(1)极客竞技APP向用户免费提供的服务；</p>
            <p>(2)极客竞技APP向用户赠送的任何产品或者服务。</p>
            <p>8、在任何情况下，极客竞技APP均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用“极客竞技”或本服务而遭受的利润损失，承担责任(即使极客竞技APP已被告知该等损失的可能性亦然)。尽管本协议中可能含有相悖的规定，极客竞技APP对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用极客竞技APP提供的服务而支付给极客竞技APP的费用(如有)。
            </p>

            <p>十二、服务的变更、中断、终止</p>
            <p>1、鉴于网络服务的特殊性，用户同意极客竞技APP有权随时变更、中断或终止部分或全部的服务(包括收费服务)。极客竞技APP变更、中断或终止的服务，极客竞技APP应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务，如用户不愿意接受替代性的服务，如果该用户已经向极客竞技APP支付费用，极客竞技APP应当按照该用户实际使用服务的情况扣除相应金额之后将剩余的费用退还用户。
            </p>
            <p>2、如发生下列任何一种情形，
                极客竞技APP有权变更、中断或终止向
                用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任:</p>
            <p>(1)根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明;</p>
            <p>(2)用户违反相关法律法规或本协议的约定;</p>
            <p>(3)按照法律规定或有权机关的要求;</p>
            <p>(4)出于安全的原因或其他必要的情形。</p>

            <p>十三、其他</p>
            <p>1、极客竞技APP郑重提醒用户注意本协议中免除极客竞技APP责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。</p>
            <p>2、开票须知:客户应自消费当天起30天内申请尹票，逾期则不予受理;若存在特殊原因或正当理由延期开票的，原则上应在本年度予以办理，跨年度的一律不得开票。</p>
            <p>3、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和极客竞技APP之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交极客竞技APP所在地的仲裁委员仲裁解决。仲裁裁决是终局的，对双方珍有约束力。
            </p>
            <p>4、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</p>
            <p>5、本协议条款的标题仅为方便阅读而设，不作为解释本协议条款的依据。</p>

        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useStore } from "@/store/store";
const router = useRouter()
const store = useStore()


</script>

<style>
.user_agreement .content {
    padding: 20px 15px;
}
</style>