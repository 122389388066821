<template>
  <div class="ranking">
    <div class="top_bg_wrapper">
      <van-image
        width="100%"
        height="auto"
        fit="fill"
        position="center"
        :src="rank_top_bg"
      />
      <div class="weektabs-wrapper">
        <div class="width flex relative z-2">
          <span class="flex-1 t-center" @click="weeksTabChange('b')">本周</span>
          <span class="flex-1 t-center" @click="weeksTabChange('s')">上周</span>
        </div>
        <div class="week-tab-active" ref="weekTabActiveRef"></div>
      </div>
    </div>
    <div class="text_tips">奖励每周一零点发放</div>

    <div class="container_bottom">
      <div class="rank_content_wrapper">
        <div class="rank_tabs_wrapper">
          <div class="rank_tabs_content" ref="rankTabsContentRef">
            <div
              :class="[
                'rank_tab_item',
                rankTabIndex === rankItem.id ? 'rank_tab_active' : '',
              ]"
              v-for="rankItem in rankTabDataList"
              :key="rankItem.id"
              @touchend="rankTabIndexChange(rankItem.id)"
            >
              {{ rankItem.tab }}
            </div>
          </div>
          <div class="rank_tabs_active_icon" ref="rankTabActiveRef"></div>
        </div>

        <div class="rank_title_wrapper">
          <div class="rank">排名</div>
          <div class="player_info">玩家</div>
          <div class="reward">奖励</div>
        </div>

        <div class="rank_list_wrapper">
          <div
            :class="{
              rank_list_item: true,
              rank_fist_item: index == 0,
              rank_two_item: index == 1,
              rank_three_item: index == 2,
              rank_odd_item: index > 2 && index % 2 != 0,
              rank_even_item: index > 2 && index % 2 == 0,
            }"
            v-for="(item, index) in rankingViewData.rank"
            :key="item.uid"
          >
            <div class="rank">
              <div class="rank_icon" v-if="index <= 2">
                <van-image
                  width="100%"
                  height="auto"
                  fit="fill"
                  :src="
                    index == 0 ? placing1 : index == 1 ? placing2 : placing3
                  "
                />
              </div>
              <div class="rank_text" v-else>
                {{ index + 1 }}
              </div>
            </div>
            <div class="player_info">
              <div class="player_info_content">
                <div class="player_info_head">
                  <HeadPortrait
                    :level="Number(item.recharge_level)"
                    :width="'100%'"
                    :height="'100%'"
                    :src="item.head ? item.head : item.head_frame"
                    :ae_id="item.select_title"
                    ae
                    fe
                  />
                </div>
                <div class="player_info_name_and_grade">
                  <div class="user_name">
                    <van-text-ellipsis
                      :content="decodeBase64String(item.player_name)"
                    />
                  </div>
                  <div class="grade">
                    <Level
                      :level_value="item.level ? Number(item.level) : 1"
                      :border="false"
                      background="rgba(255,255,255,0.2)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="reward">
              <div class="score">{{ item.num }}</div>
              <div
                class="reward_value"
                v-if="rankingViewData.tribe_rank_def[rankTabIndex][index]"
              >
                +{{ rankingViewData.tribe_rank_def[rankTabIndex][index] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onBeforeMount } from "vue";
import { useStore } from "@/store/store";
import { decodeBase64String } from "@/utils/utils";
import { asyncSendInstructions } from "@/api/sendData";
import { showToast } from "vant";
import HeadPortrait from "@/components/HeadPortrait.vue";
import Level from "@/components/Level.vue";

const rankTabIndex = ref("mgc");
const curListType = ref("rank");
const store = useStore();

const rank_top_bg = store.imgBaseUrl + "/rank/top_bg.png";
const placing1 = store.imgBaseUrl + "/rank/placing1.png";
const placing2 = store.imgBaseUrl + "/rank/placing2.png";
const placing3 = store.imgBaseUrl + "/rank/placing3.png";

const rankingViewData = ref([]);

const rankTabDataList = reactive([
  {
    tab: "魔鬼城",
    id: "mgc",
  },
  {
    tab: "爆爆乐",
    id: "bbl",
  },
  {
    tab: "马戏团",
    id: "mxt",
  },
  {
    tab: "海王III",
    id: "hw3",
  },
  {
    tab: "捕鸟",
    id: "bird",
  },
]);

const weekTabActiveRef = ref();
const rankTabActiveRef = ref();

const rankTabsContentRef = ref();
const weeksTabChange = (type) => {
  if (type == "b") {
    weekTabActiveRef.value.style.left = "0";
    curListType.value = "rank";
  } else {
    weekTabActiveRef.value.style.left = "50%";
    curListType.value = "history";
  }
  rankTabIndexChange(rankTabIndex.value);
};

const rankTabIndexChange = (value) => {
  rankTabIndex.value = value;

  let itemWidth = 85;
  let iconWidth = 85;
  if (rankTabsContentRef.value) {
    const tWidth = rankTabsContentRef.value.offsetWidth;
    itemWidth = tWidth / rankTabDataList.length;
  }

  if (rankTabActiveRef.value) {
    iconWidth = rankTabActiveRef.value.offsetWidth;
  }

  let translateX = 100;

  const index = rankTabDataList.findIndex((item) => item.id == value);

  translateX =
    ((index * itemWidth) / iconWidth) * 100 +
    ((itemWidth / 2 - iconWidth / 2) / iconWidth) * 100;

  translateX = parseInt(translateX);
  //
  rankTabActiveRef.value &&
    (rankTabActiveRef.value.style.transform = `translateX(${translateX}%)`);
  getRankingViewData(value, curListType.value);
};

const getRankingViewData = async (game_name, type) => {
  const params = {
    msg_type: "get_tribe_rank",
    game_name,
    type,
  };

  try {
    const res = await asyncSendInstructions(params);
    rankingViewData.value = res;
  } catch (error) {}
};

onBeforeMount(() => {
  rankTabIndexChange("mgc");
});
</script>

<style lang="scss" scoped>
.ranking {
  width: 100%;
  background: linear-gradient(180deg, #16063e 35%, #3a1b67 100%);
  padding-bottom: 10px;

  .top_bg_wrapper {
    position: relative;
    max-height: 265px;
    .weektabs-wrapper {
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      // padding: 6px 0;
      width: 188px;
      height: 30px;
      line-height: 30px;
      // overflow: hidden;
      font-size: 15px;
      background-color: rgba($color: #000000, $alpha: 0.6);
      border-radius: 15px;
      color: #f9dfcd;
      font-weight: 800;

      .week-tab-active {
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 100%;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank/week_tab_active_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 15px;
        transition: left 0.4s;
      }
    }
  }
  .text_tips {
    text-align: right;
    font-size: 12px;
    padding: 10px 15px;
    color: #fbe6a3;
  }
  .container_bottom {
    padding: 0px 15px 20px 15px;
    // height: calc(100% - 265px);
    .rank_content_wrapper {
      border: 1px solid #fff;
      border-radius: 15px;
      height: 100%;
      overflow: hidden;
      // display: flex;
      // flex-direction: column;
      .rank_tabs_wrapper {
        height: 50px;
        position: relative;
        background: linear-gradient(270deg, #433f92 0%, #433f92 100%);
        .rank_tabs_content {
          display: flex;
          .rank_tab_item {
            flex: 1;
            text-align: center;
            line-height: 50px;
            font-size: 12px;
          }

          .rank_tab_active {
            font-size: 14px;
            font-weight: 1000;
          }
        }
        .rank_tabs_active_icon {
          position: absolute;
          left: 0;
          bottom: 8px;
          width: 20px;
          height: 5px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank/tab_active_icon.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          transform: translateX(121%);
          transition: transform 0.4s;
        }
      }
      .rank_title_wrapper {
        height: 36px;
        line-height: 36px;
        display: flex;
        text-align: center;
        .rank {
          width: 50px;
        }
        .player_info {
          flex: 1;
        }
        .reward {
          width: 80px;
        }
      }
      .rank_list_wrapper {
        // flex: 1;
        // height: calc(100% - 86px);
        // overflow: auto;
        .rank_list_item {
          display: flex;
          // min-height: 60px;
          padding: 10px 0;
          .rank {
            position: relative;
            width: 50px;

            .rank_icon {
              width: 20px;
              height: auto;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            .rank_text {
              width: 20px;
              height: 20px;
              height: auto;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              border-radius: 50%;
              border: 1px solid rgba($color: #fff, $alpha: 0.6);
              text-align: center;
              line-height: 20px;
              font-size: 14px;
            }
          }
          .player_info {
            flex: 1;
            display: flex;
            // flex-direction: column;
            justify-content: start;
            align-items: center;
            padding-left: 40px;
            .player_info_content {
              display: flex;
              flex: 1;
              .player_info_head {
                height: 44px;
                width: 44px;
              }
              .player_info_name_and_grade {
                padding-left: 10px;
                flex: 1;
                .user_name {
                  font-size: 12px;
                  text-wrap: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                // .user_grade {}
              }
            }
          }
          .reward {
            width: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: end;
            // flex-wrap: wrap;
            .score {
              width: 100%;
              position: relative;
              font-size: 12px;
              padding-right: 32px;
              text-align: right;
              font-weight: 800;
              text-wrap: nowrap;
              &::after {
                content: "";
                position: absolute;
                right: 10px;
                bottom: 0;
                // transform: translateY(-50%);
                width: 18px;
                height: 18px;
                background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/ticket.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
              }
            }

            .reward_value {
              width: 100%;
              position: relative;
              font-size: 12px;
              padding-right: 32px;
              text-align: right;
              color: #fee22a;
              font-weight: 800;
              margin-top: 6px;
              &::after {
                content: "";
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                width: 18px;
                height: 18px;
                background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
              }
            }
          }
        }
        .rank_fist_item {
          background: linear-gradient(
            90deg,
            #dda820 0%,
            rgba(221, 168, 32, 0) 100%
          );
        }
        .rank_two_item {
          background: linear-gradient(
            90deg,
            #358bc8 0%,
            rgba(53, 139, 200, 0) 100%
          );
        }
        .rank_three_item {
          background: linear-gradient(
            90deg,
            #cb652c 0%,
            rgba(203, 101, 44, 0) 100%
          );
        }
        .rank_odd_item {
          background: linear-gradient(
            90deg,
            #16063e 0%,
            rgba(22, 6, 62, 0) 100%
          );
        }
        .rank_even_item {
          background: linear-gradient(
            90deg,
            #391b73 0%,
            rgba(57, 27, 115, 0) 100%
          );
        }
      }
    }
  }
}
</style>
