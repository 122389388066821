<template>
  <div
    class="my_forum_wrapper"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <div class="my_nav_bar_wrapper">
      <div class="nav_bar">
        <div class="nav_left_wrapper" @click="router.back()">
          <van-icon name="arrow-left" />
        </div>
        <div class="nav_text_wrapper"><span>我的发布</span></div>
        <div class="nav_right_wrapper">
          <div class="nav_right_icon" @click="goToUrl('create')">
            <van-image
              width="100%"
              height="100%"
              :show-loading="false"
              :src="create_forum_icon"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="top_info_wrapper">
      <div class="info_wrapper">
        <div class="info_container">
          <HeadPortrait
            :level="Number(store.userInfo.recharge_level)"
            :src="store.userInfo.headimgurl"
            :ae_id="store.userInfo.select_title"
            ae
            fe
          />
          <div class="info_right">
            <div class="info_right_top">
              <span class="f-16 bold mr-6">{{
                store.userInfo.player_name
              }}</span>
              <Level
                :border="false"
                :level="Number(store.userInfo.recharge_level)"
              />
            </div>
            <div class="info_right_bottom">
              <span
                ><b class="bold f-18">{{ listAllData.length }}</b> 发布</span
              >
              <span class="nbsp_in_block"></span>
              <span
                ><b class="bold f-18">{{ likes_counts }}</b
                >点赞</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="tabbar_wrapper">
        <div
          :class="[
            'tab_item',
            currentTabType == tabItem.type && 'tab_item_active',
          ]"
          v-for="tabItem in tabTypeList"
          @click="currentTabTypeChange(tabItem.type)"
        >
          <span> {{ tabItem.title }}</span>
        </div>
      </div>
    </div>

    <div class="forum_list_wrapper">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="-------没有更多啦-------"
      >
        <div
          class="forum_item"
          v-for="formItem in listDataShow"
          :key="formItem.id"
        >
          <div class="author_wrapper">
            <HeadPortrait
              width="40px"
              height="40px"
              :level="Number(store.userInfo.recharge_level)"
              :src="store.userInfo.headimgurl"
              :ae_id="store.userInfo.select_title"
              ae
              fe
            />
            <div class="author_name">
              <span>{{ store.userInfo.player_name }}</span>
            </div>
            <div class="author_level">
              <Level :border="false" :level="Number(store.userInfo.level)" />
            </div>
          </div>

          <div class="forum_statu">
            <van-image
              width="100%"
              height="100%"
              :src="forumStatus[formItem.status]"
            />
          </div>

          <div class="forum_content">
            {{ formItem.content }}
          </div>
          <div class="forum_images">
            <div
              class="forum_image_item"
              v-for="(imgItem, imgIndex) in formItem.images"
              @click="handleImagePreview(formItem.images, imgIndex)"
            >
              <van-image
                width="100%"
                height="100%"
                fit="contain"
                :src="imgItem"
              />
            </div>
          </div>
          <div class="time_like_wrapper">
            <div class="left_block">
              <span>{{ formItem.create_time }}</span>
              <span class="del" @click="handleDelForumItem(formItem.id)">
                删除</span
              >
            </div>
            <div
              class="like"
              @click="
                whenTheUserLike(
                  formItem.id,
                  formItem.liked_by_me,
                  formItem.status
                )
              "
            >
              <div class="like_icon_wrapper">
                <van-image
                  class="like_icon"
                  width="20px"
                  height="20px"
                  :src="formItem.liked_by_me ? like_icon_active : like_icon"
                />
              </div>

              <div class="count" style="margin-left: 4px">
                {{ formItem.likes_count }}
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { useRouter } from "vue-router";
import { onBeforeMount, ref, computed } from "vue";
import { showImagePreview, showConfirmDialog, showToast } from "vant";
import HeadPortrait from "@/components/HeadPortrait.vue";
import Level from "@/components/Level.vue";
import { getForumList, forumDel } from "@/api/index";
import like_icon from "@/assets/images/like_icon.png";
import like_icon_active from "@/assets/images/like_icon_active.png";

import create_forum_icon from "@/assets/images/create_forum_icon.png";
import statu_0 from "@/assets/images/forum_statu_0.png";
import statu_1 from "@/assets/images/forum_statu_1.png";
import statu_2 from "@/assets/images/forum_statu_2.png";

const store = useStore();
const router = useRouter();

//  元数据
const listAllData = ref([]);
// 展示数据
const listDataShow = ref([]);

const loading = ref(false);
const finished = ref(false);

const initListData = (dataArr) => {
  const dataClone = JSON.parse(JSON.stringify(dataArr));

  const initData = [];

  dataClone.forEach((item) => {
    const initDataObj = item;
    // initDataObj.headimgurl = `https://jikejingji.oss-cn-guangzhou.aliyuncs.com/headSculpture/${initDataObj.uid}.jpg`;

    initDataObj.images = initDataObj.images.split(";");
    initDataObj.likes_count = initDataObj.likers
      ? initDataObj.likers.length
      : 0;
    initDataObj.likers = initDataObj.likers
      ? initDataObj.likers.join("、")
      : "";
    initDataObj.recharge_level = item.recharge_level;
    initData.push(initDataObj);
  });

  return initData;
};

// 点赞数
const likes_counts = computed(() => {
  let count = 0;
  listAllData.value.forEach((item) => {
    count += item.likes_count;
  });

  return count;
});

const forumStatus = [statu_0, statu_1, statu_2];

const currentTabType = ref("all");
const tabTypeList = [
  {
    title: "全部",
    type: "all",
  },
  {
    title: "已通过",
    type: "pass",
  },
  {
    title: "审核中",
    type: "pending",
  },
  {
    title: "未通过",
    type: "refuse",
  },
];

const currentTabTypeChange = (type = "all") => {
  if (type !== currentTabType.value) {
    currentTabType.value = type;
  }

  if (type == "all") {
    listDataShow.value = listAllData.value;
    return;
  }

  listDataShow.value = listAllData.value.filter((item) => {
    if (type == "pass") return item.status == 1;
    if (type == "pending") return item.status == 0;
    if (type == "refuse") return item.status == 2;
  });
};

// 加载
const getListData = async () => {
  const params = {
    uid: parseInt(store.userInfo.uid),
    from: "my",
    page: 1,
    page: 100,
  };

  try {
    const res = await getForumList(params);
    if (res.data == null) {
      loading.value = false;
      finished.value = true;
      return;
    }

    loading.value = false;

    if (res.code == 200) {
      listAllData.value = initListData(res.data);
      finished.value = true;
      currentTabTypeChange();
    }
  } catch (error) {}
};

// 去新增
const goToUrl = (type) => {
  if (type == "create") router.push("/createForum");
};

// 删除
const handleDelForumItem = (post_id) => {
  showConfirmDialog({
    className: "hh_dialog",
    title: "",
    message: "确定要删除这条帖子吗？",
    theme: "round-button",
    showCancelButton: true,
  })
    .then(async () => {
      const res = await forumDel({ post_id });

      if (res.code == 200) {
        listAllData.value = listAllData.value.filter((item) => {
          return item.id != post_id;
        });
        currentTabTypeChange();
        showToast({
          type: "success",
          message: "删除成功",
        });
      }
    })
    .catch(() => {
      // on cancel
    });
};

// 预览
const handleImagePreview = (images, index) => {
  showImagePreview({
    images,
    startPosition: index,
  });
};

// 点赞
const whenTheUserLike = async (post_id, liked_by_me, statu) => {
  if (liked_by_me) return;

  if (statu == 0) {
    showToast({
      type: "text",
      message: "该帖子未通过审核",
    });
    return;
  }

  const params = {
    post_id,
    uid: parseInt(store.userInfo.uid),
  };

  try {
    const res = await likePost(params);

    if (res.code == 200) {
      listAllData.value.some((item) => {
        if (item.id == post_id) {
          item.liked_by_me = true;

          let likers = item.likers;

          likers == ""
            ? (likers = store.userInfo.player_name)
            : (likers += `、${store.userInfo.player_name}`);

          item.likers = likers;
          item.likes_count += 1;
          currentTabTypeChange(currentTabType.value);
          return true;
        } else return false;
      });
    }
  } catch (error) {}
};

onBeforeMount(() => {
  getListData();
});
</script>

<style lang="scss" scoped>
.my_nav_bar_wrapper {
  padding: 0 15px;
  height: 34px;
  line-height: 34px;

  .nav_bar {
    display: flex;

    .nav_left_wrapper {
      width: 28px;
      font-size: 20px;
    }
    .nav_text_wrapper {
      position: relative;
      flex: 1;
      font-size: 20px;
      span {
        position: relative;
        z-index: 2;
      }
      // &::after {
      //   content: "";
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   width: 80px;
      //   height: 12px;
      //   border-radius: 6px;
      //   z-index: 1;
      //   background: linear-gradient(270deg, #391284 0%, #871fba 100%);
      // }
      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: 4px;
      //   left: 88px;
      //   width: 10px;
      //   height: 10px;
      //   border-radius: 50%;
      //   z-index: 1;
      //   background: linear-gradient(270deg, #391284 0%, #871fba 100%);
      // }
    }
    .nav_right_wrapper {
      width: 72px;
      display: flex;
      justify-content: space-around;
      align-content: center;
      .nav_right_icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.top_info_wrapper {
  .info_wrapper {
    height: 112px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .info_container {
      width: 100%;
      height: 60px;
      display: flex;
      .info_right {
        margin-left: 12px;
        flex: 1;
        display: flex;
        flex-direction: column;
        .info_right_top {
          flex: 1;
          display: flex;
          align-items: center;
          // flex-direction: column;
          // justify-content: center;
        }
        .info_right_bottom {
          flex: 1;
          .nbsp_in_block {
            display: inline-block;
            width: 20px;
          }
        }
      }
    }
  }
  .tabbar_wrapper {
    height: 66px;
    background-color: #211e38;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    padding: 0 15px;
    display: flex;
    .tab_item {
      font-size: 14px;
      color: #fff;
      padding: 0 10px;
      line-height: 66px;
      span {
        display: inline-block;
        opacity: 0.5;
        transition: transform 0.2s linear;
        transition: opacity 0.2s linear;
        font-weight: bold;
      }
    }
    .tab_item_active {
      span {
        opacity: 1;
        transform: scale(1.2);
      }
    }
  }
}

.my_forum_wrapper {
  background-color: #181627;
  background-image: url("@/assets/images/forum_top_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  min-height: 100vh;
  .forum_list_wrapper {
    padding: 0 15px;
    .forum_item {
      background: linear-gradient(180deg, #20192b 0%, #212040 100%);
      border-radius: 10px;
      padding: 20px 15px;
      margin-top: 10px;
      overflow: hidden;
      position: relative;

      .forum_statu {
        position: absolute;
        right: 15px;
        top: 29px;
        width: 54px;
        height: 22px;
      }

      .author_wrapper {
        display: flex;
        .author_name {
          margin: 0 5px;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .author_level {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      .forum_content {
        margin-top: 15px;
        font-size: 14px;
      }

      .forum_images {
        margin-top: 15px;
        display: flex;
        justify-content: start;
        .forum_image_item {
          width: 31%;
          margin-right: 8px;
          height: 100px;
          background-color: #181627;
          &:last-child {
            margin-right: 0;
          }
        }
      }

      .time_like_wrapper {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        height: 24px;
        line-height: 24px;
        .left_block {
          color: rgba($color: #ffffff, $alpha: 0.5);
          font-size: 12px;
          .del {
            color: #71d9e5;
          }
        }
        .like {
          font-size: 12px;
          display: flex;
          .like_icon_wrapper {
            width: 22px;
            position: relative;
            .like_icon {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
          // .count {}
          // vertical-align: middle;
        }
      }

      .liker_names {
        margin-top: 15px;
        padding: 8px 15px;
        color: rgba($color: #ffffff, $alpha: 0.7);
        background-color: #181627;
        font-size: 10px;
        font-weight: 500;
        border-radius: 6px;
      }
    }
  }
}
</style>
