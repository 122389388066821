<template>
  <div class="sit_down_hint_wrapper">
    <div
      class="sit_down_hint_dom_item"
      v-for="hintDom in 4"
      :key="'hintDom' + hintDom"
    >
      <div
        class="hint_show"
        v-if="store.current_device.device_index == hintDom"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

import { useStore } from "@/store/store";
const store = useStore();
</script>

<style scoped lang="scss">
.sit_down_hint_wrapper {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  height: 45px;
  z-index: 3;

  display: flex;
  overflow: hidden;

  .sit_down_hint_dom_item {
    flex: 1;
    position: relative;

    .hint_show {
      width: 76px;
      height: 45px;
      position: absolute;
      left: 50%;
      top: 0%;
      transform: translateX(-50%);
      // margin: 0 auto;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/sitDownHint.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      animation: sitDownAnimt 1s infinite;
    }
  }
}

@keyframes sitDownAnimt {
  0% {
    top: 0px;
  }

  50% {
    top: 10px;
  }

  100% {
    top: 0px;
  }
}

@-webkit-keyframes sitDownAnimt

/* Safari 与 Chrome */ {
  0% {
    top: 0;
  }

  50% {
    top: 50%;
  }

  100% {
    top: 0;
  }
}
</style>
