const classMGCIDs = [
  {
    classID: 0,
    cardName: "修罗巨魔",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_01@2x.png",
  },
  {
    classID: 1,
    cardName: "千年南瓜怪",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_02@2x.png",
  },
  {
    classID: 2,
    cardName: "巨石炎魔",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_03@2x.png",
  },
  {
    classID: 3,
    cardName: "幻影死神",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_04@2x.png",
  },
  {
    classID: 4,
    cardName: "狂暴飞龙",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_05@2x.png",
  },
  {
    classID: 5,
    cardName: "电光破",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_06@2x.png",
  },
  {
    classID: 6,
    cardName: "系统爆机",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_13%402x.png",
  },
  {
    classID: 7,
    cardName: "连环爆裂",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_07@2x.png",
  },
  {
    classID: 8,
    cardName: "锁链封印",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_08@2x.png",
  },
  {
    classID: 9,
    cardName: "驱魔烈焰",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_09@2x.png",
  },
  {
    classID: 10,
    cardName: "骷髅暴君",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_10@2x.png",
  },
  {
    classID: 11,
    cardName: "魔旋弹",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_11@2x.png",
  },
  {
    classID: 12,
    cardName: "魔法召唤",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_12@2x.png",
  },
];
const classBBLIDs = [
  {
    classID: 0,
    cardName: "不可思议",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/bbl/c03@2x.png",
  },
  {
    classID: 1,
    cardName: "你是传奇",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/bbl/c04@2x.png",
  },
  {
    classID: 2,
    cardName: "太棒啦",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/bbl/c01@2x.png",
  },
  {
    classID: 3,
    cardName: "幸运降临",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/bbl/c02@2x.png",
  },
  {
    classID: 4,
    cardName: "连爆大挑战",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/bbl/c05@2x.png",
  },
];
const classMXTIDs = [
  {
    classID: 0,
    cardName: "JP1",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/m04@2x.png",
  },
  {
    classID: 1,
    cardName: "JP2",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/m05@2x.png",
  },
  {
    classID: 2,
    cardName: "JP3",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/m06@2x.png",
  },
  {
    classID: 3,
    cardName: "全盘奖",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/m02@2x.png",
  },
  {
    classID: 4,
    cardName: "叠叠乐",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/m03@2x.png",
  },
  {
    classID: 5,
    cardName: "小玛莉",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/m01@2x.png",
  },
];

const classMSQYIDs = [
  {
    classID: 0,
    cardName: "无中奖",
    url: "",
  },
  {
    classID: 1,
    cardName: "JP3",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/m06@2x.png",
  },
  {
    classID: 2,
    cardName: "JP2",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/m05@2x.png",
  },
  {
    classID: 3,
    cardName: "JP1",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/m04@2x.png",
  },
  {
    classID: 4,
    cardName: "叠叠乐",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/m03@2x.png",
  },
  {
    classID: 5,
    cardName: "小玛莉",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/m01@2x.png",
  },
  {
    classID: 6,
    cardName: "连线奖",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/m07@2x.png",
  },
  {
    classID: 7,
    cardName: "全盘奖",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/m01@2x.png",
  },
  {
    classID: 8,
    cardName: "开心60S",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/m08@2x.png",
  },
];

const classHW3IDs = [
  {
    classID: 0,
    cardName: "史前巨鳄",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_4.png",
  },
  {
    classID: 1,
    cardName: "旋风鱼",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_11.png",
  },
  {
    classID: 2,
    cardName: "暗夜巨兽",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_1.png",
  },
  {
    classID: 3,
    cardName: "深海八爪",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_3.png",
  },
  {
    classID: 4,
    cardName: "电磁炮",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_6.png",
  },
  {
    classID: 5,
    cardName: "至尊神龙",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_13.png",
  },
  {
    classID: 6,
    cardName: "觉醒",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_16.png",
  },
  {
    classID: 7,
    cardName: "赤炎龙龟",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_12.png",
  },
  {
    classID: 8,
    cardName: "转盘蟹",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_14.png",
  },
  {
    classID: 9,
    cardName: "连环炸弹蟹",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_7.png",
  },
  {
    classID: 10,
    cardName: "钻头炮",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_10.png",
  },
  {
    classID: 11,
    cardName: "闪电连锁",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_8.png",
  },
  {
    classID: 12,
    cardName: "雷神锤",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_15.png",
  },
  {
    classID: 13,
    cardName: "雷霸龙",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_5.png",
  },
  {
    classID: 14,
    cardName: "霸王蟹",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_9.png",
  },
  {
    classID: 15,
    cardName: "霸王鲸",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_2.png",
  },
  {
    classID: 16,
    cardName: "系统爆机",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_13%402x.png",
  },
  {
    classID: 17,
    cardName: "美人鱼",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_17.png",
  },
  {
    classID: 18,
    cardName: "浴火凤凰",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_18.png",
  },
];

const collectClassIDs = {
  mgc: classMGCIDs,
  bbl: classBBLIDs,
  mxt: classMXTIDs,
  msqy: classMSQYIDs,
  hw3: classHW3IDs,
};

export default collectClassIDs;
