<template>
  <div class="other_wrapper">
    <NavBar title="我的成就" />

    <div class="exhibition_area">
      <div class="exhibition_head">
        <HeadPortrait
          width="100%"
          height="100%"
          :src="store.userInfo.headimgurl"
          :level="Number(store.userInfo.recharge_level)"
          :ae_id="cur_index"
          :ae="true"
          :fe="true"
        />
      </div>
    </div>

    <div class="data_list_wrapper">
      <van-config-provider :theme-vars="tabsVars">
        <van-tabs v-model:active="active_title_type" swipe-threshold="3">
          <van-tab
            v-for="typeItem in title_type_list"
            :title="typeItem.type_name"
          >
            <div class="data_type2_wrapper" v-if="active_title_type == 0">
              <div
                :class="{
                  type2_item: true,
                  type2_item_active: cur_type2_active == type2Item.type,
                }"
                v-for="type2Item in data_type2_list"
                @click="cur_type2_active_toggle(type2Item.type)"
              >
                {{ type2Item.text }}
              </div>
            </div>

            <template v-if="typeItem.type == 'game'">
              <div class="data_list">
                <div class="list">
                  <div
                    class="list_item"
                    v-for="(_v, _k) in typeItem.title_list[cur_type2_active]"
                    :style="{
                      borderColor: cur_index == _k ? '#F9E1A1' : '#421e77',
                    }"
                    @click="select_cur_index(_k)"
                  >
                    <div class="lock_mask" v-if="_v.complete > _v.num"></div>

                    <div
                      class="cur_title"
                      v-if="store.userInfo.select_title == _k"
                    >
                      当前佩戴
                    </div>

                    <div class="title_cover">
                      <van-image
                        width="100%"
                        height="100%"
                        fit="contain"
                        position="center"
                        :show-loading="false"
                        :src="_v.cover"
                        block
                      />
                    </div>
                    <div class="title_name">{{ _v.t_name }}</div>
                    <div class="title_complete">
                      {{ _v.complete_text }}
                      <span style="color: #fee22a">{{
                        `${_v.num}/${_v.complete}`
                      }}</span>
                    </div>

                    <div class="title_time">有效期为当前赛季</div>
                    <div
                      class="gold_title"
                      v-if="_v.complete > _v.num && _v.gold > 0"
                    >
                      <span> 奖励 {{ _v.gold }}</span>
                      <span class="gold_icon"></span>
                    </div>
                  </div>
                </div>

                <div
                  class="change_achievement_btn"
                  @click="changePlayerAchievement"
                >
                  {{
                    store.userInfo.select_title == cur_index &&
                    store.userInfo.select_title != 0
                      ? "取消佩戴"
                      : "立即佩戴"
                  }}
                </div>
              </div>
            </template>

            <template v-else>
              <div class="data_list">
                <div class="list">
                  <div
                    class="list_item"
                    v-for="(_v, _k) in typeItem.title_list"
                    :style="{
                      borderColor: cur_index == _k ? '#F9E1A1' : '#421e77',
                    }"
                    @click="select_cur_index(_k)"
                  >
                    <div class="lock_mask" v-if="_v.complete > _v.num"></div>

                    <div
                      class="cur_title"
                      v-if="store.userInfo.select_title == _k"
                    >
                      当前佩戴
                    </div>

                    <div class="title_cover">
                      <van-image
                        width="100%"
                        height="100%"
                        fit="contain"
                        position="center"
                        :src="_v.cover"
                        block
                      />
                    </div>
                    <div class="title_name">{{ _v.t_name }}</div>
                    <div class="title_complete">
                      {{ _v.complete_text }}
                      <span style="color: #fee22a">{{
                        `${_v.num}/${_v.complete}`
                      }}</span>
                    </div>

                    <div class="title_time">有效期为当前赛季</div>

                    <div
                      class="gold_title"
                      v-if="_v.complete > _v.num && _v.gold > 0"
                    >
                      <span> 奖励 {{ _v.gold }}</span>
                      <span class="gold_icon"></span>
                    </div>
                  </div>
                </div>

                <div
                  class="change_achievement_btn"
                  @click="changePlayerAchievement"
                >
                  {{
                    store.userInfo.select_title == cur_index &&
                    store.userInfo.select_title != 0
                      ? "取消佩戴"
                      : "立即佩戴"
                  }}
                </div>
              </div>
            </template>
          </van-tab>
        </van-tabs>
      </van-config-provider>
    </div>
  </div>
</template>

<script setup>
import { asyncSendInstructions } from "@/api/sendData";
import HeadPortrait from "@/components/HeadPortrait.vue";
import { useStore } from "@/store/store";
import { showToast, closeToast } from "vant";

import { ref, onBeforeMount, reactive, computed } from "vue";
import NavBar from "@/components/NavBar.vue";
const store = useStore();
const aeList = {
  1: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_1.png",
  2: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_2.png",
  3: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_3.png",
  4: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_4.png",
  5: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_5.png",
  6: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_6.png",
  7: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_7.png",
  8: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_8.png",
  9: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_9.png",
  10: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_10.png",
  11: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_11.png",
  12: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_12.png",
  13: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_13.png",
  14: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_14.png",
  15: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_15.png",
  16: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_16.png",
  17: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_17.png",
  18: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_18.png",
  19: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_19.png",
  20: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_20.png",
  21: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_21.png",
  22: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_22.png",
  23: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_23.png",
  24: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_24.png",
  25: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_25.png",
  26: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_26.png",
  27: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_27.png",
  28: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_28.png",
  98: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_98.png",
  99: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_99.png",
};

//#region 类型切换
const active_title_type = ref(0);

const tabsVars = reactive({
  tabsNavBackground: "none",
  tabTextColor: "#ffffff60",
  tabActiveTextColor: "#FFF",
  tabsBottomBarColor: "#93F6FC",
  tabsLineHeight: "35px",
  tabFontSize: "16px",
});

// 称号数据
const all_title_type_data = {
  1: {
    t_name: "疯魔爆机王",
    complete: 99,
    num: 4,
    cover: aeList[1],
    complete_text: "魔鬼城赛季内爆机",
    gold: 0,
  },
  2: {
    t_name: "海王爆机王",
    complete: 99,
    num: 4,
    cover: aeList[2],
    complete_text: "海王赛季内爆机",
    gold: 0,
  },
  3: {
    t_name: "我是传奇",
    complete: 99,
    num: 4,
    cover: aeList[3],
    complete_text: "爆爆乐赛季内获得传奇",
    gold: 0,
  },
  4: {
    t_name: "全盘王",
    complete: 10,
    num: 0,
    cover: aeList[4],
    complete_text: "推币机赛季内获得全盘",
    gold: 0,
  },
  5: {
    t_name: "全能大神",
    complete: 4,
    num: 0,
    cover: aeList[5],
    complete_text: "获得四种游戏的战士成就",
    gold: 0,
  },
  6: {
    t_name: "挑战达人",
    complete: 2,
    num: 0,
    cover: aeList[6],
    complete_text: "赛季内获得两次挑战周冠军",
    gold: 0,
  },
  7: {
    t_name: "联赛冠军",
    complete: 1,
    num: 0,
    cover: aeList[7],
    complete_text: "赛季内获得联赛冠军",
    gold: 0,
  },
  8: {
    t_name: "联赛亚军",
    complete: 1,
    num: 0,
    cover: aeList[8],
    complete_text: "赛季内获得联赛亚军",
    gold: 0,
  },
  9: {
    t_name: "联赛季军",
    complete: 1,
    num: 0,
    cover: aeList[9],
    complete_text: "赛季内获得联赛季军",
    gold: 0,
  },
  10: {
    t_name: "龙鱼情缘-鱼",
    complete: 1,
    num: 0,
    cover: aeList[10],
    complete_text: "七夕活动获得",
    gold: 0,
  },
  11: {
    t_name: "龙鱼情缘-龙",
    complete: 1,
    num: 0,
    cover: aeList[11],
    complete_text: "七夕活动获得",
    gold: 0,
  },
  12: {
    t_name: "我的中国心",
    complete: 1,
    num: 0,
    cover: aeList[12],
    complete_text: "开宝箱获得",
    gold: 0,
  },
  13: {
    t_name: "战士",
    complete: 1,
    num: 0,
    cover: aeList[13],
    complete_text: "开宝箱获得",
    gold: 0,
  },
  14: {
    t_name: "高手",
    complete: 1,
    num: 0,
    cover: aeList[14],
    complete_text: "开宝箱获得",
    gold: 0,
  },
  15: {
    t_name: "王者",
    complete: 1,
    num: 0,
    cover: aeList[15],
    complete_text: "开宝箱获得",
    gold: 0,
  },
  16: {
    t_name: "小霸王",
    complete: 1,
    num: 0,
    cover: aeList[16],
    complete_text: "新人任务获得",
    gold: 0,
  },
  17: {
    t_name: "疯魔爆机战士",
    complete: 99,
    num: 0,
    cover: aeList[17],
    complete_text: "魔鬼城赛季内获得爆机",
    gold: 0,
  },
  18: {
    t_name: "疯魔爆机高手",
    complete: 99,
    num: 0,
    cover: aeList[18],
    complete_text: "魔鬼城赛季内获得爆机",
    gold: 0,
  },
  19: {
    t_name: "疯魔爆机达人",
    complete: 99,
    num: 0,
    cover: aeList[19],
    complete_text: "魔鬼城赛季内获得爆机",
    gold: 0,
  },
  20: {
    t_name: "海王爆机战士",
    complete: 99,
    num: 4,
    cover: aeList[20],
    complete_text: "海王赛季内爆机",
    gold: 0,
  },
  21: {
    t_name: "海王爆机高手",
    complete: 99,
    num: 4,
    cover: aeList[21],
    complete_text: "海王赛季内爆机",
    gold: 0,
  },
  22: {
    t_name: "海王爆机达人",
    complete: 99,
    num: 4,
    cover: aeList[22],
    complete_text: "海王赛季内爆机",
    gold: 0,
  },
  23: {
    t_name: "传奇战士",
    complete: 99,
    num: 4,
    cover: aeList[23],
    complete_text: "爆爆乐赛季内获得传奇",
    gold: 0,
  },
  24: {
    t_name: "传奇高手",
    complete: 99,
    num: 4,
    cover: aeList[24],
    complete_text: "爆爆乐赛季内获得传奇",
    gold: 0,
  },
  25: {
    t_name: "传奇达人",
    complete: 99,
    num: 4,
    cover: aeList[25],
    complete_text: "爆爆乐赛季内获得传奇",
    gold: 0,
  },
  26: {
    t_name: "全盘战士",
    complete: 99,
    num: 4,
    cover: aeList[26],
    complete_text: "推币机赛季内获得全盘",
    gold: 0,
  },
  27: {
    t_name: "全盘高手",
    complete: 99,
    num: 0,
    cover: aeList[27],
    complete_text: "推币机赛季内获得全盘",
    gold: 0,
  },
  28: {
    t_name: "全盘达人",
    complete: 99,
    num: 4,
    cover: aeList[28],
    complete_text: "推币机赛季内获得全盘",
    gold: 0,
  },
  98: {
    t_name: "大师",
    complete: 1,
    num: 0,
    cover: aeList[98],
    complete_text: "达成大师级别",
    gold: 0,
  },
  99: {
    t_name: "宗师",
    complete: 1,
    num: 0,
    cover: aeList[99],
    complete_text: "达成宗师级别",
    gold: 0,
  },
};

const title_type_list = reactive([
  {
    type_name: "游戏成就",
    type: "game",
    title_list: {
      tbj: {},
      mgc: {},
      by: {},
      bbl: {},
      common: {},
    },
  },
  {
    type_name: "联赛成就",
    type: "competition",
    title_list: {},
  },
  {
    type_name: "活动成就",
    type: "active",
    title_list: {},
  },
  {
    type_name: "等级成就",
    type: "level",
    title_list: {
      98: {
        t_name: "大师",
        complete: 1,
        num: 0,
        cover: aeList[98],
        complete_text: "达成大师级别",
        gold: 0,
      },
      99: {
        t_name: "宗师",
        complete: 1,
        num: 0,
        cover: aeList[99],
        complete_text: "达成宗师级别",
        gold: 0,
      },
    },
  },
]);

// const cur_game_list = computed()

// 再度子级分类
const cur_type2_active = ref("tbj");
const data_type2_list = reactive([
  {
    type: "tbj",
    text: "推币机",
  },
  {
    type: "mgc",
    text: "魔鬼城",
  },
  {
    type: "by",
    text: "捕鱼系列",
  },
  {
    type: "bbl",
    text: "爆爆乐",
  },
  {
    type: "common",
    text: "综合",
  },
]);
const cur_type2_active_toggle = (type) => {
  if (active_title_type.value != 0) return;
  cur_type2_active.value = type;
};

//#endregion

const cur_index = ref(0);
const select_cur_index = (key) => {
  cur_index.value = key;
};

const get_user_achievement_data = async () => {
  const res = await asyncSendInstructions({
    msg_type: "get_player_title",
  });

  const tbj_keys = [4, 26, 27, 28];
  const mgc_keys = [1, 17, 18, 19];
  const bbl_keys = [3, 23, 24, 25];
  const by_keys = [2, 20, 21, 22];
  const common_keys = [5, 16];

  const competition_keys = [7, 8, 9];

  const active_keys = [10, 11, 12, 13, 14, 15];

  for (const key in res.data) {
    const item = res.data[key];

    all_title_type_data[key].num = item.num || 0;
    all_title_type_data[key].complete = item.complete || 99;

    if (item.reward && item.reward.num > 0) {
      all_title_type_data[key].gold = item.reward.num;
    }

    const _include_ = Number(key);

    //推币机
    if (tbj_keys.includes(_include_))
      title_type_list[0].title_list.tbj[key] = all_title_type_data[key];
    // 魔鬼城
    if (mgc_keys.includes(_include_))
      title_type_list[0].title_list.mgc[key] = all_title_type_data[key];

    // 爆爆乐
    if (bbl_keys.includes(_include_))
      title_type_list[0].title_list.bbl[key] = all_title_type_data[key];

    // 捕鱼
    if (by_keys.includes(_include_))
      title_type_list[0].title_list.by[key] = all_title_type_data[key];

    // 综合
    if (common_keys.includes(_include_))
      title_type_list[0].title_list.common[key] = all_title_type_data[key];

    // 联赛
    if (competition_keys.includes(_include_))
      title_type_list[1].title_list[key] = all_title_type_data[key];

    // 活动
    if (active_keys.includes(_include_))
      title_type_list[2].title_list[key] = all_title_type_data[key];
  }
};

const changePlayerAchievement = async () => {
  // 找到对应数据
  let ir = null;

  if (active_title_type.value == 0) {
    ir =
      title_type_list[active_title_type.value].title_list[
        cur_type2_active.value
      ][cur_index.value];
  } else {
    ir = title_type_list[active_title_type.value].title_list[cur_index.value];
  }

  // 取消佩戴
  if (store.userInfo.select_title == cur_index.value) {
    const ccparams = {
      msg_type: "select_player_title",
      index: 0,
    };
    const cres = await asyncSendInstructions(ccparams);

    if (cres) {
      store.$patch((state) => {
        state.userInfo.select_title = 0;
        state.userInfo.select_head_kuang.type = cres.type;
        state.userInfo.select_head_kuang.level = cres.level;
      });
    }
    cur_index.value = 0;
    return;
  }

  // 没有对应数据
  if (!ir) {
    showToast({
      message: "请稍后重试",
      type: "fail",
    });
    return;
  }

  if (ir.num < ir.complete) {
    showToast({
      message: "未解锁",
      type: "fail",
    });
    return;
  }

  if (
    (cur_index.value == 7 || cur_index.value == 8 || cur_index.value == 9) &&
    store.userInfo.recharge_level < 7
  ) {
    showToast({
      message: "段位不够",
      type: "fail",
    });
    return;
  }

  // 天梯/联赛等级切换
  if (cur_index.value == 99 || cur_index.value == 98) {
    const ccparams = {
      msg_type: "select_player_head_kuang",
      type: "tianti",
      level: cur_index.value == 98 ? 10 : 15,
    };
    const cres = await asyncSendInstructions(ccparams);

    if (cres) {
      store.$patch((state) => {
        state.userInfo.select_head_kuang.type = cres.type;
        state.userInfo.select_head_kuang.level = cres.level;
      });
    }

    return;
  }

  // 正常成就切换
  const res = await asyncSendInstructions({
    msg_type: "select_player_title",
    index: cur_index.value,
  });

  if (res.error == "ok") {
    store.$patch((state) => {
      state.userInfo.select_title = cur_index.value;
    });

    showToast({
      message: "切换成功",
      type: "success",
    });
  } else {
    showToast({
      type: "fail",
      message: res.error,
    });
  }
};

onBeforeMount(() => {
  get_user_achievement_data();

  if (store.userInfo.select_title > 0) {
    cur_index.value = store.userInfo.select_title;
  }
  // 大师
  if (store.userInfo.level >= 10) {
    title_type_list[3].title_list[98].num = 1;
  }
  // 宗师
  if (store.userInfo.level >= 15) {
    title_type_list[3].title_list[99].num = 1;
  }
});
</script>

<style lang="scss" scoped>
.other_wrapper {
  padding-top: 35px;
  height: 100vh;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/top_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;

  background-color: #3a1b67;

  .exhibition_area {
    position: relative;
    height: 180px;

    .exhibition_head {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 78px;
      height: 78px;
    }
  }

  // .data_list_wrapper {

  // }

  .data_type2_wrapper {
    height: 40px;
    padding: 10px 0 10px 28px;
    display: flex;
    .type2_item {
      padding: 0 10px;
      // margin-right: 16px;
      border-radius: 10px;
      font-size: 12px;
      line-height: 20px;
      white-space: nowrap;
      color: #ffffff60;
      border: 1px solid transparent;
    }

    .type2_item_active {
      color: #fff;
      background-color: rgba($color: #93f6fc, $alpha: 0.3);
      border-color: #93f6fc;
    }
  }

  .data_list {
    position: relative;
    height: calc(100vh - 330px);

    padding: 0 15px;
    .list {
      // display: flex;
      // flex-wrap: wrap;
      height: 100%;
      padding-bottom: 100px;
      overflow: auto;
      // align-items: stretch;
      .list_item {
        position: relative;
        float: left;
        width: calc(50% - 5px);
        height: 140px;
        background-color: #421e77;
        margin-right: 10px;
        border-radius: 10px;
        padding-top: 8px;
        margin-top: 10px;
        border: 1px solid #421e77;
        overflow: hidden;
        .cur_title {
          position: absolute;
          right: 0;
          top: 0;
          width: 54px;
          height: 18px;
          background-color: #e94392;
          color: #fff;
          line-height: 18px;
          text-align: center;
          font-size: 10px;
        }

        .lock_mask {
          position: absolute;
          width: 14px;
          height: 14px;
          left: 10px;
          bottom: 10px;
          // transform: translate(-50%, -50%);
          // background-color: rgba($color: #000000, $alpha: 0.2);
          border-radius: 50%;
          z-index: 3;
          &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 14px;
            height: 14px;
            transform: translate(-50%, -50%);
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/control/lock_icon.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }

        &:nth-child(2n) {
          margin-right: 0;
        }

        .title_cover {
          margin: 0 auto;
          margin-top: 12px;
          width: 110px;
          height: 60px;
        }

        .title_name {
          font-size: 10px;
          text-align: center;
        }

        .title_complete {
          font-size: 10px;
          color: #ffffff60;
          text-align: center;
        }

        .title_time {
          position: absolute;
          left: 0;
          top: 0;
          // min-width: 72px;
          font-size: 8px;
          // width: 80px;
          padding-left: 8px;
          // text-align: center;
          line-height: 20px;
          height: 20px;
          // background-color: #e94392;
          color: #ffffff60;
        }

        .gold_title {
          // position: absolute;
          // left: 0;
          // top: 0;
          // min-width: 72px;
          width: 100%;
          height: 20px;
          // background-color: #e94392;
          color: #fff;
          line-height: 20px;
          text-align: center;
          font-size: 10px;
          display: flex;
          justify-content: center;
          .gold_icon {
            width: 20px;
            height: 20px;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
            background-size: 100% 100%;
            margin-left: 4px;
          }
        }
      }
    }

    .change_achievement_btn {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      width: 272px;
      height: 44px;
      background: linear-gradient(180deg, #a88bff 11%, #7e5df1 100%);
      border-radius: 22px;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
      text-align: center;
      z-index: 10;
    }
  }
}
</style>
