<template>
  <div style="position: relative">
    <NavBar />

    <MenuLc />
    <br />
    <br />
    <br />
    <br />
    <van-button
      style="color: #221c2b; border-radius: 6px; font-weight: bold"
      block
      color="linear-gradient(to bottom, #f0d551, #ff7f00)"
      @click="toggleData"
    >
      切换 {{ cStore.isAccept }}
    </van-button>

    <br />
    <br />

    <van-button
      style="color: #221c2b; border-radius: 6px; font-weight: bold"
      block
      color="linear-gradient(to bottom, #f0d551, #ff7f00)"
      @click="get_sent_data"
    >
      同步获取信息
    </van-button>
    <br />
    <br />

    <van-button
      style="color: #221c2b; border-radius: 6px; font-weight: bold"
      block
      color="linear-gradient(to bottom, #f0d551, #ff7f00)"
      @click="get_sent_data2"
    >
      异步获取信息
    </van-button>

    <h3>将要发送的参数：</h3>
    <div class="">
      <pre style="user-select: auto; font-size: 20px; overflow-x: auto">{{
        JSON.stringify(params, null, 2)
      }}</pre>
    </div>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <pre style="user-select: auto; font-size: 20px; overflow-x: auto">{{
      JSON.stringify(__cctv, null, 2)
    }}</pre>

    <br />
    <br />
    <br />
    <br />

    <van-collapse v-model="activeNames">
      <van-collapse-item
        v-for="(state, index) in states"
        :key="index"
        :title="index"
      >
        <pre style="user-select: auto; font-size: 20px; overflow-x: auto">{{
          JSON.stringify(state, null, 2)
        }}</pre>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import { ref, computed } from "vue";
import { useStore, useCocosData } from "@/store/store";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
import MenuLc from "@/components/MenuLc.vue";
const store = useStore();
const cStore = useCocosData();
const activeNames = ref([]);
const __cctv = ref();

const states = computed(() => {
  if (cStore.isAccept) {
    return cStore.$state;
  } else return store.$state;
});

const toggleData = () => {
  cStore.$patch((state) => {
    state.isAccept = !state.isAccept;
  });
};

const params = {
  msg_type: "get_player_sprog_task",
};

const get_sent_data = async () => {
  const res = await asyncSendInstructions(params);
  __cctv.value = res;
};
const get_sent_data2 = async () => {
  const res = await asyncSendInstructions({
    msg_type: "get_player_sprog_task_reward",
    step: 2,
  });
  __cctv.value = res;
};
// const get_sent_data2 = () => {
//   sendInstructions(params);
// };
</script>
