const game_info_map = {
  mxt: {
    game_name: "超级马戏团",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/px1.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/mxt_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/mxt_tuto.png",
  },
  msqy: {
    game_name: "魔术奇缘",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/msqy_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/msqy_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/mxt_tuto.png",
  },
  msqy_line: {
    game_name: "魔术连线",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/msqy_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/msqy_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/mxt_tuto.png",
  },
  mgc: {
    game_name: "疯狂魔鬼城",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/cover_2.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/mgc_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/mgc_tuto.png",
  },
  bbl: {
    game_name: "宝石爆爆乐",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/bbl/bbl_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/bbl_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/bbl_tuto.png",
  },
  hw3: {
    game_name: "海王III",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/hw3_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/hw3_tuto.png",
  },
  zww: {
    game_name: "抓娃娃",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/zww/zww_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/zww_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/hw3_tuto.png",
  },
  dhf: {
    game_name: "大黄蜂",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/dhf_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/dhf_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/dhf_tuto.png",
  },
  other: {
    game_name: "娱乐街机",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/dhf_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/other_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/dhf_tuto.png",
  },
  nw: {
    game_name: "千鸟之王",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/qnzw_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/daniao_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/dhf_tuto.png",
  },
  ccly: {
    game_name: "虫虫乐园",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/ccly_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/ccly_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/dhf_tuto.png",
  },
  xycs: {
    game_name: "幸运财神",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/xycs_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/xycs_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/dhf_tuto.png",
  },
  go: {
    game_name: "天天向前GO",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/go_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/go_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/go_tuto.png",
  },
  ttq: {
    game_name: "魔法跳跳球",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/ttq_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/ttq_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/ttq_tuto.png",
  },
  fnly: {
    game_name: "飞鸟乐园",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/fnly_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/fnly_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/dhf_tuto.png",
  },
  bird: {
    game_name: "捕鸟系列",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/fnly_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/fnly_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/dhf_tuto.png",
  },
  nwzb: {
    game_name: "鸟王争霸",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/nwzb_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/nwzb_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/dhf_tuto.png",
  },
  yhfh: {
    game_name: "浴火凤凰",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/yhfh_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/yhfh_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/hw3_tuto.png",
  },
  bjwg: {
    game_name: "北极王国",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/bjwg_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/bjwg_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/mxt_tuto.png",
  },
  bbxc: {
    game_name: "百变小丑",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/bbxc_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/bbxc_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/mxt_tuto.png",
  },
  hdcz: {
    game_name: "海盗船长",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/hdcz_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/hdcz_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/mxt_tuto.png",
  },
  bjl: {
    game_name: "疯狂冰淇淋",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/bql_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/bql_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/mxt_tuto.png",
  },
  bql: {
    game_name: "疯狂冰淇淋",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/bql_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/bql_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/mxt_tuto.png",
  },
  xqdz: {
    game_name: "星球大战",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/xqdz_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/xqdz_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/dhf_tuto.png",
  },
  duobao: {
    game_name: "秘境夺宝",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/duobao_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/xqdz_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/dhf_tuto.png",
  },
  hxz: {
    game_name: "花仙子",
    cover:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/hxz_room_cover.png",
    load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/hxz_loding.png",
    tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/dhf_tuto.png",
  },
};

function findGameInfoData(name) {
  return (
    game_info_map[name] ?? {
      game_name: "新街机",
      cover:
        "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/dhf_room_cover.png",
      load: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game_loding/xqdz_loding.png",
      tuto: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/tutorial/dhf_tuto.png",
    }
  );
}

export default findGameInfoData;
