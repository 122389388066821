<template>
  <div class="user_info_wrapper" ref="user_info_wrapper">
    <div class="user_portrait">
      <div class="head">
        <HeadPortrait
          width="100%"
          height="100%"
          :src="store.userInfo.headimgurl"
          :level="Number(store.userInfo.recharge_level)"
          :ae_id="store.userInfo.select_title"
          ae
          fe
        />
      </div>
    </div>

    <div class="user_name_wrapper">
      <p class="name_key">
        <van-text-ellipsis :content="store.userInfo.player_name" />
      </p>
      <p class="uid_key">UID:&nbsp;{{ store.userInfo.uid }}</p>
    </div>

    <div class="user_grade_wrapper">
      <div class="user_grade_content">
        <Level
          :border="false"
          level_type="recharge_level"
          background="rgba(0,0,0,0.4)"
          :level_value="Number(store.userInfo.recharge_level)"
        />

        <Level
          :border="false"
          background="rgba(0,0,0,0.4)"
          :level_value="Number(store.userInfo.level)"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import HeadPortrait from "@/components/HeadPortrait.vue";
import Level from "@/components/Level.vue";
import { onMounted, onBeforeUnmount, ref } from "vue";

import { useStore } from "@/store/store";
const store = useStore();

// const user_info_wrapper = ref();

onMounted(() => {});

onBeforeUnmount(() => {});
</script>

<style lang="scss" scoped>
.user_info_wrapper {
  position: absolute;
  transform-origin: right top;
  display: flex;
  background-color: rgba($color: #000, $alpha: 0.4);
  border-radius: 40px;
  top: 20px;
  left: 20px;
  .user_portrait {
    width: 52px;
    height: 52px;
    position: relative;
    left: -4px;
    top: 0;

    .head {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
    }
  }

  .user_name_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .name_key {
      font-size: 14px;
      font-weight: bold;
      text-wrap: nowrap;
      max-width: 123px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .uid_key {
      font-size: 12px;
      color: rgba($color: #fff, $alpha: 0.6);
    }
  }

  .user_grade_wrapper {
    position: relative;
    // width: 140px;
    // height: 40px;
    padding: 0 12px;

    .user_grade_content {
      display: flex;
      flex-direction: column;
      // height: 80px;
      height: 100%;
      justify-content: space-around;
    }
  }
}
</style>
