<template>
  <div class="room_id_wrapper">
    {{
      findGameInfoData(
        store.current_machine.child_name || store.current_machine.product_name
      ).game_name + store.current_machine.machine_index
    }}号 {{ store.current_machine.exchange }}币房
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import findGameInfoData from "@/utils/gameInfoMap";

const store = useStore();
</script>

<style lang="scss" scoped>
.room_id_wrapper {
  position: absolute;
  top: 16.4918vh;
  left: 15px;

  padding: 0 8px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  background-color: rgba($color: #000, $alpha: 0.4);
  border-radius: 12px;
  font-size: 12px;
  margin-right: 4px;
}
</style>
