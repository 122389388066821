<template>
  <div
    class="dsp_view"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <NavBar fixed />

    <van-image
      :show-loading="false"
      class="aa"
      width="100%"
      height="autu"
      block
      src="https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/active_9_bg.png"
    />
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import NavBar from "@/components/NavBar.vue";

const store = useStore();
</script>

<style lang="scss" scoped>
.aa {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
