<template>
  <LandscapePopup
    :show="store.sprog_task_show"
    position="right"
    @open="whenThePopupOpen"
    @close="whenThePopupClose"
  >
    <div class="sprog_task_wrapper">
      <div class="close_icon" @click="store.sprogTaskShowToggle(false)"></div>
      <div class="count_down_timer">还剩{{ SprogCountDownTimeShow }}</div>
      <div class="_content">
        <div
          class="step_box"
          v-for="boxItem in sprogTaskData"
          :style="{
            left: boxItem.p_x,
            top: boxItem.p_y,
            backgroundPosition: `${boxItem.bg_x} ${
              boxItem.step == current_step
                ? '-5.86667rem'
                : boxItem.step < current_step
                ? '-11.73334rem'
                : 0
            }`,
          }"
        >
          <div
            class="task_bubble"
            @click="handleTaskBubbleClick(boxItem.task_info)"
          ></div>
          <div class="task_btn" v-if="current_step == boxItem.step">
            立即开始
          </div>
        </div>
      </div>
    </div>
  </LandscapePopup>

  <LandscapePopup :show="task_info_show" z-index="2002">
    <div class="task_popup_wrapper">
      <div class="task_content">
        <div class="title">任务内容</div>
        <div class="task_list">
          <div class="task_item" v-for="tItem in cur_task_info_data.task">
            {{ `${tItem.task_t} ${tItem.num}/${tItem.complete_num}` }}
          </div>
        </div>
      </div>

      <div class="task_award_wrapper">
        <div class="title">任务奖励</div>
        <div class="award_list">
          <div
            class="award_item"
            v-for="aItem in cur_task_info_data.task_award"
          >
            <div class="award_icon">
              <van-image
                width="100%"
                height="100%"
                block
                :show-loading="false"
                :src="aItem.award_icon"
              />
            </div>

            <div class="award_num" v-if="aItem.num > 0">x{{ aItem.num }}</div>
          </div>
        </div>
      </div>

      <div
        class="task_popup_close_icon"
        @click="task_info_show_toggle(false)"
      ></div>
      <div class="task_start_btn" v-show="cur_task_info_data.statu < 3">
        开始任务
      </div>
    </div>
  </LandscapePopup>
</template>

<script setup>
import LandscapePopup from "@/components/LandscapePopup.vue";

import { sendInstructions, asyncSendInstructions } from "@/api/sendData";

import { reactive, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store/store";
import { showToast, closeToast } from "vant";
import { useCountDown, useToggle } from "@vant/use";

const store = useStore();
const router = useRouter();

const SprogCountDownTimer = useCountDown({
  time: 604800000,
  onFinish() {
    // store.userInfo.register_time = false;
    store.sprogTaskShowToggle(false);
  },
});

const SprogCountDownTimeShow = computed(() => {
  let _d = SprogCountDownTimer.current.value.days;
  let _h = SprogCountDownTimer.current.value.hours;
  let _m = SprogCountDownTimer.current.value.minutes;
  let _s = SprogCountDownTimer.current.value.seconds;

  _h < 10 && (_h = "0" + _h);
  _m < 10 && (_m = "0" + _m);
  _s < 10 && (_s = "0" + _s);

  return `${_d}天 ${_h}:${_m}:${_s}`;
});

//#region 任务数据
const current_step = ref(1);
const sprogTaskData = reactive([
  {
    step: 1,
    p_x: "5.86667rem",
    p_y: "3.36rem",
    bg_x: 0,
    bubble_x: 0,
    bubble_y: 0,
    task_info: {
      statu: 2,
      task: [
        {
          task_t: "消耗50币",
          complete_num: 3,
          num: 0,
        },
        {
          task_t: "获得1000彩券",
          complete_num: 1000,
          num: 0,
        },
      ],
      task_award: [],
    },
  },
  {
    step: 2,
    p_x: "13.2rem",
    p_y: "4rem",
    bg_x: "-5.86667rem",
    bubble_x: 0,
    bubble_y: 0,
    task_info: {
      statu: 2,
      task: [
        {
          task_t: "获得闪电链锁",
          complete_num: 1,
          num: 0,
        },
        {
          task_t: "击杀骷髅暴君",
          complete_num: 1,
          num: 0,
        },
      ],
      task_award: [],
    },
  },
  {
    step: 3,
    p_x: "20.72rem",
    p_y: "3.36rem",
    bg_x: "-11.73334rem",
    bubble_x: 0,
    bubble_y: 0,
    task_info: {
      statu: 2,
      task: [
        {
          task_t: "消耗300币",
          complete_num: 300,
          num: 0,
        },
        {
          task_t: "获得连线",
          complete_num: 1,
          num: 0,
        },
      ],
      task_award: [],
    },
  },
  {
    step: 4,
    p_x: "28rem",
    p_y: "4rem",
    bg_x: "-17.6rem",
    bubble_x: 0,
    bubble_y: 0,
    task_info: {
      statu: 2,
      task: [
        {
          task_t: "解锁前三个宝箱",
          complete_num: 3,
          num: 0,
        },
      ],
      task_award: [],
    },
  },
  {
    step: 5,
    p_x: "35.28rem",
    p_y: "3.36rem",
    bg_x: "-23.4667rem",
    bubble_x: 0,
    bubble_y: 0,
    task_info: {
      statu: 2,
      task: [
        {
          task_t: "获得太棒啦",
          complete_num: 1,
          num: 0,
        },
        {
          task_t: "获得幸运奖励",
          complete_num: 1,
          num: 0,
        },
      ],
      task_award: [],
    },
  },
  {
    step: 6,
    p_x: "42.5rem",
    p_y: "4rem",
    bg_x: "-29.3333rem",
    bubble_x: 0,
    bubble_y: 0,
    task_info: {
      statu: 2,
      task: [
        {
          task_t: "觉醒一次",
          complete_num: 1,
          num: 0,
        },
        {
          task_t: "击杀雷霸龙",
          complete_num: 1,
          num: 0,
        },
        {
          task_t: "击杀凤凰",
          complete_num: 1,
          num: 0,
        },
      ],
      task_award: [],
    },
  },
  {
    step: 7,
    p_x: "49.8rem",
    p_y: "4rem",
    bg_x: "-35.2rem",
    bubble_x: 0,
    bubble_y: 0,
    task_info: {
      statu: 2,
      task: [
        {
          task_t: "解锁前六个宝箱",
          complete_num: 6,
          num: 0,
        },
      ],
      task_award: [],
    },
  },
  {
    step: 8,
    p_x: "57.2rem",
    p_y: "3.36rem",
    bg_x: "-41.0667rem",
    bubble_x: 0,
    bubble_y: 0,
    task_info: {
      statu: 2,
      task: [
        {
          task_t: "魔鬼城挑战成功",
          complete_num: 1,
          num: 0,
        },
      ],
      task_award: [],
    },
  },
]);

const award_icon_maps = {
  lottery:
    "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png",
  gold: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png",
  jiacheng_card:
    "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png",
  xiaobawang:
    "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png",
};

// 判断是否是普通对象
function isPlainObject(val) {
  return (
    typeof val === "object" &&
    val !== null &&
    Object.prototype.toString.call(val) === "[object Object]"
  );
}

const initSprogTaskData = async () => {
  const res = await asyncSendInstructions({
    msg_type: "get_player_sprog_task",
  });

  console.log("resssssssssssssssss", res);

  if (!res.task_def || !res.player_sprog_task) return;

  const { task_def, player_sprog_task } = res;

  current_step.value = player_sprog_task.step;

  task_def.forEach((task_item, task_step) => {
    // 奖励列表
    const _task_award = [];
    task_item.reward.forEach((task_reward_item) => {
      let _type = task_reward_item.reward_type;
      let _num = task_reward_item.num ?? task_reward_item.complete_num ?? 0;
      if (_type == "item") {
        _type = task_reward_item.item_type;
      }
      if (_type == "chenghao") _type = task_reward_item.title_name;

      // console.log("_type_type_type", _type);
      _task_award.push({
        type: _type,
        award_icon: award_icon_maps[_type],
        num: _num,
      });
    });

    sprogTaskData[task_step].task_info.task_award = _task_award;

    const player_task = player_sprog_task.task[task_step];
    for (const _k in player_task) {
      const pTask = player_task[_k];
      const pIndex = Number(_k) - 1;
      if (sprogTaskData[task_step].task_info.task[pIndex]) {
        sprogTaskData[task_step].task_info.task[pIndex].complete_num =
          pTask.complete_num;

        sprogTaskData[task_step].task_info.task[pIndex].num = pTask.num;
      }
    }
  });
};

//#endregion

//#region 任务弹窗
const [task_info_show, task_info_show_toggle] = useToggle(false);
const cur_task_info_data = ref({
  statu: 3,
  task: [
    {
      task_t: "解锁前六个宝箱",
      complete_num: 6,
      num: 1,
    },
  ],
  task_award: [
    {
      type: "gold",
      num: 998,
      award_icon:
        "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png",
    },
  ],
});

const handleTaskBubbleClick = (task_info) => {
  cur_task_info_data.value = task_info;
  task_info_show_toggle(true);
};

//#endregion

const whenThePopupOpen = () => {
  initSprogTaskData();

  SprogCountDownTimer.start();
};
const whenThePopupClose = () => {
  console.log("关闭----");
  SprogCountDownTimer.pause();
};
</script>

<style lang="scss" scoped>
.task_popup_wrapper {
  position: absolute;
  z-index: 200;
  left: 50%;
  top: 42px;
  transform: translateX(-50%);
  width: 310px;
  height: 215px;
  background-image: url("@/assets/images/sprog/task_pop_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // background-color: pink;
  .title {
    font-size: 12px;
    text-align: center;
  }
  .task_content {
    position: absolute;
    width: 280px;
    // background-color: pink;
    top: 60px;
    left: 15px;
    padding: 14px;

    .task_list {
      margin-top: 6px;
      display: flex;
      justify-content: center;
      .task_item {
        white-space: nowrap;
        font-size: 10px;
        margin: 0 4px;
      }
    }
  }

  .task_award_wrapper {
    position: absolute;

    width: 280px;
    // background-color: pink;
    top: 104px;
    left: 15px;
    padding: 14px;

    .award_list {
      padding: 0 8px;
      display: flex;
      margin-top: 6px;
      justify-content: center;
      .award_item {
        position: relative;
        width: 44px;
        height: 44px;
        background: #ca3edd;
        box-shadow: inset 0px 0px 5px 4px #bc1ecb;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #ff50f4;
        margin: 0 5px;

        .award_icon {
          position: absolute;
          width: 26px;
          height: 26px;
          top: 2px;
          left: 50%;
          transform: translateX(-50%);
        }

        .award_num {
          position: absolute;
          right: 2px;
          bottom: 0;
          font-size: 10px;
        }
      }
    }
  }

  .task_popup_close_icon {
    position: absolute;
    right: 0;
    top: 26px;
    width: 26px;
    height: 26px;
    transform: translateX(36px);
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .task_start_btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 120px;
    height: 32px;
    transform: translate(-50%, 50px);
    background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
    border-radius: 16px;
    line-height: 32px;
    text-align: center;
    color: #693015;
    font-size: 16px;
  }
}

.sprog_task_wrapper {
  width: 100%;
  height: 100%;
  // position: absolute;
  // left: 0;
  // top: 0;
  // width: 100vh;
  // height: 100vw;
  // transform: rotate(90deg) translateY(-100%);
  // transform-origin: left top;

  // overflow: hidden;

  overflow-x: auto;

  .count_down_timer {
    position: absolute;
    bottom: 20px;
    left: 40px;
    width: 148px;
    height: 30px;
    color: #724026;
    line-height: 38px;
    text-align: center;
    font-size: 12px;
    background-image: url("@/assets/images/sprog/time_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    z-index: 3;
  }

  .close_icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 40px;
    background-size: 100% 100%;
    background-image: url("@/assets/images/navbar_back_icon.png");
    background-repeat: no-repeat;
    z-index: 3;
  }

  ._content {
    position: relative;
    width: 2446px;
    height: 100%;
    background-image: url("@/assets/images/sprog/bg_hr.png"),
      url("@/assets/images/sprog/bg_fuzzy.png");
    background-size: auto 100%;
    background-repeat: no-repeat;

    .step_box {
      position: absolute;

      width: 220px;
      height: 220px;
      background-image: url("@/assets/images/sprog/boxs.png");
      background-size: 1760px auto;
      background-repeat: no-repeat;
      background-position: 0 0px;
      // background-color: pink;

      .task_bubble {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -60px);
        width: 100px;
        height: 103px;
        background-image: url("@/assets/images/sprog/task_bubbles.png");
        background-size: 500px auto;
        background-repeat: no-repeat;
        background-position: 0 0px;
      }

      .task_btn {
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
        width: 110px;
        height: 34px;
        background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
        border-radius: 18px;
        text-align: center;
        line-height: 34px;
        font-size: 16px;
        color: #724026;
      }
    }
  }
}
</style>
