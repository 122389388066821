<template>
  <div
    class="set_view"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <van-nav-bar
      title="设置"
      left-arrow
      @click-left="router.back()"
      :border="false"
      :placeholder="true"
    />

    <div class="container">
      <van-config-provider :theme-vars="themeVars">
        <van-cell-group :border="false">
          <!-- id -->
          <van-cell
            :value="store.userInfo.uid"
            is-link
            :border="false"
            :clickable="false"
            @click="handleCopyValueClick"
          >
            <template #title>
              <div class="cell_right_title">
                <div class="cell_right_icon">
                  <van-image
                    width="100%"
                    height="100%"
                    round
                    :src="cell_icon_1"
                  />
                </div>
                <div class="cell_right_text">我的UID</div>
              </div>
            </template>
            <template #right-icon>
              <div class="w-20 h-28 relative">
                <van-icon
                  style="
                    position: absolute !important;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  "
                  :name="copy_icon"
                />
              </div>
            </template>
          </van-cell>
          <!-- 头像 -->
          <van-cell
            :value="store.userInfo.uid"
            is-link
            :border="false"
            :clickable="false"
            @click="cellEventClick('head')"
          >
            <template #title>
              <div class="cell_right_title">
                <div class="cell_right_icon">
                  <van-image
                    width="100%"
                    height="100%"
                    round
                    :src="cell_icon_2"
                  />
                </div>
                <div class="cell_right_text">我的头像</div>
              </div>
            </template>

            <template #value>
              <div class="cell_right_value">
                <van-image
                  width="100%"
                  height="100%"
                  round
                  :src="store.userInfo.headimgurl || user_head_img"
                />
              </div>
            </template>
          </van-cell>
          <!-- 游戏昵称 -->
          <van-cell
            :value="
              store.userInfo.player_name.length > 8
                ? store.userInfo.player_name.substring(0, 8) + '...'
                : store.userInfo.player_name
            "
            is-link
            :border="false"
            :clickable="false"
            @click="cellEventClick('name')"
          >
            <template #title>
              <div class="cell_right_title">
                <div class="cell_right_icon">
                  <van-image
                    width="100%"
                    height="100%"
                    round
                    :src="cell_icon_3"
                  />
                </div>
                <div class="cell_right_text">游戏昵称</div>
              </div>
            </template>
          </van-cell>

          <!-- 隐私政策 -->
          <van-cell
            value=""
            is-link
            :border="false"
            :clickable="false"
            @click="cellEventClick('privacyPolicy')"
          >
            <template #title>
              <div class="cell_right_title">
                <div class="cell_right_icon">
                  <van-image
                    width="100%"
                    height="100%"
                    round
                    :src="cell_icon_7"
                  />
                </div>
                <div class="cell_right_text">隐私政策</div>
              </div>
            </template>
          </van-cell>

          <!-- 用户协议 -->
          <van-cell
            value=""
            is-link
            :border="false"
            :clickable="false"
            @click="cellEventClick('userAgreement')"
          >
            <template #title>
              <div class="cell_right_title">
                <div class="cell_right_icon">
                  <van-image
                    width="100%"
                    height="100%"
                    round
                    :src="cell_icon_8"
                  />
                </div>
                <div class="cell_right_text">用户协议</div>
              </div>
            </template>
          </van-cell>

          <!-- 注销账号 -->
          <van-cell
            is-link
            :border="false"
            :clickable="false"
            @click="cellEventClick('logout')"
          >
            <template #title>
              <div class="cell_right_title">
                <div class="cell_right_icon">
                  <van-image
                    width="100%"
                    height="100%"
                    round
                    :src="cell_icon_6"
                  />
                </div>
                <div class="cell_right_text">注销账号</div>
              </div>
            </template>
          </van-cell>

          <!-- 推送 -->
          <!-- <van-cell :border="false" :clickable="false">
            <template #title>
              <div class="cell_right_title">
                <div class="cell_right_icon">
                  <van-image
                    width="100%"
                    height="100%"
                    round
                    :src="cell_icon_4"
                  />
                </div>
                <div class="cell_right_text">游戏通知</div>
              </div>
            </template>
            <template #right-icon>
              <van-switch
                size="22px"
                active-color="#7BAFFF"
                inactive-color="#909090"
                v-model="pushMsgStatu"
              />
            </template>
          </van-cell>
          <van-cell :border="false" :clickable="false">
            <template #title>
              <div class="cell_right_title">
                <div class="cell_right_icon">
                  <van-image
                    width="100%"
                    height="100%"
                    round
                    :src="cell_icon_5"
                  />
                </div>
                <div class="cell_right_text">挑战推送</div>
              </div>
            </template>
            <template #right-icon>
              <van-switch
                size="22px"
                active-color="#7BAFFF"
                inactive-color="#909090"
                v-model="pushChallengMsgStatu"
              />
            </template>
          </van-cell> -->
        </van-cell-group>
      </van-config-provider>
    </div>

    <div class="exit_login_btn" @click="exitLogin">退出登录</div>
    <div class="copyright_wrapper">Copyright © 2023 蔚品网络科技</div>
    <van-popup
      v-model:show="changeInfoShow"
      position="bottom"
      round
      closeable
      :close-on-click-overlay="false"
      :style="{ height: '36%' }"
      @click-close-icon="closeIconClick"
    >
      <div class="change_info_wrapper">
        <div class="title">
          <span v-if="infoType == 'head'">更换头像</span>
          <span v-if="infoType == 'name'">修改昵称</span>
        </div>

        <div class="content">
          <div class="change_head_img" v-if="infoType == 'head'">
            <van-image
              width="100%"
              round
              height="100%"
              :src="'data:image/png;base64,' + head_pic"
            />
          </div>
          <div class="change_user_name_wrapper" v-if="infoType == 'name'">
            <van-config-provider :theme-vars="themeVars">
              <van-cell-group inset>
                <van-field
                  v-model="newUserName"
                  maxlength="6"
                  :placeholder="store.userInfo.player_name"
                />
              </van-cell-group>
            </van-config-provider>
          </div>
        </div>
        <div class="bottom_btn_wrapper">
          <div class="bottom_btn" @click="infoChangeConfirm">确认修改</div>
        </div>
      </div>
    </van-popup>

    <!-- 注销账号 -->
    <van-popup v-model:show="logoutShow" round :close-on-click-overlay="false">
      <div class="logout_wrapper">
        <p class="logout_title">温馨提示</p>
        <p class="logout_content">
          一旦确认注销账户， 您账号下的所有虚拟资产将全部清除，无法恢复！
        </p>
        <div class="logout_btns">
          <div
            class="logout_btn"
            @click="
              () => {
                logoutShow = false;
              }
            "
          >
            取消
          </div>
          <div
            class="logout_btn"
            style="
              background-image: linear-gradient(
                90deg,
                rgb(223, 100, 100) 0%,
                #ee0a24 100%
              );
            "
            @click="confirmLogout"
          >
            确认注销
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useStore } from "@/store/store";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";

import useClipboard from "vue-clipboard3";
// import { encode } from "js-base64";
import { headSculpture } from "@/api/index";
import { ref, watch, reactive, onBeforeUnmount } from "vue";
import { closeToast, showToast, showConfirmDialog } from "vant";
import { validateUsername } from "@/utils/utils";

const store = useStore();
const router = useRouter();
const changeInfoShow = ref(false);
//const pushMsgStatu = ref(false);
// const pushChallengMsgStatu = ref(false);
const logoutShow = ref(false);

const cell_icon_1 = store.imgBaseUrl + "/myview/cell_icon_1.png";
const cell_icon_2 = store.imgBaseUrl + "/myview/cell_icon_2.png";
const cell_icon_3 = store.imgBaseUrl + "/myview/cell_icon_3.png";
// const cell_icon_4 = store.imgBaseUrl +  "/myview/cell_icon_4.png";
// const cell_icon_5 = store.imgBaseUrl +  "/myview/cell_icon_5.png";
const cell_icon_6 = store.imgBaseUrl + "/myview/cell_icon_6.png";
import cell_icon_7 from "@/assets/images/cell_icon_7.png";
import cell_icon_8 from "@/assets/images/cell_icon_8.png";

const copy_icon = store.imgBaseUrl + "/myview/copy_icon.png";
const user_head_img = store.imgBaseUrl + "/user_info.png";

const { toClipboard } = useClipboard();

const themeVars = reactive({
  cellBackground: "none",
  cellGroupBackground: "none",
  cellTextColor: "#fff",
  cellLineHeight: "30px",
  cellValueColor: "#fff",
  fieldInputTextColor: "#3e4056",
});

const infoType = ref("head");

const newUserName = ref("");

const handleCopyValueClick = async () => {
  try {
    await toClipboard(store.userInfo.uid);
    showToast({
      type: "success",
      message: "复制成功!",
    });
  } catch (e) {
    console.error(e);
    showToast({
      type: "fail",
      message: "复制失败!",
    });
  }
};

const exitLogin = () => {
  sendInstructions(
    {
      msg_type: "restart_game",
    },
    0
  );
};

const head_pic = ref("");

const cellEventClick = async (type = "head") => {
  infoType.value = type;
  if (type == "head") {
    if (store.userInfo.is_custom_head) {
      showToast({
        type: "fail",
        message: "修改次数上限",
      });
      return;
    }

    const res = await asyncSendInstructions({
      msg_type: "get_head_pic",
    });

    head_pic.value = res;
    changeInfoShow.value = true;

    return;
  }

  if (type == "logout") {
    showConfirmDialog({
      className: "hh_dialog",
      title: "温馨提示",
      message:
        "一旦确认注销账户， 您账号下的所有虚拟资产将全部清除，无法恢复！",
      theme: "round-button",
      showCancelButton: true,
    })
      .then(() => {
        // on confirm
        // 发送指令
        sendInstructions(
          {
            msg_type: "delete_account",
          },
          0
        );
      })
      .catch(() => {
        console.log("cancel-------->");
        // on cancel
      });
  }

  if (type == "name") {
    if (store.userInfo.is_custom_name) {
      showToast({
        type: "fail",
        message: "修改次数上限",
      });
      return;
    }
    changeInfoShow.value = true;
  }

  if (type == "privacyPolicy") {
    router.push("/privacyPolicy");
  }
  if (type == "userAgreement") {
    router.push("/userAgreement");
  }
};

// 确认注销
const confirmLogout = () => {
  // 发送指令
  sendInstructions(
    {
      msg_type: "delete_account",
    },
    0
  );
  // 回到登录界面
};

const closeIconClick = () => {
  head_pic.value = "";
};

const infoChangeConfirm = async () => {
  if (infoType.value == "head") upDataUserHeard();
  if (infoType.value == "name") {
    if (!validateUsername(newUserName.value)) {
      showToast({
        type: "fail",
        message: "不合法昵称",
      });
      return;
    }

    const res = await asyncSendInstructions({
      msg_type: "change_name",
      player_name: newUserName.value,
    });

    if ((res.error == "error" || res.error != "ok") && res.error != "") {
      showToast({
        type: "fail",
        message: "修改失败",
      });
    } else if (res.error == "ok") {
      showToast({
        type: "success",
        message: "修改成功",
      });
      store.$patch((state) => {
        state.userInfo.player_name = newUserName.value;
      });
      changeInfoShow.value = false;
      newUserName.value = "";
    }
  }
};

//  发送更改头像请求
const upDataUserHeard = async () => {
  if (head_pic.value == "") return;

  showToast({
    type: "loading",
    forbidClick: true,
  });
  const params = {
    uid: parseInt(store.userInfo.uid),
    img: head_pic.value,
  };

  try {
    const { state, msg, headimgurl } = await headSculpture(
      params,
      "/imageUpload/headSculpture"
    );

    if (msg == "ok" || state == 0) {
      closeToast();

      store.$patch((state) => {
        state.userInfo.headimgurl = headimgurl + "?random=" + Math.random();
      });
      head_pic.value = "";
      changeInfoShow.value = false;
      showToast({
        type: "success",
        message: "修改成功",
      });
    }
  } catch (error) {
    closeToast();
    showToast({
      type: "fail",
      message: "网络错误",
    });
  }
};
</script>

<style lang="scss" scoped>
.set_view {
  height: 100%;
  min-height: 100vh;
  position: relative;

  .copyright_wrapper {
    position: absolute;
    bottom: 6%;
    left: 50%;
    transform: translateX(-50%);
  }
  .exit_login_btn {
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    width: 238px;
    text-align: center;
    line-height: 40px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/myview/change_info_btn.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #1d0053;
    font-size: 16px;
    font-weight: bold;
  }
}
.cell_right_title {
  display: flex;

  .cell_right_icon {
    width: 30px;
    height: 30px;
  }

  .cell_right_text {
    margin-left: 8px;
    line-height: 30px;
    font-size: 14px;
  }
}

.cell_right_value {
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
}

.change_info_wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  height: 100%;
  background-color: #f7f8fa;
  .title {
    text-align: center;
    padding: 8px 0;
    span {
      font-weight: 800;
      font-size: 20px;
      background: linear-gradient(90deg, #3967ff 0%, #7091ff 44%, #8c85ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .content {
    flex: 1;
    position: relative;
    .change_head_img {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100px;
      height: 100px;
      transform: translate(-50%, -50%);
    }

    .change_user_name_wrapper {
      position: absolute;

      top: 50%;
      width: 100%;
      transform: translateY(-50%);
    }
  }

  .bottom_btn_wrapper {
    height: 40px;

    .bottom_btn {
      width: 238px;
      text-align: center;
      line-height: 40px;
      margin: 0 auto;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/myview/change_info_btn.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: #1d0053;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.logout_wrapper {
  width: 90vw;
  padding-top: 30px;
  padding-bottom: 20px;
  // height: 200px;
  background: linear-gradient(180deg, #bbd8f9 0%, #ffffff 58%, #eef6f9 100%);
  text-align: center;

  .logout_title {
    font-size: 24px;
    background: linear-gradient(90deg, #3967ff 0%, #7091ff 44%, #8c85ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 1000;
  }

  .logout_content {
    margin-top: 20px;
    color: #3e4056;
    font-size: 16px;
    margin-bottom: 34px;
    padding: 0 10px;
  }

  .logout_btns {
    display: flex;
    justify-content: center;
    .logout_btn {
      width: 40%;
      height: 40px;
      background: linear-gradient(180deg, #81c3ff 0%, #5034ff 100%);
      margin-right: 10px;
      line-height: 40px;
      border-radius: 20px;
    }
  }
}
</style>
