<template>
  <div
    class="level_wrapper"
    :style="{
      minWidth: props.width,
      height: props.height,
      borderRadius: radiusValue + 'px',
      border: props.border ? '1px solid #9a44e7' : '',
      display: props.block ? 'block' : 'inline-block',
      background:
        props.background != 'none' ? props.background : 'rgba(0,0,0,0.4)',
    }"
  >
    <div class="level_content">
      <div class="level_icon">
        <van-image
          width="100%"
          height="100%"
          block
          :show-loading="false"
          :src="store.gradLevels[_level].iconUrl"
          v-if="props.level_type == 'recharge_level'"
        />
        <van-image
          width="100%"
          height="100%"
          block
          :show-loading="false"
          :src="rankLevelIcon[_level].iconUrl"
          v-else
        />
      </div>
      <div
        class="level_text"
        :style="{
          lineHeight: lineHeightValue + 'px',
          color: props.color,
        }"
      >
        <span v-if="props.level_type == 'recharge_level'">{{
          store.gradLevels[_level].level_text
        }}</span>
        <span v-else>{{ rankLevelIcon[_level].level_text }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { rankLevelIcon } from "@/utils/levelData";
import { computed } from "vue";
import { useStore } from "@/store/store";
const store = useStore();
const props = defineProps({
  width: {
    type: String,
    default: "30px",
  },
  height: {
    type: String,
    default: "14px",
  },
  level_type: {
    // level  or  recharge_level
    type: String,
    default: "level",
  },
  level_value: {
    type: Number,
    default: 1,
  },
  border: {
    type: Boolean,
    default: false,
  },
  block: {
    type: Boolean,
    default: false,
  },
  background: {
    type: String,
    default: "rgba(0,0,0,0.4)",
  },
  color: {
    type: String,
    default: "#Fff",
  },
});

const radiusValue = computed(() => parseInt(props.height) / 2);
const lineHeightValue = computed(() => {
  if (props.border) return parseInt(props.height) - 2;
  if (!props.border) return parseInt(props.height);
});

const _level = computed(() => {
  return props.level_value - 1 <= 0 ? 0 : props.level_value - 1;
});
</script>

<style scoped lang="scss">
.level_wrapper {
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  line-height: 14px;

  .level_content {
    display: flex;
    width: 100%;
    height: 100%;

    padding: 0 4px;

    justify-content: center;
    align-items: center;

    .level_icon {
      // margin-top: 2px;
      width: 12px;
      height: 12px;
    }

    .level_text {
      font-size: 10px;
      padding-left: 2px;
    }
  }
}
</style>
